import 'react-datepicker/dist/react-datepicker.css'

import IconInfo from 'assets/icons/icon-info-circle.svg'
import ColCs from 'components/ColCs'
import { DatePickerDiv, FormGroup, FormInput, FormLabel, InputContainer, WarningDefineTime } from 'components/Forms'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import RowCs from 'components/RowCs'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import styled from 'styled-components/macro'

import { ButtonSecondary } from '../../../../components/Button'
import IconPortal from '../../../../components/IconPortal'
import ModalConfirmSetRedemption from '../../../../components/Modal/ModalConfirm'
import { OptionType } from '../../../../entities/OptionType'
import { useApi } from '../../../../hooks/useApi'
import { useGetCollectionsQuery, useGetRedemptionWindowQuery } from '../../../../state/collections/slice'
import { customStylesSelect } from '../../../../theme'
import { checkDateGreater } from '../../../../utils/helper'

const Redemption = (props: any) => {
  const { selectedCollection, setSelectedCollection, isSelectDisabled } = props
  const { data: collections } = useGetCollectionsQuery({})

  const { value: collectionAddress } = selectedCollection
  const {
    data: redemptionWindow,
    isLoading: redemptionWindowLoding,
    refetch: refetchRedemptionWindow,
  } = useGetRedemptionWindowQuery(
    {
      address: collectionAddress,
    },
    { skip: !collectionAddress }
  )

  const { post } = useApi()
  const [showError, setShowError] = useState<boolean>(false)
  const [collectionOptions, setCollectionOptions] = useState<OptionType[]>([])
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [errorMessage, setErrormessage] = useState<string>('')
  const today = moment.utc().format('YYYY-MM-DD HH:mm:ss')
  const todayUTC = new Date(today)
  const todayUTCHours = todayUTC.getHours()
  const [startDate, setStartDate] = useState<Date | null>(todayUTC)
  const [endDate, setEndDate] = useState<Date | null>(todayUTC)
  const [redemptionPrice, setRedemptionPrice] = useState<string | null>('0')
  const [checkEndDate, setCheckEndDate] = useState<boolean>(false)
  const [checkStartDate, setCheckStartDate] = useState<boolean>(false)

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date)
    const { greaterDay, greaterHour }: any = checkDateGreater(date, todayUTC)
    if (greaterDay) {
      setCheckStartDate(true)
    } else if (!greaterDay && greaterHour) {
      setCheckStartDate(false)
    } else {
      setCheckStartDate(false)
      setStartDate(todayUTC)
    }
  }
  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date)
    const { greaterDay, greaterHour }: any = checkDateGreater(date, todayUTC)
    if (greaterDay) {
      setCheckEndDate(true)
    } else if (!greaterDay && greaterHour) {
      setCheckEndDate(false)
    } else {
      setCheckEndDate(false)
      setEndDate(todayUTC)
    }
  }

  const handleSetRedemption = useCallback(() => {
    post(
      {
        url: process.env.REACT_APP_SERVER_URL + `redemption`,
        data: {
          collectionAddress: selectedCollection.value,
          timeFrom: moment(startDate).toDate().getTime() / 1000,
          timeTo: moment(endDate).toDate().getTime() / 1000,
          redemptionPrice: redemptionPrice ?? redemptionWindow?.redemptionPrice,
        },
        callback: () => {
          setShowSuccess(true)
          refetchRedemptionWindow()
        },
        callbackFinally: () => {
          setShowConfirm(false)
        },
        errorCallback: (res) => {
          console.log(res)
          setErrormessage(res.response.data)
          setShowError(true)
        },
      },
      true
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionAddress, startDate, endDate, redemptionPrice])

  useEffect(() => {
    if (collections) {
      const collectionOptionsList: OptionType[] = []
      for (let i = 0; i < collections.length; i++) {
        const collection = collections[i]
        collectionOptionsList.push({
          value: collection.address,
          label: collection.name,
        })
      }
      setCollectionOptions(collectionOptionsList)
    }
  }, [collections, setSelectedCollection])

  useEffect(() => {
    if (!redemptionWindowLoding && collectionAddress) {
      setStartDate(moment.utc(redemptionWindow?.timeFrom ?? todayUTC, 'X').toDate())
      setEndDate(moment.utc(redemptionWindow?.timeTo ?? todayUTC, 'X').toDate())
      setRedemptionPrice(redemptionWindow?.redemptionPrice ?? 0)
    }
  }, [redemptionWindow, redemptionWindowLoding, collectionAddress])

  return (
    <RedemptionWrapper>
      <NameSection>Redemption Window</NameSection>
      {selectedCollection?.value &&
        ((isSelectDisabled && <h3>{selectedCollection.label}</h3>) || (
          <SectionSelect>
            <Select
              styles={customStylesSelect}
              defaultValue={selectedCollection}
              options={collectionOptions}
              classNamePrefix={'select-cs'}
              id="filter"
              name="filter"
              onChange={(value) => {
                if (!value) {
                  return
                }
                console.log(8)

                setSelectedCollection(value)
              }}
            />
          </SectionSelect>
        ))}
      <RedemptionContainer>
        <DivRedemption>
          <RowCs space={-16}>
            <ColCs xs={6} gutter={16}>
              <FormLabel className={'time-utc'}>Start time</FormLabel>
              <DatePickerDiv>
                <LabelBox>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    showTimeSelect
                    minDate={todayUTC}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minTime={
                      checkStartDate
                        ? setHours(setMinutes(todayUTC, 0), 0)
                        : setHours(setMinutes(todayUTC, 30), todayUTCHours)
                    }
                    maxTime={setHours(setMinutes(todayUTC, 30), 23)}
                  />
                </LabelBox>
              </DatePickerDiv>
            </ColCs>
            <ColCs xs={6} gutter={16}>
              <FormLabel className={'time-utc'}>End time</FormLabel>
              <DatePickerDiv>
                <LabelBox>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    showTimeSelect
                    minDate={todayUTC}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minTime={
                      checkEndDate
                        ? setHours(setMinutes(todayUTC, 0), 0)
                        : setHours(setMinutes(todayUTC, 30), todayUTCHours)
                    }
                    maxTime={setHours(setMinutes(todayUTC, 30), 23)}
                  />
                </LabelBox>
              </DatePickerDiv>
            </ColCs>
          </RowCs>
          <WarningDefineTime>
            <IconPortal widthIcon={'20px'} heightIcon={'20px'} SrcImageIcon={IconInfo} />
            <span>The defined dates are set based on UTC timestamp</span>
          </WarningDefineTime>
          <RowCs space={-16} className={'cta-redemption-group'}>
            <ColCs xs={12} gutter={16}>
              <FormGroup>
                <InputContainer>
                  <FormInput
                    placeholder={'Enter redemption price'}
                    id={'redemptionPrice'}
                    name={'redemptionPrice'}
                    value={redemptionPrice ?? '0'}
                    onChange={(e) => {
                      setRedemptionPrice(e.target.value)
                    }}
                  />
                  USDT
                </InputContainer>
              </FormGroup>
            </ColCs>
          </RowCs>
          <RowCs space={-16} className={'cta-redemption-group'}>
            <ColCs xs={6} gutter={16}>
              <CtaSetRedemption onClick={() => setShowConfirm(true)}>SET THE REDEMPTION WINDOW</CtaSetRedemption>
            </ColCs>
          </RowCs>
        </DivRedemption>
        {/*<TableRedemption />*/}
      </RedemptionContainer>
      {showConfirm && (
        <ModalConfirmSetRedemption
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={handleSetRedemption}
          subText={'Are you sure you want to update the redemption window?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={errorMessage} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={'The redemption window for this collection has been successfully set.'}
      />
    </RedemptionWrapper>
  )
}

const RedemptionWrapper = styled.div`
  width: 100%;
`
const RedemptionContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  .cta-redemption-group {
    margin-top: 32px;
  }
`
const DivRedemption = styled.div`
  margin-bottom: 32px;
`
const NameSection = styled.h3`
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`
const CtaRedemption = styled(ButtonSecondary)`
  padding: 10px 24px;
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 600;
  line-height: 1.5;
`
const CtaSetRedemption = styled(CtaRedemption)`
  display: flex;
  gap: 16px;
  color: ${({ theme }) => theme.secondary1};
  background-color: ${({ theme }) => theme.primary6};
`
const SectionSelect = styled.div`
  width: 100%;
  //max-width: 450px;
  margin-bottom: 24px;
`

const LabelBox = styled.label``
export default Redemption
