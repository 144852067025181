import { useState } from 'react'
import { SingleValue } from 'react-select'
import styled from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import { OptionType } from '../../../../entities/OptionType'
import { useGetAttributesAssignQuery } from '../../../../state/assignAttributes/slice'
import FormAssign from './FormAssign'
import TableAssign from './TableAssign'

const AssignAttributes = (props: any) => {
  const { collectionOptions, selectedCollection, setSelectedCollection, isSelectDisabled } = props
  const [term, setTerm] = useState<string>('')
  const [keyword] = useDebounce(term, 500)
  const handleChangeSelectedCollection = (collection: SingleValue<OptionType>) => {
    setSelectedCollection(collection as OptionType)
  }

  const { data, isLoading, refetch } = useGetAttributesAssignQuery(
    {
      collectionAddress: selectedCollection.value,
      keyword,
    },
    { skip: !selectedCollection.value }
  )

  const onSearch = (term: string) => {
    setTerm(term)
  }

  return (
    <Wrapper>
      <Title>Setting Attributes</Title>
      <FormAssign
        collectionOptions={collectionOptions}
        selectedCollection={selectedCollection}
        refetch={refetch}
        handleChangeSelectedCollection={handleChangeSelectedCollection}
        profile={props.profile}
        isSelectDisabled={isSelectDisabled}
      />
      <TableAssign data={data} isLoading={isLoading} onSearch={onSearch} collectionAddress={selectedCollection.value} />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const Title = styled.h3`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`

export default AssignAttributes
