import React from 'react'

import { Address } from '../../../../components/Address'
import { DateWithCalendar } from '../../../../components/DateWithCalendar'
import { formatDateFromTimestamp } from '../../../../utils/formatDate'
import { POAP } from '../../models/POAP'
import placeholderImage from './assets/placeholder.png'
import * as Styled from './styled'

interface POAPItemProps {
  poap: POAP
}

export const POAPItem = ({ poap }: POAPItemProps) => {
  return (
    <Styled.POAPItemContainer>
      <Styled.POAPDetailsContainer>
        <Styled.POAPImage src={poap.event.imageKey || placeholderImage} />
        <Styled.POAPEventName> {poap.event.name} </Styled.POAPEventName>
        <Address address={poap.event.formattedAddress} />
        <DateWithCalendar date={formatDateFromTimestamp(poap.event.endTime)} />
      </Styled.POAPDetailsContainer>
    </Styled.POAPItemContainer>
  )
}
