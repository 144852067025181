import { useQueryClient } from '@tanstack/react-query'
import { ButtonPrimary, ButtonSecondary, ButtonTransparent } from 'components/Button'
import Loader from 'components/Loader'
import { QueryKeys } from 'constants/queryKeys'
import { useCancelEventInRegistry } from 'modules/Events/hooks/useCancelEventInRegistry'
import { useUpdateEvent } from 'modules/Events/hooks/useUpdateEvent'
import { Event } from 'modules/Events/models/Event'
import { useRef } from 'react'

import IconClose from '../../../../assets/images/svg/icon-close.svg'
import IconPortal from '../../../../components/IconPortal'
import Modal from '../../../../components/Modal'
import * as Styled from './styled'

interface CancelEventModalProps {
  isOpen: boolean
  onClose: () => void
  event: Event | undefined
  partnerAddress: string
}

export const CancelEventModal = ({ isOpen, onClose, event, partnerAddress }: CancelEventModalProps) => {
  const cancelBtn = useRef<HTMLButtonElement | null>(null)

  const { mutateAsync: cancelEventInRegistry, isLoading: isCancellingEventInRegistry } = useCancelEventInRegistry()
  const { mutateAsync: editEvent, isLoading: isEditingEvent } = useUpdateEvent()
  const queryClient = useQueryClient()

  // `event` will always be there, this is only to satisfy TypeScript
  if (!event) {
    onClose()
  }

  const handleCancelEvent = async () => {
    if (!event?.eventId) return

    await cancelEventInRegistry({ eventId: Number(event.eventId), isCancelled: true })
    await editEvent({ eventId: Number(event.eventId), partnerAddress, data: { ...event, isCancelled: true } })
    await Promise.all([
      queryClient.invalidateQueries([QueryKeys.PartnerEvents]),
      queryClient.invalidateQueries([QueryKeys.Events]),
    ])

    onClose()
  }

  const isCancelling = isCancellingEventInRegistry || isEditingEvent

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} maxWidth={750} initialFocusRef={cancelBtn}>
      <Styled.CancelEventModalContainer>
        <Styled.CancelEventModalHeaderContainer>
          <Styled.CancelEventModalHeader>Cancel Event</Styled.CancelEventModalHeader>
          <ButtonTransparent onClick={onClose}>
            <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
          </ButtonTransparent>
        </Styled.CancelEventModalHeaderContainer>

        <Styled.CancelEventModalHeading>
          You are about to cancel{' '}
          <Styled.CancelEventModalEventName>&quot;{event?.name}&quot;</Styled.CancelEventModalEventName>
        </Styled.CancelEventModalHeading>
        <Styled.CancelEventModalParagraph>
          This action cannot be undone! If you still want to proceed, press SUBMIT.
        </Styled.CancelEventModalParagraph>

        <Styled.CancelEventModalButtonsContainer>
          <ButtonPrimary onClick={handleCancelEvent} disabled={isCancelling}>
            {' '}
            {isCancelling ? <Loader stroke="#ffffff" size="20.5px" /> : 'Submit'}
          </ButtonPrimary>
          <ButtonSecondary disabled={isCancelling} ref={cancelBtn} onClick={onClose}>
            Cancel
          </ButtonSecondary>
        </Styled.CancelEventModalButtonsContainer>
      </Styled.CancelEventModalContainer>
    </Modal>
  )
}
