import React from 'react'
import styled from 'styled-components/macro'
const Card = (props: any) => {
  const { data, dataHtml } = props
  return (
    <CardWrapper>
      <BoxImage>
        <BoxRatio>
          <img src={data?.link || dataHtml?.link} alt={'img'} />
        </BoxRatio>
      </BoxImage>

      {dataHtml.text && <RichText dangerouslySetInnerHTML={{ __html: dataHtml.text }} />}

      {data?.tile && <MainHeading>{data.tile}</MainHeading>}
      {data?.title && <SubHeading>{data.title}</SubHeading>}
      {data?.text && <Paragraph>{data.text}</Paragraph>}
    </CardWrapper>
  )
}
const RichText = styled.div``
const CardWrapper = styled.div``
const BoxImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 20px;
`
const BoxRatio = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    vertical-align: middle;
  }
`
const MainHeading = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  color: ${({ theme }) => theme.white};
`
const SubHeading = styled.h4`
  font-family: 'DIN Pro Bold', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  color: ${({ theme }) => theme.secondary8};
  margin: 0 0 12px 0;
`
const Paragraph = styled.p`
  margin: 0 0 40px 0;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
`
export default Card
