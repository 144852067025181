import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'
import { Event } from '../models/Event'

export type UseEventsQueryParams = Partial<IQueryParams>

export interface IQueryParams {
  startTimeFrom: number
  startTimeTo: number
  endTimeFrom: number
  endTimeTo: number
  cancelledStatus: boolean
  withoutClerksOnly: boolean
  withoutAllowedCollectionOnly: boolean
}

interface UseEventsProps {
  queryParams?: UseEventsQueryParams
}

export const useEvents = (
  params?: UseEventsProps,
  options?: UseQueryOptions<UseEventsProps, AxiosError<any>, Event[]>
) => {
  const { client } = useApiClientContext()

  return useQuery<UseEventsProps, AxiosError<any>, Event[]>(
    [QueryKeys.Events, params?.queryParams],
    async () => {
      const result = await client.get(apiEndpoints.events, {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
        params: params?.queryParams,
      })

      return result?.data
    },
    options
  )
}
