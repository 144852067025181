import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

const useTimer = (exactStart?: Date, exactEnd?: Date, callback?: () => void) => {
  //get end times
  const end = useMemo(() => {
    return exactEnd ? exactEnd.getTime() : 0
  }, [exactEnd])

  //current time
  const [time, setTime] = useState(() => moment(Date.now()).valueOf())

  const timeSecondsProgressbar = useMemo(() => {
    return end - Date.now()
  }, [end])

  useEffect((): (() => void) | void => {
    if (time <= end) {
      const timeout = setTimeout(() => setTime(moment(Date.now()).valueOf()), 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [time, end])

  //start check time
  const timeUntilGenesis = exactStart ? exactStart.getTime() - time : 0
  //end check time

  const timeUntilEnd = end - time

  let timeRemaining: number
  if (timeUntilGenesis > 0) {
    timeRemaining = timeUntilGenesis
  } else {
    const ongoing = timeUntilEnd >= 0
    if (ongoing) {
      timeRemaining = timeUntilEnd
    } else {
      timeRemaining = Infinity
    }
  }

  const days = (timeRemaining - (timeRemaining % DAY)) / DAY
  timeRemaining -= days * DAY
  const hours = (timeRemaining - (timeRemaining % HOUR)) / HOUR
  timeRemaining -= hours * HOUR
  const minutes = (timeRemaining - (timeRemaining % MINUTE)) / MINUTE
  timeRemaining -= minutes * MINUTE
  const seconds = Math.floor(timeRemaining / 1000)

  if (callback && timeRemaining <= 0) {
    callback()
  }

  return {
    days,
    hours,
    minutes,
    seconds,
    timeSecondsProgressbar,
  }
}

export default useTimer
