import { createContext, ReactNode, useContext } from 'react'
import { Flex } from 'rebass'

import Loader from '../../../components/Loader'
import { useGetProfile } from '../../../state/profile/hooks'
import { UseEventsQueryParams } from '../hooks/useEvents'
import { usePartnerEvents } from '../hooks/usePartnerEvents'
import { Event } from '../models/Event'

export interface EventListContextProps {
  events: Event[]
  refetch: () => void
}

const defaultValues: EventListContextProps = {
  events: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetch: () => {},
}

const CurrentLocationContext = createContext<EventListContextProps>(defaultValues)

export const useEventsListContext = () => {
  return useContext(CurrentLocationContext)
}

interface UserAccountContextProviderProps {
  children: ReactNode
  filters?: UseEventsQueryParams
}

export const EventListContextProvider = ({ children, filters = {} }: UserAccountContextProviderProps) => {
  const { data: profile, loading } = useGetProfile()

  const {
    data: events,
    isFetching,
    isLoading,
    refetch,
  } = usePartnerEvents(
    {
      partnerAddress: profile.address,
      queryParams: filters,
    },
    {
      enabled: Boolean(!loading && profile && profile.address),
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading || isFetching) {
    return (
      <Flex p="4" justifyContent="center" alignContent={'center'}>
        <Loader size="48px" />
      </Flex>
    )
  }

  if (!events) {
    return null
  }

  return <CurrentLocationContext.Provider value={{ events, refetch }}>{children}</CurrentLocationContext.Provider>
}
