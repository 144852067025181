import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { Role } from '../../../constants/roles'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseLockParntnerProps {
  data: {
    isLocked: boolean
    userAddress: string
    role: Role
  }
}

export const useLockPartner = (options?: UseMutationOptions<any, AxiosError, UseLockParntnerProps>) => {
  const { client } = useApiClientContext()

  return useMutation<any, AxiosError, UseLockParntnerProps>(async ({ data }) => {
    const result = await client.post(apiEndpoints.lockPartner, data, {
      headers: {
        authorization: localStorage.getItem('authToken') as string,
      },
    })

    return result?.data
  }, options)
}
