import styled from 'styled-components/macro'

import Loader from '../../../../components/Loader'
import { ModalBase } from '../../../../components/ModalBase/ModalBase'

interface SubmittingRedeemModalBodyProps {
  isOpen: boolean
  onDismiss: () => void
}

export const SubmittingRedeemModalBody = ({ isOpen, onDismiss }: SubmittingRedeemModalBodyProps) => {
  return (
    <ModalBase isOpen={isOpen} onDismiss={onDismiss} maxWidth={980}>
      <Submmitting>
        <Loader size={'54px'} />
        <h1>Submitting</h1>
        <p>Submitting your information, please wait a few seconds...</p>
      </Submmitting>
    </ModalBase>
  )
}

const Submmitting = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    text-transform: uppercase;
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: 16px;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }
`
