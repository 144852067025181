import useParticle from 'hooks/useParticle'
import React from 'react'
import styled from 'styled-components/macro'

import IconClose from '../../../assets/images/svg/icon-close.svg'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import CardFollower from '../../CardFollower'
import IconPortal from '../../IconPortal'
import Modal from '../index'

interface ModalFollowersProps {
  isOpen: boolean
  onDismiss: () => void
  users: any
  title: string
}

const ModalFollowers = ({ isOpen, onDismiss, users, title }: ModalFollowersProps) => {
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={450}>
      <ModalWrapper>
        <ModalHeader>
          <Heading>{title}</Heading>
          <span onClick={onDismiss} style={{ cursor: 'pointer' }}>
            <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
          </span>
        </ModalHeader>
        <ModalContent>
          <ListUserFollow>
            {users?.map((user: any) => {
              return (
                <CardFollower
                  key={user.followerAddress}
                  followersCount={user.followers}
                  follower={user.followee ?? user.follower}
                  account={account ?? ''}
                  isFollowed={user.isFollowed}
                  onDismiss={onDismiss}
                  isCreator={user.numberCreator > 0 ? true : false}
                />
              )
            })}
            {(!users || users.length === 0) && (
              <DivEmptyFollower>{title === 'Followers' ? 'No followers' : 'Not following any users'}</DivEmptyFollower>
            )}
          </ListUserFollow>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  )
}
const ModalWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.secondary1};
  padding: 32px;
  min-height: 560px;
  height: 560px;
  overflow-y:auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.secondary3};
    border-radius: 10px;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
`
const Heading = styled.h3`
  margin: 0;
  font-style: ${({ theme }) => theme.display3};
  line-height: 1.333334;
  font-family: 'Din Pro Bold', sans-serif;
  text-transform: uppercase;
`
const ModalContent = styled.div``
const ListUserFollow = styled.div``

const DivEmptyFollower = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primary10};
  text-transform: uppercase;
`
export default React.memo(ModalFollowers)
