import { useMutation, UseMutationOptions } from '@tanstack/react-query'

// eslint-disable-next-line no-restricted-imports
import { useAnother1ERC20Contract } from '../../../hooks/useAnother1ERC20Contract'

interface useIncreaseAnothe1TokenAllowanceParams {
  amount: string
  address: string
}

export const useIncreaseAnother1TokenAllowance = (
  options?: UseMutationOptions<any, any, useIncreaseAnothe1TokenAllowanceParams>
) => {
  const contract = useAnother1ERC20Contract()

  return useMutation<any, any, useIncreaseAnothe1TokenAllowanceParams>(async ({ amount, address }) => {
    if (!contract) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const tx = await contract.increaseAllowance(address, amount)

    const receipt = await tx.wait()

    return receipt
  }, options)
}
