import React from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components/macro'

interface PopupProps {
  isOpen: boolean
  onDismiss: any
  children?: React.ReactNode
  className?: string
}

const PopupBox = ({ isOpen, onDismiss, children, className }: PopupProps) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  return (
    <>
      {transitions.map(
        ({ item, key, props: style }) =>
          item && (
            <AnimatedPopup style={style} isOpen={isOpen} key={key} className={className ? className : ''}>
              {children}
            </AnimatedPopup>
          )
      )}
    </>
  )
}

const Popup = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.secondary2};
  z-index: 20;
  padding: 30px;
  width: 450px;
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);

  @media (max-width: 540px) {
    width: 360px;
    padding: 20px;
    &.popup-user {
      right: -120px;
    }
    &.popup-wallet {
      right: -50px;
    }
  }
`

const AnimatedPopup = animated(Popup)

export default PopupBox
