import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { TabPanel, Tabs } from 'react-tabs'
import styled from 'styled-components/macro'

import Loader from '../../components/Loader'
import NotAuthorized from '../../components/NotAuthorized'
import { TabCs, TabListCs } from '../../components/Tabs'
import { Role } from '../../constants/roles'
import { useGetCollectionDetailQuery } from '../../state/collections/slice'
import { useGetProfile } from '../../state/profile/hooks'
import BasicInfo from './BasicInfo'
import EditDescription from './EditDescription'

const EditCollectionInfo = () => {
  const { addressCollection } = useParams<{ addressCollection: string }>()
  const { data: profile, loading } = useGetProfile()

  const {
    data: collection,
    isLoading,
    refetch,
  } = useGetCollectionDetailQuery({
    collectionAddress: addressCollection,
    address: profile.address !== '' ? profile.address : null,
  })
  const checkRole = useMemo(() => {
    return (
      profile.roles.findIndex(
        (role: any) => role.role === Role.ADMIN || Role.MANAGEMENT_TEAM || Role.COLLECTION_OPERATOR
      ) === -1
    )
  }, [profile])
  return (
    <Wrapper>
      <Container>
        <Main>
          <Heading>Edit collection info</Heading>
          {isLoading && loading ? (
            <LoadingBox>
              <Loader size="60px" />
            </LoadingBox>
          ) : !checkRole ? (
            <>
              <Tabs defaultIndex={0}>
                <TabListCs style={{ marginTop: '32px', marginBottom: '80px' }}>
                  <TabCs>
                    <TabName>Basic Info</TabName>
                  </TabCs>
                  <TabCs>
                    <TabName>Description</TabName>
                  </TabCs>
                </TabListCs>
                <TabPanel>
                  <BasicInfo collection={collection} isLoading={isLoading} addressCollection={addressCollection} />
                </TabPanel>
                <TabPanel>
                  <EditDescription
                    collection={collection}
                    isLoading={isLoading}
                    addressCollection={addressCollection}
                    refetch={refetch}
                  />
                </TabPanel>
              </Tabs>
            </>
          ) : (
            <NotAuthorized />
          )}
        </Main>
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const Container = styled.div`
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`
const Main = styled.div`
  padding: 130px 100px;
  @media only screen and (max-width: 1024px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 1024px) {
    padding: 30px 0;
  }
`
const Heading = styled.div`
  font-family: 'DIN 1451 Std', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.display2};
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.primary10};
  margin-bottom: 90px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 60px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`
const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px;
`
const TabName = styled.span`
  margin: 0;
  font-size: ${({ theme }) => theme.capsHeading2};
  font-family: 'DIN 1451 Std', sans-serif;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1.2222;
`
export default EditCollectionInfo
