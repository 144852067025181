import useParticle from 'hooks/useParticle'
import React from 'react'
import Select, { SingleValue } from 'react-select'
import styled from 'styled-components/macro'

import { DividerDashed } from '../../../../components/Divider'
import { OptionType } from '../../../../entities/OptionType'
// import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'
import { useGetCollectionDetailQuery } from '../../../../state/collections/slice'
import { customStylesSelect } from '../../../../theme'
import EditCollectionLd from '../EditCollectionLd'

const AddFeatured = (props: any) => {
  const { collectionOptionsHide, selectedCollectionHide, setSelectedCollectionHide, onClose } = props
  const handleChangeSelectedHideCollection = (collection: SingleValue<OptionType>) => {
    setSelectedCollectionHide(collection as OptionType)
  }
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const { data: collection, isLoading } = useGetCollectionDetailQuery({
    collectionAddress: selectedCollectionHide.value || '',
    address: account || null,
  })
  return (
    <AddFeaturedWrapper>
      <AddFeatureContent>
        <Heading5>Select collection</Heading5>
        {selectedCollectionHide && selectedCollectionHide.value && (
          <SectionSelect>
            <Select
              styles={customStylesSelect}
              defaultValue={selectedCollectionHide}
              options={collectionOptionsHide}
              classNamePrefix={'select-cs'}
              id="filter"
              name="filter"
              onChange={handleChangeSelectedHideCollection}
            />
          </SectionSelect>
        )}
        <DividerDashed />
        {!isLoading && (
          <EditCollectionLd collection={collection} onClose={onClose} typeAdd={true} onCloseAddFeatured={onClose} />
        )}
      </AddFeatureContent>
    </AddFeaturedWrapper>
  )
}
const AddFeaturedWrapper = styled.section`
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary2};
  padding: 20px 20px 32px 20px;
`
const AddFeatureContent = styled.div``
const Heading5 = styled.h5`
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
  margin-bottom: 12px;
  margin-top: 0;
`
const SectionSelect = styled.div`
  width: 100%;
  margin-bottom: 24px;
`
export default AddFeatured
