import React from 'react'

import { CurrentLocationContextProvider } from '../../../../context/LocationContext'
import { timestampToSecondsTimestamp } from '../../../../utils/formatDate'
import { EventsList } from '../../components/EventsList'

export const List = () => {
  const now = timestampToSecondsTimestamp(new Date().getTime())

  return (
    <CurrentLocationContextProvider>
      <EventsList title="Ongoing" filters={{ startTimeTo: now, endTimeFrom: now, cancelledStatus: false }} />
      <EventsList title="Upcoming" filters={{ startTimeFrom: now + 1, cancelledStatus: false }} />
      <EventsList title="Past" filters={{ endTimeTo: now - 1, cancelledStatus: false }} />
    </CurrentLocationContextProvider>
  )
}
