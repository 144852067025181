import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ButtonPrimary } from '../../components/Button'
const NotFoundPage = () => {
  return (
    <NotFoundWrapper>
      <NotFoundContainer>
        <SectionNotFound>
          <Heading>404</Heading>
          <TextNotification>
            <h1>Ooops! Page Not Found</h1>
            <p>The requested page is not available</p>
          </TextNotification>
          <BoxCta>
            <LinkRouter to={'/'}>
              <BackToHome>Back to Home</BackToHome>
            </LinkRouter>
          </BoxCta>
        </SectionNotFound>
      </NotFoundContainer>
    </NotFoundWrapper>
  )
}
const NotFoundWrapper = styled.div`
  width: 100%;
`
const NotFoundContainer = styled.div`
  width: 100%;
`
const SectionNotFound = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 100px 0;
`
const Heading = styled.h3`
  font-family: 'DIN 1451 Std', sans-serif;
  text-transform: uppercase;
  font-size: 200px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 56px;
  color: ${({ theme }) => theme.primary6};
  margin-top: 0;
`
const TextNotification = styled.div`
  margin-bottom: 56px;
  h1 {
    color: ${({ theme }) => theme.white};
    font-family: 'DIN 1451 Std', sans-serif;
    line-height: 1.16667;
    margin: 0 0 8px 0;
    text-align: center;
  }
  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizeText1};
    line-height: 1.6;
    color: ${({ theme }) => theme.secondary6};
    text-align: center;
  }
`
const BackToHome = styled(ButtonPrimary)`
  padding: 10px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  text-transform: capitalize;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 210px;
  margin: 0 auto;
`
const BoxCta = styled.div`
  text-align: center;
  a {
    text-decoration: none;
  }
`
export default NotFoundPage
