import React, { useState } from 'react'

import { Lock } from '../../../../components/Icons/Lock'
import { Unlock } from '../../../../components/Icons/Unlock'
import Loader from '../../../../components/Loader'
import ModalError from '../../../../components/Modal/ModalError'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
import { Role } from '../../../../constants/roles'
import { useAppDispatch } from '../../../../state/hooks'
import { usersManagementApi } from '../../../../state/usersManagement/slice'
import { useLockPartner } from '../../hooks/useLockPartner'
import * as Styled from './styled'

interface LockPartnerButtonProps {
  userAddress: string
  isLocked: boolean
}

export const LockPartnerButton = ({ userAddress, isLocked }: LockPartnerButtonProps) => {
  const dispatch = useAppDispatch()

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)
  const { mutate: lockPartner, isLoading: isLockingPartner } = useLockPartner({
    onSuccess: () => {
      setIsSuccessModalOpen(true)
      dispatch(usersManagementApi.util.invalidateTags(['UsersManagement']))
    },
    onError: () => {
      setIsErrorModalOpen(true)
    },
  })

  const handleLockClick = () => {
    lockPartner({
      data: {
        userAddress,
        isLocked: !isLocked,
        role: Role.PARTNER,
      },
    })
  }

  return (
    <>
      {isLockingPartner ? (
        <Loader size="24px" />
      ) : (
        <Styled.LockUnlockButton isLocked={isLocked} onClick={handleLockClick}>
          {isLocked ? <Unlock /> : <Lock />}
        </Styled.LockUnlockButton>
      )}
      <ModalError
        isOpen={isErrorModalOpen}
        onDismiss={() => setIsErrorModalOpen(false)}
        message="Something went wrong"
      />
      <ModalSuccess
        isOpen={isSuccessModalOpen}
        onDismiss={() => setIsSuccessModalOpen(false)}
        message={isLocked ? 'Partner unlocked successfully.' : 'Partner locked successfully.'}
      />
    </>
  )
}
