import * as React from 'react'
export const Another1Logo = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <path fill="#606060" d="m16.983 35.968.006-.006h-.012l.006.006Z" />
    <path
      fill="#009962"
      d="m16.983 35.968.006-.006h-.012l.006.006ZM13.675 30.233H1.703l3.253 5.729h12.02l-3.33-5.687.028-.042ZM20.407 30.18l.026-.047-.026.047Z"
    />
    <path fill="url(#a)" d="m22.92 15.486-3.223 5.352 5.341 9.395 3.651 5.729h6.108L22.92 15.486Z" />
    <path fill="url(#b)" d="M22.919 15.486 34.797 35.96l3.536-6.015L23.06 4.173l-6.01 10.238 5.87 1.075Z" />
    <path fill="url(#c)" d="m20.407 30.181-.031.052.03-.052Z" />
    <path fill="url(#d)" d="m20.407 30.181-.031.052.03-.052Z" />
    <path fill="url(#e)" d="m17.435 24.71-.031-.06H17.4l.034.06Z" />
    <path
      fill="url(#f)"
      d="m17.05 14.412 6.01-10.238-6.506-.142-14.85 26.201h8.875l3.068.042 3.755-5.625h-6.363l6.012-10.238Z"
    />
    <path fill="#fff" d="m20.407 30.181.026.052-.026-.052ZM20.376 30.233h.057l-.026-.052-.031.052Z" />
    <path fill="url(#g)" d="m11.09 24.623-.458 5.584 3.014.069L17.4 24.65l-6.311-.026Z" />
    <path
      fill="#606060"
      d="m13.675 30.233-.029.043 3.331 5.686h.01l3.39-5.729h-6.702ZM20.407 30.18l-2.972-5.47-.034-.06 3.006 5.53Z"
    />
    <path fill="url(#h)" d="m20.407 30.18-2.972-5.47-.034-.06 3.006 5.53Z" />
    <path fill="#606060" d="m17.4 24.65-3.726 5.583h6.701l.031-.052-3.005-5.531Z" />
    <path fill="url(#i)" d="m17.401 24.65-3.726 5.583-.029.043 3.331 5.686h.01l3.39-5.729.03-.052-3.006-5.531Z" />
    <path fill="url(#j)" d="M17.05 14.411 11.038 24.65l6.012-10.238Z" />
    <path fill="url(#k)" d="M17.05 14.411 11.038 24.65l6.012-10.238Z" />
    <path
      fill="#13FF00"
      d="m11.038 24.65 6.363-.003.003.002 2.293-3.812-.031-.055 3.253-5.297-5.87-1.074-6.011 10.238Z"
    />
    <path fill="#3DF2E1" d="m17.404 24.65-.003-.003-6.363.003H17.404Z" />
    <path fill="url(#l)" d="m17.404 24.65-.003-.003-6.363.003H17.404Z" />
    <path fill="url(#m)" d="m17.404 24.65-.003-.003-6.363.003H17.404Z" />
    <path fill="url(#n)" d="m11.129 24.52 6.362-.002.37-.613 1.927-3.196 3.221-5.352-5.869-1.074L11.13 24.52Z" />
    <path fill="url(#o)" d="m19.666 20.782.031.056 3.222-5.352" />
    <path fill="url(#p)" d="m23.06 4.174-6.507-.142L1.667 30.328l6.082-.071 15.31-26.083Z" />
    <defs>
      <linearGradient id="a" x1={31.688} x2={18.194} y1={20.612} y2={43.698} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={0.109} stopColor="#5152D6" />
        <stop offset={0.294} stopColor="#3E8696" />
        <stop offset={0.472} stopColor="#2FB160" />
        <stop offset={0.635} stopColor="#23D337" />
        <stop offset={0.783} stopColor="#1AEB19" />
        <stop offset={0.91} stopColor="#15FA06" />
        <stop offset={1} stopColor="#13FF00" />
      </linearGradient>
      <linearGradient id="b" x1={17.049} x2={38.333} y1={20.067} y2={20.067} gradientUnits="userSpaceOnUse">
        <stop stopColor="#13FF00" />
        <stop offset={0.373} stopColor="#14FE02" />
        <stop offset={0.507} stopColor="#16FB09" />
        <stop offset={0.603} stopColor="#19F614" />
        <stop offset={0.68} stopColor="#1EEF25" />
        <stop offset={0.746} stopColor="#24E63B" />
        <stop offset={0.805} stopColor="#2CDA57" />
        <stop offset={0.858} stopColor="#36CD77" />
        <stop offset={0.907} stopColor="#40BD9D" />
        <stop offset={0.952} stopColor="#4DAAC8" />
        <stop offset={0.993} stopColor="#5A97F6" />
        <stop offset={1} stopColor="#5D93FF" />
      </linearGradient>
      <linearGradient id="c" x1={23.05} x2={1.764} y1={30.207} y2={30.207} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={0.18} stopColor="#366CF3" />
        <stop offset={0.341} stopColor="#3AB4E9" />
        <stop offset={0.456} stopColor="#3CE1E2" />
        <stop offset={0.514} stopColor="#3DF2E0" />
      </linearGradient>
      <linearGradient id="d" x1={21.361} x2={12.654} y1={30.192} y2={30.325} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="e" x1={21.267} x2={12.562} y1={24.619} y2={24.751} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="f" x1={-15.999} x2={50.833} y1={17.154} y2={17.154} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={0.03} stopColor="#5644E7" />
        <stop offset={0.097} stopColor="#486BB7" />
        <stop offset={0.169} stopColor="#3B8E8C" />
        <stop offset={0.246} stopColor="#31AD66" />
        <stop offset={0.328} stopColor="#27C646" />
        <stop offset={0.416} stopColor="#20DB2C" />
        <stop offset={0.513} stopColor="#1AEB19" />
        <stop offset={0.624} stopColor="#16F60B" />
        <stop offset={0.761} stopColor="#14FD02" />
        <stop offset={1} stopColor="#13FF00" />
      </linearGradient>
      <linearGradient id="g" x1={21.291} x2={12.623} y1={27.311} y2={27.443} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="h" x1={18.373} x2={20.101} y1={19.456} y2={44.733} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={0.18} stopColor="#366CF3" />
        <stop offset={0.341} stopColor="#3AB4E9" />
        <stop offset={0.456} stopColor="#3CE1E3" />
        <stop offset={0.514} stopColor="#3DF2E1" />
      </linearGradient>
      <linearGradient id="i" x1={15.994} x2={19.362} y1={14.751} y2={64.031} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={0.109} stopColor="#5152D6" />
        <stop offset={0.294} stopColor="#3E8696" />
        <stop offset={0.472} stopColor="#2FB160" />
        <stop offset={0.635} stopColor="#23D337" />
        <stop offset={0.783} stopColor="#1AEB19" />
        <stop offset={0.91} stopColor="#15FA06" />
        <stop offset={1} stopColor="#13FF00" />
      </linearGradient>
      <linearGradient id="j" x1={23.059} x2={1.704} y1={19.53} y2={19.53} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={0.18} stopColor="#366CF3" />
        <stop offset={0.341} stopColor="#3AB4E9" />
        <stop offset={0.456} stopColor="#3CE1E2" />
        <stop offset={0.514} stopColor="#3DF2E0" />
      </linearGradient>
      <linearGradient id="k" x1={9.999} x2={15.73} y1={25.775} y2={17.142} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="l" x1={21.279} x2={12.543} y1={24.54} y2={24.673} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="m" x1={12.441} x2={18.159} y1={27.392} y2={18.765} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="n" x1={12.314} x2={18.045} y1={27.123} y2={18.491} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={1} stopColor="#5D31FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="o" x1={19.665} x2={22.918} y1={18.162} y2={18.162} gradientUnits="userSpaceOnUse">
        <stop stopColor="#13FF00" />
        <stop offset={0.373} stopColor="#14FE02" />
        <stop offset={0.507} stopColor="#16FB09" />
        <stop offset={0.603} stopColor="#19F614" />
        <stop offset={0.68} stopColor="#1EEF25" />
        <stop offset={0.746} stopColor="#24E63B" />
        <stop offset={0.805} stopColor="#2CDA57" />
        <stop offset={0.858} stopColor="#36CD77" />
        <stop offset={0.907} stopColor="#40BD9D" />
        <stop offset={0.952} stopColor="#4DAAC8" />
        <stop offset={0.993} stopColor="#5A97F6" />
        <stop offset={1} stopColor="#5D93FF" />
      </linearGradient>
      <linearGradient id="p" x1={24.248} x2={8.365} y1={-2.731} y2={22.729} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={1} stopColor="#5D31FF" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
