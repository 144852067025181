import { Input } from '@rebass/forms'
import SearchIcon from 'assets/images/Search-icon.svg'
import IconPortal from 'components/IconPortal'
import { darken } from 'polished'
import React from 'react'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'

import IconClose from '../../assets/images/svg/icon-close.svg'
const SearchBox = styled(Flex)`
  display: flex;
  flex: 1;
  align-items: center;
  background-color: transparent;
  padding: 5px 15px;
  border: 1px solid ${({ theme }) => theme.secondary5};
  img {
    vertical-align: center;
    margin-right: auto;
    margin-left: auto;
  }
`
const InputCs = styled(Input)<{ iconClose?: string }>`
  font-family: 'Din Pro Regular', sans-serif;
  color: ${({ theme }) => darken(0.53, theme.white)};
  font-weight: 500;
  border: none !important;
  & :focus-visible {
    outline: none;
  }
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background-image: url(${(props) => props.iconClose});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }
`
interface PropsType {
  onChange?: (term: string) => void
  placeholder?: string
}
const SearchBar = (props: PropsType) => {
  const { onChange, placeholder } = props
  return (
    <SearchBox as="form" onSubmit={(e: any) => e.preventDefault()}>
      <IconPortal SrcImageIcon={SearchIcon} widthIcon={'24px'} heightIcon={'24px'} />
      <InputCs
        id="search"
        name="search"
        type="search"
        placeholder={placeholder || 'Search by name or attribute'}
        autoComplete="off"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!onChange) {
            return
          }
          onChange(event.target.value.trim())
        }}
        iconClose={IconClose}
      />
    </SearchBox>
  )
}

export default SearchBar
