import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { UseEventsQueryParams } from 'modules/Events/hooks/useEvents'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseClerksProps {
  partnerAddress: string
  queryParams?: UseEventsQueryParams
}

export const useClerks = (params: UseClerksProps, options?: UseQueryOptions<UseClerksProps, AxiosError<any>, any>) => {
  const { client } = useApiClientContext()

  return useQuery<UseClerksProps, AxiosError<any>, any>(
    [QueryKeys.EventClerks, params],
    async () => {
      const result = await client.get(apiEndpoints.clerks.replace('{partnerAddress}', params.partnerAddress), {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
        params: params.queryParams,
      })

      return result?.data
    },
    options
  )
}
