import styled from 'styled-components/macro'

export const TimeContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TimeHeader = styled.h5`
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #979797;
  margin: 0;
`

interface TimeDataProps {
  ended?: boolean
}

export const TimeData = styled.p<TimeDataProps>`
  font-weight: 400;
  font-size: 20px;
  color: ${({ ended, theme }) => (ended ? theme.orange5 : theme.primary5)};
  margin: 0;
`
