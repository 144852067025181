import axios from 'axios'
import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../hooks'
import { getCollectionItem } from './reducer'

export const useFetchCollectionItem = () => {
  const dispatch = useAppDispatch()

  return useCallback(
    async (addressOrSlug?: string, account?: string | null, page?: number, limit?: number) => {
      const pageSize = limit || 16
      const finalAccount = account || null
      await axios
        .get(
          process.env.REACT_APP_SERVER_URL + `assets/${addressOrSlug}/${finalAccount}?page=${page}&limit=${pageSize}`
        )
        .then((res) => {
          const { data } = res
          dispatch(getCollectionItem(data))
        })
        .catch((err) => {
          console.log(err)
        })
    },
    [dispatch]
  )
}
export function useGetCollectionDetail(): any {
  const selectorCollection = useAppSelector((state) => state.collectionDetail)

  return {
    pagination: selectorCollection.pagination,
  }
}
