import React from 'react'
import { useParams } from 'react-router-dom'

import { routes } from '../../../../constants/routes'
import { CreateEventStepContainer } from '../../components/CreateEventStepContainer'
import { WhitelistUpload } from '../../components/WhitelistUpload'

interface AddWhitelistUrlParams {
  eventId: string
}

export const AddWhitelist = () => {
  const { eventId } = useParams<AddWhitelistUrlParams>()

  return (
    <CreateEventStepContainer>
      <WhitelistUpload eventId={eventId} continueSkipRoute={routes.partnerPanel.eventManagement} />
    </CreateEventStepContainer>
  )
}
