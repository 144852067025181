import { useEffect, useState } from 'react'

import { useGetAttributesAssignQuery } from '../state/assignAttributes/slice'
export const useOptionAssignAttributeCollection = (collectionAddress: any) => {
  const [optionAttribute, setOptionAttribute] = useState<any>([])
  const { data } = useGetAttributesAssignQuery(
    {
      collectionAddress,
    },
    { skip: !collectionAddress }
  )
  useEffect(() => {
    if (!data) {
      return
    }
    const optionCv = data.data.map((item: any) => {
      const optionList =
        item.attribute.type === 'INPUT'
          ? null
          : item.attribute.options.map((option: string[]) => {
              return {
                label: `${option}`,
                value: `${option}`,
              }
            })
      return {
        ...item.attribute,
        optionList,
      }
    })
    setOptionAttribute(optionCv)
  }, [data])
  return optionAttribute
}
