import styled from 'styled-components/macro'

import { ButtonTransparent } from '../../../../components/Button'

interface LockUnlockButtonProps {
  isLocked: boolean
}

export const LockUnlockButton = styled(ButtonTransparent)<LockUnlockButtonProps>`
  color: ${({ theme, isLocked }) => (isLocked ? theme.primary4 : theme.red4)};

  &:hover {
    color: black;
  }
`
