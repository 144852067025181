import * as React from 'react'
export const PenIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g fill="#6A6B6C" clipPath="url(#a)">
      <path d="m8.616 2.677-6.97 6.969a.5.5 0 0 0-.128.222l-1.5 5.5a.5.5 0 0 0 .614.614l5.5-1.5a.5.5 0 0 0 .222-.128l6.969-6.97-4.707-4.707ZM15.561 3.025 12.975.44a1.5 1.5 0 0 0-2.121 0L9.677 1.616l4.707 4.707 1.177-1.177a1.5 1.5 0 0 0 0-2.12Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
