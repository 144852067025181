import axios from 'axios'
import keccak256 from 'keccak256'
import MerkleTree from 'merkletreejs'

export type MerkleWithProof = {
  root: string
  proof: string[]
  isValid: boolean
}

export async function getFullMerkle(saleAddress: string, node: string): Promise<MerkleWithProof | string> {
  const whitelist = (await axios.get(`${process.env.REACT_APP_SERVER_URL}whitelists/${saleAddress}.json`)).data

  const leaves = whitelist.map((x: string) => keccak256(x).toString('hex'))
  const tree = new MerkleTree(leaves, keccak256, { sortPairs: true })
  const root = tree.getRoot().toString('hex')

  let leaf: Buffer
  let proof: any[]

  if (node) {
    leaf = keccak256(node)
    proof = tree.getProof(leaf)
    const isValid: boolean = tree.verify(proof, leaf, root)
    return { root, proof: tree.getHexProof(leaf), isValid }
  }

  return root
}
