import * as React from 'react'
export const Another1LogoSmall = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={20} fill="none" {...props}>
    <path fill="#606060" d="m9.19 19.58.003-.003h-.007l.004.004Z" />
    <path
      fill="#009962"
      d="m9.19 19.58.003-.003h-.007l.004.004ZM7.205 16.14H.022l1.951 3.437h7.213l-1.998-3.412.017-.025ZM11.244 16.108l.016-.028-.016.028Z"
    />
    <path fill="url(#a)" d="m12.751 7.292-1.933 3.21 3.205 5.638 2.19 3.437h3.665L12.751 7.291Z" />
    <path fill="url(#b)" d="m12.751 7.291 7.127 12.286L22 15.967 12.835.505 9.23 6.647l3.521.644Z" />
    <path fill="url(#c)" d="m11.244 16.109-.019.031.019-.031Z" />
    <path fill="url(#d)" d="m11.244 16.109-.019.031.019-.031Z" />
    <path fill="url(#e)" d="m9.461 12.826-.019-.036h-.001l.02.036Z" />
    <path fill="url(#f)" d="M9.23 6.647 12.836.504 8.932.42.022 16.14h5.325l1.841.025 2.253-3.375H5.623L9.23 6.647Z" />
    <path fill="#fff" d="m11.244 16.109.016.031-.016-.031ZM11.225 16.14h.035l-.016-.031-.019.031Z" />
    <path fill="url(#g)" d="m5.654 12.774-.275 3.35 1.808.041L9.44 12.79l-3.786-.016Z" />
    <path
      fill="#606060"
      d="m7.205 16.14-.017.025 1.999 3.412h.006l2.033-3.437h-4.02ZM11.244 16.108l-1.783-3.282-.02-.036 1.803 3.319Z"
    />
    <path fill="url(#h)" d="m11.244 16.108-1.783-3.282-.02-.036 1.803 3.319Z" />
    <path fill="#606060" d="m9.44 12.79-2.235 3.35h4.02l.019-.032L9.44 12.79Z" />
    <path fill="url(#i)" d="m9.44 12.79-2.235 3.35-.017.025 1.999 3.412h.006l2.033-3.437.018-.032-1.803-3.318Z" />
    <path fill="url(#j)" d="M9.23 6.647 5.623 12.79 9.23 6.647Z" />
    <path fill="url(#k)" d="M9.23 6.647 5.623 12.79 9.23 6.647Z" />
    <path
      fill="#13FF00"
      d="m5.623 12.79 3.818-.002.001.002 1.376-2.288-.018-.033 1.951-3.178-3.521-.644-3.607 6.143Z"
    />
    <path fill="#3DF2E1" d="m9.442 12.79-.001-.002-3.818.002h3.819Z" />
    <path fill="url(#l)" d="m9.442 12.79-.001-.002-3.818.002h3.819Z" />
    <path fill="url(#m)" d="m9.442 12.79-.001-.002-3.818.002h3.819Z" />
    <path fill="url(#n)" d="m5.677 12.712 3.818-.001.221-.368 1.157-1.918 1.933-3.211-3.522-.644-3.607 6.142Z" />
    <path fill="url(#o)" d="m10.8 10.47.018.033 1.933-3.212" />
    <path fill="url(#p)" d="M12.835.504 8.932.42 0 16.197l3.65-.043L12.835.504Z" />
    <defs>
      <linearGradient id="a" x1={18.013} x2={9.916} y1={10.367} y2={24.219} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={0.109} stopColor="#5152D6" />
        <stop offset={0.294} stopColor="#3E8696" />
        <stop offset={0.472} stopColor="#2FB160" />
        <stop offset={0.635} stopColor="#23D337" />
        <stop offset={0.783} stopColor="#1AEB19" />
        <stop offset={0.91} stopColor="#15FA06" />
        <stop offset={1} stopColor="#13FF00" />
      </linearGradient>
      <linearGradient id="b" x1={9.229} x2={22} y1={10.04} y2={10.04} gradientUnits="userSpaceOnUse">
        <stop stopColor="#13FF00" />
        <stop offset={0.373} stopColor="#14FE02" />
        <stop offset={0.507} stopColor="#16FB09" />
        <stop offset={0.603} stopColor="#19F614" />
        <stop offset={0.68} stopColor="#1EEF25" />
        <stop offset={0.746} stopColor="#24E63B" />
        <stop offset={0.805} stopColor="#2CDA57" />
        <stop offset={0.858} stopColor="#36CD77" />
        <stop offset={0.907} stopColor="#40BD9D" />
        <stop offset={0.952} stopColor="#4DAAC8" />
        <stop offset={0.993} stopColor="#5A97F6" />
        <stop offset={1} stopColor="#5D93FF" />
      </linearGradient>
      <linearGradient id="c" x1={12.83} x2={0.059} y1={16.125} y2={16.125} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={0.18} stopColor="#366CF3" />
        <stop offset={0.341} stopColor="#3AB4E9" />
        <stop offset={0.456} stopColor="#3CE1E2" />
        <stop offset={0.514} stopColor="#3DF2E0" />
      </linearGradient>
      <linearGradient id="d" x1={11.817} x2={6.592} y1={16.116} y2={16.195} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="e" x1={11.76} x2={6.537} y1={12.772} y2={12.851} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="f" x1={-10.599} x2={29.5} y1={8.292} y2={8.292} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={0.03} stopColor="#5644E7" />
        <stop offset={0.097} stopColor="#486BB7" />
        <stop offset={0.169} stopColor="#3B8E8C" />
        <stop offset={0.246} stopColor="#31AD66" />
        <stop offset={0.328} stopColor="#27C646" />
        <stop offset={0.416} stopColor="#20DB2C" />
        <stop offset={0.513} stopColor="#1AEB19" />
        <stop offset={0.624} stopColor="#16F60B" />
        <stop offset={0.761} stopColor="#14FD02" />
        <stop offset={1} stopColor="#13FF00" />
      </linearGradient>
      <linearGradient id="g" x1={11.774} x2={6.574} y1={14.387} y2={14.466} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="h" x1={10.024} x2={11.061} y1={9.674} y2={24.84} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={0.18} stopColor="#366CF3" />
        <stop offset={0.341} stopColor="#3AB4E9" />
        <stop offset={0.456} stopColor="#3CE1E3" />
        <stop offset={0.514} stopColor="#3DF2E1" />
      </linearGradient>
      <linearGradient id="i" x1={8.596} x2={10.617} y1={6.85} y2={36.419} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={0.109} stopColor="#5152D6" />
        <stop offset={0.294} stopColor="#3E8696" />
        <stop offset={0.472} stopColor="#2FB160" />
        <stop offset={0.635} stopColor="#23D337" />
        <stop offset={0.783} stopColor="#1AEB19" />
        <stop offset={0.91} stopColor="#15FA06" />
        <stop offset={1} stopColor="#13FF00" />
      </linearGradient>
      <linearGradient id="j" x1={12.835} x2={0.022} y1={9.718} y2={9.718} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={0.18} stopColor="#366CF3" />
        <stop offset={0.341} stopColor="#3AB4E9" />
        <stop offset={0.456} stopColor="#3CE1E2" />
        <stop offset={0.514} stopColor="#3DF2E0" />
      </linearGradient>
      <linearGradient id="k" x1={4.999} x2={8.438} y1={13.465} y2={8.285} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="l" x1={11.767} x2={6.526} y1={12.724} y2={12.803} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="m" x1={6.464} x2={9.897} y1={14.436} y2={9.259} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3114FF" />
        <stop offset={1} stopColor="#3114FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="n" x1={6.388} x2={9.827} y1={14.274} y2={9.095} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={1} stopColor="#5D31FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="o" x1={10.799} x2={12.751} y1={8.897} y2={8.897} gradientUnits="userSpaceOnUse">
        <stop stopColor="#13FF00" />
        <stop offset={0.373} stopColor="#14FE02" />
        <stop offset={0.507} stopColor="#16FB09" />
        <stop offset={0.603} stopColor="#19F614" />
        <stop offset={0.68} stopColor="#1EEF25" />
        <stop offset={0.746} stopColor="#24E63B" />
        <stop offset={0.805} stopColor="#2CDA57" />
        <stop offset={0.858} stopColor="#36CD77" />
        <stop offset={0.907} stopColor="#40BD9D" />
        <stop offset={0.952} stopColor="#4DAAC8" />
        <stop offset={0.993} stopColor="#5A97F6" />
        <stop offset={1} stopColor="#5D93FF" />
      </linearGradient>
      <linearGradient id="p" x1={13.549} x2={4.019} y1={-3.638} y2={11.637} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D31FF" />
        <stop offset={1} stopColor="#5D31FF" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
