import { formatBignumberToDecimal } from '../utils/formatBignumberToDecimal'
import { useAnother1ERC20Decimals } from './useAnother1ERC20Decimals'

export const useFormatErc20Tokens = (amount?: string): string | null => {
  const { data: decimals, isLoading } = useAnother1ERC20Decimals()

  if (isLoading || !decimals || !amount) {
    return null
  }

  return formatBignumberToDecimal(amount, decimals)
}
