import EmptyIcon from 'assets/icons/Empty.svg'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { Box, Flex, Image, Text } from 'rebass'
import styled from 'styled-components/macro'

import CollectionDropItem from '../CollectionDropItem'

const TabsUpcoming = (props: any) => {
  const refSlider = useRef<Slider | null>(null)
  const [sliderCard, setSliderCard] = useState<any>(null)
  const { data, isUpcoming } = props
  const settingSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  }
  useEffect(() => {
    setSliderCard(refSlider.current)
  }, [])
  return (
    <div>
      <CollectionDropList>
        {Array.isArray(data) && data.length > 2 ? (
          <Slider {...settingSlider} ref={refSlider} asNavFor={sliderCard}>
            {data.map((item: any, index: number) => {
              return (
                <DropItem key={item.address}>
                  <CollectionDropItem dataItem={item} isUpcoming={isUpcoming} />
                </DropItem>
              )
            })}
          </Slider>
        ) : data.length > 0 ? (
          <BoxDropList>
            {Array.isArray(data) &&
              data.map((item: any, index: number) => {
                return (
                  <DropItem key={item.address} className={'dropitem-notslide'}>
                    <CollectionDropItem dataItem={item} isUpcoming={isUpcoming} />
                  </DropItem>
                )
              })}
          </BoxDropList>
        ) : (
          <Flex justifyContent={'center'}>
            <Box py="6">
              <Image
                src={EmptyIcon}
                alt="no collections"
                sx={{
                  width: ['60px'],
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
              <Text textAlign={'center'} mt="4" color={'#6D6D6D'}>
                Sorry, there are no collections at the moment.
              </Text>
            </Box>
          </Flex>
        )}
      </CollectionDropList>
    </div>
  )
}
const CollectionDropList = styled.div`
  .slick-list {
    width: 100%;
  }
  //.slick-track {
  //  margin-left: -16px;
  //  margin-right: -16px;
  //}
  .slick-slide {
    margin: 0;
    padding: 0 16px 16px 16px;
    //overflow: hidden;
    &.slick-active {
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
`
const DropItem = styled.div`
  &:hover {
    background-color: transparent;
    //border-color: ${({ theme }) => theme.secondary6};
    //box-shadow: 0 10px 50px #093a29;
  }
`
const BoxDropList = styled.div`
  display: flex;
  flex-wrap: wrap;
  .dropitem-notslide {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 16px 16px 16px;
    &:nth-of-type(1) {
      padding-left: 0;
    }
    &:nth-of-type(2) {
      padding-right: 0;
    }
    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`
export default TabsUpcoming
