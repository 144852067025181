import React from 'react'

import { timestampToSecondsTimestamp } from '../../../../utils/formatDate'
import { EventListContextProvider } from '../../../Events/context/EventListContext'
import { EventsTable } from '../EventsTable'
import { NoClerksTableActions } from '../NoClerksTableActions'
import { TableSubTitle, TableTitle } from '../TableTitle'

export const EventsTableNoClerk = () => {
  return (
    <div>
      <TableTitle>Events with no clerks assigned</TableTitle>
      <TableSubTitle>Ensure your events have clerks assigned so they can distribute awards to flexers</TableSubTitle>
      <EventListContextProvider
        filters={{
          withoutClerksOnly: true,
          cancelledStatus: false,
          endTimeFrom: timestampToSecondsTimestamp(new Date().getTime()),
        }}
      >
        <EventsTable ActionsComponent={NoClerksTableActions} />
      </EventListContextProvider>
    </div>
  )
}
