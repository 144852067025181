import ConfirmationIcon from 'assets/icons/confirm-green.svg'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { ButtonPrimary } from '../../Button'
import Loader from '../../Loader'
import Modal from '../index'
interface ModalContactProps {
  isOpen: boolean
  onDismiss: () => void
  submit: () => void
  name?: string
  subText?: string
  chooseNFTConvert: any[]
}
const Confirmation = (props: ModalContactProps) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const { isOpen, onDismiss, submit, subText, chooseNFTConvert } = props
  const handleConfirmation = useCallback(() => {
    setLoading(true)
    submit()
  }, [setLoading, submit])

  const handleClose = () => {
    onDismiss()
    setLoading(false)
  }

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setLoading(false)
  }, [isOpen])
  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} maxWidth={600}>
      <ModalContent>
        <ModalHeader>
          <img src={ConfirmationIcon} alt="Icon" />
          <Text>
            <Caps>Confirmation</Caps>
            {subText && <SubText>{subText}</SubText>}
          </Text>
        </ModalHeader>
        <ModalBody>
          <AboutNFT>
            <AboutNFTName>
              <TitleNFT>Name</TitleNFT>
            </AboutNFTName>
            <AboutNFTNumber>
              <TitleNFT>Amount to convert</TitleNFT>
            </AboutNFTNumber>
          </AboutNFT>
          {chooseNFTConvert.map((element, index) => {
            return (
              <AboutNFT key={element.id}>
                <AboutNFTName>
                  <p>{element.name}</p>
                </AboutNFTName>
                <AboutNFTNumber>
                  <p>{element.convert}</p>
                </AboutNFTNumber>
              </AboutNFT>
            )
          })}
          <TotalConvert>
            Your Total converting{' '}
            <span>
              <span>
                {chooseNFTConvert.length !== 0
                  ? chooseNFTConvert.reduce((total: number, element) => {
                      return (total += parseInt(element.convert))
                    }, 0)
                  : 0}
              </span>{' '}
              Token
            </span>
          </TotalConvert>
        </ModalBody>
        <ModalFooter>
          <CtaGroup>
            <CtaGotIt onClick={handleConfirmation} disabled={isLoading}>
              Confirm {isLoading && <Loader size={'24px'} stroke={'#151515'} />}
            </CtaGotIt>
            <CtaGotIt className={'exit'} onClick={handleClose} disabled={isLoading}>
              Back
            </CtaGotIt>
          </CtaGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
const ModalContent = styled.div`
  padding: 48px 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.secondary4};
`
const ModalFooter = styled.div``
const ModalBody = styled.div`
  padding: 32px 0;
`
const Text = styled.div`
  margin: 32px 0;
`
const Caps = styled.h3`
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};
  margin: 0 0 8px 0;
  font-weight: 600;
`

const SubText = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText2};
  text-align: center;
  color: ${({ theme }) => theme.secondary5};
  margin-bottom: 0;
`
const CtaGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`
const CtaGotIt = styled(ButtonPrimary)`
  display: flex;
  font-size: 18px;
  line-height: 1.33;
  font-weight: 600;
  padding: 10px 12px;
  width: 100%;
  color: ${({ theme }) => theme.secondary1};
  background-color: ${({ theme }) => theme.primary6};
  &[disabled] {
    background-color: ${({ theme }) => theme.primary2};
  }
  &.exit {
    background-color: ${({ theme }) => theme.secondary2};
    color: ${({ theme }) => theme.primary10};
  }
`
const AboutNFT = styled.div`
  display: flex;
  p {
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.3;
    margin: 0 0 24px 0;
  }
`
const AboutNFTName = styled.div`
  flex: 0 0 calc(100% - 175px);
  max-width: calc(100% - 175px);
  padding-right: 10px;
`
const AboutNFTNumber = styled.div`
  flex: 0 0 175px;
  max-width: 175px;
  padding-left: 10px;
  p {
    text-align: right;
  }
`
const TitleNFT = styled.h4`
  line-height: 1.4;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.secondary7};
  margin-top: 0;
`
const TotalConvert = styled.p`
  padding-top: 34px;
  border-top: 1px dashed ${({ theme }) => theme.secondary3};
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  & > span > span {
    color: ${({ theme }) => theme.primary6};
  }
`
export default Confirmation
