import { Country, ICountry, State } from 'country-state-city'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import Select from 'react-select'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import IconProtected from '../../../../assets/icons/protected.svg'
import { ButtonPrimary } from '../../../../components/Button'
import ColCs from '../../../../components/ColCs'
import IconPortal from '../../../../components/IconPortal'
import RowCs from '../../../../components/RowCs'
import { ItemAttribute, optionsAttribute } from '../../../../entities/OptionType'
import { useOptionAssignAttributeCollection } from '../../../../hooks/useOptionAssignAttributeCollection'
import { customStylesSelect } from '../../../../theme'

interface ShippingInformationFormProps {
  initialValues: any
  onSubmit: (value: any) => void
  nftDetail: any
}

export const ShippingInformationForm = ({ initialValues, onSubmit, nftDetail }: ShippingInformationFormProps) => {
  const optionAttribute = useOptionAssignAttributeCollection(nftDetail?.collection?.address)

  const x = useMemo(() => {
    return optionAttribute.reduce((total: any, item: ItemAttribute, index: number) => {
      if (item.type === 'INPUT') {
        total[`${item.name}`] = Yup.string().required('This field cannot be empty')
      } else {
        total[`${item.name}`] = Yup.object().shape({
          value: Yup.string().required('This field cannot be empty'),
        })
      }
      return total
    }, {})
  }, [optionAttribute])

  const redeemFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      fullName: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'Invalid name!\n It can only contain letters.')
        .min(4, 'The full name must have a minimum of 4 characters')
        .required('Sorry, this field cannot be empty'),
      email: Yup.string()
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Sorry, this is not a valid email address')
        .required('Sorry, this field cannot be empty'),
      phone: Yup.string()
        .matches(
          /\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)?\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/,
          'Enter a valid phone number'
        )
        .required('Please provide a valid phone number'),
      zipCode: Yup.string().min(3, 'Must include a zip code').required('This field cannot be empty'),
      address: Yup.string().required('This field cannot be empty'),
      passportNumber: Yup.string()
        .matches(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/, 'Passport number is invalid')
        .required('This field cannot be empty'),
      country: Yup.object().shape({
        value: Yup.string().required('This field cannot be empty'),
      }),
      ...x,
    }),
    onSubmit,
  })

  const { values, setValues } = redeemFormik

  const countries = Country.getAllCountries()
  const updatedCountries = countries.map((country: ICountry) => {
    return {
      label: country.name,
      value: country.isoCode,
      ...country,
    }
  })
  const updatedStates = (countryCode?: any) => {
    return State.getStatesOfCountry(countryCode)?.map((state: any) => ({
      label: state.name,
      value: state.name,
      ...state,
    }))
  }

  return (
    <form onSubmit={redeemFormik.handleSubmit}>
      <RowCs className={'form-row'}>
        <ColCs md={4} xs={6} className={'form-col'}>
          <FormGroup>
            <FormLabel>Full name</FormLabel>
            <FormInput
              type={'text'}
              placeholder={'Enter full name'}
              id={'fullName'}
              name={'fullName'}
              value={redeemFormik.values.fullName ?? ''}
              onChange={redeemFormik.handleChange}
            />
            {redeemFormik.errors.fullName && redeemFormik.values.fullName && (
              <ErrorMessage> {redeemFormik.errors.fullName.toString()} </ErrorMessage>
            )}
          </FormGroup>
        </ColCs>
        <ColCs md={4} xs={6} className={'form-col'}>
          <FormGroup>
            <FormLabel>Phone number</FormLabel>
            <FormInput
              placeholder={'Enter phone number'}
              id={'phone'}
              name={'phone'}
              value={redeemFormik.values.phone ?? ''}
              onChange={redeemFormik.handleChange}
            />
            {redeemFormik.errors.phone && redeemFormik.values.phone && (
              <ErrorMessage> {redeemFormik.errors.phone.toString()} </ErrorMessage>
            )}
          </FormGroup>
        </ColCs>
        <ColCs md={4} xs={6} className={'form-col'}>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <FormInput
              type={'text'}
              placeholder={'Enter email'}
              id={'email'}
              name={'email'}
              value={redeemFormik.values.email ?? ''}
              onChange={redeemFormik.handleChange}
            />
            {redeemFormik.errors.email && redeemFormik.values.email && (
              <ErrorMessage> {redeemFormik.errors.email.toString()} </ErrorMessage>
            )}
          </FormGroup>
        </ColCs>
        <ColCs md={4} xs={6} className={'form-col'}>
          <FormGroup>
            <FormLabel>Country</FormLabel>
            <FormSelect className={'form-select-country'}>
              <Select
                styles={customStylesSelect}
                options={updatedCountries}
                placeholder={'Select country'}
                classNamePrefix={'select-cs'}
                value={values.country}
                id="country"
                name="country"
                onChange={(value) => {
                  const city = updatedStates(value.value)
                  setValues((prevValue: any) => {
                    return {
                      ...prevValue,
                      country: {
                        label: value?.label,
                        value: value?.value,
                      },
                      city: {
                        label: city.length !== 0 ? city[0].label : '',
                        value: city.length !== 0 ? city[0].value : '',
                      },
                    }
                  })
                }}
              />
            </FormSelect>
            {redeemFormik.errors.country && redeemFormik.values.country?.value && (
              <ErrorMessage> This field cannot be empty </ErrorMessage>
            )}
          </FormGroup>
        </ColCs>
        <ColCs md={4} xs={6} className={'form-col'}>
          <FormGroup>
            <FormLabel>City/State</FormLabel>
            <FormSelect className={'form-select-city'}>
              <Select
                styles={customStylesSelect}
                options={updatedStates(values.country.value)}
                placeholder={'Select city state'}
                classNamePrefix={'select-cs'}
                value={values.city.label !== '' && values.city}
                id="city"
                name="city"
                onChange={(valueState) => {
                  setValues((prevValue: any) => {
                    return {
                      ...prevValue,
                      city: {
                        label: valueState?.label,
                        value: valueState?.label,
                      },
                    }
                  })
                }}
              />
            </FormSelect>
            {redeemFormik.errors.city && redeemFormik.values.city?.name && (
              <ErrorMessage> This field cannot be empty </ErrorMessage>
            )}
          </FormGroup>
        </ColCs>
        <ColCs md={4} xs={6} className={'form-col'}>
          <FormGroup>
            <FormLabel>Zip code</FormLabel>
            <FormInput
              type={'text'}
              placeholder={'Enter zip code'}
              id={'zipCode'}
              name={'zipCode'}
              value={redeemFormik.values.zipCode ?? ''}
              onChange={redeemFormik.handleChange}
            />
          </FormGroup>
          {redeemFormik.errors.zipCode && redeemFormik.values.zipCode && (
            <ErrorMessage> {redeemFormik.errors.zipCode.toString()} </ErrorMessage>
          )}
        </ColCs>
        <ColCs md={8} xs={7} className={'form-col'}>
          <FormGroup>
            <FormLabel>Address</FormLabel>
            <FormInput
              type={'text'}
              placeholder={'Enter address'}
              id={'address'}
              name={'address'}
              value={redeemFormik.values.address ?? ''}
              onChange={redeemFormik.handleChange}
            />
            {redeemFormik.errors.address && redeemFormik.values.address && (
              <ErrorMessage> {redeemFormik.errors.address.toString()} </ErrorMessage>
            )}
          </FormGroup>
        </ColCs>
        <ColCs md={4} xs={5} className={'form-col'}>
          <FormGroup>
            <FormLabel>Passport number</FormLabel>
            <FormInput
              className={'passport'}
              type={'text'}
              placeholder={'Enter passport number'}
              id={'passportNumber'}
              name={'passportNumber'}
              value={redeemFormik.values.passportNumber ?? ''}
              onChange={redeemFormik.handleChange}
            />
            {redeemFormik.errors.passportNumber && redeemFormik.values.passportNumber && (
              <ErrorMessage> {redeemFormik.errors.passportNumber.toString()} </ErrorMessage>
            )}
          </FormGroup>
        </ColCs>
      </RowCs>
      <Protected>
        <IconPortal SrcImageIcon={IconProtected} widthIcon={'20px'} heightIcon={'20px'} />
        <Text>We make sure your information is safe and secure</Text>
      </Protected>
      <RowCs className={'form-row'}>
        {optionAttribute?.map((optionAttribute: optionsAttribute, index: number) => {
          const { optionList, name, type, placeholder } = optionAttribute
          return (
            <ColCs xs={4} className={'form-col'} key={`${optionAttribute.id}`}>
              <FormGroup>
                <FormLabel>{name}</FormLabel>
                {type === 'INPUT' ? (
                  <FormInput
                    type={'text'}
                    placeholder={placeholder ? placeholder : ''}
                    id={`${name}`}
                    name={`${name}`}
                    value={values[`${name}`] ?? ''}
                    onChange={redeemFormik.handleChange}
                  />
                ) : (
                  <FormSelect className="form-select">
                    <Select
                      styles={customStylesSelect}
                      options={optionList}
                      placeholder={`Select ${name}`}
                      classNamePrefix={'select-cs'}
                      defaultInputValue={values[`${name}`]?.label ?? ''}
                      id={`${name}`}
                      name={`${name}`}
                      menuPosition="fixed"
                      onChange={(value) => {
                        setValues((prevValue: any) => {
                          return {
                            ...prevValue,
                            [`${name}`]: {
                              value: value?.value,
                              label: value?.label,
                            },
                          }
                        })
                      }}
                    />
                  </FormSelect>
                )}
                {((type === 'INPUT' && !values[`${name}`]) || (type !== 'INPUT' && !values[`${name}`]?.value)) && (
                  <ErrorMessage> This field cannot be empty </ErrorMessage>
                )}
              </FormGroup>
            </ColCs>
          )
        })}
      </RowCs>
      <DivFooter>
        <CtaSubmit disabled={!redeemFormik.isValid}>Submit</CtaSubmit>
      </DivFooter>
    </form>
  )
}

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 15px;
`

const FormLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary7};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
`
const FormInput = styled.input`
  font-family: 'Din Pro Regular', sans-serif;
  padding: 12px 16px;
  border: 1.5px solid ${({ theme }) => theme.secondary2};
  border-radius: 2px;
  outline: none;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary2};
  color: ${({ theme }) => theme.secondary10};
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  &::placeholder {
    color: ${({ theme }) => theme.secondary5};
    font-weight: 600;
  }
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.passport {
    text-transform: uppercase;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  ::placeholder {
    /* Recent browsers */
    text-transform: none;
  }
`
const DivFooter = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  margin-top: 30px;
`
const CtaSubmit = styled(ButtonPrimary)`
  padding: 16px 32px !important;
  font-size: 24px;
  line-height: 1;
  text-transform: capitalize;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 100%;
`

const Protected = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary3};
`
const Text = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 20px;
  color: ${({ theme }) => theme.secondary6};
  margin: 0 0 0 8px;
`

const ErrorMessage = styled.p`
  margin: 8px 0 0;
  font-family: 'Din Pro Regular', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeText3};
  color: ${({ theme }) => theme.red4};
  white-space: normal;
`
const FormSelect = styled.div`
  &.form-select-size {
    z-index: 10;
    position: relative;
    .select-cs-size__menu-list {
      max-height: 100px;
    }
  }
  &.form-select-country {
    z-index: 15;
    position: relative;
  }
  &.form-select-city {
    z-index: 14;
    position: relative;
  }
`
