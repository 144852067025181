import React from 'react'
import styled from 'styled-components/macro'

import FAQs from '../../../components/FAQs'
import { TypeFaq } from '../../../components/FAQs/FAQItem'
const TextFAQs: TypeFaq[] = [
  {
    title: 'What is Another-1?',
    description:
      'Another-1 is the ultimate web3 luxury fashion platform built on Polygon, where users can build, collect, stake, and trade metaverse-ready digital fashion backed by NFC (Near Field Communication) authenticated physical goods. By repurposing fashion products via NFTs for metaverse interoperability; Another-1 serves as a one-stop shop for a new transcending asset class that merges realities to create a dynamic ecosystem of hypebeasts, designers, brands, and resellers secured on-chain.',
  },
  {
    title: 'Why Another-1?',
    description:
      'Another-1 offers anyone access to an open dApp where users can freely purchase, stake, create, trade, vote on the development of, lend, share and exchange fashion products, bypassing the burdens of physical collections. By utilizing our ‘Become A Creator’ module and connections in the space we seek to migrate the world of fashion digitally and physically on multiple levels to create an authentic phygital experience for the newly launched ADO collections.',
  },
  {
    title: 'What is an ADO?',
    description:
      'Our platform’s NFT Launchpad module (ADO - Another Drop Offering) enables the creation of metaverse-ready phygital fashion backed by physical goods thanks to a unique production process that involves the integration of NFC chips connected to Polygon for on-chain authentication and further IRL gamification via our ‘Flex 2 Earn’ module after which all produced assets are seamlessly integrated for trading on the Another-1 PAM (Phygital Asset Marketplace) which is expected to launch end of Q4.',
  },
  {
    title: 'What is Flex 2 Earn?',
    description:
      "The 'Flex to Earn' module is designed to reward users for “Flexing” products and collections in Another - 1’s Phygital partner network. Therefore F2E represents an unique loyalty program and will be the key application to encourage users to engage in NFC / NFT technology bridging the physical to the virtual / digital worlds. This will also empower the token utility by creating real - world use cases for rewarding both collectors and partners.",
  },
  {
    title: 'What is PAM?',
    description:
      'The Another-1 Phygital Asset Marketplace (PAM) engages consumers with fashion suppliers, facilitating direct access to authenticated physical products, or peer-to-peer resales within the community. Apart from hosting all of the assets generated through the ADOs, the upcoming Another-1 PAM will allow for the fractionalization of digital asset ownership; a platform functionality that enables users to instantly create, own and trade a digital liquid representative of a physical collection, creating new possibilities for investing in the culture.',
  },
  {
    title: 'What other projects are under the AN1 umbrella?',
    description:
      'Apart from frequent collabs with established fashion brands like Casadei, Another-1 is home to: NTR1 META - the world’s coolest NFC chipped phygital sneaker project, Drip-Lab - an upcoming phygital art & fashion project devoted to web3 graffiti and street culture, TSS - the strongest web3 sneaker head community and Sandbox game (TBA).',
  },
]
const FAQsLanding = () => {
  return (
    <FAQsWrapper>
      <TitleFaq>FAQs</TitleFaq>
      <FAQs faq={TextFAQs} />
    </FAQsWrapper>
  )
}
const FAQsWrapper = styled.div`
  width: 100%;
`
const TitleFaq = styled.h1`
  line-height: 1.1667;
  color: ${({ theme }) => theme.secondary10};
  margin-bottom: 40px;
  font-family: 'DIN 1451 Std', sans-serif;
  font-weight: normal;
  @media (max-width: 768px) {
    text-align: center;
  }
`
export default FAQsLanding
