import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface Data {
  whitelist: string[]
}

interface UseGenerateEventWhitelistProps {
  data: Data
  eventId: string
  partnerAddress: string
}

interface UseGenerateEventWhitelistPropsResponse {
  merkleTreeRoot: string
}

export const useGenerateEventWhitelist = (
  options?: UseMutationOptions<UseGenerateEventWhitelistPropsResponse, AxiosError, UseGenerateEventWhitelistProps>
) => {
  const { client } = useApiClientContext()

  return useMutation<UseGenerateEventWhitelistPropsResponse, AxiosError, UseGenerateEventWhitelistProps>(
    async ({ data, eventId, partnerAddress }) => {
      const result = await client.put(
        apiEndpoints.eventWhitelist.replace('{partnerAddress}', partnerAddress).replace('{eventId}', String(eventId)),
        data,
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
        }
      )

      return result?.data
    },
    options
  )
}
