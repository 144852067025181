import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseEventClerksProps {
  partnerAddress: string
  eventId: string
}

interface Clerk {
  address: string
  createdAt: string
  eventId: string
  updatedAt: string
}

export const useEventClerks = (
  params: UseEventClerksProps,
  options?: UseQueryOptions<UseEventClerksProps, AxiosError<any>, any>
) => {
  const { client } = useApiClientContext()

  return useQuery<UseEventClerksProps, AxiosError<any>, Clerk[]>(
    [QueryKeys.EventClerks, params],
    async () => {
      const result = await client.get(
        apiEndpoints.eventClerks
          .replace('{partnerAddress}', params.partnerAddress)
          .replace('{eventId}', params.eventId),
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
        }
      )

      return result?.data
    },
    options
  )
}
