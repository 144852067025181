import { Flex } from 'rebass'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'

import CopyHelper from '../../../components/Copy'
import TruncateText from '../../../components/TruncateText'

type CollectionDetail = {
  label: string
  dataIndex: string
  value: any
}

const DescriptionNFT = (props: any) => {
  const { asset } = props
  const collection = asset && asset.collection

  const DetailInformationTop: Array<CollectionDetail> =
    (collection && [
      {
        label: 'collection',
        dataIndex: 'collection',
        value: collection['name'],
      },
    ]) ||
    []

  const DetailInformationBottom: Array<CollectionDetail> =
    (asset && [
      {
        label: 'blockchain',
        dataIndex: 'blockchain',
        value: 'Polygon',
      },
      {
        label: 'address',
        dataIndex: 'address',
        value: asset.collectionAddress,
      },
      {
        label: 'tokenID',
        dataIndex: 'tokenID',
        value: `# ${asset.tokenId}`,
      },
      {
        label: 'tokenStandard',
        dataIndex: 'tokenStandard',
        value: 'ERC-1155',
      },
    ]) ||
    []
  return (
    <>
      <DescriptionContent>
        <Description>
          <h5>Description</h5>
          <TruncateText text={asset?.description} />
        </Description>
      </DescriptionContent>
      <DivDetailInfo>
        {DetailInformationTop.map(({ label, dataIndex, value }, index: number) => {
          return (
            <InfoItem key={index}>
              <Flex justifyContent="space-between" alignItems={'center'}>
                <FieldName>{label}</FieldName>
                <Data>{value}</Data>
              </Flex>
            </InfoItem>
          )
        })}
      </DivDetailInfo>
      <DivDetailInfo>
        {DetailInformationBottom.map(({ label, dataIndex, value }, index: number) => {
          return (
            <InfoItem key={index}>
              <Flex justifyContent="space-between" alignItems={'center'}>
                <FieldName>{label}</FieldName>
                {(label === 'address' && (
                  <Data style={{ display: 'flex', alignItems: 'center' }}>
                    <CopyHelper toCopy={value}>
                      <TextAddress>{shortenAddress(value)}</TextAddress>
                    </CopyHelper>
                  </Data>
                )) || <Data>{value}</Data>}
              </Flex>
            </InfoItem>
          )
        })}
      </DivDetailInfo>
    </>
  )
}
const DescriptionContent = styled.div`
  padding-top: 32px;
`
const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  margin-bottom: 16px;
  h5 {
    margin: 0 0 16px 0;
    color: ${({ theme }) => theme.white};
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    margin: 0;
  }
  div {
    span {
      color: ${({ theme }) => theme.secondary6};
    }
  }
`
const DivDetailInfo = styled.div`
  padding: 24px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.secondary2};
  }

  & > div:last-of-type {
    margin-bottom: 0;
  }
`
const InfoItem = styled.div`
  margin-bottom: 8px;
`
const FieldName = styled.h5`
  color: ${({ theme }) => theme.secondary7};
  line-height: 1.5;
  margin: 0;
  text-transform: capitalize;
`
const Data = styled.p`
  color: ${({ theme }) => theme.secondary10};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.25;
`
const TextAddress = styled.span`
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary10};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizeText2};
  + span {
    color: ${({ theme }) => theme.secondary5};
  }
`
export default DescriptionNFT
