import { useEffect, useMemo, useState } from 'react'

import { OptionType } from '../entities/OptionType'
import { useGetCollectionsQuery } from '../state/collections/slice'

export const useInitChooseCollection = () => {
  const { data, isLoading } = useGetCollectionsQuery({})
  const [collectionOptions, setCollectionOptions] = useState<OptionType[]>([])
  const [selectedCollection, setSelectedCollection] = useState<OptionType>({})
  const [collectionOptionsHide, setCollectionOptionsHide] = useState<OptionType[]>([])
  const [selectedCollectionHide, setSelectedCollectionHide] = useState<OptionType>({})
  useEffect(() => {
    if (!data || isLoading) {
      return
    }
    const collectionOptionsList: OptionType[] = []
    for (let i = 0; i < data.length; i++) {
      const collection = data[i]
      collectionOptionsList.push({
        value: collection.address,
        label: collection.name,
      })
    }
    setCollectionOptions(collectionOptionsList)
    if (collectionOptionsList.length > 0) setSelectedCollection(collectionOptionsList[0])

    const collectionOptionsListHide: OptionType[] = []
    for (let i = 0; i < data.length; i++) {
      const collection = data[i]
      if (!collection.isDisplayed) {
        collectionOptionsListHide.push({
          value: collection.address,
          label: collection.name,
        })
      }
    }
    setCollectionOptionsHide(collectionOptionsListHide)
    if (collectionOptionsListHide.length > 0) setSelectedCollectionHide(() => collectionOptionsListHide[0])
  }, [data, isLoading])
  const collectionDisplayed = useMemo(() => {
    if (!data || isLoading) {
      return
    }
    return data.filter((item: any) => {
      return item.isDisplayed
    })
  }, [isLoading, data])
  return {
    collectionOptions,
    selectedCollection,
    setSelectedCollection,
    isLoading,
    collectionOptionsHide,
    selectedCollectionHide,
    setSelectedCollectionHide,
    collectionDisplayed,
  }
}
