const getCsvRows = (columns: any, data: any) => {
  const csvRows = data.map((row: any) => {
    const csvRow = columns.map(({ key }: { key: any }) => {
      return row[key]
    })

    return csvRow
  })
  return csvRows
}

const getCsvHeader = (columns: any) => {
  const csvHeader = columns.reduce((currentHeader: any, { title }: { title: any }, index: any) => {
    const isLastColumn = index !== columns.length - 1

    return `${currentHeader}${title}${isLastColumn ? ',' : '\n'}`
  }, '')

  return csvHeader
}

const mergeCsvData = (csvHeader: any, csvRows: any) => {
  csvRows.forEach((row: any) => {
    csvHeader += row.join(',')
    csvHeader += '\n'
  })
  return csvHeader
}

const getCsvData = (columns: any, data: any) => {
  const csvHeader = getCsvHeader(columns)
  const csvRows = getCsvRows(columns, data)

  return mergeCsvData(csvHeader, csvRows)
}
export const exportCSV = ({ columns, data, fileName = 'csvFile' }: { columns: any; data: any; fileName: string }) => {
  const csvData = getCsvData(columns, data)
  const link = document.createElement('a')
  link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData)
  link.target = '_blank'

  link.download = `${fileName}.csv`
  link.click()
}

export const checkStringEmpty = (text: string) => {
  return !text.trim() || text.length === 0
}
interface GreaterObject {
  greaterDay: boolean
  greaterHour: boolean
}
export const checkDateGreater = (date: Date | null, today: Date): undefined | GreaterObject => {
  if (!date) {
    return
  }
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const dayCompare = today.getDate()
  const monthCompare = today.getMonth()
  const yearCompare = today.getFullYear()
  const hoursCompare = today.getHours()
  const minutesCompare = today.getHours()
  let greaterDay = false
  let greaterHour = false
  if (year > yearCompare) {
    greaterDay = true
  }
  if (year === yearCompare && month > monthCompare) {
    greaterDay = true
  }
  if (year === yearCompare && month === monthCompare && day > dayCompare) {
    greaterDay = true
  }
  if (year === yearCompare && month === monthCompare && day === dayCompare && hours > hoursCompare) {
    greaterDay = false
    greaterHour = true
  }
  if (
    year === yearCompare &&
    month === monthCompare &&
    day === dayCompare &&
    hours === hoursCompare &&
    minutes > minutesCompare
  ) {
    greaterDay = false
    greaterHour = true
  }

  return {
    greaterDay,
    greaterHour,
  }
}

export const isVideoLink = (url?: string) => {
  if (!url) {
    return false
  }

  const urlLowerCase = url.toLowerCase()
  return urlLowerCase.endsWith('.mov') || urlLowerCase.endsWith('.mp4')
}
