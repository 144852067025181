import styled from 'styled-components/macro'

import { InputVerificationCodeAnother } from '../../../../theme'

export const ModalAuthWrapper = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  min-height: 280px;
`

export const ModalHeader = styled.div`
  padding-bottom: 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    background-color: ${({ theme }) => theme.secondary3};
  }
`

export const MainHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Heading = styled.h3`
  text-transform: uppercase;
  margin: 0;
  line-height: 1.333334;
`
export const SubHeading = styled.h5`
  margin-top: 6px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary6};
`
export const ModalBody = styled.div``

export const InputVerificationCodeBox = styled(InputVerificationCodeAnother)``

export const Notification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
export const TextNoti = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 143%;
  color: ${({ theme }) => theme.secondary6};
  margin: 0 0 0 8px;
`
export const Submitting = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h5 {
    text-transform: uppercase;
    line-height: 1.5;
    margin: 20px 0 0 0;
    font-size: 32px;
    color: ${({ theme }) => theme.white};
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
    margin: 0;
  }
`
export const Div = styled.div`
  padding-top: 120px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success {
    color: ${({ theme }) => theme.primary6};
  }
  .error {
    color: ${({ theme }) => theme.red5};
  }
  h2 {
    margin: 24px 0 0 0;
    text-align: center;
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: ${({ theme }) => theme.fontSizeText2};
    margin: 6px 0 0 0;
    text-align: center;
  }
`

export const TextVerification = styled.h4`
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  color: ${({ theme }) => theme.secondary7};
  text-align: center;
  margin: 20px 0;
`

export const RetryButtonWrapper = styled.div`
  margin-top: 48px;
`
