import React, { InputHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'

import { CustomLabel, PrimaryCheckbox } from '../CheckBox'
import * as Styled from './styled'

interface FormInputCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

export const FormInputCheckbox = ({ name, label }: FormInputCheckboxProps) => {
  const { register } = useFormContext()

  return (
    <Styled.CheckboxContainer>
      <CustomLabel>
        <PrimaryCheckbox id={name} {...register(name)} />
        {label}
      </CustomLabel>
    </Styled.CheckboxContainer>
  )
}
