import React from 'react'
import styled from 'styled-components/macro'
interface TypeProps {
  fieldName: string
  onClick: () => void
  selectedColumnKeys: any
}
const FormCheckbox = (props: TypeProps) => {
  const { fieldName, onClick, selectedColumnKeys } = props
  return (
    <FormGroup>
      <input type="checkbox" id={fieldName} checked={!!selectedColumnKeys[fieldName]} readOnly={true} />
      <label htmlFor={fieldName} onClick={onClick}>
        {fieldName}
      </label>
    </FormGroup>
  )
}
const FormGroup = styled.div`
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
    color: ${({ theme }) => theme.primary10};
    text-transform: capitalize;
    &::before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.secondary4};
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 4px;
    }
  }
  input:checked + label::before {
    background-color: ${({ theme }) => theme.primary6};
    border-color: ${({ theme }) => theme.primary6};
  }
  input:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 7px;
    width: 4px;
    height: 8px;
    border: solid ${({ theme }) => theme.secondary1};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`
export default FormCheckbox
