import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ButtonPrimary } from '../../../../components/Button'
import { FormInput } from '../../../../components/FormInput'
import Loader from '../../../../components/Loader'
import { QueryKeys } from '../../../../constants/queryKeys'
import { useGetProfile } from '../../../../state/profile/hooks'
import { useCheckClerkInEvent } from '../../../Events/hooks/useCheckClerkInEvent'
import { useAddClerksToEventInRegistry } from '../../hooks/useAddClerksToEventInRegistry'
import { useUpdateEventClerk } from '../../hooks/useUpdateEventClerk'
import { ClerksList } from '../ClerksList'
import { schema } from './schema'
import * as Styled from './styled'
import { backendErrorParser, metamaskErrorParser } from './utils/errorParser'

export interface ClerkFormValues {
  address: string
}

interface ClerkFormProps {
  eventId: string
  continueSkipRoute?: string
}

export const ClerkForm = ({ eventId, continueSkipRoute }: ClerkFormProps) => {
  const queryClient = useQueryClient()
  const { mutateAsync: addClerk, error: updateClerkError, isLoading: isAddingClerk } = useUpdateEventClerk()
  const {
    mutateAsync: addClerkToRegistry,
    error: updateClerkInRegistryError,
    isLoading: isAddingClerkToRegistry,
  } = useAddClerksToEventInRegistry()
  const { mutateAsync: checkIfClerkInEvent } = useCheckClerkInEvent()
  const { data: profile } = useGetProfile()
  const [isClerkInEvent, setIsClerkInEvent] = useState(false)

  const formValues = useForm({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    resetField,
  } = formValues

  const handleAddClerk = async (values: ClerkFormValues) => {
    try {
      const result = await checkIfClerkInEvent({ address: values.address })

      if (result && !isClerkInEvent) {
        setIsClerkInEvent(true)
        return
      }
    } catch (err) {
      // caught error means clerk is not assigned to any event, hence we can proceed without displaying an error
    }

    try {
      await addClerkToRegistry({
        eventId: Number(eventId),
        clerkAddresses: [values.address],
      })

      await addClerk({
        eventId: Number(eventId),
        partnerAddress: profile.address,
        clerkAddress: values.address,
      })

      await queryClient.invalidateQueries([QueryKeys.EventClerks])

      resetField('address')

      setIsClerkInEvent(false)
    } catch (err) {}
  }

  const errorMessage = useMemo(() => {
    if (updateClerkError) {
      return backendErrorParser(updateClerkError)
    }

    if (updateClerkInRegistryError) {
      return metamaskErrorParser(updateClerkInRegistryError)
    }

    return ''
  }, [updateClerkError, updateClerkInRegistryError])

  return (
    <>
      <FormProvider {...formValues}>
        <form onSubmit={handleSubmit(handleAddClerk)}>
          <FormInput name="address" label="Address" autoFocus customError={errorMessage} />
          {isClerkInEvent && (
            <Styled.AlertBox> Clerk already assigned to event. Do you want to continue?</Styled.AlertBox>
          )}
          <ButtonPrimary type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Loader stroke="#ffffff" size="20.5px" /> : 'Add clerk'}
          </ButtonPrimary>
        </form>
      </FormProvider>
      <ClerksList
        eventId={eventId}
        areButtonsDisabled={isAddingClerk || isAddingClerkToRegistry}
        continueSkipRoute={continueSkipRoute}
      />
    </>
  )
}
