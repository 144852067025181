import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as IconPlus } from '../../../../assets/icons/icon-plus.svg'
import { ReactComponent as IconClose } from '../../../../assets/images/svg/icon-close.svg'
import { ButtonSecondary } from '../../../../components/Button'
import Editor from '../Editor'
import MediaUpload from '../MediaUpload'

interface IMultipleItem {
  order: string
  multipleItem?: { [key: string]: string }[]
  setMultipleItem?: (item: Array<any>) => void
  handleFetchMedia: (file: any) => any
  setTitle: (text: string) => void
  title: string
}

const MultipleItem = (props: IMultipleItem) => {
  const [mediaFiles, setMediaFile] = useState<any>()

  const { order, multipleItem = [], setMultipleItem, handleFetchMedia, setTitle, title } = props
  const [linkMediaUpload, setLinkUploadMedia] = useState<{ index: number; link: string }>({
    index: 0,
    link: '',
  })

  useEffect(() => {
    if (!mediaFiles) {
      return
    }

    handleFetchMedia(mediaFiles).then((url: string) => {
      const copyMultipleItem = [...multipleItem]
      multipleItem.length > 0 &&
        copyMultipleItem.splice(linkMediaUpload.index, 1, {
          ...copyMultipleItem[linkMediaUpload.index],
          link: url,
        })

      setMultipleItem && setMultipleItem(copyMultipleItem)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkMediaUpload, mediaFiles])

  const handleAddItem = () => {
    const copyMultipleItem = [...multipleItem]
    copyMultipleItem.push({
      link: '',
      text: '',
    })
    setMultipleItem && setMultipleItem(copyMultipleItem)
  }

  const handleDeleteItem = (index: number) => {
    const multipleItemClone = [...multipleItem]
    multipleItemClone.splice(index, 1)
    setMultipleItem && setMultipleItem(multipleItemClone)
  }

  const handleChangeItemMedia = (index: number, text: string) => {
    const multipleItemClone = [...multipleItem]
    multipleItemClone[index] = {
      ...multipleItemClone[index],
      text,
    }
    setMultipleItem && setMultipleItem(multipleItemClone)
  }

  return (
    <MultipleEditWrapper>
      <Editor
        toolbarId={`${order}_multi-title`}
        placeholder={'Enter content...'}
        setValueRichText={setTitle}
        value={title}
      />
      {multipleItem.map((item, index: number) => {
        return (
          <MultipleEditItem key={`multi_${index}`}>
            <DivFlex>
              <IconCloseBox
                className={'icon-close-box'}
                show={multipleItem.length === 1}
                onClick={() => handleDeleteItem(index)}
              >
                <IconClose />
              </IconCloseBox>
            </DivFlex>
            <MediaBox>
              <MediaUpload
                index={index}
                link={item.link}
                setLinkUploadMedia={setLinkUploadMedia}
                setMediaFile={setMediaFile}
              />
            </MediaBox>

            <Editor
              toolbarId={`${order}_multi_${index}`}
              value={item.text}
              onChangeItem={(index: number, text: string) => handleChangeItemMedia(index, text)}
              index={index}
            />
          </MultipleEditItem>
        )
      })}
      <CtaAddOther onClick={handleAddItem}>
        <IconPlus /> Add other
      </CtaAddOther>
    </MultipleEditWrapper>
  )
}
const MultipleEditWrapper = styled.div`
  width: 100%;
`
const MultipleEditItem = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.secondary3};
  padding: 10px;
  &:not(:last-of-type) {
    .icon-close-box {
      display: flex;
    }
  }
`
const MediaBox = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
`

const DivFlex = styled.div`
  display: flex;
  .form-input {
    margin-bottom: 16px;
  }
`
const IconCloseBox = styled.span<{ show?: boolean }>`
  width: 51px;
  height: 51px;
  border: 1.5px solid ${({ theme }) => theme.secondary2};
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: ${({ theme }) => theme.secondary1};
    }
  }
  ${({ show }) =>
    show &&
    css`
      display: none;
    `}
`
const CtaAddOther = styled(ButtonSecondary)`
  padding: 10px 20px !important;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 16px;
  color: ${({ theme }) => theme.primary10};
  text-transform: none;
  font-weight: 700;
  width: max-content;
  svg {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    path {
      fill: ${({ theme }) => theme.primary10};
    }
  }
`

export default MultipleItem
