import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'

import Fallback from '../../../../assets/images/fallback.svg'
import IconEditImg from '../../../../assets/images/svg/icon-edit.svg'
import VideoBanner from '../../../CollectionDetail/Header/VideoBanner/VideoBanner'

interface IMediaUpload {
  index?: number
  link: string
  setLinkUploadMedia?: ({ index, link }: { index: number; link: string }) => void
  setMediaFile: (file: any) => void
}

const MediaUpload = (props: IMediaUpload) => {
  const { index = 0, link, setLinkUploadMedia, setMediaFile } = props

  const [file, setFile] = useState<any>({
    name: '',
  })
  const [srcMedia, setSrcMedia] = useState<string>('')

  const handleChangeMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', async () => {
      setFile(file)
      setMediaFile(file)
      setSrcMedia(String(reader.result))
      setLinkUploadMedia &&
        setLinkUploadMedia({
          index,
          link: String(reader.result),
        })
    })
  }

  return (
    <MediaUploadWrapper>
      <AvatarBoxEdit>
        <label htmlFor={`media-${index}`}>
          <IconEdit>
            <img src={IconEditImg} alt={'Edit_Media'} />
          </IconEdit>
        </label>
        <input
          type={'file'}
          accept="image/*,video/*"
          id={`media-${index}`}
          hidden={true}
          name={'media'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeMedia(event)
          }}
        />
        <Avatar>
          {srcMedia && (
            <>
              {file.type.includes('video') ? (
                <VideoBanner url={srcMedia} />
              ) : (
                <img src={srcMedia} alt={'Avatar'} onError={(e) => (e.currentTarget.src = Fallback)} />
              )}
            </>
          )}
          {!srcMedia &&
            link &&
            (isVideoLink(link) ? (
              <VideoBanner url={link} />
            ) : (
              <img src={link} alt={'Avatar'} onError={(e) => (e.currentTarget.src = Fallback)} />
            ))}
        </Avatar>
      </AvatarBoxEdit>
    </MediaUploadWrapper>
  )
}
const MediaUploadWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const AvatarBoxEdit = styled.div`
  background-color: ${({ theme }) => theme.secondary1};
  position: relative;
  border: 2px solid ${({ theme }) => theme.secondary2};
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  label {
    position: relative;
    z-index: 5;
  }
`
const Avatar = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 100%;
  position: absolute;
  z-index: 1;
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const IconEdit = styled.span`
  width: 36px;
  height: 36px;
  display: block;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
export default MediaUpload
