import { ReactComponent as IconPlus } from 'assets/icons/icon-plus.svg'
import { ButtonPrimary } from 'components/Button'
import ColCs from 'components/ColCs'
import { FormGroup, FormLabel } from 'components/Forms'
import Loader from 'components/Loader'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import RowCs from 'components/RowCs'
import { OptionType } from 'entities/OptionType'
import { useApi } from 'hooks/useApi'
import { useInitChooseType } from 'hooks/useInitChooseType'
import { useState } from 'react'
import Select from 'react-select'
import { useCreateAttributesAssignMutation } from 'state/assignAttributes/slice'
import styled from 'styled-components/macro'
import { customStylesSelect } from 'theme'

const FormAssign = (props: any) => {
  const { collectionOptions, selectedCollection, refetch, handleChangeSelectedCollection, isSelectDisabled } = props
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const optionAttributes = useInitChooseType()
  const [currentOptionAttribute, setCurrentOptionAttribute] = useState<OptionType>({
    label: '',
    value: '',
  })
  const { post } = useApi()
  const [createAttributesAssign] = useCreateAttributesAssignMutation()
  const [isAssign, setIsAssign] = useState<boolean>(false)
  const handleSuccess = () => {
    setShowSuccess(true)
  }
  const handleError = () => {
    setShowError(true)
  }
  const handleFinally = () => {
    setIsAssign(false)
  }
  const handleAssignAttribute = () => {
    setIsAssign(true)
    createAttributesAssign({
      post,
      attributeId: currentOptionAttribute.value || '',
      collectionAddress: selectedCollection.value,
      refetch,
      handleSuccess,
      handleError,
      handleFinally,
    })
  }
  return (
    <div>
      <RowCs space={-16}>
        <ColCs xs={12} sm={6} gutter={16}>
          <FormGroup>
            <FormLabel>Collection</FormLabel>
            <FormSelect>
              {selectedCollection?.value &&
                ((isSelectDisabled && <h3>{selectedCollection.label}</h3>) || (
                  <Select
                    styles={customStylesSelect}
                    defaultValue={selectedCollection}
                    options={collectionOptions}
                    classNamePrefix={'select-cs'}
                    id="collectionsOption"
                    name="collectionsOption"
                    onChange={handleChangeSelectedCollection}
                  />
                ))}
            </FormSelect>
          </FormGroup>
        </ColCs>
        <ColCs xs={12} sm={6} gutter={16}>
          <FormGroup>
            <FormLabel>Attributes</FormLabel>
            <FormSelect>
              <Select
                isClearable
                styles={customStylesSelect}
                options={optionAttributes}
                placeholder={'Select attributes'}
                classNamePrefix={'select-cs'}
                id="chooseAttributes"
                name="chooseAttributes"
                onChange={(value) => {
                  if (!value) {
                    return
                  }
                  setCurrentOptionAttribute(value)
                }}
              />
            </FormSelect>
          </FormGroup>
        </ColCs>
      </RowCs>

      <CtaAdd onClick={handleAssignAttribute} disabled={isAssign}>
        {isAssign ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loader size={'24px'} stroke={'#151515'} />
            <span className={'icon-plus'} style={{ marginLeft: '4px' }}>
              <IconPlus />
            </span>
            Assign attribute
          </div>
        ) : (
          <>
            <span className={'icon-plus'}>
              <IconPlus />
            </span>
            Assign attribute
          </>
        )}
      </CtaAdd>
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={`Assign Attributes Failed`} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={`Assign Attributes Successfully.`}
      />
    </div>
  )
}
const CtaAdd = styled(ButtonPrimary)`
  padding: 10px 16px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 32px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  .icon-plus {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    align-self: flex-start;
    margin-top: 2px;
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: ${({ theme }) => theme.secondary1};
      }
    }
  }
`
const FormSelect = styled.div``
export default FormAssign
