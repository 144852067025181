import styled from 'styled-components/macro'

export const InfoCard = styled.button<{ active?: boolean }>`
  background-color: transparent;
  padding: 1rem;
  outline: none;
  width: 100% !important;
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary2};
  }
  border: 1px solid ${({ theme }) => theme.secondary3};
`

export const OptionCard = styled(InfoCard as any)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1rem;
`

export const OptionCardRight = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  width: 100%;
  display: flex;
  text-align: left;
  align-content: flex-start;
  align-items: left;
  justify-content: center;
  height: 100%;
  margin-left: 15px;
  h5 {
    ${({ theme }) => theme.flexRowNoWrap};
    color: ${({ theme }) => theme.white} !important;
    font-size: 1rem;
    font-family: 'Din Pro Regular', sans-serif;
    padding-right: 20px;
    font-weight: 500;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
  }

  span {
    color: ${({ theme }) => theme.secondary6};
    margin-top: 10px;
    font-size: 12px;
  }
`

export const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
  text-align: center;
  margin-top: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
    background-color: ${({ theme }) => theme.secondary3};
  }
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`
