import * as React from 'react'
export const Calendar = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" {...props}>
    <path
      fill="#00FFA3"
      fillRule="evenodd"
      d="M19.26 10.06H5.166a.593.593 0 0 1 0-1.185H19.26a.593.593 0 0 1 0 1.186ZM15.729 13.148a.596.596 0 0 1-.596-.593.59.59 0 0 1 .589-.593h.007a.593.593 0 0 1 0 1.186ZM12.22 13.148a.596.596 0 0 1-.596-.593.59.59 0 0 1 .589-.593h.007a.593.593 0 0 1 0 1.186ZM8.704 13.148a.597.597 0 0 1-.597-.593.59.59 0 0 1 .59-.593h.007a.593.593 0 0 1 0 1.186ZM15.729 16.222a.596.596 0 0 1-.596-.593.59.59 0 0 1 .589-.593h.007a.593.593 0 0 1 0 1.186ZM12.22 16.222a.596.596 0 0 1-.596-.593.59.59 0 0 1 .589-.593h.007a.593.593 0 0 1 0 1.186ZM8.704 16.222a.597.597 0 0 1-.597-.593.59.59 0 0 1 .59-.593h.007a.593.593 0 0 1 0 1.186ZM15.406 6.808a.593.593 0 0 1-.593-.593V3.613a.593.593 0 0 1 1.186 0v2.602a.593.593 0 0 1-.593.593ZM9.02 6.808a.593.593 0 0 1-.594-.593V3.613a.593.593 0 0 1 1.186 0v2.602a.593.593 0 0 1-.593.593Z"
      clipRule="evenodd"
    />
    <mask
      id="a"
      width={16}
      height={17}
      x={4}
      y={4}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <path fill="#fff" fillRule="evenodd" d="M4.5 4.269h15.419V20.02H4.5V4.269Z" clipRule="evenodd" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#00FFA3"
        fillRule="evenodd"
        d="M8.865 5.455c-2.05 0-3.179 1.093-3.179 3.079v7.155c0 2.029 1.13 3.145 3.18 3.145h6.687c2.05 0 3.18-1.096 3.18-3.085V8.534c.003-.977-.26-1.736-.78-2.258-.537-.538-1.363-.821-2.393-.821H8.865Zm6.688 14.565H8.865c-2.692 0-4.365-1.66-4.365-4.331V8.534c0-2.632 1.673-4.265 4.365-4.265h6.695c1.352 0 2.469.404 3.232 1.169.74.744 1.13 1.814 1.127 3.097v7.214c0 2.634-1.674 4.271-4.366 4.271Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
