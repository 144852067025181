import { ModalBase } from '../../../../components/ModalBase/ModalBase'
import { ClerkForm } from '../ClerkForm'

interface ClerksModalProps {
  isOpen: boolean
  onClose: () => void
  eventId: string | null
}

export const ClerksModal = ({ isOpen, onClose, eventId }: ClerksModalProps) => {
  return (
    <ModalBase isOpen={isOpen} onDismiss={onClose} header="Manage Clerks">
      <ClerkForm eventId={eventId as string} />
    </ModalBase>
  )
}
