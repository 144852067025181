import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseAddAllowedCollectionToEventProps {
  eventId: string
  partnerAddress: string
  collectionAddress: string
}

export const useAddAllowedCollectionToEvent = (
  options?: UseMutationOptions<any, AxiosError, UseAddAllowedCollectionToEventProps>
) => {
  const { client } = useApiClientContext()

  return useMutation<any, AxiosError, UseAddAllowedCollectionToEventProps>(
    async ({ eventId, partnerAddress, collectionAddress }) => {
      const result = await client.put(
        apiEndpoints.updateAllowedCollectionToEvent
          .replace('{partnerAddress}', partnerAddress)
          .replace('{eventId}', eventId)
          .replace('{collectionAddress}', collectionAddress),
        {},
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
          params: {
            force: true,
          },
        }
      )

      return result?.data
    },
    options
  )
}
