import ImgDefaultItem from 'assets/images/fallback.svg'
import Pagination from 'rc-pagination'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, Text } from 'rebass'
import styled from 'styled-components/macro'

import IconLeft from '../../../assets/images/svg/icon-chevronleft.svg'
import IconRight from '../../../assets/images/svg/icon-chevronright.svg'
import { PaginationBox } from '../../../components/Pagination'
import { useGetListNFTsQuery } from '../../../state/nfts/slice'

const ListMoreCollection = (props: any) => {
  const { collectionAddress, userAddress } = props
  const history = useHistory()
  const [page, setPage] = useState<number>(1)
  const handleChangePage = (current: number) => {
    setPage(current)
  }
  const { data, isLoading, isError } = useGetListNFTsQuery({
    userAddress,
    collectionAddress,
    page: page - 1,
    limit: 12,
  })
  return (
    <ListItemWrapper>
      {!isLoading && !isError && (
        <>
          <Grid>
            {data.assets.length > 0 &&
              data.assets.map((item: any, index: number) => (
                <ItemBox key={index}>
                  <DivItem>
                    <ImageBox
                      className={'image-box'}
                      onClick={() => {
                        history.push(`/nft/${collectionAddress}/${item.tokenId}`)
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: 'smooth',
                        })
                      }}
                    >
                      <OverlayImageBox className={'overlay-image-box'} />
                      <CustomImage src={item?.image ? item?.image : ImgDefaultItem} />
                    </ImageBox>
                    {item?.metadata?.name && <TitleCard className={'title-card'}>{item.metadata.name}</TitleCard>}

                    {item?.collection?.name && <CollectionInfo fontSize="14px">{item.collection.name}</CollectionInfo>}
                  </DivItem>
                </ItemBox>
              ))}
          </Grid>
          <PaginationBox>
            <Pagination
              onChange={(current) => {
                handleChangePage(current)
              }}
              current={page}
              pageSize={12}
              total={data.totalItems}
              prevIcon={() => (
                <SpanIcon>
                  <img src={IconLeft} alt={'icon_left'} />
                </SpanIcon>
              )}
              nextIcon={() => (
                <SpanIcon>
                  <img src={IconRight} alt={'icon_right'} />
                </SpanIcon>
              )}
            />
          </PaginationBox>
        </>
      )}
    </ListItemWrapper>
  )
}
const ListItemWrapper = styled.div`
  margin-top: 32px;
`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
`
const ItemBox = styled.div`
  padding: 0 16px;
  flex: 0 0 25%;
  max-width: 25%;
  margin-bottom: 16px;
  @media (max-width: 1024px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media (max-width: 575px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const DivItem = styled.div``
const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`
const OverlayImageBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: rgba(4, 33, 23, 0.6);
  z-index: 10;
  opacity: 0;
`
const ImageBox = styled.div`
  position: relative;
  transition: all 0.3s;
  border: 2px solid transparent;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.primary6};
    .overlay-image-box {
      opacity: 1;
    }
    .icon-heart {
      opacity: 1;
      visibility: visible;
    }
  }
  .overlay {
    z-index: 15;
  }
  .icon-heart {
    position: absolute;
    z-index: 15;
    top: 20px;
    right: 20px;
    width: 22px;
    height: 22px;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;

    &.active {
      color: ${({ theme }) => theme.primary6};
      fill: ${({ theme }) => theme.primary6};
    }
  }
`
const CustomImage = styled(Image)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
`
const TitleCard = styled.h5`
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary10};
  margin: 15px 0 12px;
`
const CollectionInfo = styled(Text)`
  color: ${({ theme }) => theme.primary6};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`
export default ListMoreCollection
