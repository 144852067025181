import React from 'react'
import styled from 'styled-components/macro'

import DiscordIcon from '../../../assets/icons/discord2.svg'
import ImgShadow from '../../../assets/images/img/dropshadow_logo.png'
import Logo from '../../../assets/images/img/Logo.png'
import { ButtonHasIcon } from '../../../components/Button'
const Community = () => {
  return (
    <CommunityWrapper>
      <CommunityContainer>
        <DivFlex>
          <Content>
            <BoxRichText>
              <MainHeading>
                Join our <span>community</span>
              </MainHeading>
              <TextDesc>
                Wheter you are a designer, collector or enthusiast.. enthusiast this is your call to become a part of
                our diverse community and embark on a unique journey of merging the physical and digital realms of two
                of the most hype cultures.
              </TextDesc>
              <ButtonDiscord>
                <a href="https://discord.com/invite/Hr5JrtGw9t" target="_blank" rel="noreferrer">
                  <img src={DiscordIcon} alt={'discord_icon'} />
                  <p>JOIN OUR COMMUNITY</p>
                </a>
              </ButtonDiscord>
            </BoxRichText>
          </Content>
          <BoxImage>
            <ImageAnimation>
              <DivLogo>
                <img src={Logo} alt={'logo_another1'} />
              </DivLogo>
              <DivDropShadow>
                <img src={ImgShadow} alt={'drop_shadow'} />
              </DivDropShadow>
            </ImageAnimation>
          </BoxImage>
        </DivFlex>
      </CommunityContainer>
    </CommunityWrapper>
  )
}
const CommunityWrapper = styled.div`
  width: 100%;
`
const CommunityContainer = styled.div`
  padding: 48px;
  @media (max-width: 1024px) {
    padding: 32px 16px;
  }
`
const DivFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
`
const Content = styled.div`
  flex: 0 0 75%;
  max-width: 75%;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
  }
`
const BoxImage = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  @media (max-width: 1024px) {
    padding-right: 0;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
`
const MainHeading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.display2};
  line-height: 1.16667;
  color: ${({ theme }) => theme.secondary10};
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 0;
  font-family: 'DIN 1451 Std', sans-serif;
  span {
    color: ${({ theme }) => theme.primary6};
  }
  @media (max-width: 768px) {
    max-width: 330px;
    margin: 60px auto 12px;
  }
`
const BoxRichText = styled.div`
  max-width: 580px;
  @media (max-width: 1024px) {
    max-width: 446px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`
const TextDesc = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText1};
  line-height: 1.6;
  color: ${({ theme }) => theme.secondary6};
  margin-top: 0;
  margin-bottom: 32px;
`
const ButtonDiscord = styled(ButtonHasIcon)`
  a {
    display: flex;
    text-decoration: none;
    color: white;
  }

  img {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  p {
    margin: 0;
  }
`
const ImageAnimation = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1024px) {
    top: -40px;
  }
  @media (max-width: 768px) {
    position: static;
    top: auto;
    max-width: 270px;
    width: 100%;
    margin: 0 auto;
  }
`
const DivLogo = styled.div`
  margin-bottom: 32px;
  animation: translateVertical 2s infinite alternate-reverse;
  img {
    width: 100%;
    height: 100%;
    display: block;
    vertical-align: middle;
  }
`
const DivDropShadow = styled.div`
  img {
    width: 100%;
    height: 100%;
    display: block;
    vertical-align: middle;
  }
`
export default Community
