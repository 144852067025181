import { Input } from '@rebass/forms'
import { ButtonSecondary } from 'components/Button'
import { darken } from 'polished'
import React from 'react'
import { X } from 'react-feather'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'

import SearchIcon from '../../assets/images/Search-icon.svg'
import IconPortal from '../IconPortal'

interface Props {
  onSubmit?: any
  searchText?: string
}

const SearchBar = (props: Props) => {
  const { onSubmit, searchText } = props
  return (
    <SearchBox as="form" onSubmit={(e: any) => e.preventDefault()}>
      <IconPortal SrcImageIcon={SearchIcon} widthIcon={'24px'} heightIcon={'24px'} />
      <Input
        id="search"
        name="search"
        type="text"
        placeholder="Search items, collections & accounts"
        autoComplete="off"
        value={searchText}
        onChange={(e) => onSubmit(e.target.value)}
      />
      {searchText && searchText?.length > 0 && (
        <BtnClear onClick={() => onSubmit('')}>
          <X />
        </BtnClear>
      )}
    </SearchBox>
  )
}

export default SearchBar

const SearchBox = styled(Flex)`
  display: flex;
  flex: 1;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary11};
  padding: 5px 15px;
  height: 48px;
  &:hover {
    border: 2px solid ${({ theme }) => theme.secondary3};
  }
  img {
    vertical-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  input {
    font-family: 'Din Pro Regular', sans-serif;
    color: ${({ theme }) => darken(0.53, theme.white)};
    border: none;
    font-weight: 500;

    & :focus-visible {
      outline: none;
    }
  }
`

const BtnClear = styled(ButtonSecondary)`
  background-color: transparent;
  padding: 0px;
  width: 30px;

  &:hover {
    background-color: transparent;
  }

  svg {
    stroke: ${({ theme }) => darken(0.53, theme.white)};
  }
`
