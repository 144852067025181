import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { parseUnits } from 'ethers/lib/utils'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ButtonPrimary } from '../../../../components/Button'
import { FormInput } from '../../../../components/FormInput'
import Loader from '../../../../components/Loader'
import { QueryKeys } from '../../../../constants/queryKeys'
import { useAnother1ERC20Decimals } from '../../../../hooks/useAnother1ERC20Decimals'
import { preventNonNumbersOnKeyPress } from '../../../../utils/preventNonNumbersOnKeyPress'
import { useDepositTokensToFlex2Earn } from '../../hooks/useDepositTokensToFlex2Earn'
import { useIncreaseAnother1TokenAllowance } from '../../hooks/useIncreaseAnother1TokenAllowance'
import { schema } from './schema'

export interface DepositTokenFormValues {
  amount: string
}

interface DepositTokenFormProps {
  onSuccess: () => void
}

export const DepositTokenForm = ({ onSuccess }: DepositTokenFormProps) => {
  const formValues = useForm({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formValues

  const { data: decimals } = useAnother1ERC20Decimals()

  const { mutateAsync: depositTokens } = useDepositTokensToFlex2Earn()
  const { mutateAsync: increaseAllowance } = useIncreaseAnother1TokenAllowance()

  const [isAllowanceIncreased, setIsAllowanceIncreased] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const handleDepositTokens = async (values: DepositTokenFormValues) => {
    try {
      const amount = parseUnits(values.amount, decimals).toString()

      if (!isAllowanceIncreased) {
        await increaseAllowance({
          address: process.env.REACT_APP_ANOTHER1_FLEX2EARN_ADDRESS as string,
          amount: amount,
        })

        setIsAllowanceIncreased(true)
      } else {
        await depositTokens({ amount })
      }
    } finally {
      if (isAllowanceIncreased) {
        await queryClient.invalidateQueries([QueryKeys.Erc20Balance])
        await queryClient.invalidateQueries([QueryKeys.EventsSummary])
        onSuccess()
      }
    }
  }

  const submitText = isAllowanceIncreased ? 'Deposit Tokens' : 'Increase Allowance'

  return (
    <FormProvider {...formValues}>
      <form onSubmit={handleSubmit(handleDepositTokens)}>
        <FormInput name="amount" label="Amount" autoFocus onKeyPress={preventNonNumbersOnKeyPress} />
        <ButtonPrimary type="submit">
          {isSubmitting ? <Loader stroke="#ffffff" size="20.5px" /> : submitText}
        </ButtonPrimary>
      </form>
    </FormProvider>
  )
}
