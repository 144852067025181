import { ReactComponent as ChevronDown } from 'assets/icons/icon-chevrondown.svg'
import { ReactComponent as IconSearch } from 'assets/images/Search-icon.svg'
import ColCs from 'components/ColCs'
import { FormGroup, FormInput, FormLabel } from 'components/Forms'
import Loader from 'components/Loader'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import RowCs from 'components/RowCs'
import { Role } from 'constants/roles'
import { useFormik } from 'formik'
import { useApi } from 'hooks/useApi'
import React, { useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import { useGetProfile } from 'state/profile/hooks'

import { apiEndpoints } from '../../../../constants/apiEndpoints'
import { CollectionType } from '../../../../entities/Collection'
import { OptionType } from '../../../../entities/OptionType'
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'
import { useGetCollectionsQuery } from '../../../../state/collections/slice'
import { useAppDispatch } from '../../../../state/hooks'
import { usersManagementApi } from '../../../../state/usersManagement/slice'
import { customStylesSelect } from '../../../../theme'
import { schema } from './schema'
import * as Styled from './styled'

const DropdownIndicator = () => (
  <div style={{ height: 24, width: 32 }}>
    <IconSearch />
  </div>
)

const initialValues = {
  role: {
    label: '',
    value: '',
  },
  multiCollection: [],
  userAddress: '',
}

export const UserRoleForm = () => {
  const { post } = useApi()
  const dispatch = useAppDispatch()
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [errorMessage, setErrormessage] = useState<any>('')
  const { data: profile } = useGetProfile()
  const { data: collectionsData } = useGetCollectionsQuery({})

  const collections: OptionType[] =
    (collectionsData &&
      collectionsData.map((collection: CollectionType) => {
        return { value: collection.address, label: collection.name }
      })) ||
    []

  const roles: OptionType[] = useMemo(() => {
    if (profile.roles.some((x: any) => x.role === Role.ADMIN)) {
      return [
        { value: Role.ADMIN, label: 'Admin' },
        { value: Role.MANAGEMENT_TEAM, label: 'Management team' },
        { value: Role.FACTORY_WORKER, label: 'Factory worker' },
        { value: Role.COLLECTION_OPERATOR, label: 'Collection Operator' },
        { value: Role.PARTNER, label: 'Partner' },
      ]
    }

    if (profile.roles.some((x: any) => x.role === Role.MANAGEMENT_TEAM)) {
      return [
        { value: Role.FACTORY_WORKER, label: 'Factory worker' },
        { value: Role.COLLECTION_OPERATOR, label: 'Collection Operator' },
      ]
    }

    return []
  }, [profile])

  const permissionForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      const { userAddress, role } = values
      try {
        await post({
          url: apiEndpoints.addUserRole,
          data: {
            userAddress,
            role: role.value,
            collections: values.multiCollection,
          },
          callback: () => {
            setShowSuccess(true)
            dispatch(usersManagementApi.util.invalidateTags(['UsersManagement']))
          },
          errorCallback: (res) => {
            setShowError(true)
            setErrormessage(res.response?.data)
          },
        })
      } catch (e) {
        console.log(e, 'ERROR ADD ROLE')
      }
    },
  })
  const { values, handleChange, handleSubmit, setValues, isSubmitting, errors } = permissionForm
  const [isOpenCollection, setIsOpenCollection] = useState<boolean>(false)
  const multiCollectionNode = useRef<HTMLDivElement>()
  useOnClickOutside(multiCollectionNode, isOpenCollection ? () => setIsOpenCollection(false) : undefined)

  const handleRoleSelect = (value: any) => {
    setValues((prevValue: any) => {
      return {
        ...prevValue,
        role: {
          value: value?.value,
          label: value?.label,
        },
      }
    })
  }

  return (
    <form onSubmit={handleSubmit} data-testid="role-form">
      <Styled.DivFlex>
        <RowCs space={-16} className={'row-cs'}>
          <ColCs gutter={16} sm={6} xs={12}>
            <FormGroup>
              <FormLabel>User wallet address</FormLabel>
              <FormInput
                type="text"
                placeholder="Please enter the address"
                id="userAddress"
                name="userAddress"
                value={values.userAddress ?? ''}
                onChange={handleChange}
                className={errors?.userAddress && values.userAddress ? 'error' : ''}
                data-testid="user-address-input"
              />
              {errors.userAddress && values.userAddress && (
                <Styled.Error data-testid="user-address-error"> {errors.userAddress.toString()} </Styled.Error>
              )}
            </FormGroup>
          </ColCs>
          <ColCs gutter={16} sm={6} xs={12}>
            <FormGroup>
              <FormLabel>Role</FormLabel>
              <Styled.FormSelect className="form-select" data-testid="role-select-input">
                <Select
                  styles={customStylesSelect}
                  options={roles}
                  placeholder="Select role"
                  classNamePrefix="select-cs"
                  id="role"
                  name="role"
                  menuPosition="fixed"
                  isSearchable={false}
                  onChange={handleRoleSelect}
                />
              </Styled.FormSelect>
            </FormGroup>
          </ColCs>
          {(values.role.value === Role.FACTORY_WORKER || values.role.value === Role.COLLECTION_OPERATOR) && (
            <ColCs gutter={16} sm={6} xs={12}>
              <FormGroup style={{ marginBottom: '0' }}>
                <FormLabel>Collection</FormLabel>
                <Styled.Dropdown ref={multiCollectionNode as any}>
                  <Styled.DivSelected onClick={() => setIsOpenCollection(!isOpenCollection)}>
                    <div>
                      {values.multiCollection.length > 0 ? (
                        values.multiCollection.map((value: OptionType, index: number) => {
                          return (
                            <span key={value.value}>
                              {index === 0 ? '' : ', '}
                              {value.label}
                            </span>
                          )
                        })
                      ) : (
                        <Styled.SpanPlaceholder>Select collections</Styled.SpanPlaceholder>
                      )}
                    </div>
                    <Styled.SpanIcon isVisible={isOpenCollection}>
                      <ChevronDown />
                    </Styled.SpanIcon>
                  </Styled.DivSelected>
                  <Styled.FormSelectMulti className="form-select-multi" isVisible={isOpenCollection}>
                    <Select
                      styles={customStylesSelect}
                      options={collections}
                      placeholder="Search by collection name"
                      classNamePrefix="select-cs"
                      id="multiCollection"
                      name="multiCollection"
                      backspaceRemovesValue={false}
                      components={{ DropdownIndicator, IndicatorSeparator: null }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen
                      onChange={(value) => {
                        setValues((prevValue: any) => {
                          return {
                            ...prevValue,
                            multiCollection: [value],
                          }
                        })
                      }}
                    />
                  </Styled.FormSelectMulti>
                </Styled.Dropdown>
              </FormGroup>
            </ColCs>
          )}

          <ColCs gutter={16} sm={6} xs={12} style={{ alignSelf: 'flex-end' }}>
            <Styled.CtaAddRole
              type="submit"
              disabled={!permissionForm.dirty || !permissionForm.isValid}
              data-testid="add-role-btn"
            >
              {!isSubmitting ? 'Add role' : <Loader size={'24px'} stroke={'#151515'} />}
            </Styled.CtaAddRole>
          </ColCs>
        </RowCs>
      </Styled.DivFlex>
      <ModalError
        isOpen={showError}
        onDismiss={() => setShowError(false)}
        message={errorMessage?.errorMessage || 'Unexpected error occurred'}
      />
      <ModalSuccess isOpen={showSuccess} onDismiss={() => setShowSuccess(false)} message="Add role successfully." />
    </form>
  )
}
