export interface ItemCollectionDetailType {
  image?: string
  name?: string
  creator?: string
  address?: string
  id?: string
}

export interface ContentCollectionDetailType {
  text?: string
  title?: string
  mediaLink?: string
}

export interface DropdownItemType {
  icon?: string
  name?: string
}

export interface TypeNFCPhygital {
  collectionAddress: string
  tokenId: string
  nfcId: string | number | null
  name: string | null
  description: string | null
  image: string | null
  thumbnail: string | null
  metadata: {
    name: string | null
    image: string | null
    tokenId: number | null
    thumbnail: string | null
    attributes: { value: string | null; trait_type: string | null }[]
  }
  creatorAddress: string | null
  mintedAt: string | null
  ownerAddress: string | null
  isMinted: boolean
  status: number
}

export enum OptionsAdd {
  SUB_TITLE,
  LEFT_SIDE_IMAGE,
  RIGHT_SIDE_IMAGE,
  FULL_ROW_IMAGE,
  FULL_ROW_TEXT,
  FAQ,
  MULTIPLES_ITEM,
}
export interface FAQItem {
  title: string
  text: string
}

export interface IFile {
  order: number
  url: string
  alt?: string
  name?: string
  file?: any
}
interface MultipleImage {
  text?: string
  link?: string
}
export interface SectionEdit {
  type: OptionsAdd
  caption?: string
  text?: string
  link?: IFile[]
  items?: FAQItem[] | MultipleImage[]
  create?: string
}
