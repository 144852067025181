import styled from 'styled-components/macro'

export const TitleContainer = styled.div`
  background: rgba(44, 84, 187, 0.1);
  border-radius: 12px;
  padding: 12px 20px;
  margin-bottom: 42px;
`

export const Title = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 36px;
  color: #07a6ff;
`

export const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
