import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components/macro'

import IconEditImg from '../../assets/images/svg/icon-edit-green.svg'
import { FormInputError } from '../FormInputError'
import { FormInputLabel } from '../FormInputLabel'

interface FormInputPicture {
  name: string
  label: string
  defaultImageInputName: string
}

export const FormInputPicture = ({ name, label, defaultImageInputName }: FormInputPicture) => {
  const {
    register,
    formState: { errors },
    watch,
    resetField,
    setValue,
  } = useFormContext()

  const errorMessage = useMemo(() => {
    if (!errors || !errors[name] || !errors[name]?.message) {
      return ''
    }

    return errors[name]?.message
  }, [errors, name])

  const uploadedImage = watch(name)
  const uploadedImageUrl = watch(defaultImageInputName)

  const preview = useMemo(() => {
    if (!uploadedImage || !uploadedImage[0]) {
      return null
    }

    return URL.createObjectURL(uploadedImage[0])
  }, [uploadedImage])

  const handleClear = () => {
    resetField(name)

    if (defaultImageInputName) {
      setValue(defaultImageInputName, '')
    }
  }

  return (
    <>
      <FormInputLabel htmlFor={name}>{label}</FormInputLabel>
      {preview || uploadedImageUrl ? (
        <Preview src={preview || uploadedImageUrl} />
      ) : (
        <FileUploadWrapper htmlFor={name}>
          <IconEdit>
            <img src={IconEditImg} alt="Edit_Cover_Image" />
          </IconEdit>
        </FileUploadWrapper>
      )}
      <UploadButton htmlFor={name}> Upload </UploadButton>
      {(preview || uploadedImageUrl) && (
        <ClearButton onClick={handleClear} type="button">
          Clear
        </ClearButton>
      )}
      <FormInputError> {errorMessage as string} </FormInputError>
      <input id={name} type="file" accept="image/*" hidden {...register(name)} />
      {defaultImageInputName && (
        <input id={defaultImageInputName} type="file" accept="image/*" hidden {...register(defaultImageInputName)} />
      )}
    </>
  )
}

const FileUploadBase = css`
  border: 2px solid #232323;
  border-radius: 4px;
  background: #151515;
  height: 319px;
  width: 100%;
`

const FileUploadWrapper = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${FileUploadBase};
  cursor: pointer;
`

const IconEdit = styled.span`
  width: 56px;
  height: 56px;
`

const Preview = styled.img`
  ${FileUploadBase};
  width: 100%;
  height: 319px;
  object-fit: cover;
`

const UploadUploadBase = css`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  cursor: pointer;
  padding: 10px 24px;
  border: 2px solid #232323;
  margin-top: 16px;
  background-color: transparent;
`

const UploadButton = styled.label`
  ${UploadUploadBase}
`

const ClearButton = styled.button`
  ${UploadUploadBase}
`
