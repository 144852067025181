import { useMemo } from 'react'

import { TableCellName } from '../../../../components/TableCellName'
import { TableNoMinHeight } from '../../../../components/TableNoMinHeight'
import { formatDateFromTimestamp } from '../../../../utils/formatDate'
import { useEventsListContext } from '../../../Events/context/EventListContext'
import { Event } from '../../../Events/models/Event'

interface EventsTableProps {
  ActionsComponent?: any
}

export const EventsTable = ({ ActionsComponent }: EventsTableProps) => {
  const { events } = useEventsListContext()

  const columns: any = useMemo(() => {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        width: 120,
        align: 'left',
        className: 'secondary10',
        render: (value: string) => {
          return <TableCellName>{value}</TableCellName>
        },
      },
      {
        title: 'START/END',
        dataIndex: 'startTime',
        key: 'startTime',
        align: 'left',
        width: 120,
        className: 'secondary10',
        render: (value: number, object: Event) => {
          return (
            <div>
              <div>{formatDateFromTimestamp(value)}</div>
              <div>{formatDateFromTimestamp(object.endTime)}</div>
            </div>
          )
        },
      },
      {
        title: 'ADDRESS',
        dataIndex: 'formattedAddress',
        key: 'formattedAddress',
        width: 120,
        align: 'left',
        render: (address: string, object: Event) => {
          if (address && address.length) {
            return address
          }

          if (!object?.location?.latitude || !object?.location?.longitude) {
            return <>-</>
          }

          return (
            <div>
              {`lat: ${object.location.latitude.toFixed(2)}`}
              <br />
              {`lng: ${object.location.longitude.toFixed(2)}`}
            </div>
          )
        },
      },
      {
        title: 'ACTIONS',
        dataIndex: 'eventId',
        key: 'eventId',
        width: 120,
        align: 'right',
        render: (value: string, event: Event) => {
          if (!ActionsComponent) {
            return null
          }

          return <ActionsComponent eventId={value} partnerAddress={event.userAddress} />
        },
      },
    ]
  }, [ActionsComponent])

  return (
    <TableNoMinHeight
      rowKey={function (record) {
        return record.eventId
      }}
      columns={columns}
      data={events}
    />
  )
}
