import styled from 'styled-components/macro'

export const ClerksListContainer = styled.div`
  margin-top: 40px;
`

export const ClerksListHeader = styled.h4`
  margin: 0 0 12px 0;
  font-weight: 500;
`

export const ClerkListItem = styled.div`
  display: flex;
  justify-content: space-between;
`
