import React from 'react'
import styled from 'styled-components/macro'

interface Props {
  widthIcon?: string
  heightIcon?: string
  SrcImageIcon?: string
}
const SpanIconPortal = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.widthIcon || '36px'};
  height: ${(props) => props.heightIcon || '36px'};
`
const ImageIcon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: contain;
`
const IconPortal = (props: Props) => {
  const { SrcImageIcon, widthIcon, heightIcon } = props
  return (
    <SpanIconPortal widthIcon={widthIcon} heightIcon={heightIcon}>
      <ImageIcon src={SrcImageIcon} alt={'Image_icon'} />
    </SpanIconPortal>
  )
}

export default IconPortal
