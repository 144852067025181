import React from 'react'
import styled from 'styled-components/macro'

import { shortenAddress } from '../../utils'
interface PropsType {
  img?: string
  labelName?: string
  name?: string | null
  address?: string
}
const BoxUserCard = (props: PropsType) => {
  const { img, labelName, name, address } = props
  return (
    <Content>
      <DivFlex>
        <Avatar>
          <img src={img} alt={'avatar'} />
        </Avatar>
        <DivInfo>
          <Label>{labelName}</Label>
          <Username>{name ? name : address ? shortenAddress(address) : '#Username'}</Username>
        </DivInfo>
      </DivFlex>
    </Content>
  )
}
const Content = styled.div`
  width: 100%;
`
const DivFlex = styled.div`
  display: flex;
  align-items: center;
`
const Avatar = styled.div`
  width: 42px;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.secondary3};
  border-radius: 50px;
  box-sizing: border-box;
  overflow: hidden;
  flex: 0 0 42px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  object-fit: cover;
`
const DivInfo = styled.div`
  margin-left: 8px;
`
const Label = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 20px;
  color: ${({ theme }) => theme.secondary6};
`
const Username = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.white};
`
export default BoxUserCard
