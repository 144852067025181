import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseCreateEventInRegistryProps {
  eventId: number
  partnerAddress: string
  clerkAddress: string
}

export const useDeleteEventClerk = (options?: UseMutationOptions<any, AxiosError, UseCreateEventInRegistryProps>) => {
  const { client } = useApiClientContext()

  return useMutation<any, AxiosError, UseCreateEventInRegistryProps>(
    async ({ eventId, partnerAddress, clerkAddress }) => {
      const result = await client.delete(
        apiEndpoints.eventClerk
          .replace('{partnerAddress}', partnerAddress)
          .replace('{eventId}', String(eventId))
          .replace('{clerkAddress}', clerkAddress),
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
        }
      )

      return result?.data
    },
    options
  )
}
