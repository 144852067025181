import React from 'react'
import { isVideoLink } from 'utils/helper'

export const VideoOrImage = (props: { url: string; imgFallback?: string; imgAlt?: string }) => {
  return isVideoLink(props.url) ? (
    <video
      width={'100%'}
      height={'100%'}
      src={props.url}
      controls={false}
      muted={true}
      playsInline={true}
      autoPlay={true}
      loop={true}
    />
  ) : (
    <img
      src={props.url}
      alt={props.imgAlt || 'item_media'}
      onError={(e) => (e.currentTarget.src = props.imgFallback || '')}
    />
  )
}
