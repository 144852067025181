import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import { Flex2earn } from '../../../abis'
import abi from '../../../abis/flex2earn.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

export const useWithdrawTokensFromFlex2Earn = (options?: UseMutationOptions<any, any>) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, void>(async () => {
    if (!provider) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const contract = new Contract(
      process.env.REACT_APP_ANOTHER1_FLEX2EARN_ADDRESS as string,
      abi,
      provider
    ) as Flex2earn

    const tx = await contract.withdrawByPartner()

    const receipt = await tx.wait()

    return receipt
  }, options)
}
