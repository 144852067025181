import { useEffect, useState } from 'react'

import { OptionType } from '../entities/OptionType'
import { useGetAttributesQuery } from '../state/settingAttributes/slice'
export const useInitChooseType = () => {
  const { data } = useGetAttributesQuery({
    limit: 20,
  })
  const [optionAttributes, setOptionAttributes] = useState<OptionType[]>([])
  useEffect(() => {
    if (!data) {
      return
    }
    const optionListConvert =
      data.data.length === 0
        ? []
        : data.data.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            }
          })
    setOptionAttributes(optionListConvert)
  }, [data])
  return optionAttributes
}
