import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect } from 'react'
import { Box, Text } from 'rebass'
import styled from 'styled-components/macro'

const TermsAndConditions = () => {
  useDocumentTitle(`Terms and Conditions | Another-1 Launchpad`)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div>
      <Title py="4" textAlign={'center'}>
        Terms and Conditions
      </Title>
      <Content>
        <Text color="white" fontSize={'34px'}>
          1. Introduction
        </Text>
        <P>
          1.1 The Another-1 website (
          <a target={'_blank'} href="https://another-1.io/" rel="noreferrer">
            https://another-1.io/
          </a>
          ) (“Website”) is created and controlled by Another-1 Pte. Ltd. (UEN: 202138712N) and any holding companies,
          subsidiaries, or related entities, all of which are referred to as “AN1”, “we”, “us” or “our”. Your access to
          the Website is subject to the terms and conditions set out herein, Privacy Policy, Shipping Policy, notices,
          disclaimers and any other terms and conditions or other statements issued or authorised by us that are
          contained on the Website (collectively, “Terms”). These Terms supplement and incorporate our Privacy Policy
          and Shipping Policy, as may be posted or updated on the Website from time to time.
        </P>
        <P>
          PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS. BY
          CLICKING “ACCEPT” AND/OR USING OUR SERVICES PROVIDED ON THE WEBSITE (“SERVICES”), YOU AGREE TO BE BOUND BY
          THESE TERMS AND ALL OF THE TERMS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY
          NOT ACCESS THE WEBSITE OR USE OUR SERVICES.
        </P>
        <P>
          1.2. As part of our Services offered on the Website, we provide a NFT Launchpad (“Launchpad”), where we sell
          unique non-fungible tokens (“NFTs”) to users of the Website (“Users”) who purchase the NFTs (“Buyers”), which
          have been developed on the Polygon blockchain, representing ownership of the Underlying Assets (defined as the
          “Digital Collectibles” in Clause 1.3 below) (further, the term “Underlying Assets” have been defined in Clause
          2 below), on the Website (“Initial Shoe Offering”).
        </P>
        <P>
          1.3. We will also provide a digital marketplace (“Marketplace”) for Users of the Website (including Buyers and
          Sellers, as defined below) to engage in the sale and purchase of NFTs representing digital art / media of
          fashion collectibles (“Digital Collectibles”). The target launch date of the Marketplace will be announced on
          the Website.
        </P>
        <P>1.4. Both the Launchpad and Marketplace are built on the Polygon blockchain network.</P>
        <P>
          1.5. We do not offer, as part of our Services, any digital wallet services, digital exchange services,
          brokering services, financial services, or credit services. We provide a peer-to-peer service that enables
          Users to discover Digital Collectibles available on public blockchains and directly interact with each other
          in relation to such NFTs. We do not have custody or control over the Digital Collectibles or blockchains you
          interact with and we do not execute or effectuate purchases, transfers, payments, or sales of the Digital
          Collectibles. In order to utilise our Services, you must use a third-party digital wallet (e.g. MetaMask,
          Trust Wallet) which enables you to engage in transactions on the blockchain. We draw your attention that Trust
          Wallet and MetaMask are third parties and advise you to read their terms of use.
        </P>
        <P>
          1.6. We are not party to any agreement between any Users. You shall bear full responsibility for verifying the
          identity, legitimacy, and authenticity of Digital Collectibles that you purchase from third-party Sellers on
          the Marketplace. We make no claims or representations on or about the identity, legitimacy, functionality, or
          authenticity of other Users or Digital Collectibles (and any content associated with such NFTs) on the
          Marketplace.
        </P>
        <P>
          1.7. If you are a User using the Launchpad, Marketplace, the Website, or our Services on behalf of another
          party (i.e. employer or a business entity), you, in your individual capacity, represent and warrant that you
          are authorised to act on behalf of such other party and that such other party acknowledges and accepts these
          Terms.
        </P>
        <P>
          1.8. For the avoidance of doubt, services provided by third-party service providers (e.g. Fractal, MetaMask)
          are not provided by us and we have no control over the provision of these services, and neither are we a party
          to any agreement entered into in relation to such third-party services. We shall not be liable for any failure
          or non-performance of such third-party services, or for any damages or losses incurred in connection thereto.
        </P>
        <Text color="white" fontSize={'34px'}>
          2. Definitions
        </Text>
        <P>In these Terms, the following terms shall have the meanings set forth below:</P>
        <P>
          “<b>Affiliate</b>” or “<b>Affiliates</b>” means any corporation, firm, partnership, or other entity that
          directly or indirectly controls, or is controlled by, or is under common control with AN1;
        </P>
        <P>
          “<b>AN1</b>” has the meaning given to it in Clause 1.1;
        </P>
        <P>
          “<b>Buyer</b>” or “<b>Buyers</b>” means any User of the Website who engages in the sale and/or purchase of a
          Digital Collectible;
        </P>
        <P>
          “<b>Digital Collectibles</b>” shall have the meaning given to it in Clause 1.3;
        </P>
        <P>
          “<b>EU</b>” means the European Union;
        </P>
        <P>
          “<b>Excluded Country</b>” shall have the meaning given to it in Clause 8.5;
        </P>
        <P>
          “<b>Initial Shoe Offering</b>” shall have the meaning given to it in Clause 1.2;
        </P>
        <P>
          “<b>Intellectual Property Rights</b>” means all rights of any nature in patents, registered designs,
          registered trademarks and service marks, and all extensions and renewals thereof, unregistered trademarks and
          service marks, business and customer names, unregistered designs, internet domain names and email addresses,
          design rights, topography rights, rights in inventions, utility models, database rights, know-how and
          copyrights (including moral rights); applications for any of the foregoing and the right to apply for any of
          the foregoing in any country; rights under licences, consents, orders, statutes or otherwise in relation to
          the foregoing; rights of the same or similar effect or nature which now subsist; and the right to sue for past
          and future infringements of any of the foregoing rights;
        </P>
        <P>
          “<b>Launchpad</b>” shall have the meaning given to it in Clause 1.2;
        </P>
        <P>
          “<b>Marketplace Services</b>” shall have the meaning given to it in Clause 3.5;
        </P>
        <P>
          “<b>Marketplace</b>” shall have the meaning given to it in Clause 1.3;
        </P>
        <P>
          “<b>NFTs</b>” shall have the meaning given to it in Clause 1.2;
        </P>
        <P>
          “<b>Personal Data</b>” has the meaning as set out in the Personal Data Protection Act 2012 (2020 Rev. Ed.) of
          Singapore, and shall include, without limitation, any User’s name, phone number, email address, contact
          details, and other personal information incidental to such User’s interaction with the Website, but excluding
          any business contact information;
        </P>
        <P>
          “<b>Physical Collectible</b>” shall have the meaning given to it in Clause 8.1;
        </P>
        <P>
          “<b>Purchase Price</b>” means the amount paid to AN1 by the Users (in the case of the Launchpad) or the amount
          paid to the Sellers by the Buyers (in the case of the Marketplace), in consideration for the Digital
          Collectibles;
        </P>
        <P>
          “<b>Redemption Period</b>” shall have the meaning given to it in Clause 8.4;
        </P>
        <P>
          “<b>Seller</b>” or “<b>Sellers</b>” means the vendors or resellers of the Digital Collectibles on the
          Marketplace;
        </P>
        <P>
          “<b>Services</b>” has the meaning given to it in Clause 1.1;
        </P>
        <P>
          “<b>Taxes</b>” shall have the meaning given to it in Clause 9.18;
        </P>
        <P>
          “<b>User</b>” or “<b>Users</b>” shall have the meaning given to it in Clause 1.2;
        </P>
        <P>
          “<b>Underlying Assets</b>” mean curated designs by various fashion designers and brands assetized by the
          Company in the form of NFTs;
        </P>
        <P>
          “<b>Wallet</b>” shall have the meaning given to it in Clause 9.3;
        </P>
        <P>
          “<b>Website</b>” shall have the meaning given to it in Clause 1.1; and
        </P>
        <P>
          “<b>Website Content</b>” refers to all information, text, images, data, links, or other material posted on the
          Website that are publicly available and which you have the right or ability to access, whether created by us
          or provided by a third party to be displayed on the Website.
        </P>
        <Text color="white" fontSize={'34px'}>
          3. Acceptance of Terms
        </Text>
        <P>
          3.1. Please read the Terms carefully as they create a binding contract between you and AN1 and govern your
          access to and use of the Launchpad, Marketplace, the Website and our Services (inclusive of any content,
          functionality, and services offered on or through the Launchpad, Marketplace and/or the Website). By using the
          Launchpad, Marketplace, the Website and/or our Services, Users will be deemed to have accepted these Terms.
        </P>
        <P>
          3.2. We reserve the right to amend these Terms from time to time without prior notice to you and at our
          absolute discretion. Access and use of the Website, Launchpad, Marketplace and/or our Services shall be
          subject to these Terms, as amended or updated from time to time and published on the Website in our sole and
          absolute discretion. All amendments to these Terms are effective immediately from when such amended Terms are
          posted on the Website. You should therefore read this page carefully each time you access the Website. Your
          continued use of the Website shall deem you to have read, understood, and accepted these Terms published on
          the Website, as may be updated or amended from time to time.
        </P>
        <P>
          3.3. Please review these Terms before using the Website, Launchpad, Marketplace and/or our Services. If you do
          not agree to these Terms, you must immediately cease your use of the same.
        </P>
        <P>
          3.4. The Launchpad allows Users to explore designs created by third parties such as fashion designers and
          brands (“Creators”). Users may vote for the design(s) to be issued as Digital Collectibles, to be produced and
          manufactured as Physical Collectibles by AN1. Users may also interact with the AN1 community and participate
          in the Initial Shoe Offering on the Launchpad.{' '}
        </P>
        <P>
          3.5. The Marketplace allows Buyers and Sellers to find each other and advertise, purchase and sell, collect
          and showcase Digital Collectibles online. Buyers and Sellers may, via the Marketplace and our Services, form
          contracts, between themselves, for the sale and purchase of Digital Collectibles (“Marketplace Services”).
        </P>
        <P>
          3.6. We do not make any representations or warranties about any third-party content visible through our
          Website, including any content associated with NFTs displayed on the Launchpad and Marketplace, and you bear
          responsibility for verifying the legitimacy, authenticity, and legality of NFTs that you purchase from any
          third-party sellers. We also cannot guarantee that any NFTs visible on the Launchpad and Marketplace will
          always remain visible and/or available to be bought, sold, or transferred.
        </P>
        <P>
          3.7. You understand and acknowledge that we only make the Launchpad, Marketplace and the Marketplace Services
          available for Users. We are not ourselves party to any agreement entered into between Buyers and Sellers and
          we have no control over the conduct of Buyers, Sellers, or any other User on the Launchpad and/or Marketplace,
          including the sale of illegally obtained NFTs.
        </P>
        <Text color="white" fontSize={'34px'}>
          4. Use of Contact Details of the Website
        </Text>
        <P>
          The publication on the Website of any person’s contact details does not constitute any implied or express
          consent by us or by such person to receive unsolicited commercial electronic messages or spam.
        </P>
        <Text color="white" fontSize={'34px'}>
          5. Acceptable Use
        </Text>
        <P>
          5.1. As a User, you can browse and view the Website and the Website Content in accordance with these Terms
          and, in any event, for lawful and proper purposes, which includes complying with all applicable laws,
          regulations and codes of practice within Singapore or other jurisdictions from which you are accessing the
          Website.
        </P>
        <P>
          5.2. The Website and/or Website Content may not be used for any illegal purpose or in any manner inconsistent
          with these Terms. Except as otherwise provided, the Website Content shall not be reproduced, republished,
          uploaded, posted, transmitted, adapted, modified, or otherwise displayed or distributed in any manner, without
          the prior written consent of AN1.
        </P>
        <P>
          5.3. Modification of any of the Website Content or use of the Website Content for any other purpose will be a
          violation of the AN1’s copyright and other Intellectual Property Rights. Graphics, photographs, video, audio,
          software programs and images on this Website are protected by copyright and other proprietary rights and may
          not be reproduced, published, distributed, copied, modified, appropriated, or exploited for commercial,
          non-profit, or public purposes in any manner without prior written consent of AN1.
        </P>
        <P>
          5.4. You may only use the Website in the capacity of an end-user. You must not: decompile, reverse engineer,
          disassemble, rent, lease, loan, sell, sublicense, or create derivative works from the Website or the user
          content. You may not use any network monitoring or discovery software to determine the Website architecture,
          or extract information about usage, individual identities or users, or otherwise use and exploit any Website
          Content except as permitted by these Terms or with prior written permission of AN1.
        </P>
        <P>
          5.5. If you use the Website on your mobile device, your mobile carrier’s normal rates and charges may apply.
          We are not responsible for any charges you incur from your mobile carrier as a result of use of the Website.
          You are responsible for always ensuring, while using the Website, that you are not in violation of your
          wireless data service agreement. You are solely responsible for all fees and costs associated with your access
          to and use of the Website.
        </P>
        <P>
          5.6. You shall assume responsibility for controlling how your Personal Data is disclosed or used on the
          Website, including, without limitation, taking appropriate steps to protect such information.
        </P>
        <Text color="white" fontSize={'34px'}>
          6. Reliance on Information on the Website
        </Text>
        <P>
          The Website is only a general source of information. You agree that the content provided on the Website,
          including content published by Users do not constitute financial, legal, tax or professional advice, and you
          agree to not represent the content or the Website as such. If financial or other professional advice is
          required, the services of a competent professional should be sought.
        </P>
        <Text color="white" fontSize={'34px'}>
          7. Access to the Website
        </Text>
        <P>
          7.1. The accessibility and operation of the Website rely on technologies and third-party services outside our
          control. We do not guarantee continuous accessibility or uninterrupted operation of the Website.
        </P>
        <P>
          7.2. We shall not be liable in the event the Website is unavailable to you for any reason (for example, due to
          computer downtime attributable to malfunctions, upgrades, server problems, preventative or remedial
          maintenance activities or interruption in telecommunication supplies).
        </P>
        <P>
          7.3. We reserve the right to:
          <ol type="a">
            <li>
              deny or terminate all or part of your access to the Website where in our opinion, there are concerns
              regarding unreasonable use, security, or unauthorised access or where you have breached any of these
              Terms;{' '}
            </li>
            <li>
              block or suspend your account, remove your default settings, or part thereof, without prior reference to
              you;{' '}
            </li>
            <li> temporarily suspend or shut down the Website for system maintenance; and/or</li>
            <li>
              take any action in relation to the Website for such other purposes as we may require, as and when
              necessary.
            </li>
          </ol>
        </P>
        <P>
          7.4. If you choose, or you are provided with, an identification code, password, or any other piece of
          information as part of our security procedures, you must treat such information as confidential, and you must
          not disclose it to any third party. We reserve the right to disable any identification code or password,
          whether chosen by you or allocated by us, at any time, if, in our opinion, you have failed to comply with any
          of the provisions of these Terms.
        </P>
        <Text color="white" fontSize={'34px'}>
          8. Initial Shoe Offering and Ownership
        </Text>
        <P>
          8.1. Our sale of Digital Collectibles for the Initial Shoe Offering will be capped at 1000 NFTs. Each Digital
          Collectible launched and/or sold and/or minted (where applicable) on the Launchpad and/or Marketplace
          represents a unique digital art / media of fashion wearable as well as an electronic certificate / unique
          identification code. Holders of such Digital Collectibles may redeem a physical fashion wearable (e.g. a pair
          of sneakers) of which such NFT represents (”Physical Collectible”). For the avoidance of doubt, the Physical
          Collectible attributable to a Digital Collectible maybe redeemable more than once.
        </P>
        <P>
          8.2. The NFTs may be subject to any additional terms, conditions or qualifications as may be stated in the
          description of the NFT or in any smart contracts in relation thereto.{' '}
        </P>
        <P>8.3. We reserve the right to issue new NFTs and related physical goods and experiences in the future.</P>
        <P>8.4 Redemption Process</P>
        <P>
          Any Physical Collectible redeemable in relation to a Digital Collectible may only be redeemed between [insert
          launch date] and [insert end date of redemption period], or such other period designated as the redemption
          period for such Physical Collectible (the “Redemption Period”), by visiting the Website to confirm your
          election to redeem the Physical Collectible and to verify your age and shipping address, contact particulars
          and certain other details. If you do not make a redemption election within the Redemption Period, then you
          will no longer be able to redeem the Physical Collectible, without refund or other compensation. If you direct
          us to ship the Physical Collectible to an invalid address or an Excluded Country (each as defined in Clause
          8.5), we have no obligation to do so.{' '}
        </P>
        <P>8.5. Eligible Recipients and Addresses</P>
        <P>
          In order to participate in the Initial Shoe Offering and/or to receive the Physical Collectibles, the User
          must comply with the following requirements: (i) the User must be at least twenty-one (21) years old; (ii) the
          User must provide a valid address in Asia or the EU not located in an Excluded Country (as defined below) for
          the User’s Physical Collectibles to be shipped; and (iii) the User is not a national or a resident of, or a
          company incorporated under the laws of or conducting business in, any country(ies) listed in an Excluded
          Country. Those who choose to access this Website from outside of Singapore may do so on their own initiative
          and are responsible for compliance with their local laws, if and to the extent such local laws are applicable.
          No shipments of Physical Collectibles shall be made to addresses outside of Asia and EU or wherever such
          shipment is prohibited or restricted by the local law (for example, in jurisdictions that prohibit shipment of
          Physical Collectibles or that require licenses that AN1 does not have in force), if and to the extent the
          local laws are applicable.
        </P>
        <P>
          Currently, the following are “<b>Excluded Countries</b>”: North Korea; Libya; Syria; Lebanon; Iran; Pakistan;
          Canada; Bangladesh; Bolivia, Ecuador; Kyrgyzstan; Morocco, Nepal Israel; any other country which prohibits the
          possession, dissemination, or communication of NFTs and/or prohibits participation in initial coin offerings
          or any token sale. The person to whom the Physical Collectible is shipped is responsible for ensuring that
          he/she is eligible to receive the Physical Collectible.
        </P>
        <Text color="white" fontSize={'34px'}>
          9. Payments
        </Text>
        <P>
          <i>Payments on the Launchpad</i>
        </P>
        <P>9.1. We will process all payments relating to the Initial Shoe Offering on the Launchpad.</P>
        <P>
          9.2. Buyers, by purchasing Digital Collectibles from AN1 on the Launchpad, agree to pay the Purchase Price (as
          defined in Clause 2 above) of the Digital Collectible as may be determined and displayed by AN1 on the
          Launchpad.
        </P>
        <P>
          9.3. In order to use the Launchpad and participate in the Initial Shoe Offering, Buyers will need to generate
          a link key which will enable them to integrate their decentralised digital wallets (e.g. MetaMask or Trust
          Wallet) (“Wallet”) with the Launchpad, to store NFTs, and for the purposes of receiving and storing the NFTs.
        </P>
        <P>
          9.4. Buyers must pay for the Purchase Price using Matic currency (or such other supported types of
          cryptocurrencies as determined by AN1). Buyers must ensure that their linked Wallet has sufficient funds to
          pay for the Purchase Price of the Digital Collectible on the Launchpad.{' '}
        </P>
        <P>
          9.5. When full payment of the Purchase Price is received from a Buyer, it will constitute payment to AN1 (in
          the case of the Initial Shoe Offering) for a particular Digital Collectible and the Buyer&apos;s payment
          obligation to AN1 will be deemed completed at such time.
        </P>
        <P>
          <i>Payments on the Marketplace</i>
        </P>
        <P>
          9.6. We make available the Marketplace but we do not process payment for orders on behalf of Sellers as the
          agent of the Seller.{' '}
        </P>
        <P>
          9.7. Buyers, by purchasing Digital Collectibles from Sellers on the Marketplace, agree to pay the purchase
          price for such Digital Collectible at its time of purchase.{' '}
        </P>
        <P>
          9.8. In order to use the Marketplace and engage in the sale and purchase of Digital Collectibles thereon,
          Users will need to generate a link key which will enable them to integrate their decentralised digital Wallets
          with the Marketplace.
        </P>
        <P>
          9.9. Buyers must pay for the Purchase Price on the Marketplace using Matic currency (or such other supported
          types of cryptocurrencies as determined by AN1). Buyers must ensure that their linked Wallet has sufficient
          funds to pay for the Purchase Price of the Digital Collectible on the Marketplace.{' '}
        </P>
        <P>
          9.10. Each Seller agrees that when full payment of the Purchase Price is received from a Buyer, it will
          constitute payment to the Seller for a particular Digital Collectible and the Buyer&apos;s payment obligation
          to the Seller will be deemed completed at such time and the Seller will have no further claims against the
          Buyer with respect to such payment.
        </P>
        <P>
          9.11. In the event we determine a Buyer should be refunded the Purchase Price (on the Marketplace) in
          accordance with Clause 10 (Refunds), we have the right to refuse to pay the net sales proceeds to the Seller.
          The Seller acknowledges that we may set off any amounts the Seller owes to us from time to time against any
          amounts we owe to the Seller.
        </P>
        <P>
          9.12. All users (including Buyers on the Marketplace) must not pay, or attempt to pay, for their purchase by
          fraudulent or unlawful means. In the absence of fraud or mistake, all payments made are final.{' '}
        </P>
        <P>
          9.13. Every transaction on the Marketplace and/or Launchpad requires the payment of a transaction fee
          (“Transaction Fee”). Except as otherwise expressly stated in these Terms, you will be solely responsible to
          pay any Transaction Fee for any transaction entered into via the Marketplace and/or Launchpad.{' '}
        </P>
        <P>
          9.14. By buying and selling NFTs on the Marketplace, or minting NFTs on the Launchpad, you agree to pay all
          applicable fees, including transaction fees, as stipulated on the checkout screen at the time of your purchase
          and authorise us to automatically deduct any such fees directly from payments to you and/or add fees to your
          payments where applicable.
        </P>
        <P>
          9.15. Network Fees (from approved networks): These fees are often required to cover the transaction costs on
          virtual currency networks and may apply to the purchase transactions. Unless indicated otherwise in your
          account or elsewhere in these Terms, you are solely responsible for paying all network fees. We will not
          advance nor fund network fees on your behalf, nor be responsible should the network fee paid be insufficient
          or excessive. Your Wallet from which you wish to send must hold sufficient funds to cover the transaction and
          its associated network fees, failing which the transaction may be blocked, might fail or result in your value
          being suspended temporarily or forever.
        </P>
        <P>
          9.16. Any User demanding settlement through one of the third-party payment processors agrees thereby for the
          payment to be made through websites covering the payment systems and states that they have read and accepted
          the terms and conditions available on such websites. To the extent permitted by law, we shall not be liable to
          the Users for any problems related to payments for which the owners of such sites are responsible, in
          particular for any delays in processing payments or inability to process them for technical reasons. In such
          case the User shall contact the applicable payment processor&apos;s site in accordance with that website’s
          terms and conditions.
        </P>
        <P>
          9.17. You agree that you are solely responsible for (i) determining what, if any, taxes apply to your
          transactions on the Website and (ii) paying any and all sales, use, value-added and other taxes, duties, and
          assessments (except taxes on net income) now or hereafter claimed or imposed by any governmental authority
          (collectively, “Taxes”) associated with your use of, and transactions conducted on, the Website. Neither us
          nor our Affiliates or employees are responsible for determining the taxes that may apply to your transactions.
          Except for corporate income taxes levied on us, you:
          <ol type="a">
            <li>
              {' '}
              will pay or reimburse us for all national, local, or other taxes and assessments of any jurisdiction,
              including value added taxes and taxes as required by international tax treaties, customs or other import
              or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments
              made hereunder, as are now or hereafter may be imposed under the authority of any national, local or any
              other taxing jurisdiction; and{' '}
            </li>
            <li>
              {' '}
              will not be entitled to deduct the amount of any such Taxes from payments (including servicing fees) made
              to us pursuant to these Terms.
            </li>
          </ol>
        </P>
        <P>
          9.18. Users agree that we may refuse to process any payment if we believe that a transaction is fraudulent,
          invalid, suspicious, or breaches applicable law or these Terms.
        </P>
        <P>9.19. This Clause 9 will survive the termination or expiry of these Terms.</P>
        <Text color="white" fontSize={'34px'}>
          10. Refunds
        </Text>
        <P>
          10.1. All pricing and payment terms are as set forth on the Launchpad and Marketplace, and any payment
          obligations you incur are binding at the time of purchase. All sales are final. Unless otherwise required by
          applicable law, we will not provide a refund on any purchase.
        </P>
        <P>
          10.2. All Buyers are not entitled to return Digital Collectibles or receive a refund for change of mind or
          other circumstances.
        </P>
        <P>
          10.3. All Buyers of the Digital Collectibles made are final unless a refund or cancellation of a transaction
          is required by any applicable law or regulation. AN1 reserves the right to refuse or cancel NFT purchase
          requests at any time in its sole discretion.
        </P>
        <P>10.4. This Clause 10 will survive the termination or expiry of these Terms.</P>
        <Text color="white" fontSize={'34px'}>
          11. Intellectual Property Rights
        </Text>
        <P>
          11.1. AN1 is the owner and/or authorised user of all trademarks, service marks, design marks, patents,
          copyrights, database rights and all other forms of intellectual property appearing on or contained within the
          Website, unless otherwise indicated. All information, text, material, graphics, software, and advertisements
          on the Website are intellectual property of AN1, its suppliers and licensors unless expressly indicated
          otherwise by AN1.
        </P>
        <P>
          11.2. We may allow Users to, on or through our Launchpad and Marketplace:
          <ol type="a">
            <li>post listings featuring NFTs (“User Content”); and</li>
            <li>
              access and view User Content and the content and information we make available on the Launchpad and/or
              Marketplace (“Our Content”),
            </li>
          </ol>
        </P>
        <P>
          11.3. Unless otherwise indicated, we own or license all rights, title and interest (including Intellectual
          Property Rights) in our Launchpad, Marketplace, Marketplace Services and all of the Content. The use of our
          Launchpad, Marketplace and Website and your use of and access to any of the Content does not grant or transfer
          Users any rights, title or interest in relation to our Launchpad, Marketplace, Website or Our Content.
        </P>
        <P>
          11.4. All rights are reserved. No part of any works on the Website may be reproduced, stored in a retrieval
          system, or transmitted in any form or by any means, whether electronic, mechanical, photocopying, recording,
          or otherwise, without written permission from AN1. You may, however, download or print a single copy for your
          own non-commercial off-line viewing.
        </P>
        <P>
          11.5. Users must not, without the prior written consent of AN1 or the owner of the Content (as applicable) or
          except as expressly permitted by these Terms:
          <ol type="a">
            <li>copy or use, in whole or in part, any Content;</li>
            <li>
              reproduce, store in a retrieval system, reverse engineer, distribute, disseminate, sell, publish,
              broadcast, circulate or transmitted in any form or by any means, whether electronic, mechanical,
              photocopying, recording, or otherwise, any Content designated as paid, exclusive or non-shareable content
              to any third party; or
            </li>
            <li>
              breach any Intellectual Property Rights connected with our Launchpad, Marketplace or Marketplace Services,
              including by altering or modifying any of the Content, causing any of the Content to be framed or embedded
              in another website, or creating derivative works from the Content.
            </li>
          </ol>
        </P>
        <P>
          11.6. Users agree that they are solely responsible for all User Content that they make available on or through
          our Marketplace and Marketplace Services.
        </P>
        <P>
          11.7. Users represent and warrant that:
          <ol type="a">
            <li>
              they are either the sole and exclusive owner of all User Content or that they have all rights, licences,
              consents, and releases that are necessary to grant to us the rights in such User Content as contemplated
              by these Terms; and
            </li>
            <li>
              neither the User Content, nor the posting, uploading, publication, sending or receiving of the User
              Content or our use of the User Content on, through or by means of our Launchpad, Marketplace or
              Marketplace Services will infringe, misappropriate, or violate a third party&apos;s Intellectual Property
              Rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
            </li>
          </ol>
        </P>
        <P>
          11.8. By minting a Digital Collectible through the Launchpad or providing or selling a Digital Collectible
          through the Marketplace, you hereby represent and warrant that you own all legal right, title, and interest in
          all intellectual property rights to the digital art / media linked or associated with such Digital
          Collectible, or you are legally authorized by the intellectual property owner to mint, provide or sell the
          Digital Collectible on the Launchpad and the Marketplace.
        </P>
        <P>
          11.9. Certain product and company names mentioned on this Website or material displayed on this Website may be
          intellectual property belonging to third parties. AN1 does not warrant or represent that if you use such
          material, you will not infringe the legal rights of these third parties.
        </P>
        <P>11.10. This Clause 11 will survive the termination or expiry of these Terms.</P>
        <Text color="white" fontSize={'34px'}>
          12. Links from External Websites
        </Text>
        <P>
          12.1. Except as set forth below, caching and links to, and the framing of this Website or any of the Website
          Content is strictly prohibited.{' '}
        </P>
        <P>
          12.2. You must secure written permission from AN1 prior to hyper-linking to, or framing, this Website or any
          of the Website Content, or engaging in similar activities. AN1 reserves the right to impose conditions when
          permitting any hyper-linking to or framing of this Website and/or any of the Website Content.
        </P>
        <P>
          12.3. Under no circumstances shall AN1 be considered to be associated or affiliated in whatever manner with
          any trade or service marks, logos, insignia, or other devices used or appearing on web sites that link to this
          Website and/or any of the Website Content.
        </P>
        <P>
          12.4. AN1 reserves all rights to disable or block any links to, or frames of any website containing
          inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful topics, names, material or
          information, or material or information that violates any written law, any applicable intellectual property,
          proprietary, privacy or publicity rights.
        </P>
        <P>
          12.5. AN1 reserves the right to disable or block any unauthorised links or frames and disclaims any
          responsibility for the content available on any other website reached by links to or from this Website and/or
          any of the Website Content.
        </P>
        <Text color="white" fontSize={'34px'}>
          13. Links to External Websites
        </Text>
        <P>
          The Website may contain links to websites which are not operated or controlled by AN1, whether for marketing
          purposes or otherwise. These links are provided to you as a matter of convenience, and AN1 makes no
          representation or warranty whatsoever in respect of any linked web site and shall not be responsible for the
          content of any linked web site. Any non-AN1 website accessed from the Website is independent from AN1, and AN1
          has no control over the content of that website. In addition, a link to any non-AN1 website from the Website
          does not imply that AN1 endorses or accepts any responsibility for the content or use of such website. Use of
          any external website is subject to its terms of service and privacy policy. You are responsible for exercising
          caution and good judgment when using external websites.
        </P>
        <Text color="white" fontSize={'34px'}>
          14. Data Collection Notice
        </Text>
        <P>
          14.1. We collect personal information about Users in order to enable Users to access and use the Launchpad and
          Marketplace, to enable Users to perform transactions on the Launchpad and Marketplace, to contact and
          communicate with Users, to respond to your enquiries and for other purposes set out in our privacy policy.
        </P>
        <P>
          14.2. We may disclose that information to third party service providers who help us deliver our Launchpad and
          Marketplace (including information technology service providers, data storage, web-hosting and server
          providers, professional advisors, third party payment processors and our business partners) or as required by
          law. If you do not provide this information we may not be able to provide Users with access to our Launchpad
          and Marketplace. In certain circumstances, we may disclose Users’ personal information to third parties
          located, or who store data, outside Singapore.
        </P>
        <P>
          14.3. Our privacy policy contains further information about:
          <ol type="a">
            <li>how we store and use Users’ personal information;</li>
            <li>how Users can access and seek correction of your personal information;</li>
            <li>how Users can make a privacy-related complaint; and</li>
            <li>our complaint handling process.</li>
          </ol>
        </P>
        <P>
          14.4. By providing personal information to us, Users’ consent to us collecting, holding, using and disclosing
          their personal information in accordance with our privacy policy.
        </P>
        <Text color="white" fontSize={'34px'}>
          15. Risks
        </Text>
        <P>
          15.1. Risk of Loss: The Physical Collectibles redeemed from our Website are shipped by a third-party carrier
          pursuant to a shipment contract. As a result, risk of loss and title for such items pass to you upon our
          delivery to the carrier.
        </P>
        <P>
          15.2. Use of Tokens: The Website will not be responsible for any losses, damages or claims arising from events
          falling within the scope of the following five categories:
          <ol type="a">
            <li>
              mistakes made by the user of any AN1-related software or service, e.g. forgotten passwords, payments sent
              to wrong AN1 addresses, and accidental deletion of wallets.
            </li>
            <li>
              software problems of the Website and/or any AN1-related software or service, e.g. corrupted wallet file,
              incorrectly constructed transactions, unsafe cryptographic libraries, malware affecting the Website and/or
              any AN1-related software or service.
            </li>
            <li>
              technical failures in the hardware of the user of any AN1-related software or service, e.g. data loss due
              to a faulty or damaged storage device.
            </li>
            <li>
              security problems experienced by the user of any AN1-related software or service, e.g. unauthorised access
              to users&apos; wallets and/or accounts.
            </li>
            <li>
              actions or inactions of third parties and/or events experienced by third parties, e.g. bankruptcy of
              service providers, information security attacks on service providers, and fraud conducted by third
              parties.
            </li>
          </ol>
        </P>
        <P>
          15.3. Value and Volatility: You understand and agree that your access to, and use of, the Launchpad and the
          Marketplace is subject to certain risks including without limitation:
          <ol type="a">
            <li>
              The price and liquidity of collectible blockchain assets, including the NFTs, are extremely volatile and
              subjective and may be subject to fluctuations;
            </li>
            <li>Collectible blockchain assets, including the NFTs, have no inherent or intrinsic value;</li>
            <li>
              Fluctuations in the price of other digital assets could materially and adversely affect the value of your
              NFTs, which may also be subject to significant price volatility;
            </li>
            <li>NFTs are not legal tender and are not backed by any government;</li>
            <li>
              Transactions involving NFTs may be irreversible, and losses due to fraudulent or accidental transactions
              may not be recoverable, including accidental transactions whereby you provide wrong wallet addresses;
            </li>
            <li>
              The value of collectibles, including the NFTs, is inherently subjective, and factors occurring outside the
              Launchpad and/or Marketplace ecosystem may materially impact the value and desirability of any particular
              NFT;
            </li>
            <li>
              The value of NFTs may be derived from the continued willingness of market participants to exchange fiat
              currency or digital assets for NFTs, and therefore the value of NFTs is subject to the potential for
              permanent or total loss of value should the market for NFTs disappear; and
            </li>
            <li>
              (h) NFTs are subject to the risk of fraud, counterfeiting, cyberattacks and other technological
              difficulties which may prevent access to or use of your NFTs.
            </li>
          </ol>
        </P>
        <P>
          15.4. Cryptocurrency Risks: There are risks associated with using a cryptocurrency, including, but not limited
          to, the risk of hardware, software and internet connections, the risk of malicious software introduction, and
          the risk that third parties may obtain unauthorised access to information stored with your electronic wallet.
          You acknowledge and accept that we will not be responsible for any communication failures, disruptions,
          errors, distortions or delays you may experience when using the Launchpad, Marketplace, the NFTs or the
          Ethereum blockchain network, (including the Polygon blockchain), howsoever caused.
        </P>
        <P>
          15.5. Regulatory Uncertainty: The regulatory regime governing blockchain technologies, cryptocurrencies and
          tokens is uncertain, and new regulations or policies may materially adversely affect the development of the
          Launchpad and Marketplace, and by extension, the use, transfer, value and potential utility of your NFTs.
        </P>
        <P>
          15.6. Payment Services Provider Risks: The Launchpad, Marketplace and the Services offered thereunder will
          rely on third-party platforms such to perform the transactions for the purchase and sale of NFTs. If (a) we
          cease to continue any relationship with such platform providers; (b) the terms and conditions or pricing of
          such platform providers change; (c) we violate or cannot comply with the terms and conditions of such
          platforms; or (d) any of such platforms loses market share or falls out of favour or is unavailable for a
          prolonged period of time, access to and use of the Launchpad and/or Marketplace may suffer.
        </P>
        <P>
          15.7. Your Own Risk: You understand and agree that you are solely responsible for determining the nature,
          potential value, suitability and appropriateness of these risks for yourself. We do not give any advice or
          recommendations regarding the NFTs. You understand and agree that you access and use the Launchpad and/or
          Marketplace at your own risk. You understand and agree that We will not be responsible for any communication
          failures, disruptions, errors, or distortions you may experience when using the NFTs or the Launchpad and/or
          Marketplace.
        </P>
        <Text color="white" fontSize={'34px'}>
          16. Disclaimers
        </Text>
        <P>
          16.1. Under no circumstances will AN1 be liable for any loss or damage caused by your reliance on any
          information communicated or provided on the Website.
        </P>
        <P>
          16.2. All Website Content and information on, or accessible from, the Website is provided “as is” without
          warranty of any kind, either express or implied. To the fullest extent permitted by law, AN1 hereby disclaims
          any warranty:
          <ol type="a">
            <li>
              (a) As to the accuracy, correctness, completeness, reliability, timeliness, non-infringement, title,
              merchantability or fitness for any particular purpose of the Website Content; and
            </li>
            <li>
              (b) That the Website, Launchpad, Marketplace and/or the Website Content or any functions associated
              therewith will be uninterrupted or error-free, or that defects will be corrected or that this Website and
              the server is and will be free of all viruses, worms and/or other harmful or invasive elements.
            </li>
          </ol>
        </P>
        <P>
          16.3. AN1 shall not be liable for any injury, damage or loss of any kind caused as a result (direct or
          indirect) of the use of the Website, Launchpad, Marketplace, the Ethereum blockchain network, Polygon
          blockchain network or your electronic Wallet, including but not limited to any injury, damage or loss suffered
          as a result of reliance on the contents contained in or available from the Website. Neither AN1 nor any of
          their respective third party agents shall have any responsibility or liability for any injury, damage or loss
          caused by any negligence, omission or fault of the AN1, and/or its employees, agents or sub-contractors in
          connection with the Website.
        </P>
        <P>
          16.4. We will not be responsible or liable to you for any loss of any kind that you incur as a result of your
          use of the Launchpad, Marketplace, the Ethereum blockchain network, the Polygon blockchain network, or your
          electronic wallet, including but not limited to any losses, damages or claims arising from: (i) user error,
          such as forgotten passwords or incorrectly construed smart contracts or other transactions; (ii) server
          failure or data loss; (iii) corrupted wallet files; or (iv) unauthorized access or activities by third
          parties, including, but not limited to, the use of viruses, phishing, brute-forcing or other means of attack
          against the Launchpad and/or Marketplace, the Ethereum blockchain network, the Polygon blockchain network or
          any electronic wallet. We will not be responsible or liable to you for any loss of any kind from any action
          that results from you providing an incorrect wallet address.
        </P>
        <P>
          16.5. To the fullest extent provided by law, we hereby disclaim all warranties of any kind, whether express or
          implied, statutory, or otherwise, including but not limited to any warranties of merchantability, fitness for
          a particular purpose, title, correctness, accuracy, reliability and non-infringement as to the Launchpad
          and/or Marketplace. Without limiting the generality of the foregoing, We, our parent, subsidiaries,
          Affiliates, and licensors do not represent or warrant to you that
          <ol type="i">
            <li>your access to or use of the Launchpad and/or Marketplace will meet your requirements;</li>
            <li>
              your access to or use of the Launchpad and/or Marketplace will be uninterrupted, timely, secure, or free
              from error;{' '}
            </li>
            <li>usage data provided through the Launchpad and/or Marketplace will be accurate; </li>
            <li>
              the Launchpad and/or Marketplace or any content, services, or features made available on or through the
              Launchpad and/or Marketplace are free of viruses or other harmful components; or
            </li>
            <li>
              that any data that you disclose when you use the app will be secure. The foregoing does not affect any
              warranties that cannot be excluded or limited under applicable law.
            </li>
          </ol>
        </P>
        <P>
          16.6. NFTs are intangible digital assets that exist only by virtue of the ownership record maintained in the
          blockchain network. All smart contracts, including any transfer of title that might occur in any unique NFT,
          are conducted and occur on the decentralised ledger within the blockchain network. We have no control over and
          make no guarantees or promises with respect to smart contracts.
        </P>
        <P>
          16.7. We are not responsible for losses due to blockchains or any other features of the blockchain network, or
          any electronic wallet, including but not limited to late reporting by developers or representatives (or no
          reporting at all) of any issues with the blockchain supporting the Ethereum blockchain network or the Polygon
          blockchain network, including forks, technical node issues, or any other issues resulting in fund losses.
        </P>
        <Text color="white" fontSize={'34px'}>
          17. Limitation of Liability
        </Text>
        <P>
          17.1. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY
          THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR
          INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
          SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </P>
        <P>
          17.2. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING
          TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE WEBSITE,
          WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE
          AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12-MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR
          (B) S$500.00.
        </P>
        <P>
          17.3. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE WEBSITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN
          RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE WOULD NOT BE ABLE TO
          PROVIDE THE WEBSITE TO YOU WITHOUT THESE LIMITATIONS.
        </P>
        <Text color="white" fontSize={'34px'}>
          18. Indemnification
        </Text>
        <P>
          18.1. You agree to indemnify, defend and hold harmless AN1, its Affiliates, officers, directors, employees,
          agents, licensors, suppliers, representatives and any third party information providers to the Website from
          and against any claims, losses, liabilities, expenses, damages, and all costs and expenses of defence,
          including but not limited to, reasonable legal fees, resulting directly or indirectly from a claim that arises
          in connection with:
          <ol type="i">
            <li>your use of the Website or any Services offered by us;</li>
            <li>your content and your data;</li>
            <li>your misuse of any of the above;</li>
            <li>your breach or alleged breach of these Terms or any law or regulation; or</li>
            <li>violation of any right of a third party.</li>
          </ol>
        </P>
        <P>
          18.2. You also agree to indemnify AN1 against any claims that information or material which you have submitted
          to AN1 is in violation of any law or in breach of any third party rights (including, but not limited to,
          claims in respect of defamation, invasion of privacy, breach of confidence, infringement of copyright or
          infringement of any other Intellectual Property Right).
        </P>
        <P>
          18.3. Notwithstanding the indemnification given by you, AN1 shall have the right to assume the exclusive
          defence and control of any matter otherwise subject to indemnification by you under this Clause 18, and in
          such case, you agree to fully cooperate as reasonably required with such defence and in asserting any
          available defences.
        </P>
        <Text color="white" fontSize={'34px'}>
          19. Miscellaneous
        </Text>
        <P>19.1. Unlawful Activity</P>
        <P>
          AN1 reserves the right to investigate complaints or reported violations of the Terms and to take any action we
          deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement
          officials, regulators, or other third parties and disclosing any information necessary or appropriate to such
          persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses
          and traffic information.
        </P>
        <P>19.2. Release</P>
        <P>
          In the course of your use of the Website, you may be asked to provide personal information to us (“User
          Information”). AN1’s information collection and use policies with respect to such User Information are set
          forth in the AN1 Privacy Policy which is incorporated into the Terms by reference. You acknowledge and agree
          that you are solely responsible for the accuracy and content of the User Information.
        </P>
        <P>19.3. Termination</P>
        <P>
          AN1 reserves all rights to deny or restrict access to this Website by any particular person, or to block
          access from a particular IP address to this Website, at any time, without ascribing any reasons whatsoever. We
          may, in our sole discretion at any time, for any reason or no reason, and without notice or liability,
          immediately terminate your access to all or any part of the Website and any NFTs. Termination may include, but
          not be limited to (a) removal of your access to, or listing of NFTs on, the Website, (b) the deletion of all
          account information related to the NFTs from the Website, and (c) barring any further use of or access to the
          Website by you.
        </P>
        <P>19.4. Monitoring</P>
        <P>
          AN1 has the right, but not the obligation, to monitor content posted on the Website, to determine compliance
          with these Terms and any other applicable rules that we may establish, from time to time. Without limiting the
          foregoing, AN1 has the right to remove any material that AN1, in their sole discretion, finds to be in
          violation of these Terms or otherwise objectionable.
        </P>
        <P>19.5. Severability of Provisions</P>
        <P>
          If any provision of the Terms is unlawful, void or unenforceable, then that provision shall be deemed
          severable from the remaining provisions and shall not affect their validity and enforceability.
        </P>
        <P>19.6. Jurisdictional Issues</P>
        <P>
          AN1 makes no representation that information on this Website is appropriate or available for use outside
          Singapore. Those who choose to access this Website from outside Singapore do so on their own initiative and at
          their own risk and are responsible for compliance with applicable local laws. By using the Website, you
          consent to having your Personal Data transferred to and processed in Singapore, subject to the restrictions on
          such data applicable to AN1.
        </P>
        <P>19.7. Waiver</P>
        <P>
          The failure of AN1 to exercise or enforce any right or provision of these Terms shall not constitute a waiver
          of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to
          be invalid, you agree that the court shall endeavour to give effect to the parties’ intentions as reflected in
          the provision, and that the other provisions of these Terms remain in full force and effect.
        </P>
        <P>19.8. Governing Law</P>
        <P>
          These Terms shall be governed by and construed according to the laws of the Republic of Singapore. Any claim
          relating to the use of the Website shall be heard by the Singapore courts.
        </P>
        <P>
          In the event of any dispute arising out of or in connection with these Terms including any question regarding
          its existence, validity or termination, the parties shall at first instance take reasonable efforts to settle
          and resolve such disputes in good faith and in an amicable manner by negotiation.
        </P>
        <P>19.9. Rights of Third Parties</P>
        <P>
          None of the terms herein shall be enforceable by virtue of the Contracts (Rights of Third Parties) Act
          (Chapter 53B) or otherwise, by any person or entity who is not a party hereto.
        </P>
        <P>19.10. For Additional Information</P>
        <P>
          If you have any questions about these Terms, please contact us on any of our social media platforms on
          https://another-1.io/{' '}
        </P>
      </Content>
    </div>
  )
}

export default TermsAndConditions

const Title = styled(Text)`
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.display2};
  font-weight: 400;
  text-transform: uppercase;
`

const P = styled.div`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
`

const Content = styled(Box)`
  color: ${({ theme }) => theme.secondary7};
  max-width: 790px;
  max-height: 770px;
  line-height: 32px;
  overflow-y: auto;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.secondary1};
    width: 12px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.secondary3};
    width: 12px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.secondary4};
  }

  font-size: ${({ theme }) => theme.fontSizeText1};
  a {
    color: ${({ theme }) => theme.secondary5};
    text-decoration: none;
  }
  @media (max-width: 768px) {
    max-width: 600px;
  }
  @media (max-width: 575px) {
    max-width: 280px;
    font-size: 16px;
    line-height: 28px;
  }
`
