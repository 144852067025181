import { Checkbox, Label } from '@rebass/forms'
import styled from 'styled-components/macro'

export const PrimaryCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.secondary6} !important;
  cursor: pointer;
  input:checked ~ & {
    color: ${({ theme }) => theme.primary6} !important;
  }
  background-color: transparent !important;
`

export const CustomLabel = styled(Label)`
  color: ${({ theme }) => theme.secondary6};
  font-weight: 400;
`
