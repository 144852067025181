import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
