import React from 'react'
import { Link } from 'react-router-dom'
import { Flex } from 'rebass'

import { ButtonPrimary } from '../../../../components/Button'
import Loader from '../../../../components/Loader'
import { routes } from '../../../../constants/routes'
import { useGetProfile } from '../../../../state/profile/hooks'
import { EventsTable } from '../../components/EventsTable'
import { usePartnerEvents } from '../../hooks/usePartnerEvents'
import * as Styled from './styled'

export const Main = () => {
  const { data: profile, loading } = useGetProfile()

  const { data: events, isLoading } = usePartnerEvents(
    {
      partnerAddress: profile.address,
      queryParams: {
        cancelledStatus: false,
      },
    },
    {
      enabled: Boolean(!loading && profile && profile.address),
    }
  )

  return (
    <Styled.Wrapper>
      <Styled.SpaceBetween>
        <h2> Events Management </h2>
        <Link to={routes.createEvents}>
          <ButtonPrimary>Create Event</ButtonPrimary>
        </Link>
      </Styled.SpaceBetween>
      <Styled.PageTitle>Events list</Styled.PageTitle>
      {isLoading ? (
        <Flex p="4" justifyContent="center" alignContent={'center'}>
          <Loader size="48px" />
        </Flex>
      ) : (
        <EventsTable events={events || []} />
      )}
    </Styled.Wrapper>
  )
}
