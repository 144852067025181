/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import styled, { css } from 'styled-components/macro'

import TimeClock from '../../../../components/TimeClock'
import { getActivePeriod, getNextPeriod, SaleType } from '../../../../entities/Sale'

interface CollectionCountdownProps {
  sale?: SaleType
  slug: string
  isUpcoming: boolean
}

const CollectionCountdownHorizontal = ({ sale, slug, isUpcoming }: CollectionCountdownProps) => {
  const buildDependingOnSaleStatus = () => {
    if (!sale || sale.status !== 1 || !sale.periods || sale.periods.length === 0) {
      return <HighLight>COMING SOON</HighLight>
    }

    const activePeriod = sale && getActivePeriod(sale)
    const nextPeriod = sale && getNextPeriod(sale)

    const from = new Date()
    const to = new Date()
    if (activePeriod || nextPeriod) {
      const period = activePeriod && !isUpcoming ? activePeriod : nextPeriod
      from.setTime(period!.timeFrom * 1000)
      to.setTime(period!.timeTo * 1000)
      return (
        <>
          <StatusDiv>{isUpcoming ? 'UPCOMING' : 'ONGOING'}</StatusDiv>
          <CounterDiv>
            <HighLight>
              {period?.saleType == 0 ? 'PRESALE ' : 'PUBLIC SALE '}
              <span>{isUpcoming ? 'STARTS' : 'FINISHES'} IN</span>
            </HighLight>
            <TimeClock exactStart={from} exactEnd={to} isUpcoming={isUpcoming} />
          </CounterDiv>
        </>
      )
    } else {
      return <HighLight>SALE STAGES HAVE FINISHED</HighLight>
    }
  }
  // todo add warning
  return <DivStatus>{buildDependingOnSaleStatus()}</DivStatus>
}
const CounterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const StatusDiv = styled.div`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 2;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary6};
  flex: 0 0 140px;
  max-width: 140px;
  border-right: 1px solid ${({ theme }) => theme.primary6};
  display: flex;
  cursor: default;
  @media (max-width: 1366px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`
const DivStatus = styled.div<{ warning?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  text-align: left;
  width: 100%;

  .countDiv {
    & > p:first-child {
      color: ${({ theme }) => theme.primary5};
    }
  }

  ${({ warning }) =>
    warning &&
    css`
      .countDiv {
        & > p:first-child {
          color: ${({ theme }) => theme.primary5};
        }
      }
    `}
`
const HighLight = styled.span`
  color: ${({ theme }) => theme.primary5};
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600;
  span {
    color: ${({ theme }) => theme.secondary7};
  }
`
export default CollectionCountdownHorizontal
