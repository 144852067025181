import React from 'react'

import { ClerkTable } from '../../../Clerks/components/ClerkTable'
import { ClerkListContextProvider } from '../../../Clerks/context/ClerkListContext'
import { TableSubTitle, TableTitle } from '../TableTitle'

export const LowBalanceClerkTable = () => {
  return (
    <div>
      <TableTitle>Low clerks balance</TableTitle>
      <TableSubTitle>
        Maintain an adequate clerks balance to ensure award distribution to flexers during events.
      </TableSubTitle>
      <ClerkListContextProvider filters={{ cancelledStatus: false }}>
        <ClerkTable />
      </ClerkListContextProvider>
    </div>
  )
}
