import axios from 'axios'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { ProfileType } from '../../entities/Profile'
import { getAvatarSuccess, getProfileError, getProfileSuccess } from './reducer'

export function useFetchProfile(): (address: string, account?: string | null) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    async (address, account) => {
      const finalAccount = account || null
      await axios
        .get(process.env.REACT_APP_SERVER_URL + `users/detail/${address}/${finalAccount}`)
        .then((res) => {
          dispatch(getProfileSuccess(res.data))
        })
        .catch((err: string) => {
          dispatch(getProfileError(err))
        })
    },
    [dispatch]
  )
}

export function useUpdateProfiles(): (data: any) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (data) => {
      dispatch(getProfileSuccess(data))
    },
    [dispatch]
  )
}

export function useGetProfile(): { data: ProfileType; loading: boolean } {
  const { data, loading } = useAppSelector((state) => state.profile)

  return { data, loading }
}

export function useGetAvatar(): (name: string) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    async (name) => {
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}upload?key=${name}`)
        .then((res) => {
          const { url, name } = res.data
          dispatch(
            getAvatarSuccess({
              url,
              name,
            })
          )
        })
        .catch((err: string) => {
          console.log(err)
        })
    },
    [dispatch]
  )
}

export function useGetImage(): any {
  return async (nameImage: string, typeMedia: string) => {
    const type = !!typeMedia ? typeMedia.replace('/', '.') : 'image.jpg'
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}upload?key=${nameImage}&type=${type}`)
    const { url, name } = await res.data
    return {
      url,
      name,
    }
  }
}
