import 'rc-table/assets/index.css'

import { ReactComponent as IconFilter } from 'assets/icons/icon-filter-attributes.svg'
import NFTImg from 'assets/images/img/table_nfc/NFT_Table.jpg'
import IconLeft from 'assets/images/svg/icon-chevronleft.svg'
import IconRight from 'assets/images/svg/icon-chevronright.svg'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { ButtonPrimary } from 'components/Button'
import EmptySearchBox from 'components/EmptySearchBox'
import Loader from 'components/Loader'
import ModalCodeAuthentication, { AssetDetails } from 'components/Modal/ModalCodeAuthentication'
import { PaginationBox } from 'components/Pagination'
import SearchBox from 'components/SearchBox'
import Pagination from 'rc-pagination'
import Table from 'rc-table'
import React, { useEffect, useMemo, useRef, useState } from 'react'
// import { CSVLink } from 'react-csv'
import Select, { SingleValue } from 'react-select'
import { Box, Flex } from 'rebass'
import styled, { css } from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import { OptionType } from '../../../../entities/OptionType'
import { useApi } from '../../../../hooks/useApi'
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'
import { useOptionAssignAttributeCollection } from '../../../../hooks/useOptionAssignAttributeCollection'
import { useGetCollectionsQuery } from '../../../../state/collections/slice'
import { customStylesSelect } from '../../../../theme'
import { TypeNFCPhygital } from '../../../../types'
import { useSelectedColumn } from './useSelectedColumn'

const options: any = [
  { value: 'all', label: 'All' },
  { value: 'assigned', label: 'Assigned' },
  { value: 'not-assigned', label: 'Not Assigned' },
]

const AssignNFC = (props: any) => {
  const { selectedCollection, setSelectedCollection, isSelectDisabled } = props
  const { get } = useApi()
  const { data: collections, isLoading: isLoadingCollections, isFetching } = useGetCollectionsQuery({})

  const [total, setTotal] = useState<number>(0)
  const [collectionOptions, setCollectionOptions] = useState<OptionType[]>([])
  const [page, setPage] = useState<number>(1)
  const [keyword, setKeyword] = useState<string>('')
  const [debouncedSearch] = useDebounce(keyword, 500)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedAssetDetails, setSelectedAssetDetails] = useState<AssetDetails>({ collectionAddress: '', tokenId: '' })
  const [loading, setLoading] = useState<boolean>(false)
  const [shippingInfoList, setShippingInfoList] = useState<any>([])
  const [selectedFilter, setSelectedFilter] = useState<OptionType>({
    value: 'all',
    label: 'All',
  })
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const toggleClickAttribute = () => {
    setShowMenu(!showMenu)
  }
  useEffect(() => {
    if (collections) {
      const collectionOptionsList: OptionType[] = []
      for (let i = 0; i < collections.length; i++) {
        const collection = collections[i]
        collectionOptionsList.push({
          value: collection.address,
          label: collection.name,
        })
      }
      setCollectionOptions(collectionOptionsList)
    }
  }, [collections])

  const fetchRedeemedAssets = () => {
    if (selectedCollection && selectedCollection.value) {
      setLoading(true)
      get(
        {
          url: `${process.env.REACT_APP_SERVER_URL}redemption/listRedeemedAssets`,
          callback: (result: any) => {
            setShippingInfoList(result.data.shippingInfoList)
            setTotal(result.data.shippingInfoCount.count)
          },
          callbackFinally: () => {
            setLoading(false)
          },
          config: {
            headers: {
              authentication: '',
            },
            params: {
              collectionAddress: selectedCollection.value,
              page,
              keyword: debouncedSearch,
              filter: selectedFilter.value,
            },
          },
        },
        true
      )
    }
  }

  useEffect(() => {
    fetchRedeemedAssets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection, page, debouncedSearch, selectedFilter])

  const handleChangePage = (current: number) => {
    setPage(current)
  }
  const handleChangeSearch = (term: string) => {
    setKeyword(term)
    setPage(1)
  }
  const handleChangeSelectedCollection = (collection: SingleValue<OptionType>) => {
    setSelectedCollection(collection as OptionType)
  }
  const optionAttribute = useOptionAssignAttributeCollection(selectedCollection.value)
  const [columnMenu, selectedColumnKeys] = useSelectedColumn(selectedCollection.value)
  const columns: any = useMemo(() => {
    const titleAttribute = optionAttribute.map((item: any) => {
      return {
        title: item.name,
        dataIndex: ['information', item.name /*.replaceAll(' ', '').toLowerCase()*/],
        key: item.name, //.replaceAll(' ', '').toLowerCase(),
        align: 'right',
        render(value: any) {
          return <div style={{ textAlign: 'right' }}>{value}</div>
        },
      }
    })

    const allColumns = [
      {
        title: (
          <div>
            <span>ID</span>
          </div>
        ),
        dataIndex: 'tokenId',
        key: 'tokenId',
        align: 'left',
        className: 'min-w50px w50px',
      },
      {
        title: 'NFT',
        dataIndex: ['metadata', 'image'],
        key: 'image',
        className: 'min-w80px w80px',
        align: 'left',
        render(value: any) {
          return (
            <div className={'img-nft-table'}>
              <img src={value || NFTImg} alt={'img_nft'} />
            </div>
          )
        },
      },
      {
        title: 'Name',
        dataIndex: ['name'],
        key: 'name',
        align: 'left',
        className: 'min-w200px',
        render(value: any) {
          return <div>{value}</div>
        },
      },
      ...titleAttribute,
      {
        title: 'NFC',
        dataIndex: 'nfcId',
        key: 'nfcId',
        render(value: string | null, row: TypeNFCPhygital) {
          return !value ? (
            <CtaAssign
              className={'cta-assign'}
              onClick={() => {
                setModalOpen(true)
                setSelectedAssetDetails({
                  collectionAddress: row.collectionAddress,
                  tokenId: row.tokenId,
                })
              }}
            >
              Assign
            </CtaAssign>
          ) : (
            <CtaAssign className={'cta-assigned'}>Assigned</CtaAssign>
          )
        },
        className: 'field-nfc min-w130px w130px',
      },
    ]

    return allColumns
    // return allColumns.filter(({ key }: { key: string }) => !!selectedColumnKeys[key])
  }, [selectedColumnKeys, optionAttribute])

  // const csvReport = useMemo(() => {
  //   return {
  //     data: !data ? '' : data?.data,
  //     headers,
  //     filename: 'Table_NFC.csv',
  //   }
  // }, [data])
  const attributesNode = useRef<HTMLDivElement>()
  useOnClickOutside(attributesNode, showMenu ? () => setShowMenu(false) : undefined)
  return (
    <>
      <TableNFCWrapper>
        <NameTable>Assign NFC Ids</NameTable>
        {selectedCollection?.value &&
          ((isSelectDisabled && <h3>{selectedCollection.label}</h3>) || (
            <SectionSelect>
              <Select
                styles={customStylesSelect}
                defaultValue={selectedCollection}
                options={collectionOptions}
                classNamePrefix={'select-cs'}
                id="filter"
                name="filter"
                onChange={handleChangeSelectedCollection}
              />
            </SectionSelect>
          ))}
        <TableNFCDetail>
          <CustomBox>
            <Flex flexWrap="wrap" className={'search-box_flex'}>
              <SearchBoxWrap>
                <SearchBox onChange={handleChangeSearch} placeholder={'Search by ID or name'} />
              </SearchBoxWrap>
              <FilterBoxWrap>
                <Select
                  styles={customStylesSelect}
                  defaultValue={selectedFilter}
                  onChange={(value?: OptionType | null | undefined) => {
                    setSelectedFilter(value ?? { value: 'all', label: 'All' })
                    setPage(1)
                  }}
                  options={options}
                  classNamePrefix={'select-cs'}
                  id="filter"
                  name="filter"
                />
              </FilterBoxWrap>
            </Flex>
          </CustomBox>
          {loading && (
            <Flex alignItems="center" justifyContent={'center'}>
              <Box p={5}>
                <Loader size="50px" />
              </Box>
            </Flex>
          )}

          {!isLoadingCollections && !isFetching && !loading && shippingInfoList && (
            <>
              <TableDetail>
                <Table
                  rowKey="tokenId"
                  columns={columns}
                  data={shippingInfoList}
                  className={'rc-table--cs'}
                  emptyText={<EmptySearchBox />}
                  scroll={{ x: 750 }}
                  tableLayout={'fixed'}
                />
                <div className={'add-attribute'} ref={attributesNode as any}>
                  <DivIconAdd onClick={toggleClickAttribute} show={showMenu}>
                    <IconFilter />
                  </DivIconAdd>
                  <MenuOverlay show={showMenu}>
                    <Attributes>
                      attributes <IconClose onClick={() => setShowMenu(false)} />
                    </Attributes>
                    {columnMenu}
                  </MenuOverlay>
                </div>
              </TableDetail>
              {shippingInfoList?.length !== 0 && (
                <>
                  <PaginationBox>
                    <Pagination
                      onChange={(current) => {
                        handleChangePage(current)
                      }}
                      current={page}
                      pageSize={10}
                      total={total}
                      prevIcon={() => (
                        <SpanIcon>
                          <img src={IconLeft} alt={'icon_left'} />
                        </SpanIcon>
                      )}
                      nextIcon={() => (
                        <SpanIcon>
                          <img src={IconRight} alt={'icon_right'} />
                        </SpanIcon>
                      )}
                    />
                  </PaginationBox>
                  {/*<ExportCsv>*/}
                  {/*  <CSVLink {...csvReport}>Export to CSV</CSVLink>*/}
                  {/*</ExportCsv>*/}
                </>
              )}
            </>
          )}
        </TableNFCDetail>
      </TableNFCWrapper>
      <ModalCodeAuthentication
        isOpen={modalOpen}
        onDismiss={() => setModalOpen(!modalOpen)}
        name={'modal_assign'}
        reFetch={() => {
          fetchRedeemedAssets()
        }}
        assetDetails={selectedAssetDetails}
      />
    </>
  )
}
const TableNFCWrapper = styled.div`
  width: 100%;
`
const CustomBox = styled(Box)`
  width: 100%;
  margin-bottom: 32px !important;
`
const SearchBoxWrap = styled.div`
  flex: 1;
  margin-right: 24px;
`
const FilterBoxWrap = styled.div`
  flex: 0 0 154px;
  max-width: 154px;
`
const NameTable = styled.h3`
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`
const TableNFCDetail = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  .rc-table--cs {
    margin-bottom: 32px;
  }
  .rc-table-thead {
    text-transform: capitalize;
  }
  .img-nft-table {
    display: flex;
    justify-content: flex-start;
    img {
      width: 32px;
      height: 32px;
    }
  }
  .field-nfc {
    border-left: 1px solid ${({ theme }) => theme.secondary4} !important;
    max-width: 132px;
    min-width: 132px;
    width: 132px;
  }
  .th_id {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    span:first-of-type {
      margin-right: 6px;
    }
    &[data-arrowup='false'] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`
const SectionSelect = styled.div`
  width: 100%;
  margin-bottom: 24px;
`
const CtaAssign = styled(ButtonPrimary)`
  font-size: ${({ theme }) => theme.fontSizeText3};
  color: ${({ theme }) => theme.secondary1};
  line-height: 16px;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  &.cta-assigned {
    background-color: ${({ theme }) => theme.secondary2};
    color: ${({ theme }) => theme.secondary4};
  }
`
const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`
// const ExportCsv = styled(ButtonSecondary)`
//   text-transform: capitalize;
//   padding: 10px 24px;
//   margin-top: 32px;
//   a {
//     color: ${({ theme }) => theme.primary10};
//   }
// `
const TableDetail = styled.div`
  margin-bottom: 32px;
  position: relative;
  padding-right: 24px;

  .add-attribute {
    position: absolute;
    right: 0;
    top: 0;
    height: 41px;
    border-bottom: 1px solid ${({ theme }) => theme.secondary3};
    display: flex;
    align-items: center;
  }
`
const DivIconAdd = styled.div<{ show: boolean }>`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.secondary4};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  cursor: pointer;
  ${({ show }) =>
    show &&
    css`
      background-color: ${({ theme }) => theme.primary4};
      svg {
        path {
          fill: ${({ theme }) => theme.secondary1};
        }
      }
    `}
`
const MenuOverlay = styled.div<{ show: boolean }>`
  width: 250px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.secondary1};
  padding: 16px 20px;
  display: none;
  ${({ show }) =>
    show &&
    css`
      display: block;
    `}
`
const Attributes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 24px;
  svg {
    cursor: pointer;
  }
`
export default AssignNFC
