import styled from 'styled-components/macro'

export const TableTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary7};
  margin: 0;
`
export const TableSubTitle = styled.h5`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.textFollow};
  margin-top: 0;
  margin-bottom: 24px;
`
