import { createSlice, nanoid } from '@reduxjs/toolkit'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'

import { ChainIds } from '../../constants/chains'

export type PopupContent =
  | {
      txn: {
        hash: string
      }
    }
  | {
      failedSwitchNetwork: ChainIds
    }

export enum ApplicationModal {
  WALLET,
  WALLET_POPUP,
  ACCOUNT_POPUP,
  SIGNATURE_VERIFY,
  FULLSCREEN_ITEM_NFT,
  CONFIRM_MINT,
  FOLLOWERS_POPUP,
  FOLLOWING_POPUP,
  REPORT_POPUP,
  REDEEM_POPUP,
  SIGNUP_REQUEST,
  AUTHENTICATION_POPUP,
  SUCCESS_CONTACT_FORM,
}

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly chainId: number | null
  readonly openModal: ApplicationModal | null
  readonly popupList: PopupList
  readonly openPopup: ApplicationModal | null
  readonly openAccountPopup: ApplicationModal | null
  readonly openSignup: ApplicationModal | null
  readonly tabActive: number | null
}

const initialState: ApplicationState = {
  chainId: null,
  openModal: null,
  popupList: [],
  openPopup: null,
  openAccountPopup: null,
  openSignup: null,
  tabActive: 1,
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    setOpenModal(state, action) {
      state.openModal = action.payload
    },
    setOpenWalletPopup(state, action) {
      state.openPopup = action.payload
    },
    setOpenAccountPopup(state, action) {
      state.openAccountPopup = action.payload
    },
    setOpenSignupPopup(state, action) {
      state.openSignup = action.payload
    },
    setActiveTabProfile(state, action) {
      state.tabActive = action.payload
    },
    addPopup(state, { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }) {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    },
  },
})

export const {
  updateChainId,
  setOpenModal,
  addPopup,
  removePopup,
  setOpenWalletPopup,
  setOpenAccountPopup,
  setActiveTabProfile,
  setOpenSignupPopup,
} = applicationSlice.actions

export default applicationSlice.reducer
