import React, { ReactNode } from 'react'

import * as Styled from './styled'

interface PageWithSidePanelProps {
  sidePanel: ReactNode
  children: ReactNode
}

export const PageWithSidePanel = ({ sidePanel, children }: PageWithSidePanelProps) => {
  return (
    <Styled.PageWithSidePanelWrapper>
      <Styled.PageWithSidePanelContainer>
        <Styled.Flex>
          <Styled.SidebarPageWithSidePanelWrapper>{sidePanel}</Styled.SidebarPageWithSidePanelWrapper>
          <Styled.ContentPageWithSidePanelWrapper>
            <Styled.ContentPageWithSidePanel>{children}</Styled.ContentPageWithSidePanel>
          </Styled.ContentPageWithSidePanelWrapper>
        </Styled.Flex>
      </Styled.PageWithSidePanelContainer>
    </Styled.PageWithSidePanelWrapper>
  )
}
