import { ModalBase } from '../../../../components/ModalBase/ModalBase'
import { SendMaticForm } from '../SendMaticForm'

interface SendMaticModalProps {
  isOpen: boolean
  onClose: () => void
  clerkAddress: string
}

export const SendMaticModal = ({ isOpen, onClose, clerkAddress }: SendMaticModalProps) => {
  return (
    <ModalBase isOpen={isOpen} onDismiss={onClose} header="Send Matic to Clerk">
      <SendMaticForm onSuccess={onClose} clerkAddress={clerkAddress} />
    </ModalBase>
  )
}
