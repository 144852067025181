import { useApi } from 'hooks/useApi'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { shortenAddress } from 'utils'

import ImageDefaultFollower from '../../assets/images/img/imageFollower.png'
import { ProfileType } from '../../entities/Profile'
import { updateFollow } from '../../services'
import { ButtonSecondary } from '../Button'

interface CardFollowerProps {
  follower: ProfileType
  isFollowed: boolean
  account: string
  followersCount: number
  onDismiss: () => void
  isCreator: boolean
}

const CardFollower = ({ follower, account, onDismiss, isFollowed, followersCount, isCreator }: CardFollowerProps) => {
  const history = useHistory()
  const [followed, setFollowed] = useState<boolean>(isFollowed)
  const clickRedirectProfile = (addressUser: string) => {
    onDismiss()
    if (addressUser === account) {
      history.push(`/profile`)
    } else {
      history.push(`/user/${addressUser}`)
    }
  }
  const { post } = useApi()

  const handleClickFollow = (e: React.MouseEvent<HTMLButtonElement>, addressUser: string) => {
    e.stopPropagation()

    if (!account) {
      return
    }
    ;(async () => {
      const response = await updateFollow(addressUser, account, post)
      try {
        if (response?.status === 200) {
          const { message } = response.data
          isFollowed = message === 'Follower success'
          setFollowed(isFollowed)
        }
      } catch (err) {
        console.log('error', err)
      }
    })()
  }
  return (
    <UserFollow onClick={() => clickRedirectProfile(follower.address)}>
      <InfoUser>
        <ImageUser>
          <img alt={'image_follower'} src={follower.imageUrl ? follower.imageUrl : ImageDefaultFollower} />
        </ImageUser>
        <QuantityFollower>
          <div>{follower.name ?? shortenAddress(follower.address)}</div>
          <div>{followersCount} followers</div>
        </QuantityFollower>
      </InfoUser>
      {isCreator && follower.address !== account && (
        <CtaFollowUser followed={followed} onClick={(e) => handleClickFollow(e, follower.address)}>
          {followed ? 'Unfollow' : 'Follow'}
        </CtaFollowUser>
      )}
    </UserFollow>
  )
}
const UserFollow = styled.div`
  padding: 10px 16px 10px 10px;
  border: 1px solid ${({ theme }) => theme.secondary3};
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`
const InfoUser = styled.div`
  display: flex;
`
const ImageUser = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 8px;
  cursor: pointer;
  img {
    width: 100%;
    display: block;
    height: 100%;
    vertical-align: middle;
  }
`
const QuantityFollower = styled.div`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
  align-self: flex-end;
`
const CtaFollowUser = styled(ButtonSecondary)<{ followed?: boolean }>`
  width: 92px;
  flex: 0 0 92px;
  max-width: 92px;
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: ${({ theme }) => theme.secondary1};
  padding: 10px 18px;
  gap: 8px;
  margin-left: auto;
  background-color: ${({ theme }) => theme.primary6};
  &:focus {
    background-color: ${({ theme }) => theme.primary6};
  }
  ${({ followed }) =>
    followed &&
    css`
      background-color: ${({ theme }) => theme.secondary2};
      color: ${({ theme }) => theme.primary10};
      &:focus {
        background-color: ${({ theme }) => theme.secondary2};
      }
    `}
`
export default CardFollower
