import { useApi } from 'hooks/useApi'
import { useState } from 'react'
import Select from 'react-select'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'

import IconChecked from '../../../assets/icons/icon_check.svg'
import { ButtonPrimary, ButtonSecondary } from '../../Button'
import IconPortal from '../../IconPortal'
import Loader from '../../Loader'
import Modal from '../index'
const options = [
  { value: 'counterfeit', label: 'Counterfeit content' },
  { value: 'scam', label: 'Scam suspicion' },
  { value: 'other', label: 'Other' },
]
const customStyles = {
  //select
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? '#323232' : 'transparent',

    border: '2px solid #4F4F4F',
    borderColor: state.isFocused ? '#00D98B' : '#4F4F4F',
    boxShadow: state.isFocused ? null : null,

    '&:hover': {
      borderColor: state.isFocused ? '#00D98B' : '#4F4F4F',
    },
  }),
  container: (base: any, state: any) => ({
    ...base,
    color: state.isSelected ? '#ffffff' : '#ffffff',
  }),
  //value selected
  singleValue: (base: any) => ({
    ...base,
    color: '#ffffff !important',
    fontSize: '16px',
    lineHeight: '1.5',
  }),
  //dropdown menu
  menu: (base: any) => ({
    ...base,
    background: 'linear-gradient(180deg, #111111 0%, #1B1B1B 100%)',
    border: '2px solid #4F4F4F',
    borderRadius: 0,
    zIndex: '100',
  }),
  //option
  option: (base: any, state: any) => ({
    ...base,
    padding: '14px 20px',
    borderBottom: '1px solid #323232',
    backgroundColor: state.isSelected ? '#4F4F4F' : 'transparent',
    '&:hover': {
      backgroundColor: '#323232',
      color: '#F6F6F6',
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#F6F6F6',
    margin: 0,
  }),
}
const STATUS_REPORT = {
  REPORT: 'REPORT',
  SENDING: 'SENDING',
  REPORTED: 'REPORTED',
}
const ModalReport = (props: any) => {
  const { isOpen, onDismiss, userAddress } = props
  const { post } = useApi()
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [description, setDescription] = useState<string | undefined>(undefined)
  const [statusReport, setStatusReport] = useState<string>(STATUS_REPORT.REPORT)

  const handleSubmit = () => {
    setStatusReport(STATUS_REPORT.SENDING)

    if (selectedOption && description)
      post(
        {
          url: `${process.env.REACT_APP_SERVER_URL}users/report-user`,
          data: {
            report: { topic: selectedOption.label, description: description },
          },
          callback: async (res: any) => {
            setStatusReport(STATUS_REPORT.REPORTED)
          },
          errorCallback: (error) => {
            console.log(error)
          },
        },
        true
      )
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={450}>
      <ModalWrapper>
        {statusReport === STATUS_REPORT['REPORT'] && (
          <>
            <Header>Why are you reporting?</Header>
            <Content>
              <Description>Tell us how this user violates the rules of the site.</Description>
              <DivForm>
                <FormGroup>
                  <FormLabel>Reason</FormLabel>
                  <FormControl>
                    <Select
                      styles={customStyles}
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={options}
                      placeholder={'Select a reason'}
                      classNamePrefix={'select-cs'}
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Description</FormLabel>
                  <FormTextarea
                    placeholder="Tell us some details"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                </FormGroup>
              </DivForm>
            </Content>
            <Footer>
              <CtaSubmit onClick={handleSubmit} disabled={!(selectedOption && description)}>
                Submit Report
              </CtaSubmit>
              <CtaCancel onClick={onDismiss}>Cancel</CtaCancel>
            </Footer>
          </>
        )}
        {statusReport === STATUS_REPORT['SENDING'] && (
          <BoxSending>
            <Loader />
            <Captions>Sending</Captions>
            <TextSmall>Sending your report, Please wait few seconds...</TextSmall>
          </BoxSending>
        )}
        {statusReport === STATUS_REPORT['REPORTED'] && (
          <BoxReported>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <IconPortal SrcImageIcon={IconChecked} widthIcon={'72px'} heightIcon={'72px'} />
            </Flex>
            <Captions>This item has been reported.</Captions>
          </BoxReported>
        )}
      </ModalWrapper>
    </Modal>
  )
}
const ModalWrapper = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
`
const Header = styled.p`
  font-family: 'Din Pro Bold', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeText1};
  line-height: 1.33333;
  color: ${({ theme }) => theme.primary10};
  text-transform: uppercase;
  margin: 0;
`
const Content = styled.div`
  width: 100%;
  padding: 20px 0;
`
const Description = styled.p`
  margin: 0 0 20px 0;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
`
const DivForm = styled.div``
const FormGroup = styled.div`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`
const FormLabel = styled.label`
  margin-bottom: 12px;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
  display: block;
`
const FormTextarea = styled.textarea`
  outline: none;
  resize: none;
  width: 100%;
  border: 1.5px solid ${({ theme }) => theme.secondary4};
  border-radius: 2px;
  padding: 12px;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
  background-color: transparent;
  ::-webkit-input-placeholder {
    font-family: 'Din Pro Regular', sans-serif;
    color: ${({ theme }) => theme.secondary5};
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
  }

  :-ms-input-placeholder {
    font-family: 'Din Pro Regular', sans-serif;
    color: ${({ theme }) => theme.secondary5};
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
  }

  ::placeholder {
    font-family: 'Din Pro Regular', sans-serif;
    color: ${({ theme }) => theme.secondary5};
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
  }
`
const FormControl = styled.div``
const Footer = styled.div``
const CtaSubmit = styled(ButtonPrimary)`
  padding: 10px 12px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  text-transform: capitalize;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 100%;
`
const CtaCancel = styled(ButtonSecondary)`
  padding: 10px 12px !important;
  text-transform: capitalize;
  margin-top: 16px;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  background-color: ${({ theme }) => theme.secondary2};
`
const BoxSending = styled.div`
  min-height: 216px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const Captions = styled.h3`
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.display3};
  color: ${({ theme }) => theme.primary10};
  line-height: 1.25;
`
const TextSmall = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  margin: 0;
  color: ${({ theme }) => theme.secondary6};
`
const BoxReported = styled(BoxSending)``
export default ModalReport
