import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import { useGetRolesUsersManagementQuery } from '../../../../state/usersManagement/slice'
import { RoleTable } from '../RoleTable'

export const UserRoleTable = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debouncedSearch] = useDebounce(searchTerm, 500)
  const [page, setPage] = useState<number>(1)

  const { data: dataRole, isLoading } = useGetRolesUsersManagementQuery({
    keyword: debouncedSearch,
    page,
    limit: 8,
  })

  const handleChangeSearchRole = useCallback(
    (term: string) => {
      setSearchTerm(term)
    },
    [setSearchTerm]
  )

  return (
    <SectionRoleList>
      <RoleTable isLoading={isLoading} onSearch={handleChangeSearchRole} dataRole={dataRole} setPage={setPage} />
    </SectionRoleList>
  )
}

const SectionRoleList = styled.div`
  margin-top: 32px;
`
