import styled from 'styled-components/macro'

export const DateWithCalendarContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.primary6};
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
`
