import React from 'react'
import styled from 'styled-components/macro'

import { dataTabs } from '../../pages/Profile'
import Item from '../Item'

const gutterWidthGrid = 16

const GridContainer = styled.div`
  padding: 25px 0 0;
  max-width: 1440px;
  margin: auto;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${gutterWidthGrid}px;
  margin-right: -${gutterWidthGrid}px;

  & > .item-card {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: ${gutterWidthGrid}px;
    padding-right: ${gutterWidthGrid}px;
    @media (max-width: 1024px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    @media (max-width: 575px) {
      flex: 0 0 50%;
      max-width: 50%;
      .image-box {
        margin-bottom: 12px;
      }
      .title-card {
        font-size: ${({ theme }) => theme.fontSizeText2};
        line-height: 1.5;
        margin-bottom: 8px;
      }
    }
  }
`

const NftsContainer = (props: any) => {
  const { data } = props
  return (
    <GridContainer>
      <Grid>
        {data?.map((item: dataTabs, key: any) => (
          <Item key={key} dataItem={item} />
        ))}
      </Grid>
    </GridContainer>
  )
}

export default NftsContainer
