import React from 'react'

import { WalletCoins } from '../../../../components/Icons/WalletCoins'
import Loader from '../../../../components/Loader'
import { useWithdrawTokensFromFlex2Earn } from '../../hooks/useWithdrawTokensFromFlex2Earn'
import { RewardBoxButton } from '../RewardBoxButton'

interface WithdrawTokensButtonProps {
  onSuccess: () => void
}

export const WithdrawTokensButton = ({ onSuccess }: WithdrawTokensButtonProps) => {
  const { mutate: withdrawTokens, isLoading } = useWithdrawTokensFromFlex2Earn({
    onSuccess,
  })

  const handleWithdrawTokens = () => {
    withdrawTokens()
  }

  return (
    <RewardBoxButton onClick={handleWithdrawTokens}>
      {isLoading ? <Loader size="20px" /> : <WalletCoins />} <span>Withdraw Tokens</span>
    </RewardBoxButton>
  )
}
