import axios, { Axios } from 'axios'
import useParticle from 'hooks/useParticle'
import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'

import { apiEndpoints } from '../constants/apiEndpoints'
import { useFetchProfile } from '../state/profile/hooks'

export interface ApiClientContextProps {
  client: Axios
  reauthenticate: () => void
}

const defaultValues: ApiClientContextProps = {
  client: axios.create(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reauthenticate: () => {},
}

const ApiClientContext = createContext<ApiClientContextProps>(defaultValues)

export const useApiClientContext = () => {
  return useContext(ApiClientContext)
}

interface ApiClientContextProviderProps {
  children: ReactNode
}

export const ApiClientContextProvider = ({ children }: ApiClientContextProviderProps) => {
  const { account, personalSign, provider } = useParticle()
  const handleFetchProfile = useFetchProfile()

  let isReauthorizing = false

  const reauthorize = useCallback(async () => {
    if (!account) return
    const getChallengeURL = apiEndpoints.authorize.replace('{address}', account)

    const challenge = await axios.get(getChallengeURL)

    const signature = await personalSign?.(`${challenge.data.purpose}${challenge.data.challenge}`)

    if (signature) {
      const resVerificationURL = apiEndpoints.verifyAuthorization
        .replace('{challenge}', challenge.data.challenge)
        .replace('{signature}', signature)

      const resVerification = await axios.get(resVerificationURL)

      if (resVerification.status === 200) {
        const { token } = resVerification.data
        localStorage.setItem('authToken', token)
        handleFetchProfile(account)
      }
    }
  }, [account, handleFetchProfile, provider])

  const handleReauthenticate = async () => {
    isReauthorizing = true
    await reauthorize()
    isReauthorizing = false
  }

  const client = useMemo(() => {
    const apiClient = axios.create()

    apiClient.interceptors.response.use(
      async (response) => {
        return response
      },
      async (response) => {
        if (response.response.status === 401) {
          if (!isReauthorizing) {
            await handleReauthenticate()
          }
        }

        return Promise.reject(response)
      }
    )

    return apiClient
  }, [handleReauthenticate, isReauthorizing])

  return (
    <ApiClientContext.Provider value={{ client, reauthenticate: handleReauthenticate }}>
      {children}
    </ApiClientContext.Provider>
  )
}
