import React from 'react'

import { EventIcon } from '../../../../components/Icons/Event'
import { Grid } from '../../../../components/Icons/Grid'
import { SidebarMenu, SideBarMenuItem } from '../../../../components/SidebarMenu'
import { Role } from '../../../../constants/roles'
import { routes } from '../../../../constants/routes'

const partnerSidebarMenuItems: SideBarMenuItem[] = [
  {
    label: 'Partner Dashboard',
    url: routes.partnerPanel.dashboard,
    icon: <Grid />,
    permittedRoles: [Role.PARTNER],
  },
  {
    label: 'Event management',
    url: routes.partnerPanel.eventManagement,
    icon: <EventIcon />,
    permittedRoles: [Role.PARTNER],
  },
]

export const PartnerPanelSideMenu = () => {
  return <SidebarMenu items={partnerSidebarMenuItems} title="Partner Panel" />
}
