import styled from 'styled-components/macro'

export const Input = styled.input`
  padding: 12px 16px;
  border-radius: 2px;
  color: #ffffff;
  border: 1.5px solid ${({ theme }) => theme.secondary2};
  background-color: ${({ theme }) => theme.secondary2};
  width: 100%;

  ::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.secondary5};
  }

  :disabled {
    cursor: not-allowed;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
