import React, { useState } from 'react'

import { WalletCoins } from '../../../../components/Icons/WalletCoins'
import { DepositTokensModal } from '../DepositTokensModal'
import { RewardBoxButton } from '../RewardBoxButton'

interface DepositTokensButtonProps {
  onSuccess: () => void
}

export const DepositTokensButton = ({ onSuccess }: DepositTokensButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      {isModalOpen && <DepositTokensModal isOpen={isModalOpen} onClose={handleToggleModal} onSuccess={onSuccess} />}
      <RewardBoxButton onClick={handleToggleModal}>
        <WalletCoins /> <span>Deposit Tokens</span>
      </RewardBoxButton>
    </>
  )
}
