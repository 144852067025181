import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { ClerksModal } from 'modules/Clerks/components/ClerksModal'
import { CollectionsModal } from 'modules/Collections/components/CollectionsModal'
import { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ButtonPrimary } from '../../../../components/Button'
import { ButtonGray } from '../../../../components/Button'
import ColCs from '../../../../components/ColCs'
import { FormInput } from '../../../../components/FormInput'
import { FormInputCheckbox } from '../../../../components/FormInputCheckbox'
import { FormInputGoogleMaps } from '../../../../components/FormInputGoogleMaps'
import { FormInputPicture } from '../../../../components/FormInputPicture'
import Loader from '../../../../components/Loader'
import RowCs from '../../../../components/RowCs'
import { preventNonNumbersOnKeyPress } from '../../../../utils/preventNonNumbersOnKeyPress'
import { WhitelistModal } from '../WhitelistModal/WhitelistModal'
import { schema } from './schema'
import * as Styled from './styled'
import { FormHeader } from './styled'

export interface EventFormValues {
  name: string
  startDate: {
    date: string
    time: string
  }
  endDate: {
    date: string
    time: string
  }
  location: {
    latitude: number
    longitude: number
  }
  formattedAddress: string
  files?: FileList
  imageKey?: string
  rewardPerFlexer: string
  tokenRewardPool: string
  isPOAPEnabled: boolean
}

interface EventFormProps {
  onSubmit: (data: EventFormValues) => void
  isSubmitting: boolean
  defaultValues?: EventFormValues
  submitText?: string
}

interface EventParams {
  eventId: string
}

export const EventForm = ({ onSubmit, isSubmitting, defaultValues, submitText = 'Create Event' }: EventFormProps) => {
  const { eventId } = useParams<EventParams>()

  const [clerksModalOpen, setClerksModalOpen] = useState<boolean>(false)
  const [collectionsModalOpen, setCollectionsModalOpen] = useState<boolean>(false)
  const [whitelistModalOpen, setWhitelistModalOpen] = useState<boolean>(false)

  const toggleClerksModal = () => setClerksModalOpen((open) => !open)
  const toggleCollectionsModal = () => setCollectionsModalOpen((open) => !open)
  const toggleWhitelistModal = () => setWhitelistModalOpen((open) => !open)

  const formValues = useForm<EventFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      imageKey: '',
      isPOAPEnabled: false,
      ...defaultValues,
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = formValues

  const customEndDateError = useMemo(() => {
    if (!errors || !errors.endDate) {
      return undefined
    }

    return errors.endDate.message
  }, [errors])

  const defaultTimezone = format(new Date(), 'z')

  const [startDate, endDate] = watch(['startDate.date', 'endDate.date'])

  const timezoneStartDate = startDate ? format(new Date(startDate), 'z') : defaultTimezone
  const timezoneEndDate = endDate ? format(new Date(endDate), 'z') : defaultTimezone

  return (
    <>
      {clerksModalOpen && <ClerksModal isOpen={!!clerksModalOpen} onClose={toggleClerksModal} eventId={eventId} />}
      {collectionsModalOpen && (
        <CollectionsModal isOpen={!!collectionsModalOpen} onClose={toggleCollectionsModal} eventId={eventId} />
      )}
      {whitelistModalOpen && (
        <WhitelistModal isOpen={whitelistModalOpen} onClose={toggleWhitelistModal} eventId={eventId} />
      )}

      <Styled.FormPageContainer>
        <FormProvider {...formValues}>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Styled.FormColumnLeft>
              <FormInputPicture name="files" label="Event picture" defaultImageInputName="imageKey" />
            </Styled.FormColumnLeft>
            <Styled.FormColumnRight>
              <FormHeader> General Info </FormHeader>
              <FormInput name="name" label="Name" placeholder="Enter Name" autoFocus />
              <RowCs className={'row-cs'}>
                <ColCs md={6}>
                  <FormInput type="date" name="startDate.date" label={`Starts (${timezoneStartDate})`} />
                </ColCs>
                <ColCs md={6}>
                  <FormInput type="time" name="startDate.time" label="" />
                </ColCs>
              </RowCs>
              <RowCs className={'row-cs'}>
                <ColCs md={6}>
                  <FormInput
                    type="date"
                    name="endDate.date"
                    label={`Ends (${timezoneEndDate})`}
                    customError={customEndDateError}
                  />
                </ColCs>
                <ColCs md={6}>
                  <FormInput type="time" name="endDate.time" label="" />
                </ColCs>
              </RowCs>
              <RowCs className={'row-cs'}>
                <ColCs md={6}>
                  <FormInput
                    name="rewardPerFlexer"
                    label="Rewards per flexer"
                    onKeyPress={preventNonNumbersOnKeyPress}
                  />
                </ColCs>
                <ColCs md={6}>
                  <FormInput name="tokenRewardPool" label="Rewards pool" onKeyPress={preventNonNumbersOnKeyPress} />
                </ColCs>
              </RowCs>
              {defaultValues ? (
                <RowCs className={'row-cs'}>
                  <ColCs md={4}>
                    <ButtonGray type="button" onClick={toggleClerksModal}>
                      Manage Clerks
                    </ButtonGray>
                  </ColCs>
                  <ColCs md={4}>
                    <ButtonGray type="button" onClick={toggleCollectionsModal}>
                      Manage Collections
                    </ButtonGray>
                  </ColCs>
                  <ColCs md={4}>
                    <ButtonGray type="button" onClick={toggleWhitelistModal}>
                      Upload whitelist
                    </ButtonGray>
                  </ColCs>
                </RowCs>
              ) : null}
              <PoapCheckbox>
                <FormInputCheckbox label="Enable POAP" name="isPOAPEnabled" />
              </PoapCheckbox>
              <FormInputGoogleMaps name="formattedAddress" label="Address" />
              <ButtonPrimary disabled={isSubmitting} type="submit">
                {isSubmitting ? <Loader stroke="#ffffff" size="20.5px" /> : submitText}
              </ButtonPrimary>
            </Styled.FormColumnRight>
          </Styled.Form>
        </FormProvider>
      </Styled.FormPageContainer>
    </>
  )
}

const PoapCheckbox = styled.div`
  margin-top: 16px;
`
