import { useMemo } from 'react'

import { CollectionType } from '../entities/Collection'
import { getActivePeriod, getNextPeriod } from '../entities/Sale'

export function useGetOnGoingCollection(collections: CollectionType[]) {
  const collectionsOngoing = useMemo(() => {
    return collections?.filter((item) => item.sale && getActivePeriod(item.sale)) || []
  }, [collections])

  return collectionsOngoing
}

export function useGetUpcomingCollection(collections: CollectionType[]) {
  const collectionsUpcoming = useMemo(() => {
    return collections?.filter((item) => item.sale && getNextPeriod(item.sale)) || []
  }, [collections])

  return collectionsUpcoming
}
