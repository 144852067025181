import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'
import { POAP } from '../models/POAP'

export interface QueryParams {
  sth?: any
}

interface UsePOAPProps {
  address: string
  queryParams?: QueryParams
}

export const usePOAPs = (params: UsePOAPProps, options?: UseQueryOptions<UsePOAPProps, AxiosError<any>, POAP[]>) => {
  const { client } = useApiClientContext()

  return useQuery<UsePOAPProps, AxiosError<any>, POAP[]>(
    [QueryKeys.Events, params?.queryParams],
    async () => {
      const result = await client.get(apiEndpoints.poaps.replace('{address}', params.address), {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
        params: params?.queryParams,
      })

      return result?.data
    },
    options
  )
}
