import IconTrash from 'assets/icons/icon-trash.svg'
import EmptySearchBox from 'components/EmptySearchBox'
import { FormLabel } from 'components/Forms'
import Loader from 'components/Loader'
import ModalConfirmSetRedemption from 'components/Modal/ModalConfirm'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import SearchBox from 'components/SearchBox'
import { useApi } from 'hooks/useApi'
import Table from 'rc-table'
import React, { useCallback, useMemo, useState } from 'react'
import { Minus } from 'react-feather'
import { Flex } from 'rebass'
import { assignAttributesApi } from 'state/assignAttributes/slice'
import { useAppDispatch } from 'state/hooks'
import styled from 'styled-components/macro'
interface TypePropsTableAssign {
  data: any
  isLoading: boolean
  onSearch: (keyword: string) => void
  collectionAddress: string | undefined
}
const TableAssign = (props: TypePropsTableAssign) => {
  const { onSearch, data, isLoading, collectionAddress } = props
  const [idSelected, setIdSelected] = useState<string>('')
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const { totalItems, currentPage, totalPages } = data || { totalItems: 1, currentPage: 1, totalPages: 1 }
  const columns: any = useMemo(() => {
    const allColumns = [
      {
        title: (
          <div className={'th_id'}>
            <span>#</span>
          </div>
        ),
        dataIndex: 'index',
        key: 'index',
        width: 60,
        align: 'left',
        render(value: any, row: any, index: number) {
          return <span>{index}</span>
        },
      },
      {
        title: 'NAME',
        dataIndex: ['attribute', 'name'],
        key: 'name',
        align: 'left',
        width: 120,
        render(value: any) {
          return <span>{value}</span>
        },
      },
      {
        title: 'DETAILS',
        dataIndex: ['attribute', 'options'],
        key: 'options',
        width: 184,
        align: 'left',
        render(value: any) {
          return !value || value.length === 0 ? (
            <Minus />
          ) : (
            <div>
              {value.reduce((total: string, item: string) => {
                return total + `, ${item}`
              })}
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        minWidth: '52px',
        width: '52px',
        render(value: any, row: any) {
          return (
            <div>
              <button
                className={'delete'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  setIdSelected(row.attributeId)
                  setShowConfirm(true)
                }}
              >
                <img src={IconTrash} alt={'img_trash'} />
              </button>
            </div>
          )
        },
      },
    ]
    return allColumns
  }, [])
  const dispatch = useAppDispatch()
  const { deleteFnc } = useApi()
  const handleDeleteAttributes = useCallback(
    (totalItems: any, currentPage: any, totalPages: any, attributeId: any) => {
      deleteFnc(
        {
          url: `${process.env.REACT_APP_SERVER_URL}admin-panel/assigning-attributes`,
          data: {
            attributeId,
            collectionAddress,
          },
          callback: () => {
            setShowSuccess(true)
            dispatch(assignAttributesApi.util.invalidateTags(['ASSIGN_ATTRIBUTE']))
          },
          callbackFinally: () => {
            setShowConfirm(false)
          },
          errorCallback: (res) => {
            console.log(res)
            setShowError(true)
          },
        },
        true
      )
    },
    [deleteFnc, dispatch, collectionAddress]
  )
  return (
    <div>
      <FormLabel>Attributes List</FormLabel>
      <SearchBoxWrap>
        <SearchBox onChange={onSearch} placeholder={'Search by name'} />
      </SearchBoxWrap>
      {isLoading ? (
        <Flex p="4" justifyContent="center" alignContent={'center'}>
          <Loader size="48px" />
        </Flex>
      ) : (
        data?.data && (
          <TableDetail>
            <Table
              rowKey="attributeId"
              columns={columns}
              data={data.data}
              className={'rc-table--cs'}
              emptyText={<EmptySearchBox text={'No data'} />}
            />
          </TableDetail>
        )
      )}
      {showConfirm && (
        <ModalConfirmSetRedemption
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => handleDeleteAttributes(totalItems, currentPage, totalPages, idSelected)}
          subText={'Are you sure you want to delete attributes?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={`Delete Attributes Failed`} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={`Delete Attributes Successfully.`}
      />
    </div>
  )
}
const SearchBoxWrap = styled.div`
  margin-bottom: 32px;
`
const TableDetail = styled.div`
  margin-bottom: 32px;
  position: relative;
  padding-right: 24px;
`
export default TableAssign
