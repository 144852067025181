import { FormProvider, useForm } from 'react-hook-form'

import { ButtonPrimary } from '../../../../components/Button'
import { FormTextarea } from '../../../../components/Forms'
import Loader from '../../../../components/Loader'

export interface WhitelistFormValues {
  address: string
}

interface WhitelistFormProps {
  addAddress: (address: string[]) => void
  isError: boolean
}

export const WhitelistForm = ({ addAddress, isError }: WhitelistFormProps) => {
  const formValues = useForm()

  const {
    handleSubmit,
    formState: { isSubmitting },
    resetField,
    register,
  } = formValues

  const handleAddAddress = async (values: WhitelistFormValues) => {
    addAddress(values.address.split(/\s+/))
    resetField('address')
  }

  return (
    <FormProvider {...formValues}>
      <form onSubmit={handleSubmit(handleAddAddress)}>
        <FormTextarea {...register('address')} rows={10} />
        <ButtonPrimary type="submit" disabled={isSubmitting} marginTop={isError ? '72px' : '16px'}>
          {isSubmitting ? <Loader stroke="#ffffff" size="20.5px" /> : 'Add addresses'}
        </ButtonPrimary>
      </form>
    </FormProvider>
  )
}
