import Loader from 'components/Loader'
import Pagination from 'rc-pagination'
import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'rebass'
import styled from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import IconLeft from '../../../assets/images/svg/icon-chevronleft.svg'
import IconRight from '../../../assets/images/svg/icon-chevronright.svg'
import EmptySearchBox from '../../../components/EmptySearchBox'
import { PaginationBox } from '../../../components/Pagination'
import { useGetWatchlistCollectionsQuery } from '../../../state/collections/slice'
import Filter from '../components/Filter'
import SearchBox from '../components/SearchBox'
import Watchlisted from '../components/Watchlisted'
interface PropsType {
  account: any
  address: string | undefined
  getTotalItems: (totalItems: number) => void
}
const TabWatchlist = (props: PropsType) => {
  const { account, address, getTotalItems } = props
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [debouncedSearch] = useDebounce(searchTerm, 500)
  const [orderBy, setOrderBy] = useState<string>('ASC')
  const {
    data: watchListItems,
    isLoading,
    refetch,
    isFetching,
  } = useGetWatchlistCollectionsQuery({
    address: address || account,
    keyword: debouncedSearch,
    sort: orderBy,
    page,
    limit: 8,
  })
  const handleChangeSearch = (term: string) => {
    setSearchTerm(term)
  }
  const handleChangeOrder = (orderBy: string) => {
    setOrderBy(orderBy)
  }
  const handleChangePage = (current: number) => {
    setPage(current)
  }
  useEffect(() => {
    getTotalItems(watchListItems?.totalItems)
  }, [watchListItems, getTotalItems])
  useEffect(() => {
    refetch()
  }, [refetch])
  return (
    <SectionTab>
      {isLoading && (
        <Flex alignItems={'center'} justifyContent="center">
          <Box p="2">
            <Loader size="50px" />
          </Box>
        </Flex>
      )}
      {!isLoading && (
        <CustomBox>
          <Flex flexWrap="wrap" mx={-4} className={'tab-user_flex'}>
            <Box px={4} width={5 / 6} className={'tab-user_search'}>
              <SearchBox onChange={handleChangeSearch} />
            </Box>
            <Box px={4} width={1 / 6} className={'tab-user_filter'}>
              <Filter onChange={handleChangeOrder} />
            </Box>
          </Flex>
        </CustomBox>
      )}
      {watchListItems?.totalItems === 0 && <EmptySearchBox />}
      {!isFetching && <Watchlisted watchlistedItems={watchListItems?.data?.items} />}

      <PaginationBox>
        {!isLoading && watchListItems?.totalItems > 8 && (
          <Pagination
            onChange={(current) => {
              handleChangePage(current)
            }}
            pageSize={8}
            total={(watchListItems?.totalItems && watchListItems?.totalItems) || 1}
            prevIcon={() => (
              <SpanIcon>
                <img src={IconLeft} alt={'icon_left'} />
              </SpanIcon>
            )}
            nextIcon={() => (
              <SpanIcon>
                <img src={IconRight} alt={'icon_right'} />
              </SpanIcon>
            )}
          />
        )}
      </PaginationBox>
    </SectionTab>
  )
}
const SectionTab = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    .tab-user_flex {
      margin-left: -16px !important;
      margin-right: -16px !important;
    }
    .tab-user_search {
      padding-left: 16px !important;
      padding-right: 16px !important;
      flex: 0 0 calc(100% - 160px);
      max-width: calc(100% - 160px);
    }
    .tab-user_filter {
      padding-left: 16px !important;
      padding-right: 16px !important;
      flex: 0 0 160px;
      max-width: 160px;
    }
  }
  @media (max-width: 575px) {
    .tab-user_search {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .tab-user_filter {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 10px;
    }
  }
`
const CustomBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  margin: auto;
`
const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`
export default TabWatchlist
