import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { AppState } from '../index'
import {
  ApplicationModal,
  removePopup,
  setActiveTabProfile,
  setOpenAccountPopup,
  setOpenModal,
  setOpenSignupPopup,
  setOpenWalletPopup,
} from './reducer'

export function useModalOpen(modal: ApplicationModal | null): boolean {
  const openModal = useAppSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function usePopupOpen(popup: ApplicationModal | null): boolean {
  const openPopup = useAppSelector((state: AppState) => state.application.openPopup)
  return openPopup === popup
}

export function useSignupOpen(popup: ApplicationModal | null): boolean {
  const openPopup = useAppSelector((state: AppState) => state.application.openSignup)
  return openPopup === popup
}

export function useSignUpPopup(popup: ApplicationModal | null): boolean {
  const openSignUp = useAppSelector((state: AppState) => state.application.openSignup)
  return openSignUp === popup
}

export function useAccountPopup(popup: ApplicationModal | null): boolean {
  const accountPopup = useAppSelector((state: AppState) => state.application.openAccountPopup)
  return accountPopup === popup
}

export function useToggleModal(modal: ApplicationModal | null): () => void {
  const open = useModalOpen(modal)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useToggleWalletPopup(popup: ApplicationModal | null): () => void {
  const open = usePopupOpen(popup)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenWalletPopup(open ? null : popup)), [dispatch, popup, open])
}

export function useToggleWalletSignup(popup: ApplicationModal | null): () => void {
  const open = useSignupOpen(popup)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenSignupPopup(open ? null : popup)), [dispatch, popup, open])
}

export function useToggleAccountPopup(popup: ApplicationModal | null): () => void {
  const open = useAccountPopup(popup)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenAccountPopup(open ? null : popup)), [dispatch, popup, open])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}
export function useWalletPopupToggle(): () => void {
  return useToggleWalletPopup(ApplicationModal.WALLET_POPUP)
}
export function useWalletSignupToggle(): () => void {
  return useToggleWalletSignup(ApplicationModal.SIGNUP_REQUEST)
}
export function useSignUpPopupToggle(): () => void {
  return useToggleWalletPopup(ApplicationModal.SIGNUP_REQUEST)
}
export function useAccountPopupToggle(): () => void {
  return useToggleAccountPopup(ApplicationModal.ACCOUNT_POPUP)
}
export function useFullscreenNFTItemToggle(): () => void {
  return useToggleModal(ApplicationModal.FULLSCREEN_ITEM_NFT)
}
export function useCloseModalToggle(): () => void {
  return useToggleModal(null)
}
export function useClosePopupToggle(): () => void {
  return useToggleWalletPopup(null)
}
export function useModalConfirmMint(): () => void {
  return useToggleModal(ApplicationModal.CONFIRM_MINT)
}
export function useModalFollowers(): () => void {
  return useToggleModal(ApplicationModal.FOLLOWERS_POPUP)
}

export function useModalFollowing(): () => void {
  return useToggleModal(ApplicationModal.FOLLOWING_POPUP)
}

export function useModalReport(): () => void {
  return useToggleModal(ApplicationModal.REPORT_POPUP)
}
export function useModalRedeemToggle(): () => void {
  return useToggleModal(ApplicationModal.REDEEM_POPUP)
}
export function useModalAuthenticationToggle(): () => void {
  return useToggleModal(ApplicationModal.AUTHENTICATION_POPUP)
}
export function useModalContactToggle(): () => void {
  return useToggleModal(ApplicationModal.SUCCESS_CONTACT_FORM)
}
// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useAppSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter((item) => item.show), [list])
}

export function useCloseModal(): () => void {
  return useToggleModal(null)
}

export function useActiveTab(): { tabActive: number | null; setActiveTab: (params: number) => any } {
  const { tabActive } = useAppSelector((state: AppState) => state.application)
  const dispatch = useAppDispatch()

  const setActiveTab = (params: number) => {
    return dispatch(setActiveTabProfile(params))
  }

  return {
    tabActive,
    setActiveTab,
  }
}
