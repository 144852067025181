import React from 'react'
import { useParams } from 'react-router-dom'

import { routes } from '../../../../constants/routes'
import { useGetProfile } from '../../../../state/profile/hooks'
import { CollectionsForm } from '../../../Collections/components/CollectionsForm'
import { useEventCollections } from '../../../Collections/hooks/useGetAllowedCollections'
import { CreateEventStepContainer } from '../../components/CreateEventStepContainer'
import { FormHeader } from '../../components/EventForm/styled'

interface AddCollectionUrlParams {
  eventId: string
}

export const AddCollection = () => {
  const { eventId } = useParams<AddCollectionUrlParams>()

  const { data: profile, loading: profileLoading } = useGetProfile()

  const { data: eventCollections, isLoading: collectionsLoading } = useEventCollections(
    {
      partnerAddress: profile.address,
      // @ts-ignore
      eventId,
    },
    {
      enabled: Boolean(!profileLoading && profile && profile.address),
    }
  )

  const isLoading = profileLoading || collectionsLoading

  return (
    <CreateEventStepContainer>
      <FormHeader> Add Collection to your Event </FormHeader>
      <CollectionsForm
        {...{
          eventId,
          eventCollections,
          collectionsLoading: isLoading,
          partnerAddress: profile.address,
          continueSkipRoute: routes.partnerPanel.addWhitelist
            .replace(':eventId', eventId)
            .replace(':partnerAddress', profile.address),
        }}
      />
    </CreateEventStepContainer>
  )
}
