import SuccessIcon from 'assets/icons/success.svg'
import styled from 'styled-components/macro'

import { ModalBase } from '../../../../components/ModalBase/ModalBase'

interface RedeemSuccessModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export const RedeemSuccessModal = ({ isOpen, onDismiss }: RedeemSuccessModalProps) => {
  return (
    <ModalBase header="Physical pair redemption form" isOpen={isOpen} onDismiss={onDismiss} maxWidth={980}>
      <Div>
        <img src={SuccessIcon} alt="Icon" />
        <h1 className="success">Congratulations</h1>
        <p>You have successfully submitted your redemption form.</p>
        <p>You can track the shipping status of your item via the email we will send you.</p>
      </Div>
    </ModalBase>
  )
}

const Div = styled.div`
  padding-top: 78px;
  padding-bottom: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success {
    color: ${({ theme }) => theme.primary6};
  }
  .error {
    color: ${({ theme }) => theme.red5};
  }
  h1 {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: 16px;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }
`
