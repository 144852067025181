import { useState } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as IconPlus } from '../../../assets/icons/icon-plus.svg'
import { ButtonGray } from '../../../components/Button'
import Loader from '../../../components/Loader'
import AddFeatured from './AddFeatured'
import CollectionItem from './CollectionItem'

const FeatureCollection = (props: any) => {
  const { collectionOptionsHide, selectedCollectionHide, setSelectedCollectionHide, collectionDisplayed, isLoading } =
    props
  const [isAddFeatured, setIsAddFeatured] = useState<boolean>(false)
  const onCloseAddFeatured = () => {
    setIsAddFeatured(false)
  }
  const onOpenAddFeatured = () => {
    setIsAddFeatured(true)
  }
  return (
    <Wrapper>
      <Title>featured Collection</Title>
      {!isAddFeatured ? (
        <CtaFeatured onClick={onOpenAddFeatured}>
          <IconPlus />
          <span>Add featured Collection</span>
        </CtaFeatured>
      ) : (
        <AddFeatured
          collectionOptionsHide={collectionOptionsHide}
          selectedCollectionHide={selectedCollectionHide}
          setSelectedCollectionHide={setSelectedCollectionHide}
          onClose={onCloseAddFeatured}
        />
      )}
      <CollectionList>
        <TitleCollection>Collection list</TitleCollection>
        {isLoading ? (
          <EmptyCard>
            <Loader size={'24px'} />
          </EmptyCard>
        ) : (
          <List>
            {collectionDisplayed.length > 0 &&
              collectionDisplayed.map((collection: any, index: number) => {
                return (
                  <CollectionItem
                    key={collection.address}
                    collection={collection}
                    index={index}
                    onCloseAddFeatured={onCloseAddFeatured}
                  />
                )
              })}
          </List>
        )}
      </CollectionList>
    </Wrapper>
  )
}
const Wrapper = styled.div``
const Title = styled.h3`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
  text-transform: uppercase;
`
const CtaFeatured = styled(ButtonGray)`
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`
const CollectionList = styled.div`
  margin-top: 32px;
`
const TitleCollection = styled.h5`
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary7};
  margin: 0 0 12px 0;
`
const List = styled.div`
  .collection-item:not(:first-of-type) {
    margin-top: 20px;
  }
  .collection-item:last-of-type {
    border-bottom: none;
  }
`
const EmptyCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export default FeatureCollection
