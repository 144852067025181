import { format } from 'date-fns'

const BASE_DATE_FORMAT = 'yyyy-MM-dd HH:mm z'

export const formatDateFromTimestamp = (value?: number) => {
  if (!value) {
    return ''
  }

  return format(new Date(+value * 1000), BASE_DATE_FORMAT)
}

export const formatDateFromString = (value: string) => {
  return format(new Date(value), BASE_DATE_FORMAT)
}

export const timestampToSecondsTimestamp = (value: number) => {
  return Number((value / 1000).toFixed(0))
}

export const secondsTimestampToTimestamp = (value: number) => {
  return value * 1000
}
