import 'yup-phone'

import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import ErrorIcon from '../../../../assets/icons/error.svg'
import IconClose from '../../../../assets/images/svg/icon-close.svg'
import { ButtonSecondary } from '../../../../components/Button'
import IconPortal from '../../../../components/IconPortal'
import Modal from '../../../../components/Modal'
import { ItemAttribute, OptionType } from '../../../../entities/OptionType'
import { useOptionAssignAttributeCollection } from '../../../../hooks/useOptionAssignAttributeCollection'
import { useConfirmRedemption } from '../../hooks/useConfirmRedemption'
import { useFillShippingInformation } from '../../hooks/useFillShippingInformation'
import { useRedemptionInformation } from '../../hooks/useRedemptionInformation'
import { useRequestRedemptionInRegistry } from '../../hooks/useRequestRedemptionInRegistry'
import { useShippingInformation } from '../../hooks/useShippingInformation'
import { RedeemSuccessModal } from '../RedeemSuccessModal'
import { ShippingInformationForm } from '../ShippingInformationForm'
import { SubmittingRedeemModalBody } from '../SubmittingRedeemModalBody'
import { Confirm } from './Confirm'

interface ModalRedeemProps {
  isOpen: boolean
  onDismiss: () => void
  nftDetail: any
  setSuccessTime: any
}
interface IObjectKeys {
  [key: string]: string | OptionType
}

const defaultFormValues = {
  country: {
    label: 'United States',
    value: 'US',
  },
  city: {
    label: 'New York',
    value: 'New York',
  },
  fullName: '',
  phone: '',
  email: '',
  zipCode: '',
  address: '',
  passportNumber: '',
}

export const RedeemModal = ({ isOpen, onDismiss, nftDetail, setSuccessTime }: ModalRedeemProps) => {
  const [payWithMatic, setPayWithMatic] = useState<boolean>(true)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [values, setValues] = useState<any>(defaultFormValues)
  const optionAttribute = useOptionAssignAttributeCollection(nftDetail?.collection?.address)

  const { data: shippingInformation } = useShippingInformation(
    {
      collectionAddress: nftDetail?.collection?.address as string,
      tokenId: nftDetail?.tokenId as string,
    },
    {
      enabled: Boolean(nftDetail),
      onSuccess: (shippingInformation) => {
        if (shippingInformation) {
          setShowConfirmation(true)
        }
      },
    }
  )

  const formatShippingInformation = (values: any) => {
    const attrMore = optionAttribute.reduce((total: IObjectKeys, item: ItemAttribute) => {
      if (item.type === 'INPUT') {
        total[`${item.name}`] = values[`${item.name}`]
      } else {
        total[`${item.name}`] = values[`${item.name}`].value
      }
      return total
    }, {})

    return {
      ...values,
      country: values.country.label,
      cityState: values.city.value,
      phoneNumber: values.phone,
      ...attrMore,
    }
  }

  const shippingInformationInConfirmation = useMemo(() => {
    if (shippingInformation) {
      return shippingInformation
    }

    return formatShippingInformation(values)
  }, [values, shippingInformation])

  const { data: saleInformation } = useRedemptionInformation(
    {
      collectionSaleAddress: nftDetail?.collection?.sale?.address as string,
    },
    {
      enabled: Boolean(nftDetail?.collection?.sale?.address),
    }
  )

  const {
    mutateAsync: requestRedemptionInRegistry,
    isLoading: isRequestingRedemptionInRegistry,
    isError: isErrorRedeemingInRegistry,
    reset: resetRedemption,
  } = useRequestRedemptionInRegistry()
  const {
    mutateAsync: fillShippingInformation,
    isLoading: isFillingShippingInformation,
    isError: isErrorFillingShippingInformation,
    reset: resetShipping,
  } = useFillShippingInformation()
  const {
    mutateAsync: confirmRedemption,
    isLoading: isConfirming,
    isSuccess,
    isError: isErrorConfirmingRedemption,
    reset: resetConfirmation,
  } = useConfirmRedemption()

  const isSubmitting = isRequestingRedemptionInRegistry || isFillingShippingInformation || isConfirming
  const isError = isErrorRedeemingInRegistry || isErrorFillingShippingInformation || isErrorConfirmingRedemption

  useEffect(() => {
    const initOptionForm = optionAttribute.reduce((total: IObjectKeys, item: ItemAttribute) => {
      if (item.type === 'INPUT') {
        total[`${item.name}`] = ''
      } else {
        total[`${item.name}`] = {
          label: '',
          value: '',
        }
      }
      return total
    }, {})
    setValues((prevState: any) => {
      if (!prevState) {
        return
      }
      return {
        ...prevState,
        ...initOptionForm,
      }
    })
  }, [optionAttribute])

  const handleRedemption = async () => {
    if (!saleInformation) {
      return
    }

    try {
      if (!shippingInformation) {
        await fillShippingInformation({
          data: {
            collectionAddress: nftDetail.collectionAddress as string,
            tokenId: nftDetail.tokenId,
            information: formatShippingInformation(values),
          },
        })
      }

      await requestRedemptionInRegistry({
        nftDetail,
        payWithMatic,
        latestPriceInMatic: saleInformation.latestPriceInMatic.toString(),
      })

      await confirmRedemption({
        collectionAddress: nftDetail.collection.address,
        tokenId: nftDetail.tokenId,
      })
    } finally {
      setSuccessTime(true)
    }
  }

  const handleResetForm = () => {
    resetRedemption()
    resetShipping()
    resetConfirmation()
  }

  const handleShippingInformationSubmit = (values: any) => {
    setValues(values)
    setShowConfirmation(true)
  }

  if (isSubmitting) {
    return <SubmittingRedeemModalBody isOpen={isOpen} onDismiss={onDismiss} />
  }

  if (isSuccess) {
    return <RedeemSuccessModal isOpen={isOpen} onDismiss={onDismiss} />
  }

  if (isError) {
    return (
      <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={980}>
        <ModalWrapper>
          <ModalHeader>
            <MainHeading>
              <Heading>Physical pair redemption form</Heading>
              <span onClick={onDismiss} style={{ cursor: 'pointer' }}>
                <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
              </span>
            </MainHeading>
            <SubHeading>Complete the form to redeem your item</SubHeading>
          </ModalHeader>
          <ModalBody>
            <Div>
              <img src={ErrorIcon} alt="Icon" />
              <h1 className="error">Ooops!</h1>
              <p>There was an issue with submitting your form.</p>
              <p>Please check and try again.</p>
            </Div>
            <BtnRetry onClick={handleResetForm}>Retry</BtnRetry>
          </ModalBody>
        </ModalWrapper>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={980}>
      {showConfirmation ? (
        <ModalWrapper>
          <ModalBody>
            <Confirm
              nftDetail={nftDetail}
              values={shippingInformationInConfirmation}
              back={() => setShowConfirmation(false)}
              submit={handleRedemption}
              optionAttribute={optionAttribute}
              setPayWithMatic={setPayWithMatic}
              payWithMatic={payWithMatic}
              saleInformation={saleInformation}
              isBackButtonNotActive={!!shippingInformation}
            />
          </ModalBody>
        </ModalWrapper>
      ) : (
        <ModalWrapper>
          <ModalHeader>
            <MainHeading>
              <Heading>Physical pair redemption form</Heading>
              <span onClick={onDismiss} style={{ cursor: 'pointer' }}>
                <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
              </span>
            </MainHeading>
            <SubHeading>Complete the form to redeem your item</SubHeading>
          </ModalHeader>
          <ModalBody>
            <ShippingInformationForm
              nftDetail={nftDetail}
              onSubmit={handleShippingInformationSubmit}
              initialValues={values}
            />
          </ModalBody>
        </ModalWrapper>
      )}
    </Modal>
  )
}

const Div = styled.div`
  padding-top: 78px;
  padding-bottom: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success {
    color: ${({ theme }) => theme.primary6};
  }
  .error {
    color: ${({ theme }) => theme.red5};
  }
  h1 {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: 16px;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }
`

const ModalWrapper = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.secondary3};
    border-radius: 10px;
  }

  @media (max-width: 576px) {
    padding: 20px;
  }
`
const ModalHeader = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary3};
`
const MainHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    position: relative;
    top: -15px;
    right: -10px;
  }
`
const Heading = styled.h3`
  text-transform: uppercase;
  margin: 0;
  line-height: 1.333334;

  @media (max-width: 576px) {
    font-size: 20px;
  }
`
const SubHeading = styled.h5`
  margin-top: 6px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary6};
`
const ModalBody = styled.div`
  width: 100%;
  padding: 20px 0 16px;
  .form-row {
    margin-bottom: 20px;
  }
`

const BtnRetry = styled(ButtonSecondary)`
  padding: 16px 32px !important;
`
