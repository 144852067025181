import EmptyIcon from 'assets/icons/Empty.svg'
import { ButtonSecondary } from 'components/Button'
import CollectionItemBox from 'components/CollectionItemBox'
import React from 'react'
import { X } from 'react-feather'
import { animated } from 'react-spring'
import { useSpring } from 'react-spring/web'
import { Card, Image } from 'rebass'
import { useGetCollectionsMenuQuery } from 'state/collections/slice'
import styled from 'styled-components/macro'

import { ReactComponent as IconArrowLeft } from '../../assets/icons/icon-back.svg'
import { CollectionType } from '../../entities/Collection'
import { useGetOnGoingCollection, useGetUpcomingCollection } from '../../hooks/useGetCollections'
import Loader from '../Loader'

const CollectionsMenu = (props: any) => {
  const { data: collections, isLoading } = useGetCollectionsMenuQuery({})
  const { closeMenu } = props

  const collectionOngoing = useGetOnGoingCollection(collections)
  const collectionUpcoming = useGetUpcomingCollection(collections)

  const fadeTransition = useSpring({
    config: { duration: 300 },
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <AnimatedContainer style={fadeTransition}>
      <DivBackMenu onClick={closeMenu}>
        <div className={'icon-left'}>
          <IconArrowLeft />
        </div>
        <span className={'back-menu'}>Back to Menu</span>
      </DivBackMenu>
      <Row>
        <Column>
          <Header>
            Ongoing <span>({collectionOngoing.length})</span>
          </Header>
          <Content>
            {isLoading ? (
              <EmptyCard>
                <Loader size={'24px'} />
              </EmptyCard>
            ) : collectionOngoing.length === 0 ? (
              <EmptyCard>
                <Image src={EmptyIcon} alt="No collections" />
                <EmptyMsg>
                  Sorry, there are no Ongoing collections at the moment. Check our socials to get more information.
                </EmptyMsg>
              </EmptyCard>
            ) : (
              collectionOngoing.map((item: CollectionType, key: number) => (
                <CollectionItemBox key={key} isCollection={true} dataItem={item} closeMenu={closeMenu} />
              ))
            )}
          </Content>
        </Column>
        <Column className="pl">
          <Header>
            Upcoming <span>({collectionUpcoming.length})</span>
          </Header>
          <Content>
            {isLoading ? (
              <EmptyCard>
                <Loader size={'24px'} />
              </EmptyCard>
            ) : collectionUpcoming.length === 0 ? (
              <EmptyCard>
                <Image src={EmptyIcon} alt="No collections" />
                <EmptyMsg>Sorry, there are no Upcoming collections at the moment. Please come back later.</EmptyMsg>
              </EmptyCard>
            ) : (
              collectionUpcoming.map((item: CollectionType, key: number) => (
                <CollectionItemBox
                  key={key}
                  isCollection={true}
                  dataItem={item}
                  closeMenu={closeMenu}
                  isUpcoming={true}
                />
              ))
            )}
          </Content>
        </Column>
      </Row>
      <BtnClose onClick={closeMenu}>
        Close <X />
      </BtnClose>
    </AnimatedContainer>
  )
}

export default CollectionsMenu

const gutterWidthGrid = 16

const Container = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary1};
  left: 0;
  z-index: 10;
  padding: 30px;
  margin-top: 20px;
  @media (max-width: 1024px) {
    top: 0;
    z-index: 5;
    width: 769px;
    margin-top: 0;
    right: 0;
    left: auto;
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
  @media (max-width: 888px) {
    width: calc(100vw - 128px);
  }
  @media (max-width: 767px) {
    width: calc(100vw - 32px);
    padding: 16px;
  }
`

const AnimatedContainer = animated(Container)

const Content = styled.div`
  overflow-y: scroll;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 600px;

  & > .item-card {
    flex: 0 0 25%;
    max-height: 150px;

    h5 {
      font-size: 24px;
      line-height: 1.25;
    }
    div {
      font-size: 14px !important;
      line-height: 1;
    }

    @media (max-width: 1499px) {
      h5 {
        font-size: ${({ theme }) => theme.fontSizeText1};
      }
      & > div {
        padding: 16px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.secondary3};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.secondary2};
  }
  @media (max-width: 1024px) {
    height: 280px;
    margin-top: 20px;
    margin-bottom: 0;
    & > .item-card {
      max-height: 102px;
      margin-bottom: 16px;
      & > div {
        margin-bottom: 0 !important;
      }
    }
  }
`

const Row = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  @media (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
  }
`
const Column = styled.div`
  float: left;
  width: 50%;
  height: 650px;
  &:nth-of-type(1) {
    padding-right: 16px;
  }
  &.pl {
    padding-left: ${gutterWidthGrid}px;
  }
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
    height: 320px;
    &:nth-of-type(1) {
      padding-right: 0;
      margin-bottom: 20px;
    }
    &.pl {
      padding-left: 0;
    }
  }
`

const Header = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.secondary7};
  cursor: auto;

  span {
    color: ${({ theme }) => theme.primary5};
  }
`

const BtnClose = styled(ButtonSecondary)`
  background-color: transparent;
  &:hover,
  :focus {
    background-color: transparent;
  }
  svg {
    margin-left: 10px;
  }
`

const EmptyCard = styled(Card)`
  display: flex;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 30px;
  border: 2px solid ${({ theme }) => theme.secondary3};
  background-color: ${({ theme }) => theme.colorTransparent};
  @media (max-width: 1024px) {
    padding: 16px;
  }
`

const EmptyMsg = styled.div`
  color: ${({ theme }) => theme.secondary5};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  @media (max-width: 768px) {
    letter-spacing: -0.5px;
  }
`
const DivBackMenu = styled.div`
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary2};
  display: none;
  .back-menu {
    color: ${({ theme }) => theme.primary10};
    margin-left: 8px;
    font-family: 'Din Pro Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-transform: uppercase;
  }
  .icon-left {
    width: 16px;
    height: 16px;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    display: flex;
  }
`
