import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'

import Fallback from '../../../../assets/images/fallback.svg'
import IconEditImg from '../../../../assets/images/svg/icon-edit-green.svg'
import VideoBanner from '../../../CollectionDetail/Header/VideoBanner/VideoBanner'

interface EditMediaType {
  data: string | undefined
  type: string
  accept: string
  setMediaFilesChange: any
  mediaFilesChange: any
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  addressCollection: string
}
const EditMedia = (props: EditMediaType) => {
  const { data, type, accept, setMediaFilesChange, mediaFilesChange, handleChange, addressCollection } = props
  const [file, setFile] = useState<any>(null)
  const [srcImage, setSrcImage] = useState<string>()

  const handleMedia = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', async () => {
      setFile(file)
      const cloneFiles = [...mediaFilesChange]
      const indexArray = cloneFiles.findIndex((ele) => ele.type === type)
      if (indexArray < 0) {
        cloneFiles.push({
          type,
          file,
        })
      } else {
        cloneFiles.splice(indexArray, 1, { type, file })
      }
      setMediaFilesChange([...cloneFiles])
      setSrcImage(String(reader.result))
    })
  }
  return (
    <EditMediaWrapper>
      <EditMediaBox>
        <AvatarBoxEdit>
          <label htmlFor={`${type}_${addressCollection}`} className={'label_avatar'}>
            <IconEdit>
              <img src={IconEditImg} alt={'Edit_Avatar'} />
            </IconEdit>
          </label>
          <input
            type={'file'}
            accept={accept}
            id={`${type}_${addressCollection}`}
            hidden={true}
            name={type}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(event)
              handleMedia(event, type)
            }}
          />
        </AvatarBoxEdit>
        <Avatar>
          {(srcImage && (
            <>
              {file.type.includes('video') ? (
                <VideoBanner url={srcImage} />
              ) : (
                <img src={srcImage} alt={'Avatar'} onError={(e) => (e.currentTarget.src = Fallback)} />
              )}
            </>
          )) ||
            (data && isVideoLink(data) ? (
              <VideoBanner url={data} />
            ) : (
              <img src={data || Fallback} alt={'Avatar'} onError={(e) => (e.currentTarget.src = Fallback)} />
            ))}
        </Avatar>
      </EditMediaBox>
    </EditMediaWrapper>
  )
}
const EditMediaWrapper = styled.div``
const EditMediaBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:hover {
    .label_avatar > span {
      opacity: 1;
      visibility: visible;
      transition: all 0.25s ease-in;
    }
  }
`
const AvatarBoxEdit = styled.div`
  background-color: ${({ theme }) => theme.secondary1};
  position: relative;
  border: 2px solid ${({ theme }) => theme.secondary2};
  border-radius: 4px;
  height: 166px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  label {
    position: relative;
    z-index: 5;
  }
`
const IconEdit = styled.span`
  width: 36px;
  height: 36px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
const Avatar = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 100%;
  position: absolute;
  z-index: 1;
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export default EditMedia
