import React from 'react'
import styled from 'styled-components/macro'

import NotFoundPage from '../../components/NotFoundPage'
const NotFound = () => {
  return (
    <NotFoundWrapper>
      <NotFoundPage />
    </NotFoundWrapper>
  )
}
const NotFoundWrapper = styled.div`
  width: 100%;
`
export default NotFound
