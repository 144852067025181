import * as React from 'react'
export const EventIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.11 11.555H3.317a.875.875 0 0 1 0-1.75h20.795a.875.875 0 0 1 0 1.75ZM18.901 16.111a.88.88 0 0 1-.88-.875.87.87 0 0 1 .87-.875h.01a.875.875 0 0 1 0 1.75ZM13.724 16.111a.88.88 0 0 1-.88-.875.87.87 0 0 1 .87-.875h.01a.875.875 0 0 1 0 1.75ZM8.536 16.111a.88.88 0 0 1-.88-.875c0-.483.387-.875.87-.875h.01a.875.875 0 0 1 0 1.75ZM18.901 20.645a.88.88 0 0 1-.88-.875.87.87 0 0 1 .87-.875h.01a.875.875 0 0 1 0 1.75ZM13.724 20.645a.88.88 0 0 1-.88-.875.87.87 0 0 1 .87-.875h.01a.875.875 0 0 1 0 1.75ZM8.536 20.645a.88.88 0 0 1-.88-.875c0-.482.387-.875.87-.875h.01a.875.875 0 0 1 0 1.75ZM18.426 6.756a.875.875 0 0 1-.875-.875v-3.84a.875.875 0 0 1 1.75 0v3.84a.875.875 0 0 1-.875.875ZM9.001 6.756a.875.875 0 0 1-.875-.875v-3.84a.875.875 0 0 1 1.75 0v3.84a.875.875 0 0 1-.875.875Z"
      clipRule="evenodd"
    />
    <mask
      id="a"
      width={24}
      height={24}
      x={2}
      y={3}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <path fill="currentColor" fillRule="evenodd" d="M2.333 3.009h22.75v23.24H2.333V3.01Z" clipRule="evenodd" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.775 4.759c-3.026 0-4.692 1.613-4.692 4.543v10.557c0 2.994 1.666 4.641 4.692 4.641h9.867c3.025 0 4.691-1.617 4.691-4.552V9.302c.005-1.441-.382-2.561-1.151-3.331-.791-.793-2.01-1.212-3.53-1.212H8.776Zm9.867 21.491H8.775c-3.973 0-6.442-2.449-6.442-6.391V9.302c0-3.883 2.469-6.293 6.442-6.293h9.878c1.994 0 3.642.597 4.768 1.725 1.093 1.098 1.668 2.677 1.662 4.57v10.644c0 3.887-2.468 6.302-6.44 6.302Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
