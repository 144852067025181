import React, { useState } from 'react'
import Truncate from 'react-truncate'
import styled from 'styled-components/macro'

import IconChevronDown from '../../assets/icons/icon-chevrondown.svg'
import IconChevronUp from '../../assets/icons/icon-chevronup.svg'
import IconPortal from '../IconPortal'
interface PropsType {
  text: string
}
const TruncateText = (props: PropsType) => {
  const { text } = props
  const [truncated, setTruncated] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false)
  const handleTruncate = (paramTruncated?: any) => {
    if (truncated !== paramTruncated) {
      setTruncated(paramTruncated)
    }
  }
  const toggleLines = (event: React.SyntheticEvent) => {
    event.preventDefault()
    setExpanded(!expanded)
  }
  return (
    <div>
      <Truncate
        lines={!expanded && 3}
        ellipsis={
          <span>
            ...{' '}
            <SpanBlock onClick={toggleLines} className={'see-text'}>
              {'See more'}{' '}
              <div style={{ marginLeft: '4px' }}>
                <IconPortal SrcImageIcon={IconChevronDown} heightIcon={'7px'} widthIcon={'12px'} />
              </div>
            </SpanBlock>
          </span>
        }
        onTruncate={handleTruncate}
      >
        <p style={{ margin: 0 }}>{text}</p>
      </Truncate>
      {!truncated && expanded && (
        <span>
          {' '}
          <SpanBlock onClick={toggleLines} className={'see-text'}>
            {'See less'}{' '}
            <div style={{ marginLeft: '4px' }}>
              <IconPortal SrcImageIcon={IconChevronUp} heightIcon={'7px'} widthIcon={'12px'} />
            </div>
          </SpanBlock>
        </span>
      )}
    </div>
  )
}
const SpanBlock = styled.div`
  display: flex;
  color: ${({ theme }) => theme.secondary6} !important;
  font-size: 14px !important;
  cursor: pointer;
  align-items: center;
  margin-top: 16px;
  //img {
  //  width: 16px;
  //  height: 16px;
  //  display: block;
  //  margin-left: 4px;
  //  &.rotate-chevrondown {
  //    transform: rotateZ(-180deg);
  //  }
  //}
  &:hover {
    color: ${({ theme }) => theme.primary6} !important;
    svg {
      stroke: ${({ theme }) => theme.primary6};
    }
  }
`
export default TruncateText
