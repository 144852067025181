import { ButtonPrimary } from 'components/Button'
import ColCs from 'components/ColCs'
import { FormGroup, FormInput, FormLabel } from 'components/Forms'
import Loader from 'components/Loader'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import RowCs from 'components/RowCs'
import { useFormik } from 'formik'
import { useApi } from 'hooks/useApi'
import React, { useState } from 'react'
import { useBlacklistUserMutation, useGetBlacklistedUsersQuery } from 'state/usersManagement/slice'
import styled from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import BlacklistTable from './BlacklistTable'

interface Props {
  comment?: string | null | undefined
  userAddress: string
}

const BlackList = () => {
  const [status, setStatus] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [keyword, setKeyword] = useState<string>('')
  const [debouncedSearch] = useDebounce(keyword, 500)
  const { post } = useApi()
  const [blacklistUser, { isLoading }] = useBlacklistUserMutation()

  const {
    data: blacklist,
    isLoading: blacklistLoading,
    refetch: refetchBlacklist,
  } = useGetBlacklistedUsersQuery({
    keyword: debouncedSearch,
    page,
    limit: 8,
  })

  const blacklistForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      userAddress: '',
      comment: '',
    },
    onSubmit: (values) => handleBlacklistUser(values),
  })

  const handleBlacklistUser = (values: Props) => {
    const { userAddress, comment } = values
    blacklistUser({ userAddress, comment, post, callback: setStatus, refetchBlacklist }).then((res: any) => {
      blacklistForm.resetForm()
    })
  }

  const { values, handleChange, handleSubmit } = blacklistForm
  return (
    <Wrapper>
      <Container>
        <Main>
          <Title>Black list management</Title>
          <form onSubmit={handleSubmit}>
            <RowCs space={-16}>
              <ColCs gutter={16} sm={6} xs={12}>
                <FormGroup>
                  <FormLabel>User wallet address</FormLabel>
                  <FormInput
                    type={'text'}
                    placeholder={'Please enter the address'}
                    id={'userAddress'}
                    name={'userAddress'}
                    value={values.userAddress ?? ''}
                    onChange={handleChange}
                  />
                </FormGroup>
              </ColCs>
              <ColCs gutter={16} sm={6} xs={12}>
                <FormGroup>
                  <FormLabel>Comment</FormLabel>
                  <FormInput
                    type={'text'}
                    placeholder={'Please leave a comment'}
                    id={'comment'}
                    name={'comment'}
                    value={values.comment ?? ''}
                    onChange={handleChange}
                  />
                </FormGroup>
              </ColCs>
            </RowCs>
            <CtaAddBlacklist type={'submit'} disabled={!blacklistForm.dirty || !blacklistForm.isValid}>
              {!isLoading ? 'Add to blacklist' : <Loader size={'24px'} stroke={'#151515'} />}
            </CtaAddBlacklist>
          </form>
          <SectionBlacklist>
            <BlacklistTable
              setPage={setPage}
              page={page}
              blacklist={blacklist}
              setKeyword={setKeyword}
              isLoading={blacklistLoading}
            />
          </SectionBlacklist>
          <ModalSuccess
            isOpen={status === 'success'}
            onDismiss={() => setStatus('')}
            message={'This address has been added to the blacklist.'}
          />
          <ModalError
            isOpen={status === 'error'}
            onDismiss={() => setStatus('')}
            message={'This address has already been blacklisted.'}
          />
        </Main>
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const Container = styled.div`
  width: 100%;
`
const Main = styled.div`
  padding: 32px 0;
  td.secondary10 {
    color: ${({ theme }) => theme.secondary10};
  }
`
const Title = styled.h3`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`
const CtaAddBlacklist = styled(ButtonPrimary)`
  padding: 10px 16px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  margin-top: 17px;
`
const SectionBlacklist = styled.div`
  width: 100%;
  margin-top: 32px;
`
export default BlackList
