import styled from 'styled-components/macro'

export const CountDownContainer = styled.div`
  display: flex;
  gap: 28px;
`

export const GapContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const GapText = styled.span`
  font-size: 32px;
  line-height: 32px;
  color: ${({ theme }) => theme.secondary5};
`

export const SingleCountDownContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Number = styled.p`
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary5};
  margin: 0;
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.textFollow};
  margin: 0;
`

export const TitleContainer = styled.p`
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
`

export const TitleGreen = styled.span`
  color: ${({ theme }) => theme.primary5};
`
