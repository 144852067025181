import styled from 'styled-components/macro'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 24px;
`

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.h3`
  margin: 0;
`

export const ModalSubHeader = styled.h5`
  margin-top: 6px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary6};
`
