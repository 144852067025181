import React, { useEffect, useRef } from 'react'

const VideoBanner = ({ url }: { url: string }) => {
  const videoRef = useRef<any>(null)

  useEffect(() => {
    videoRef.current?.load()
  }, [url])
  return (
    <>
      <video loop muted autoPlay ref={videoRef} preload={'auto'}>
        <source src={url} type="video/mp4" />
      </video>
    </>
  )
}

export default VideoBanner
