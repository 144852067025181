import { useState } from 'react'
import Select, { SingleValue } from 'react-select'
import styled from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import { OptionType } from '../../../../entities/OptionType'
import { useGetWhitelistedUsersQuery } from '../../../../state/collections/slice'
import { customStylesSelect } from '../../../../theme'
import SlotManagement from './SlotManagement'
import UploadWhitelist from './UploadWhitelist'
import Whitelist from './Whitelist'

const WhitelistManagement = (props: any) => {
  const { selectedCollection, setSelectedCollection, collectionOptions, isSelectDisabled, isLoading } = props
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debouncedSearch] = useDebounce(searchTerm, 500)
  const [page, setPage] = useState<number>(1)
  const {
    data: whitelist,
    isLoading: whitelistLoading,
    refetch: refetchWhitelist,
  } = useGetWhitelistedUsersQuery(
    {
      address: selectedCollection.value,
      keyword: debouncedSearch,
      page,
      limit: 8,
    },
    { skip: !selectedCollection.value || Object.keys(selectedCollection).length === 0 }
  )
  const handleChangeSelectedCollection = (collection: SingleValue<OptionType>) => {
    setSelectedCollection(collection as OptionType)
  }

  return (
    <div>
      <SectionHeader>Whitelist management</SectionHeader>
      {!isLoading && Object.keys(selectedCollection) && (
        <>
          {selectedCollection.value &&
            ((isSelectDisabled && <h3>{selectedCollection.label}</h3>) || (
              <SectionSelect>
                <Select
                  styles={customStylesSelect}
                  defaultValue={selectedCollection}
                  options={collectionOptions}
                  classNamePrefix={'select-cs'}
                  id="filter"
                  name="filter"
                  onChange={handleChangeSelectedCollection}
                />
              </SectionSelect>
            ))}
          <SlotManagement collectionAddress={selectedCollection.value} />

          <Hr />
          <UploadWhitelist selectedCollection={selectedCollection} refetchData={refetchWhitelist} />
          <Section>
            <Whitelist
              whitelist={whitelist}
              isLoading={whitelistLoading}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
              refetchData={refetchWhitelist}
              collectionAddress={selectedCollection.value}
              collectionName={selectedCollection.label}
            />
          </Section>
        </>
      )}
    </div>
  )
}

export default WhitelistManagement

const Section = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`

const SectionHeader = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 32px;
`

const Hr = styled.hr`
  border: none;
  height: 1px;
  background: #000;
  background: repeating-linear-gradient(
    90deg,
    ${({ theme }) => theme.secondary3},
    ${({ theme }) => theme.secondary3},
    12px,
    transparent 6px,
    transparent 20px
  );
  margin-top: 24px;
  margin-bottom: 24px;
`

const SectionSelect = styled.div`
  width: 100%;
  margin-bottom: 24px;
`
