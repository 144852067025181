// eslint-disable-next-line no-restricted-imports

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import useParticle from 'hooks/useParticle'

// eslint-disable-next-line no-restricted-imports
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

interface UseSendMaticParams {
  amount: string
  address: string
}

export const useSendMatic = (options?: UseMutationOptions<any, any, UseSendMaticParams>) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, UseSendMaticParams>(async ({ amount, address }) => {
    if (!provider) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const signer = provider.getSigner()

    const tx = await signer.sendTransaction({
      to: address,
      value: amount,
    })

    const receipt = await tx.wait()

    return receipt
  }, options)
}
