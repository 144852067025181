import * as React from 'react'

export const Collection = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.371 5.83c0-.806.653-1.459 1.46-1.459h14.005c.806 0 1.46.653 1.46 1.46v3.5a.875.875 0 0 0 1.75 0v-3.5a3.21 3.21 0 0 0-3.21-3.21H5.83a3.21 3.21 0 0 0-3.209 3.21v16.34a3.21 3.21 0 0 0 3.21 3.209h4.668a.875.875 0 1 0 0-1.75H5.83a1.46 1.46 0 0 1-1.459-1.46V5.83Zm3.794 2.627a.875.875 0 1 0 0 1.75h7.003a.875.875 0 0 0 0-1.75H8.165Zm-.875 5.544c0-.484.392-.875.875-.875h2.334a.875.875 0 1 1 0 1.75H8.165A.875.875 0 0 1 7.29 14Zm16.277-2.045c-.79 0-1.55.315-2.108.876l-7.352 7.352a1.85 1.85 0 0 0-.523 1.046l-.45 3.151a.875.875 0 0 0 .974.992l3.064-.378h.002a1.858 1.858 0 0 0 1.077-.525l.002-.002 7.42-7.421a2.976 2.976 0 0 0 .002-4.216 2.976 2.976 0 0 0-2.108-.875Zm.869 2.111.62-.617-.617.62a1.225 1.225 0 0 1 0 1.737l-7.422 7.422a.11.11 0 0 1-.062.03l-1.927.237.288-2.019a.1.1 0 0 1 .028-.056l7.353-7.353.002-.001a1.226 1.226 0 0 1 1.737 0Z"
      clipRule="evenodd"
    />
  </svg>
)
