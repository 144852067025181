import React from 'react'
import { TabPanel, Tabs } from 'react-tabs'
import styled from 'styled-components/macro'

import { TabCs, TabListCs } from '../../../components/Tabs'
import { useGetOnGoingCollection, useGetUpcomingCollection } from '../../../hooks/useGetCollections'
import TabsContent from './TabsContent'
const CollectionDrop = (props: any) => {
  const { isLoading, collections } = props
  const collectionsOngoing = useGetOnGoingCollection(collections)
  const collectionsUpcoming = useGetUpcomingCollection(collections)

  return (
    <CollectionWrap>
      <TitleCollection>notable drops</TitleCollection>
      {!isLoading && (
        <Tabs defaultIndex={0}>
          <TabListCs style={{ marginTop: '32px', marginBottom: '40px' }} className={'tab-list__cs'}>
            {collectionsOngoing?.length > 0 && (
              <TabCs>
                <TabName>Ongoing Collections</TabName>
              </TabCs>
            )}
            {collectionsUpcoming?.length > 0 && (
              <TabCs>
                <TabName>Upcoming Collections</TabName>
              </TabCs>
            )}
          </TabListCs>
          {collectionsOngoing?.length > 0 && (
            <TabPanel>
              <TabsContent data={collectionsOngoing} />
            </TabPanel>
          )}
          {collectionsUpcoming?.length > 0 && (
            <TabPanel>
              <TabsContent data={collectionsUpcoming} isUpcoming={true} />
            </TabPanel>
          )}
        </Tabs>
      )}
    </CollectionWrap>
  )
}
const CollectionWrap = styled.div`
  width: 100%;
  .tab-list__cs {
    @media (max-width: 768px) {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }
`
const TitleCollection = styled.h1`
  line-height: 1.1667;
  color: ${({ theme }) => theme.secondary10};
  margin-bottom: 40px;
  margin-top: 0;
  font-family: 'DIN 1451 Std', sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
`

const TabName = styled.span`
  margin: 0;
  font-size: 24px;
  font-family: 'DIN 1451 Std', sans-serif;
  white-space: nowrap;
`
export default CollectionDrop
