import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { WatchlistType } from 'entities/Watchlist'

import { WhitelistSpot } from '../../entities/Whitelist'
import { SectionEdit } from '../../types'

export const collectionsApi = createApi({
  reducerPath: 'collections',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ['Collection', 'Whitelist'],
  endpoints: (builder) => ({
    getCollections: builder.query({
      query: () => {
        return {
          url: 'collections',
        }
      },
      providesTags: ['Collection'],
    }),
    getCollectionsMenu: builder.query({
      query: () => {
        return {
          url: 'collections/menu',
        }
      },
      providesTags: ['Collection'],
    }),
    getCollectionDetail: builder.query({
      query: ({ collectionAddress, address }: { collectionAddress: string; address?: string | null | undefined }) => {
        const account = address || null
        return {
          url: `collections/${collectionAddress}`,
          params: { address: account },
        }
      },
      providesTags: ['Collection'],
    }),
    updateCollection: builder.mutation({
      query: ({
        collectionAddress,
        name,
        descriptions,
        media,
        websiteUrl,
        instagram,
        twitter,
        patch,
        handleSuccess,
        handleError,
        refetch,
        isDisplayed,
      }: //handleFinally,
      {
        collectionAddress: string
        name: string
        descriptions?: any
        media: any
        websiteUrl?: string
        instagram?: string
        twitter?: string
        patch: any
        handleSuccess?: any
        handleError?: any
        //handleFinally?: any
        refetch: any
        isDisplayed?: boolean
      }) => {
        return patch(
          {
            callback: async (res: any) => {
              handleSuccess()
              if (res.status === 200) {
                refetch()
              }
            },
            errorCallback: () => {
              handleError()
            },
            url: `${process.env.REACT_APP_SERVER_URL}collections/edit`,
            data: { collectionAddress, name, descriptions, media, websiteUrl, instagram, twitter, isDisplayed },
          },
          true
        ).catch((err: any) => {
          console.log('Error edit collection', err)
        })
      },
      invalidatesTags: ['Collection'],
    }),
    updateCollectionDescriptions: builder.mutation({
      query: ({
        collectionAddress,
        sections,
        post,
        handleSuccess,
        handleError,
        refetch,
      }: {
        collectionAddress: string
        sections: SectionEdit[]
        post: any
        handleSuccess?: any
        handleError?: any
        refetch: any
      }) => {
        return post(
          {
            callback: () => {
              handleSuccess()
            },

            errorCallback: () => {
              handleError()
            },
            url: `${process.env.REACT_APP_SERVER_URL}collections/descriptions`,
            data: { collectionAddress, sections },
          },
          true
        )
          .then((res: any) => {
            if (res.status === 200) {
              refetch()
            }
          })
          .catch((err: any) => {
            console.log('Error edit description collection', err)
          })
      },
      invalidatesTags: ['Collection'],
    }),
    updateWatchlist: builder.mutation<WatchlistType, Partial<WatchlistType>>({
      query: ({
        userAddress,
        collectionAddress,
        refetch,
        post,
      }: {
        userAddress: string
        collectionAddress: string
        refetch: any
        post: any
      }) => {
        return post(
          {
            callback: () => {
              refetch()
            },
            url: `${process.env.REACT_APP_SERVER_URL}collections/watchlist`,
            data: {
              userAddress,
              collectionAddress,
            },
          },
          true
        )
      },
    }),
    whitelist: builder.mutation<WhitelistSpot, Partial<WhitelistSpot>>({
      query: ({
        userAddress,
        collectionAddress,
        post,
      }: {
        userAddress: string
        collectionAddress: string
        post: any
      }) => {
        return post(
          {
            url: `${process.env.REACT_APP_SERVER_URL}collections/whitelist`,
            data: { userAddress, collectionAddress },
          },
          true
        )
      },
    }),
    getWatchlistCollections: builder.query({
      query: ({
        address,
        page,
        limit,
        keyword,
        sort,
      }: {
        address: string
        page?: number
        limit?: number
        keyword?: string
        sort?: string
      }) => {
        return {
          url: `search/watchlist/${address}`,
          params: { page: page || 1, limit: limit || 8, keyword: keyword || '', sort: sort || 'ASC' },
        }
      },
    }),
    getWhitelistedUsers: builder.query({
      query: ({
        address,
        keyword,
        page,
        limit,
      }: {
        address?: string
        keyword?: string
        page?: number
        limit?: number
      }) => {
        return {
          url: `search/whitelist/${address}`,
          params: { keyword, page: page || 1, limit: limit || 1 },
        }
      },
      providesTags: ['Whitelist'],
    }),
    uploadWhitelist: builder.mutation({
      query: ({
        collectionAddress,
        whitelists,
        post,
        handleSuccess,
        handleError,
        handleFinally,
      }: {
        collectionAddress: string
        whitelists: any
        post: any
        handleSuccess?: any
        handleError?: any
        handleFinally?: any
      }) => {
        return post(
          {
            callback: () => {
              handleSuccess()
            },
            callbackFinally: () => {
              handleFinally()
            },
            errorCallback: () => {
              handleError()
            },
            url: `${process.env.REACT_APP_SERVER_URL}upload/whitelist`,
            data: { collectionAddress, whitelists },
          },
          true
        )
      },
      invalidatesTags: ['Whitelist'],
    }),
    deleteWhitelist: builder.mutation({
      query: ({
        collectionAddress,
        userAddress,
        deleteFnc,
        refetch,
      }: {
        collectionAddress: string
        userAddress: string
        deleteFnc: any
        refetch: any
      }) => {
        return deleteFnc(
          {
            callback: () => {
              refetch()
            },
            url: `${process.env.REACT_APP_SERVER_URL}admin-panel/whitelist`,
            data: { collectionAddress, userAddress },
          },
          true
        )
      },
      invalidatesTags: ['Whitelist'],
    }),
    getRedemptionWindow: builder.query({
      query: ({ address }: { address?: string }) => {
        return {
          url: `redemption/window/${address}`,
        }
      },
    }),
    getWhitelistSettings: builder.query({
      query: ({ address }: { address?: string }) => {
        return {
          url: `admin-panel/whitelist-management/${address}`,
        }
      },
    }),
    updateWhitelistSettings: builder.mutation({
      query: ({
        address,
        slotLimit,
        startTime,
        endTime,
        post,
        handleSuccess,
        handleError,
      }: {
        address?: string
        slotLimit?: number
        startTime?: number
        endTime?: number
        post?: any
        handleSuccess?: any
        handleError?: any
      }) => {
        return post(
          {
            callback: () => {
              handleSuccess()
            },
            errorCallback: (e: any) => {
              handleError(e)
            },
            url: `${process.env.REACT_APP_SERVER_URL}users/whitelist-management`,
            data: { collectionAddress: address, slotLimit, startTime, endTime },
          },
          true
        )
      },
    }),
  }),
})

export const {
  useGetCollectionsQuery,
  useGetCollectionsMenuQuery,
  useGetCollectionDetailQuery,
  useUpdateWatchlistMutation,
  useGetWatchlistCollectionsQuery,
  useWhitelistMutation,
  useGetWhitelistedUsersQuery,
  useUploadWhitelistMutation,
  useGetRedemptionWindowQuery,
  useDeleteWhitelistMutation,
  useGetWhitelistSettingsQuery,
  useUpdateWhitelistSettingsMutation,
  useUpdateCollectionMutation,
  useUpdateCollectionDescriptionsMutation,
} = collectionsApi
