import React from 'react'

import Loader from '../../../../components/Loader'
import { useGetProfile } from '../../../../state/profile/hooks'
import { Center } from '../../../Events/components/EventsList/styled'
import { usePOAPs } from '../../hooks/usePOAPs'
import { POAP } from '../../models/POAP'
import { POAPItem } from '../POAPItem'
import * as Styled from './styled'

export const POAPList = () => {
  const { data: profile, loading } = useGetProfile()
  const {
    data: poaps,
    isLoading,
    error,
  } = usePOAPs(
    {
      address: profile.address,
    },
    {
      enabled: Boolean(!loading && profile && profile.address),
    }
  )

  if (isLoading) {
    return (
      <Center>
        <Loader size="24px" />
      </Center>
    )
  }

  if (error) {
    return <> Error </>
  }

  return (
    <Styled.POAPListContainer>
      {poaps.map((poap: POAP) => (
        <POAPItem key={poap.id} poap={poap} />
      ))}
    </Styled.POAPListContainer>
  )
}
