import React, { ReactNode } from 'react'

import { Another1LogoSmall } from '../../../../components/Icons/Another1LogoSmall'
import Loader from '../../../../components/Loader'
import * as Styled from './styled'

interface RewardBoxProps {
  title: string
  amount: string
  isLoading: boolean
  children?: ReactNode
}

export const RewardBox = ({ title, amount, isLoading, children }: RewardBoxProps) => {
  return (
    <Styled.RewardBoxCointainer>
      <Styled.Title> {title} </Styled.Title>
      <Styled.AmountCointainer>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div>
              <Another1LogoSmall />
            </div>
            <Styled.Amount> {amount} </Styled.Amount>
          </>
        )}
      </Styled.AmountCointainer>
      {children}
    </Styled.RewardBoxCointainer>
  )
}
