import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const assignAttributesApi = createApi({
  reducerPath: 'assignAttributes',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ['ASSIGN_ATTRIBUTE'],
  endpoints: (builder) => ({
    getAttributesAssign: builder.query({
      query: ({
        page,
        limit,
        keyword,
        collectionAddress,
      }: {
        page?: number
        limit?: number
        keyword?: string
        collectionAddress?: string
      }) => {
        return {
          url: `search/assigning-attributes/${collectionAddress}`,
          params: {
            keyword: keyword || '',
            page: page || 1,
            limit: limit || 20,
          },
        }
      },
      providesTags: ['ASSIGN_ATTRIBUTE'],
    }),
    createAttributesAssign: builder.mutation({
      query: ({
        attributeId,
        collectionAddress,
        post,
        handleSuccess,
        handleError,
        handleFinally,
        refetch,
      }: {
        attributeId: string
        collectionAddress?: string

        post: any
        handleSuccess?: any
        handleError?: any
        handleFinally?: any
        refetch: any
      }) => {
        return post(
          {
            callback: () => {
              handleSuccess()
            },
            callbackFinally: () => {
              handleFinally()
            },
            errorCallback: () => {
              handleError()
            },
            url: `${process.env.REACT_APP_SERVER_URL}admin-panel/assigning-attributes`,
            data: { attributeId, collectionAddress },
          },
          true
        )
          .then((res: any) => {
            if (res.status === 200) {
              refetch()
            }
          })
          .catch((err: any) => {
            console.log(err, 'ERROR')
          })
      },
      invalidatesTags: ['ASSIGN_ATTRIBUTE'],
    }),
  }),
})

export const { useGetAttributesAssignQuery, useCreateAttributesAssignMutation } = assignAttributesApi
