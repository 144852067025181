import React, { useMemo } from 'react'

import { Address } from '../../../../components/Address'
import { useCurrentLocationContext } from '../../../../context/LocationContext'
import { useAnother1ERC20Decimals } from '../../../../hooks/useAnother1ERC20Decimals'
import { formatBignumberToDecimal } from '../../../../utils/formatBignumberToDecimal'
import { haversineDistance } from '../../../../utils/haversineDistance'
import { Event } from '../../models/Event'
import placeholderImage from './assets/placeholder.png'
import { TimeInformation } from './components/TimeInformation'
import * as Styled from './styled'

interface EventBoxProps {
  event: Event
}

export const EventBox = ({ event }: EventBoxProps) => {
  const { location } = useCurrentLocationContext()
  const { data: decimals } = useAnother1ERC20Decimals()

  const distance = haversineDistance(location, event.location).toFixed(2)

  const parsedTokenRewardPool = useMemo(() => {
    if (!decimals) {
      return ''
    }
    return formatBignumberToDecimal(String(event.tokenRewardPool), decimals as number)
  }, [event, decimals])

  const parsedRewardPerFlexer = useMemo(() => {
    if (!decimals) {
      return ''
    }

    return formatBignumberToDecimal(String(event.rewardPerFlexer), decimals as number)
  }, [event, decimals])

  return (
    <Styled.EventBoxContainer>
      <Styled.ImageContainer>
        <Styled.Image src={event.imageKey || placeholderImage} />
      </Styled.ImageContainer>
      <Styled.InfoContainer>
        <Styled.EventName> {event.name} </Styled.EventName>
        <Address address={`${event.formattedAddress} (${Number(distance) === -1 ? '?' : distance}km away)`} />
        <Styled.TokenTimeContainer>
          <Styled.TokenInfoContainer>
            <Styled.TokenInfo>
              <Styled.TokenInfoHeader>Token pool</Styled.TokenInfoHeader>
              <Styled.TokenInfoData>{parsedTokenRewardPool}</Styled.TokenInfoData>
            </Styled.TokenInfo>
            <Styled.TokenInfo>
              <Styled.TokenInfoHeader>Token rewards per flexer</Styled.TokenInfoHeader>
              <Styled.TokenInfoData>{parsedRewardPerFlexer}</Styled.TokenInfoData>
            </Styled.TokenInfo>
          </Styled.TokenInfoContainer>
          <Styled.TimeContainer>
            <TimeInformation startTime={event.startTime} endTime={event.endTime} />
          </Styled.TimeContainer>
        </Styled.TokenTimeContainer>
      </Styled.InfoContainer>
    </Styled.EventBoxContainer>
  )
}
