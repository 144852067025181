import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import React from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as IconPlus } from '../../../../assets/icons/icon-plus.svg'
import { ButtonSecondary } from '../../../../components/Button'
import { FormInput } from '../../../../components/Forms'
import Editor from '../Editor'
interface PropFaq {
  order: string
  listFaq: { [key: string]: string }[]
  setListFaq: any
}
const FAQEdit = (props: PropFaq) => {
  const { order, listFaq, setListFaq } = props

  const handleAddItem = () => {
    const cloneList = [...listFaq]
    cloneList.push({
      title: '',
      description: '',
    })
    setListFaq(cloneList)
  }
  const handleDeleteItem = (index: number) => {
    const cloneList = [...listFaq]
    cloneList.splice(index, 1)
    setListFaq(cloneList)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value
    const cloneList = [...listFaq]
    cloneList[index] = {
      ...cloneList[index],
      title: value,
    }
    setListFaq(cloneList)
  }
  const handleChangeItemFaq = (index: number, description: string) => {
    const cloneList = [...listFaq]
    cloneList[index] = {
      ...cloneList[index],
      description,
    }
    setListFaq(cloneList)
  }

  return (
    <FAQEditWrapper>
      {listFaq.map((item, index: number) => {
        return (
          <FAQEditItem key={`faq_${index}`}>
            <DivFlex>
              <IconCloseBox
                className={'icon-close-box'}
                show={listFaq.length === 1}
                onClick={() => handleDeleteItem(index)}
              >
                <IconClose />
              </IconCloseBox>
              <FormInput
                type={'text'}
                placeholder={'Enter a question title'}
                className={'form-input'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, index)}
                defaultValue={item.title || ''}
              />
            </DivFlex>
            <Editor
              toolbarId={`${order}_faq_${index}`}
              value={item.description}
              onChangeItem={(index: number, description: string) => handleChangeItemFaq(index, description)}
              index={index}
            />
          </FAQEditItem>
        )
      })}
      <CtaAddOther onClick={handleAddItem}>
        <IconPlus /> Add other
      </CtaAddOther>
    </FAQEditWrapper>
  )
}
const FAQEditWrapper = styled.div`
  width: 100%;
`
const FAQEditItem = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.secondary3};
  padding: 10px;
  &:not(:last-of-type) {
    .icon-close-box {
      display: flex;
    }
  }
`
const DivFlex = styled.div`
  display: flex;
  .form-input {
    margin-bottom: 16px;
  }
`
const IconCloseBox = styled.span<{ show?: boolean }>`
  width: 51px;
  height: 51px;
  border: 1.5px solid ${({ theme }) => theme.secondary2};
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: ${({ theme }) => theme.secondary1};
    }
  }
  ${({ show }) =>
    show &&
    css`
      display: none;
    `}
`
const CtaAddOther = styled(ButtonSecondary)`
  padding: 10px 20px !important;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 16px;
  color: ${({ theme }) => theme.primary10};
  text-transform: none;
  font-weight: 700;
  width: max-content;
  svg {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    path {
      fill: ${({ theme }) => theme.primary10};
    }
  }
`

export default FAQEdit
