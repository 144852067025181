import { Select } from '@rebass/forms'
import React from 'react'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components/macro'

const FilterBox = styled(Select)`
  padding: 12px 10px !important;
  min-width: 380px;
  border: 1px solid ${({ theme }) => theme.secondary5} !important;
  font-family: 'Din Pro Regular', sans-serif;
  & :focus-visible {
    outline: none;
  }
`

const Filter = () => {
  return (
    <Box>
      <FilterBox id="country" name="country" defaultValue="United States">
        <option>Name (A - Z)</option>
        <option>Name (A - Z)</option>
      </FilterBox>
    </Box>
  )
}

export default Filter
