import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'
import { Event } from '../models/Event'

interface UseEventProps {
  partnerAddress: string
  eventId: string
}

export const useEvent = (params: UseEventProps, options?: UseQueryOptions<UseEventProps, AxiosError<any>, Event>) => {
  const { client } = useApiClientContext()

  return useQuery<UseEventProps, AxiosError<any>, Event>(
    [QueryKeys.Event, params],
    async () => {
      const result = await client.get(
        apiEndpoints.event.replace('{partnerAddress}', params.partnerAddress).replace('{eventId}', params.eventId),
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
        }
      )

      return result?.data
    },
    options
  )
}
