import ErrorIcon from 'assets/icons/error.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import IconPortal from 'components/IconPortal'
import React from 'react'
import styled from 'styled-components/macro'

import Modal from '../index'
interface Props {
  isOpen: boolean
  onDismiss: () => void
  message?: string
}

const ModalError = (props: Props) => {
  const { isOpen, onDismiss, message } = props
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={550}>
      <ModalContent>
        <ModalHeader>
          <MainHeading>
            <span onClick={onDismiss} style={{ cursor: 'pointer' }}>
              <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
            </span>
          </MainHeading>
        </ModalHeader>
        <Div>
          <img src={ErrorIcon} alt="Icon" />
          <h2 className="error">Error!</h2>
          <p>{message}</p>
        </Div>
      </ModalContent>
    </Modal>
  )
}
const ModalContent = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  min-height: 280px;
`

const Div = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success {
    color: ${({ theme }) => theme.primary6};
  }
  .error {
    color: ${({ theme }) => theme.red5};
  }
  h2 {
    margin: 24px 0 0 0;
    text-align: center;
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: ${({ theme }) => theme.fontSizeText2};
    margin: 6px 0 0 0;
    text-align: center;
  }
`

const ModalHeader = styled.div`
  padding-bottom: 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    background-color: ${({ theme }) => theme.secondary3};
  }
`

const MainHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export default ModalError
