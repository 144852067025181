import ModalWhitelisted from 'components/Modal/ModalWhitelisted'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory, useParams } from 'react-router-dom'
import { Link as LinkRouter } from 'react-router-dom'
import { TabPanel, Tabs } from 'react-tabs'
import { Box, Flex } from 'rebass'
import { useModalOpen, useModalReport } from 'state/application/hooks'
import { useWalletPopupToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useGetCollectionDetailQuery } from 'state/collections/slice'
import styled from 'styled-components/macro'

import ImgCube2 from '../../assets/images/img/imgCube2.png'
import { ButtonPrimaryAnother } from '../../components/Button'
import { CountItem, TabCs, TabListCs } from '../../components/Tabs'
import TimeClock from '../../components/TimeClock'
import { getActivePeriod, getNextPeriod, SalePeriodType } from '../../entities/Sale'
import { useFetchCollectionItem, useGetCollectionDetail } from '../../state/collections/hooks'
import Sections from './Description/Sections'
import Filter from './Filter'
import HeaderCollectionDetail from './Header'
import ListItem from './ListItem'
import SearchBox from './SearchBox'

export type CollectionParams = {
  id: string
}

const CollectionDetail = () => {
  const { id } = useParams<CollectionParams>()
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const {
    data: collection,
    isLoading,
    isFetching,
    refetch,
  } = useGetCollectionDetailQuery({
    collectionAddress: id,
    address: account || null,
  })
  const toggleModalSuccess = useModalReport()
  const modalSuccessOpen = useModalOpen(ApplicationModal.REPORT_POPUP)
  const toggleWalletPopup = useWalletPopupToggle()
  const history = useHistory()

  const fetchCollectionItem = useFetchCollectionItem()
  const [_connectAndMint, setConnectAndMint] = useState<boolean>(false)

  const [activePeriod, setActivePeriod] = useState<SalePeriodType | undefined>(undefined)
  const [nextPeriod, setNextPeriod] = useState<SalePeriodType | undefined>(undefined)
  const [currentPeriod, setCurrentPeriod] = useState<SalePeriodType | undefined>(undefined)

  const connectAndMint = () => {
    setConnectAndMint(true)
    toggleWalletPopup()
  }

  useEffect(() => {
    if (_connectAndMint && account) {
      history.push(`/${collection.slug}/mint`)
    }

    const sale = collection?.sale

    if (sale) {
      const activePeriod = sale && getActivePeriod(sale)
      const nextPeriod = sale && getNextPeriod(sale)

      setActivePeriod(activePeriod)
      setNextPeriod(nextPeriod)

      if (activePeriod || nextPeriod) {
        const period = activePeriod ? activePeriod : nextPeriod
        setCurrentPeriod(period)
      }
    }
  }, [account, _connectAndMint, history, collection])

  useEffect(() => {
    const paramAccount = account ? account : null
    fetchCollectionItem(id, paramAccount, 1).then()
  }, [fetchCollectionItem, id, account])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const getCollectionDetail = useGetCollectionDetail()
  const { totalItems } = getCollectionDetail.pagination
  useDocumentTitle(`${collection?.name ? collection.name + ' - Collection |' : ''}  Another-1 Launchpad`)

  return (
    <Wrapper>
      {isLoading ? (
        <Skeleton
          height={'calc(100vh - 150px)'}
          duration={2}
          borderRadius={0}
          baseColor={'rgba(0, 0, 0, 0.5)'}
          highlightColor={'#323232'}
        />
      ) : (
        <HeaderCollectionDetail collection={collection} isLoading={isLoading || isFetching} refetch={refetch} />
      )}
      {collection && collection.status !== 'past' && (
        <CountDownWrapper>
          <CountDownContainer>
            <CountDownContent>
              <CountDownBox>
                <DivFlex>
                  <CubeBox>
                    <img src={ImgCube2} alt={'cube_img'} />
                  </CubeBox>
                  <DivArtWorld>
                    <TextArt>
                      {collection?.status === 'ongoing' ? (
                        <>
                          The art world&lsquo;s NFT. <br />
                          <TextHighLight>Start Minting</TextHighLight>
                        </>
                      ) : (
                        <>
                          Be the <TextHighLight>First ones</TextHighLight> to explore the collection
                        </>
                      )}
                    </TextArt>
                    {!account && (
                      <Connect type={'button'} onClick={connectAndMint}>
                        <span>CONNECT & MINT</span>
                      </Connect>
                    )}
                    {account && collection?.sale && getActivePeriod(collection.sale) && (
                      <CtaArtWorld type={'button'}>
                        <LinkRouter to={`/${collection.slug}/mint`}>MINT NOW</LinkRouter>
                      </CtaArtWorld>
                    )}
                  </DivArtWorld>
                  <TimeBox>
                    {collection?.sale && currentPeriod && (
                      <>
                        <TitleBoxTime>
                          {currentPeriod.saleType === 0 ? 'PRESALE' : 'PUBLIC SALE'}{' '}
                          {activePeriod ? 'FINISHES IN' : 'UPCOMING'}
                        </TitleBoxTime>
                        <TimeClock
                          exactStart={new Date(currentPeriod.timeFrom * 1000)}
                          exactEnd={new Date(currentPeriod.timeTo * 1000)}
                          isUpcoming={typeof activePeriod === 'undefined'}
                        />
                      </>
                    )}
                    {/*{collection?.typeSale === 'past' && <HighLight>PAST</HighLight>}*/}
                  </TimeBox>
                </DivFlex>
              </CountDownBox>
            </CountDownContent>
          </CountDownContainer>
        </CountDownWrapper>
      )}
      <ContentWrapper>
        <ContentMain>
          <Tabs defaultIndex={0}>
            {!isLoading && (
              <>
                <TabListCs data-show={collection?.status === 'past'}>
                  <TabCs>
                    <TabName>Description</TabName>
                  </TabCs>
                  <TabCs>
                    <TabName>ITEMS</TabName>
                    {totalItems && <CountItem className={'count_item'}>({totalItems})</CountItem>}
                  </TabCs>
                </TabListCs>
                <TabPanel>
                  {/*{collection?.descriptions ? <Description descriptions={collection.descriptions} /> : ''}*/}
                  {collection?.collectionDescriptions ? (
                    <Sections sections={collection?.collectionDescriptions.sections} />
                  ) : (
                    ''
                  )}
                </TabPanel>
                <TabPanel>
                  <CustomBox>
                    <Flex flexWrap="wrap" mx={-4}>
                      <Box px={4} width={5 / 6}>
                        <SearchBox />
                      </Box>
                      <Box px={4} width={1 / 6}>
                        <Filter />
                      </Box>
                    </Flex>
                  </CustomBox>
                  <ListItem />
                </TabPanel>
              </>
            )}
          </Tabs>
        </ContentMain>
      </ContentWrapper>
      <ModalWhitelisted isOpen={modalSuccessOpen} onDismiss={toggleModalSuccess} />
    </Wrapper>
  )
}
const ContentWrapper = styled.div`
  margin-top: 100px;
`
const ContentMain = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`
const Wrapper = styled.div`
  width: 100%;
`
const CountDownWrapper = styled.div`
  width: 100%;
`
const TitleBoxTime = styled.div`
  text-align: right;
  font-size: 14px;
  color: ${({ theme }) => theme.primary5};
`
const CountDownContainer = styled.div`
  padding-left: 160px;
  padding-right: 160px;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 0;
  }
  @media (max-width: 575px) {
    padding-left: 0;
  }
`
const CountDownContent = styled.div`
  padding-top: 32px;
  margin-top: 40px;
  @media (max-width: 575px) {
    position: relative;
    padding-top: 80px;
  }
`
const CountDownBox = styled.div`
  border: 1px solid ${({ theme }) => theme.secondary3};
`
const DivFlex = styled.div`
  display: flex;
  padding: 50px;
  @media (max-width: 1024px) {
    padding: 32px;
  }
  @media (max-width: 768px) {
    padding: 16px;
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
`
const CubeBox = styled.div`
  flex: 0 0 304px;
  max-width: 304px;
  margin-top: -82px;
  margin-left: -80px;
  img {
    display: block;
    width: 100%;
    min-height: 1px;
    vertical-align: middle;
  }
  @media (max-width: 1024px) {
    margin-top: -52px;
    margin-left: -50px;
    flex: 0 0 200px;
    max-width: 200px;
  }
  @media (max-width: 768px) {
    margin-top: -36px;
    margin-left: -34px;
  }
  @media (max-width: 575px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
`
const DivArtWorld = styled.div`
  flex: 1;
  //padding: 50px 60px 50px 0;
  padding-right: 60px;
  @media (max-width: 1024px) {
    padding-right: 16px;
  }
  @media (max-width: 575px) {
    padding-right: 0;
    margin-top: 48px;
  }
`
const TimeBox = styled.div`
  flex: 0 0 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1024px) {
    flex: 0 0 200px;
    max-width: 200px;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    align-items: flex-start;
    margin-top: 12px;
  }
`
const TextArt = styled.h2`
  font-weight: 500;
  line-height: 1.33333;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};
  margin: 0 0 20px 0;
  font-family: 'DIN 1451 Std', sans-serif;
`
const TextHighLight = styled.span`
  color: ${({ theme }) => theme.primary5};
`

const CtaArtWorld = styled(ButtonPrimaryAnother)`
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 600;
  width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border: none;
  &:hover {
    border: none;
  }
  a {
    display: block;
    width: 100%;
    text-decoration: none;
    color: ${({ theme }) => theme.secondary1};
  }
`
const Connect = styled(CtaArtWorld)`
  width: 250px;
`

const TabName = styled.span`
  margin: 0;
`

const HighLight = styled.span`
  color: ${({ theme }) => theme.primary5};
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 1024px) {
    text-align: right;
  }
`

const CustomBox = styled(Box)`
  width: 100%;
  margin-top: 40px !important;
`
export default CollectionDetail
