import styled from 'styled-components/macro'

const ParticleMethods = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  gap: 6px;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.secondary8};
  border-radius: 6px;
  img {
    height: 60px;
    width: 60px;
    cursor: pointer;
  }
`

export { ParticleMethods }
