import React from 'react'
import { Heart } from 'react-feather'
import styled from 'styled-components/macro'

import Fallback from '../../../assets/images/fallback.svg'
import IconExpandFull from '../../../assets/images/svg/icon-expand-full.svg'
import Modal from '../../../components/Modal'
interface PropTypes {
  isOpen: boolean
  onDismiss: () => void
  name?: string
  srcImg?: string
  numbersLike?: number | undefined
  onClickLike?: () => void
  isLiked?: boolean | undefined
}
const ModalFullscreenNFT = (props: PropTypes) => {
  const { isOpen, onDismiss, name, srcImg, numbersLike, onClickLike, isLiked } = props
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} name={name}>
      <ModalContent>
        <BoxImg>
          <img src={srcImg ?? srcImg} alt={'img_shoe_nft'} onError={(e) => (e.currentTarget.src = Fallback)} />
        </BoxImg>

        <DivAction>
          <BoxFavorite className={'cs-favorite'}>
            <span onClick={onClickLike}>
              <Heart className={'icon-heart ' + (isLiked ? 'active' : '')} />
            </span>
            <span>{numbersLike ? numbersLike : 0}</span>
          </BoxFavorite>
          <BoxExpandFull onClick={onDismiss}>
            <span>
              <img src={IconExpandFull} alt={'IconExpandFull'} />
            </span>
          </BoxExpandFull>
        </DivAction>
      </ModalContent>
    </Modal>
  )
}
const ModalContent = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`
const BoxImg = styled.div`
  background-color: ${({ theme }) => theme.secondary1};
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const DivAction = styled.div`
  top: 32px;
  right: 32px;
  position: fixed;
  display: flex;
`
const BoxFavorite = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primary6};
  font-size: ${({ theme }) => theme.fontSizeText2};
  font-weight: 500;
  border: 2px solid rgba(0, 255, 163, 0.16);
  width: 94px;
  padding: 9px;
  justify-content: center;
  cursor: pointer;
  span:first-of-type {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    img {
      display: block;
      width: 100%;
    }
  }
  .icon-heart.active {
    color: ${({ theme }) => theme.primary6};
    fill: ${({ theme }) => theme.primary6};
  }
`
const BoxExpandFull = styled.div`
  width: 42px;
  height: 42px;
  border: 2px solid rgba(0, 255, 163, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  img {
    display: block;
    width: 24px;
    height: 24px;
  }
`
export default ModalFullscreenNFT
