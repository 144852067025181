import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseShippingInformationProps {
  tokenId: string
  collectionAddress: string
}

export const useShippingInformation = (
  params: UseShippingInformationProps,
  options?: UseQueryOptions<UseShippingInformationProps, AxiosError<any>, any>
) => {
  const { client } = useApiClientContext()

  return useQuery<UseShippingInformationProps, AxiosError<any>, any>(
    [QueryKeys.ShippingInformation, params],
    async () => {
      const result = await client.get(
        apiEndpoints.shippingInformation
          .replace(':collectionAddress', params.collectionAddress)
          .replace(':tokenId', params.tokenId),
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
        }
      )

      return result?.data
    },
    options
  )
}
