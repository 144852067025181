import Fallback from 'assets/images/fallback.svg'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Flex, Image, Text } from 'rebass'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'

import { CollectionType } from '../../entities/Collection'
import CollectionCountdown from '../../pages/MintingCollection/CollectionOtherItem/CollectionCountdown'
import { shortenAddress } from '../../utils'

interface CollectionItemBoxProps {
  dataItem: CollectionType
  isCollection?: any
  closeMenu?: any
  isUpcoming?: boolean
}

const CollectionItemBox = ({ dataItem, isCollection, closeMenu, isUpcoming = false }: CollectionItemBoxProps) => {
  const history = useHistory()
  const [itemChecked, setItemChecked] = React.useState<boolean>(false)

  const handleItemOnClick = (e: any, slug: string | undefined) => {
    e.preventDefault()
    if (isCollection) {
      history.push(`/collection/${slug}`)
      closeMenu && closeMenu()
    } else {
      setItemChecked(!itemChecked)
    }
  }

  return (
    <Flex className={'item-card'}>
      <CollectionItem onClick={(e) => handleItemOnClick(e, dataItem?.slug)} className={itemChecked ? 'is-checked' : ''}>
        {dataItem.media?.image ? (
          isVideoLink(dataItem.media?.image) ? (
            <CustomVideo
              width={'100%'}
              height={'100%'}
              src={dataItem.media?.image}
              controls={false}
              muted={true}
              playsInline={true}
              autoPlay={true}
              loop={true}
            />
          ) : (
            <CustomImage src={dataItem.media?.image || Fallback} />
          )
        ) : (
          <CustomImage src={Fallback} />
        )}

        <ContentBox>
          <TitleCard>{dataItem.name}</TitleCard>
          <CollectionInfo fontSize="14px">
            {dataItem?.creator?.name || (dataItem && dataItem.creator && shortenAddress(dataItem?.creator.address))}
          </CollectionInfo>
        </ContentBox>
        <RightBox>
          <CollectionCountdown sale={dataItem.sale} isUpcoming={isUpcoming} />
        </RightBox>
      </CollectionItem>
    </Flex>
  )
}

export default CollectionItemBox

const CollectionItem = styled(Card)`
  display: flex;
  cursor: pointer;
  width: 100%;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.secondary3};
  background-color: ${({ theme }) => theme.secondary11};
  margin-bottom: 42px !important;

  &.is-checked {
    border: 1px solid ${({ theme }) => theme.primary6};
  }
  &:hover {
    background-color: ${({ theme }) => theme.secondary2};
    border: 1px solid ${({ theme }) => theme.primary5};
    box-shadow: 0 10px 50px #093a29;
  }
  &:hover {
    background-color: ${({ theme }) => theme.secondary2};
    border: 1px solid ${({ theme }) => theme.primary5};
    box-shadow: 0 10px 50px #093a29;
  }
`

const CustomImage = styled(Image)`
  height: 64px !important;
  width: 64px;
  min-width: auto !important;
`

const CustomVideo = styled.video`
  height: 64px !important;
  width: 64px;
  min-width: auto !important;
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-left: 20px;
  padding-right: 20px;
`

const TitleCard = styled.h5`
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 5px;
`

const CollectionInfo = styled(Text)`
  color: ${({ theme }) => theme.primary6};
  font-size: 20px !important;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase;
`

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
