import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { CollectionType } from '../../../entities/Collection'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { useGetCollectionsQuery } from '../../../state/collections/slice'
import CollectionOtherItem from '../CollectionOtherItem'

interface PropsType {
  collectionAddress?: string
}

const CollectionOtherList = ({ collectionAddress }: PropsType) => {
  const [collectionOngoing, setCollectionOnGoing] = useState<any>(null)
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const { data: collections } = useGetCollectionsQuery({})

  useEffect(() => {
    const dataOngoing = collections?.filter((item: any) => {
      return item.address !== collectionAddress && item.sale && item.sale.periods && item.sale.periods.length > 0
    })
    setCollectionOnGoing(dataOngoing)
  }, [collections, account, collectionAddress])

  return (
    <>
      {collectionOngoing && collectionOngoing.length > 0 && (
        <>
          <HeadingOther>Other ongoing collections</HeadingOther>
          <div>
            {collectionOngoing?.map((collection: CollectionType) => {
              return <CollectionOtherItem key={collection.address} collection={collection} />
            })}
          </div>
        </>
      )}
    </>
  )
}

const HeadingOther = styled.h3`
  font-size: ${({ theme }) => theme.display2};
  line-height: 1.16667;
  margin-bottom: 80px;
  font-family: 'DIN 1451 Std', sans-serif;
  text-transform: uppercase;
  margin-top: 0;
`

export default CollectionOtherList
