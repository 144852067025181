import React from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'

import ErrorIcon from '../../../../assets/icons/error.svg'
import IconInfo from '../../../../assets/icons/icon-info-circle.svg'
import SuccessIcon from '../../../../assets/icons/success.svg'
import { ButtonPrimary } from '../../../../components/Button'
import IconPortal from '../../../../components/IconPortal'
import Loader from '../../../../components/Loader'
import {
  Div,
  InputVerificationCodeBox,
  ModalBody,
  Notification,
  RetryButtonWrapper,
  TextNoti,
  TextVerification,
} from './styles'

interface ClaimNFTModalBodyProps {
  isError: boolean
  errorMessage: string
  isSuccess: boolean
  isLoading: boolean
  successMessage: string
  onSubmit: (data: string) => void
  onRetry: () => void
  onSeeNFT: () => void
}

export const ClaimNFTModalBody = ({
  isError,
  isSuccess,
  errorMessage,
  onSubmit,
  successMessage,
  onRetry,
  onSeeNFT,
  isLoading,
}: ClaimNFTModalBodyProps) => {
  if (isLoading) {
    return (
      <Div>
        <Loader size={'24px'} />
      </Div>
    )
  }
  if (isError) {
    return (
      <Div className="error">
        <img src={ErrorIcon} alt="Icon" />
        <h2 className="error">An error occurred!</h2>
        <p>{errorMessage}</p>
        <RetryButtonWrapper>
          <ButtonPrimary onClick={onRetry}> Retry </ButtonPrimary>
        </RetryButtonWrapper>
      </Div>
    )
  }

  if (isSuccess) {
    return (
      <Div>
        <img src={SuccessIcon} alt="Icon" />
        <h2 className="success">Congratulations</h2>
        <p>{successMessage}</p>
        <RetryButtonWrapper>
          <ButtonPrimary onClick={onSeeNFT}> See your NFT </ButtonPrimary>
        </RetryButtonWrapper>
      </Div>
    )
  }
  return (
    <ModalBody>
      <TextVerification>Input the verification code</TextVerification>
      <InputVerificationCodeBox>
        <ReactInputVerificationCode autoFocus={true} value={''} placeholder={'_'} length={6} onCompleted={onSubmit} />
      </InputVerificationCodeBox>
      <Notification>
        <IconPortal SrcImageIcon={IconInfo} widthIcon={'16px'} heightIcon={'16px'} />
        <TextNoti>It may take a minute to receive your code</TextNoti>
      </Notification>
    </ModalBody>
  )
}
