import useDocumentTitle from 'hooks/useDocumentTitle'
import styled from 'styled-components/macro'

const BecomeCreator = () => {
  useDocumentTitle(`Become a Creator | Another-1 Launchpad`)

  return (
    <BecomeCreatorWrapper>
      <BecomeCreatorContainer>
        <CreatorHeader>
          <Header>
            <HeaderContent>
              <Caps>Become a Creator / Open Call</Caps>
              <Caps>Initial Digital Fashion Competition</Caps>
            </HeaderContent>
          </Header>
        </CreatorHeader>
        <CreatorBody>
          <Section>
            <Subheader>Overview:</Subheader>
            <p>
              Another-1 is excited to announce our first <HighLight>Become a Creator</HighLight> module in partnership
              with <Link href="https://unionavatars.com/">Union Avatars</Link> and
              <Link href="https://theimmersivekind.com/"> The Immersive Kind</Link>. We are looking for emerging fashion
              designers, students, and creative professionals who are interested in showcasing their designs and
              creativity to style a Union Avatars 3D model on the Another-1 platform.
            </p>
            <Subheader>Competition Details:</Subheader>
            <p>
              The competition designers have complete creative freedom to create a full digital outfit that can be worn
              by the 3D Union Avatars models.
              <br />
              <br />
              Specific technical requirements (GLB + FBX files - video or 3D animation &gt;{' '}
              <Link href="https://drive.google.com/drive/folders/1Jni61ClBRxYDpnoOowP2jw3eQogI62iD">
                full details here
              </Link>
              )
              <br />
              <br />
              In addition Union Avatars will provide LIVE tutorial sessions on Discord to enable creators to express
              their creativity on top of the existing tutorial recordings available on their YouTube channel:{' '}
              <Link href="https://www.youtube.com/watch?v=BXONTLXFCHk&list=PLmbbwynovJOj8DaGX60rCJ-OjSrRbIRYi">
                here
              </Link>
            </p>
            <Subheader>Selection Process:</Subheader>
            <p>
              The participants will be required to submit their design, meeting the specified technical requirements
              (video or 3D animation &gt;
              <Link href="https://docs.google.com/document/d/1gW98q6YWc3P99IiHgWD64q5Lgk3LVoVr/edit?usp=sharing&ouid=116213021754490420014&rtpof=true&sd=true">
                full details
              </Link>
              ). <br />
              <br />
              The selection process will consist of two rounds. <br />
              The submitted designs will go through our partners voting to shortlist 10 participants, followed by the
              second round of fashion experts&apos; jury voting for the top 5 finalists who will receive a cash prize
              (top 3), an NTR1-Meta phygital sneakers NFT, and web3 fashion exposure through our network.
            </p>
            <Subheader>Timeline:</Subheader>
            <ul>
              <li>April 11th: Launch of the open call competition and the BAC landing page</li>
              <li>May 28th: Last day to send the submissions</li>
              <li>May 29th till June 11th: Announcement of the shortlist</li>
              <li>June 20th: Winners announcement</li>
            </ul>
            <Subheader>Winners:</Subheader>
            <ul>
              <li>1st Laura Caicoya - $1,000 USDT + NTR1-Meta NFT</li>
              <li>2nd Gulshan Huseynli - $500 USDT + NTR1-Meta NFT</li>
              <li>3rd Isabel Ibasco - $500 USDT + NTR1-Meta NFT</li>
              <li>4th Yolanda Santiago Márquez - NTR1-Meta NFT</li>
              <li>5th Zih Ling Chen - NTR1-Meta NFT</li>
            </ul>
            <DesignPreview
              src="https://images-upload.sgp1.digitaloceanspaces.com/bac-winning-designs.png"
              alt="winning-designs-preview"
            />
          </Section>
        </CreatorBody>
      </BecomeCreatorContainer>
    </BecomeCreatorWrapper>
  )
}

const Link = styled.a`
  color: ${({ theme }) => theme.primary5};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  z-index: 2000;
`

const BecomeCreatorWrapper = styled.div`
  width: 100%;
`
const BecomeCreatorContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`
const CreatorHeader = styled.div``
const Header = styled.div`
  padding-top: 60px;
  padding-bottom: 80px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`
const HeaderContent = styled.div`
  position: relative;
  z-index: 2;
  p {
    margin: 0 auto;
    text-align: center;
    max-width: 552px;
    font-size: ${({ theme }) => theme.fontSizeText1};
    font-weight: 400;
    line-height: 1.6;
    color: ${({ theme }) => theme.secondary6};
  }
`

const Caps = styled.h3`
  font-family: 'DIN 1451 Std', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 1.11;
  text-align: center;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary10};
  margin: 20px;
`

const Subheader = styled.h4`
  font-family: 'DIN 1451 Std', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 1.11;
  text-align: left;
  letter-spacing: -0.015em;
  color: ${({ theme }) => theme.primary10};
  margin-bottom: 20px;
`

const CreatorBody = styled.div``
const Section = styled.div`
  max-width: 1120px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
`
const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primary10};
  margin-bottom: 64px;
  span {
    font-family: 'DIN 1451 Std', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.capsHeading2};
    line-height: 40px;
    text-transform: uppercase;
  }
`
const HighLight = styled.span`
  color: ${({ theme }) => theme.primary5};
`

const DesignPreview = styled.img`
  width: 100%;
  margin-top: 40px;
`

export default BecomeCreator
