import React, { ReactNode } from 'react'

import * as Styled from './styled'

interface FormInputLabelProps {
  children: ReactNode
  htmlFor: string
}

export const FormInputLabel = ({ children, htmlFor }: FormInputLabelProps) => {
  return <Styled.Label htmlFor={htmlFor}> {children} </Styled.Label>
}
