import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'

import Fallback from '../../../assets/images/fallback.svg'
import IconInstagramImg from '../../../assets/images/svg/icon-instagram-gray.svg'
import IconTwitterImg from '../../../assets/images/svg/icon-twitter-gray.svg'
import IconWebsiteImg from '../../../assets/images/svg/icon-website-gray.svg'
import { CollectionType } from '../../../entities/Collection'
import CollectionCountdown from './CollectionCountdown'

interface CollectionOtherItemProps {
  collection: CollectionType
}

const CollectionOtherItem = ({ collection }: CollectionOtherItemProps) => {
  return (
    <CollectionOtherWrapper>
      <RowItem>
        <DivImgCollection>
          <DivRatioRelative>
            <DivRatioPos>
              {isVideoLink(collection?.media?.image) ? (
                <CustomVideo
                  width={'100%'}
                  height={'100%'}
                  src={collection.media?.image}
                  controls={false}
                  muted={true}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                ></CustomVideo>
              ) : (
                <img
                  src={collection?.media?.image || Fallback}
                  alt={'img_shoe_nft'}
                  onError={(e) => (e.currentTarget.src = Fallback)}
                />
              )}
            </DivRatioPos>
          </DivRatioRelative>
        </DivImgCollection>
        <DivInfoCollection>
          <InfoFlex wrap={'wrap'}>
            <BoxText>
              <Title>
                <LinkRouter to={`/collection/${collection.slug}`}>{collection.name}</LinkRouter>
              </Title>
              <Sub>{collection.creator ? collection.creator.name : ''}</Sub>
              <Desc>{collection.descriptions?.subTile}</Desc>
            </BoxText>
            <SocialGroup>
              <SocialItem href={collection.twitter ? collection.twitter : '/'}>
                <img src={IconTwitterImg} alt={'twitter'} />
              </SocialItem>
              <SocialItem href={collection.instagram ? collection.instagram : '/'}>
                <img src={IconInstagramImg} alt={'instagram'} />
              </SocialItem>
              <SocialItem href={collection.websiteUrl ? collection.websiteUrl : '/'}>
                <img src={IconWebsiteImg} alt={'website'} />
              </SocialItem>
            </SocialGroup>
          </InfoFlex>
          <InfoOther>
            <TotalItems>
              <p>Items</p>
              <h3>{collection.assetCount}</h3>
            </TotalItems>
            <InfoTime>
              <CollectionCountdown sale={collection.sale} />
            </InfoTime>
          </InfoOther>
        </DivInfoCollection>
      </RowItem>
    </CollectionOtherWrapper>
  )
}

const CustomVideo = styled.video`
  min-width: auto !important;
`

const InfoOther = styled(Flex)`
  flex-wrap: wrap;
  background-color: rgba(35, 35, 35, 0.6);
`
const TotalItems = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 20px;
    bottom: 20px;
    right: 0;
    width: 1px;
    height: calc(100% - 40px);
    background-color: ${({ theme }) => theme.secondary2};
  }
  p {
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizeText3};
    color: ${({ theme }) => theme.secondary7};
    line-height: 17px;
    text-transform: uppercase;
    margin: 0 0 8px 0;
  }
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.6667;
    margin: 0;
    color: ${({ theme }) => theme.primary6};
  }
`
const InfoTime = styled.div`
  padding: 20px;
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const SocialGroup = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: auto;
`
const SocialItem = styled.a`
  text-decoration: none;
  margin: 0 10px;
  display: flex;
`
const InfoFlex = styled(Flex)`
  margin-bottom: 24px !important;
`
const BoxText = styled.div`
  margin-right: 80px;
`
const Desc = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary8};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
const Title = styled.p`
  font-size: ${({ theme }) => theme.display3};
  line-height: 1.25;
  margin: 0 0 4px 0;
  font-family: 'DIN 1451 Std', sans-serif;
  text-transform: uppercase;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary10};
  }
`
const Sub = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 1.3;
  color: ${({ theme }) => theme.primary6};
  margin: 0 0 12px 0;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary6};
  }
`
const CollectionOtherWrapper = styled.div`
  padding: 24px;
  margin-bottom: 32px;
  &:last-of-type {
    margin-bottom: 0;
  }
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(
      59.01% 242.73% at 50% 50%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(122, 123, 123, 0.14) 0.01%,
      rgba(175, 175, 175, 0.022) 100%
    );
`
const DivRatioRelative = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
`
const DivRatioPos = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: yellowgreen;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`
const RowItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
`
const DivImgCollection = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 12px;
  padding-right: 12px;
`
const DivInfoCollection = styled.div`
  flex: 0 0 75%;
  max-width: 75%;
  padding-left: 12px;
  padding-right: 12px;
`
export default CollectionOtherItem
