import styled from 'styled-components/macro'

export const POAPItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  align-items: center;
  justify-content: center;
`

export const POAPEventName = styled.p`
  font-weight: 400;
  font-size: 32px;
  color: #ffffff;
  margin: 4px 0 12px 0;
`

export const POAPImage = styled.img`
  object-fit: cover;
  width: auto;
  height: 260px;
  max-width: 100%;
`

export const POAPDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
