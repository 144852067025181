import * as React from 'react'
export const Grid = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3 2.25a.75.75 0 0 0-.75.75v7c0 .414.336.75.75.75h7a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75H3Zm.75 7v-5.5h5.5v5.5h-5.5Zm10.25-7a.75.75 0 0 0-.75.75v7c0 .414.336.75.75.75h7a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75h-7Zm.75 7v-5.5h5.5v5.5h-5.5ZM2.25 14a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 .75.75v7a.75.75 0 0 1-.75.75H3a.75.75 0 0 1-.75-.75v-7Zm1.5.75v5.5h5.5v-5.5h-5.5ZM14 13.25a.75.75 0 0 0-.75.75v7c0 .414.336.75.75.75h7a.75.75 0 0 0 .75-.75v-7a.75.75 0 0 0-.75-.75h-7Zm.75 7v-5.5h5.5v5.5h-5.5Z"
      clipRule="evenodd"
    />
  </svg>
)
