import React, { useEffect, useMemo, useState } from 'react'

import * as Styled from './styled'

interface CountDownClockProps {
  fromTime: number
}

export const CountDownClock = ({ fromTime }: CountDownClockProps) => {
  const [now, setNow] = useState(new Date().getTime())

  const hrsMinsSecs = useMemo(() => {
    if (!fromTime || !now) {
      return {
        secs: 0,
        mins: 0,
        hrs: 0,
      }
    }

    const secs = (((fromTime - now) / 1000) % 60).toFixed(0).toString().padStart(2, '0')

    const mins = (((fromTime - now) / 1000 / 60) % 60).toFixed(0).toString().padStart(2, '0')

    const hrs = ((fromTime - now) / 1000 / 3600).toFixed(0).toString().padStart(2, '0')

    return { secs, mins, hrs }
  }, [now, fromTime])

  useEffect(() => {
    // @ts-ignore
    const i = setInterval(() => {
      setNow(new Date().getTime())
    }, 1000)

    // @ts-ignore
    return () => clearInterval(i)
  }, [])

  return (
    <>
      <Styled.TitleContainer>
        <Styled.TitleGreen>Event</Styled.TitleGreen> finishes in
      </Styled.TitleContainer>
      <Styled.CountDownContainer>
        <Styled.SingleCountDownContainer>
          <Styled.Number>{hrsMinsSecs.hrs}</Styled.Number>
          <Styled.Text> Hrs </Styled.Text>
        </Styled.SingleCountDownContainer>
        <Styled.GapContainer>
          <Styled.GapText>:</Styled.GapText>
        </Styled.GapContainer>
        <Styled.SingleCountDownContainer>
          <Styled.Number>{hrsMinsSecs.mins}</Styled.Number>
          <Styled.Text> Mins </Styled.Text>
        </Styled.SingleCountDownContainer>
        <Styled.GapContainer>
          <Styled.GapText>:</Styled.GapText>
        </Styled.GapContainer>
        <Styled.SingleCountDownContainer>
          <Styled.Number>{hrsMinsSecs.secs}</Styled.Number>
          <Styled.Text> Secs </Styled.Text>
        </Styled.SingleCountDownContainer>
      </Styled.CountDownContainer>
    </>
  )
}
