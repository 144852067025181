import { Marker } from '@react-google-maps/api'
import { InputHTMLAttributes, useMemo } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useFormContext } from 'react-hook-form'

import * as Styled from '../FormInput/styled'
import { FormInputError } from '../FormInputError'
import { FormInputLabel } from '../FormInputLabel'
import { GoogleMaps } from '../GoogleMaps'

interface FormInputGoogleMapsProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

export const USER_INPUT_FIELD_KEY = 'userAddress'
export const LOCATION_FIELD_KEY = 'location'

const formatLatLng = (lat: number | string | null, lng: number | string | null) => {
  if (lat && lng) {
    return `${lat}, ${lng}`
  }

  return ''
}

export const FormInputGoogleMaps = ({ name, label, ...inputProps }: FormInputGoogleMapsProps) => {
  const {
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useFormContext()

  const location = watch(LOCATION_FIELD_KEY)
  const values = getValues()

  const markerPosition = useMemo(() => {
    if (!location) {
      return undefined
    }

    return {
      lat: location.latitude,
      lng: location.longitude,
    }
  }, [location])

  const errorMessage = useMemo(() => {
    if (!errors || !errors[name] || !errors[name]?.message) {
      return ''
    }

    return errors[name]?.message
  }, [errors, name])

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'en',
    options: {
      types: ['establishment'],
    },
    onPlaceSelected: (place) => {
      if (place?.formatted_address) {
        setValue(name, place.formatted_address)
        setValue(USER_INPUT_FIELD_KEY, null)
      }

      const lat = place.geometry?.location?.lat()
      const lng = place.geometry?.location?.lng()

      if (lat && lng) {
        setValue(LOCATION_FIELD_KEY, {
          latitude: lat,
          longitude: lng,
        })
      } else {
        setValue(LOCATION_FIELD_KEY, null)
      }
    },
  })

  const { placesService } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setValue(LOCATION_FIELD_KEY, {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      })
    }

    // @ts-ignore
    const { placeId } = e

    if (placeId) {
      placesService?.getDetails(
        {
          placeId,
        },
        (placeDetails) => {
          if (placeDetails?.formatted_address) {
            if (ref?.current) {
              ref.current.value = placeDetails.formatted_address
            }
            setValue(name, placeDetails?.formatted_address)
            setValue(USER_INPUT_FIELD_KEY, null)
          }
        }
      )
    } else {
      if (ref?.current && e.latLng) {
        setValue(name, '')
        ref.current.value = formatLatLng(e.latLng.lat(), e.latLng.lng())
      }
    }
  }

  return (
    <>
      <Styled.InputContainer>
        <FormInputLabel htmlFor={name}> {label} </FormInputLabel>
        <Styled.Input
          id={name}
          name={name}
          defaultValue={values[name] || formatLatLng(location?.latitude, location?.longitude)}
          onInput={({ currentTarget: { value } }) => {
            setValue(name, '')
            setValue(USER_INPUT_FIELD_KEY, value)
            setValue(LOCATION_FIELD_KEY, null)
          }}
          ref={ref}
          {...inputProps}
        />
        <FormInputError>{errorMessage as string}</FormInputError>
      </Styled.InputContainer>
      <GoogleMaps center={markerPosition} onMapClick={handleMapClick}>
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMaps>
    </>
  )
}
