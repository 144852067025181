export const haversineDistance = (mk1: any, mk2: any) => {
  if (!mk1 || !mk2 || !mk1.latitude || !mk1.longitude || !mk2.latitude || !mk2.longitude) {
    return -1
  }

  const R = 6371 // Radius of the Earth in km
  const rlat1 = Number(mk1.latitude) * (Math.PI / 180) // Convert degrees to radians
  const rlat2 = Number(mk2.latitude) * (Math.PI / 180) // Convert degrees to radians
  const difflat = rlat2 - rlat1 // Radian difference (latitudes)
  const difflon = (Number(mk2.longitude) - Number(mk1.longitude)) * (Math.PI / 180) // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    )
  return d
}
