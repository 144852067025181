import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'
import { TabPanel, Tabs } from 'react-tabs'
import styled from 'styled-components/macro'

import { CountItem, TabCs, TabListCs } from '../../components/Tabs'
// import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { List as EventList } from '../../modules/Events/screens/List'
import { POAPList } from '../../modules/POAP/components/POAPList'
import Header from './components/Header'
import NotConnected from './components/NotConnected'
import TabCollected from './TabCollected'
import TabLiked from './TabLiked'
import TabWatchlist from './TabWatchlist'
export interface dataTabs {
  image?: string
  title?: string
  desc?: string
  author?: string
  createAt?: string
  recent?: boolean
  eth?: string
  type?: string
  status?: boolean
}
const Profile = () => {
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()
  const { address } = useParams<any>()
  const { location } = useHistory()
  const { hash } = location
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [totalItemsCollected, setTotalItemsCollected] = useState<number>(0)
  const [totalItemsLiked, setTotalItemsLiked] = useState<number>(0)
  const [totalItemsWatchlist, setTotalItemsWatchlist] = useState<number>(0)
  const getTotalItemsCollected = (totalItems: number) => {
    setTotalItemsCollected(totalItems)
  }
  const getTotalItemsLiked = (totalItems: number) => {
    setTotalItemsLiked(totalItems)
  }
  const getTotalItemsWatchlist = (totalItems: number) => {
    setTotalItemsWatchlist(totalItems)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  useEffect(() => {
    const hashName = hash.split('=')[1]
    switch (hashName) {
      case 'collected':
        setTabIndex(0)
        break
      case 'likes':
        setTabIndex(1)
        break
      case 'watchlist':
        setTabIndex(2)
        break
      case 'poaps':
        setTabIndex(3)
        break
      case 'events':
        setTabIndex(4)
        break
      default:
        setTabIndex(0)
    }
  }, [hash])

  return address || account ? (
    <div style={{ width: '100%' }}>
      <Header />
      <BoxTabs id="tab">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabListCs style={{ marginTop: '32px', marginBottom: '40px' }}>
            <TabCs>
              <RouterLink to={{ hash: '#tab?tab=collected' }}>
                <TabName>Collected</TabName>
                <CountItem className={'count_item'}>{totalItemsCollected > 0 && totalItemsCollected}</CountItem>
              </RouterLink>
            </TabCs>
            <TabCs>
              <RouterLink to={{ hash: '#tab?tab=likes' }}>
                <TabName>Liked</TabName>
                <CountItem className={'count_item'}>{totalItemsLiked > 0 && totalItemsLiked}</CountItem>
              </RouterLink>
            </TabCs>
            <TabCs>
              <RouterLink to={{ hash: '#tab?tab=watchlist' }}>
                <TabName>Watchlist</TabName>
                <CountItem className={'count_item'}>{totalItemsWatchlist > 0 && totalItemsWatchlist}</CountItem>
              </RouterLink>
            </TabCs>
            <TabCs>
              <RouterLink to={{ hash: '#tab?tab=poaps' }}>
                <TabName>POAPs</TabName>
              </RouterLink>
            </TabCs>
            <TabCs>
              <RouterLink to={{ hash: '#tab?tab=events' }}>
                <TabName>Events</TabName>
              </RouterLink>
            </TabCs>
          </TabListCs>

          <TabPanel>
            <TabCollected account={account} address={address} getTotalItems={getTotalItemsCollected} />
          </TabPanel>
          <TabPanel>
            <TabLiked account={account} address={address} getTotalItems={getTotalItemsLiked} />
          </TabPanel>
          <TabPanel>
            <TabWatchlist account={account} address={address} getTotalItems={getTotalItemsWatchlist} />
          </TabPanel>
          <TabPanel>
            <POAPList />
          </TabPanel>
          <TabPanel>
            <EventList />
          </TabPanel>
        </Tabs>
      </BoxTabs>
    </div>
  ) : (
    <NotConnected />
  )
}

const BoxTabs = styled.div`
  width: 100%;
  padding: 0 160px;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    .tab-list__cs {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }
`
const TabName = styled.span`
  margin: 0;
  font-size: 24px;
  font-family: 'DIN 1451 Std', sans-serif;
  white-space: nowrap;
`
export default Profile
