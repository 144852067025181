/* eslint-disable */

export interface SalePeriodType {
  saleAddress: string
  index: number
  saleType: number
  timeFrom: number
  timeTo: number
  merkleRoot?: string
  maxAllocation: number
  maxPerMint: number
  totalMinted: number
  maxPerPeriod: number
  price: string
  isValid: boolean
}

export interface SaleType {
  address?: string
  saleType: number
  status: number
  periods: SalePeriodType[]
}

export function getNextPeriod(sale: SaleType): SalePeriodType | undefined {
  if (!sale.periods) {
    return undefined
  }
  const nowTs = Math.floor(Date.now() / 1000)
  let nextPeriod: SalePeriodType | undefined = undefined
  for (const period of sale.periods) {
    if (period.timeFrom >= nowTs) {
      if (nextPeriod === undefined || nextPeriod.timeFrom > period.timeFrom) {
        nextPeriod = period
      }
    }
  }
  return nextPeriod
}

export function getActivePeriod(sale: SaleType): SalePeriodType | undefined {
  if (!sale.periods) {
    return undefined
  }
  const nowTs = Math.floor(Date.now() / 1000)
  return sale.periods.find((period: SalePeriodType) => {
    return period.timeFrom <= nowTs && period.timeTo >= nowTs
  })
}
