import * as Yup from 'yup'

import { isAddress } from '../../../../utils'

export const schema = Yup.object().shape({
  userAddress: Yup.string()
    .test('Valid address wallet', 'Invalid address wallet', (val: string | undefined) => {
      if (!val) {
        return false
      }
      const parsed = isAddress(val)

      return Boolean(parsed)
    })
    .required('This field cannot be empty'),
  role: Yup.object().shape({
    value: Yup.string().required('This field cannot be empty'),
  }),
})
