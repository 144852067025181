import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

export const SidebarAdminMain = styled.div`
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(
      59.01% 242.73% at 50% 50%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(122, 123, 123, 0.14) 0.01%,
      rgba(175, 175, 175, 0.022) 100%
    );
  padding: 32px;
`

export const SidebarHeaderWrapper = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.secondary4};
  text-align: center;
  margin-bottom: 32px;
`

export const SidebarWelcomeText = styled.p`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.textFollow};
  font-weight: 500;
`

export const SidebarHeader = styled.h2`
  font-family: 'DIN 1451 Std', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  margin: 0 0 12px 0;
  color: ${({ theme }) => theme.primary6};
  text-transform: uppercase;
`

export const SidebarMenuItemList = styled.div`
  display: flex;
  flex-direction: column;
`

export const SidebarMenuItem = styled(NavLink)`
  padding: 19px 26px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.textFollow};
  background-color: transparent;
  font-size: ${({ theme }) => theme.fontSizeText1}
  font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    color: #ffffff;
  }
  
  &.selected {
    background-color: ${({ theme }) => theme.primary1};
    color: #ffffff;
    text-transform: uppercase;
  }
`

export const SidebarMenuItemIcon = styled.div`
  margin-right: 16px;
`

export const ItemPanelName = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText1} !important;
  line-height: 1.3;
  margin: 0;
`
export const SidebarMenuSubItem = styled(NavLink)`
  padding: 6px 26px 6px 58px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.textFollow};
  background-color: transparent;
  font-size: ${({ theme }) => theme.fontSizeText1}
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 6px;

  &:hover {
    color: #ffffff;
  }
  
  &.selected {
    color: #ffffff;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 36px;
      width: 4px;
      height: 20px;
      background-color: ${({ theme }) => theme.primary3};
      z-index: 5;
    }
  }
`
