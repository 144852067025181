import FileAttachment from 'assets/icons/file-attachment.svg'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { BtnSubmit } from 'components/Button'
import ColCs from 'components/ColCs'
import { ErrorMessage, FormGroup, FormInput, FormLabel } from 'components/Forms'
import IconPortal from 'components/IconPortal'
import RowCs from 'components/RowCs'
import { useApi } from 'hooks/useApi'
import { parse } from 'papaparse'
import React, { useRef, useState } from 'react'
import { useUploadWhitelistMutation } from 'state/collections/slice'
import styled from 'styled-components/macro'
import { isAddress } from 'utils'

import ModalConfirm from '../../../../../components/Modal/ModalConfirm'
import ModalError from '../../../../../components/Modal/ModalError'
import ModalSuccess from '../../../../../components/Modal/ModalSuccess'
interface fileWhiteList {
  name: string
  type: string
}
interface PropsType {
  refetchData: any
  selectedCollection: any
}
const UploadWhitelist = ({ selectedCollection, refetchData }: PropsType) => {
  const { post } = useApi()

  const [fileWhiteList, setFileWhiteList] = useState<any>(null)
  const [dataCSV, setDataCSV] = useState<string[]>([])
  const [valueAddress, setValueAddress] = useState<string>('')

  const [uploadWhiteList] = useUploadWhitelistMutation()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const refFile = useRef<any>(null)
  const handleFileUpload = (file: any) => {
    if (file && file.type === 'text/csv') {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', async () => {
        const fileObj: fileWhiteList = {
          name: file.name,
          type: file.type,
        }
        const content = await file.text()
        const result = parse(content, { header: true })
        const arrayAddress: string[] = []
        result.data.forEach((item: any) => {
          if ('address' in item && item.address.trim() !== '') {
            arrayAddress.push(item['address'].toLowerCase())
          }
        })
        setFileWhiteList(fileObj)
        setDataCSV(arrayAddress)
      })
    }
  }
  const handleDropFile = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const dt = event.dataTransfer
    const file = dt.files[0]
    handleFileUpload(file)
  }
  const handleChangeUploadFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    handleFileUpload(file)
  }
  const onDragEnter = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const onDragOver = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleSuccess = () => {
    setShowSuccess(true)
    refetchData()
  }
  const handleError = () => {
    setShowError(true)
    setShowConfirm(false)
    // setShowSuccess(false)
  }
  const handleFinally = () => {
    setShowConfirm(false)
    setValueAddress('')
  }
  const handleAddWhiteList = async () => {
    const finalList: string[] = Array.from(new Set([...dataCSV, valueAddress])).filter((item: string) => {
      return item.trim() !== ''
    })
    if (finalList.length === 0) {
      return
    }

    const tt = finalList.some((item: string) => !isAddress(item))

    if (tt === true) {
      setErrorMessage('The file contains an invalid wallet address. Please check and try again.')
      handleError()
      // console.log('there is an invalid ')
    } else {
      setErrorMessage('Could not be uploaded, please try again.')
      await uploadWhiteList({
        collectionAddress: selectedCollection.value,
        whitelists: finalList,
        post,
        handleSuccess,
        handleError,
        handleFinally,
      })
    }
  }
  const handleDeleteFile = (event: any) => {
    event.preventDefault()
    setDataCSV([])
    refFile.current.value = ''
  }

  return (
    <Wrapper>
      <RowCs>
        <ColCs md={6} xs={6} className={'form-col'}>
          <FormGroup>
            <FormLabel>Add users</FormLabel>
            <FormInput
              type={'text'}
              placeholder={'Enter an address'}
              id={'address'}
              name={'address'}
              value={valueAddress}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValueAddress(event.currentTarget.value.trim().toLowerCase())
              }}
            />
            {valueAddress && !isAddress(valueAddress) && <ErrorMessage>Wallet address is invalid</ErrorMessage>}
          </FormGroup>
        </ColCs>
        <ColCs md={6} xs={6} className={'form-col'}>
          <div onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={handleDropFile} className={'box-file'}>
            <input
              type="file"
              id="file_upload"
              accept={'.csv'}
              hidden
              name={'file_upload'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeUploadFile(e)}
              ref={refFile}
            />
            <BtnUploadCSV htmlFor={'file_upload'}>
              <IconPortal SrcImageIcon={FileAttachment} widthIcon={'20px'} heightIcon={'20px'} />
              {dataCSV.length === 0 ? (
                'Upload spreadsheet/CSV file'
              ) : (
                <div style={{ textAlign: 'left', width: '100%' }}>{fileWhiteList.name}</div>
              )}
            </BtnUploadCSV>
            {dataCSV.length !== 0 && (
              <span onClick={handleDeleteFile} className={'icon-delete-file'}>
                <IconClose />
              </span>
            )}
          </div>
        </ColCs>
      </RowCs>
      <BtnSubmit
        onClick={() => setShowConfirm(true)}
        disabled={(valueAddress && !isAddress(valueAddress)) || (valueAddress.trim() === '' && dataCSV.length === 0)}
      >
        Add to whitelist
      </BtnSubmit>
      <ModalConfirm
        isOpen={showConfirm}
        onDismiss={() => setShowConfirm(false)}
        submit={() => handleAddWhiteList()}
        subText={'Are you sure you want to add to whitelist?'}
      />
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={errorMessage} />
      <ModalSuccess isOpen={showSuccess} onDismiss={() => setShowSuccess(false)} message={'Add successfully'} />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  .box-file {
    position: relative;
    .icon-delete-file {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .example-csv {
    color: ${({ theme }) => theme.primary6};
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.25;
    margin-top: 12px;
    display: block;
  }
`

const BtnUploadCSV = styled.label`
  padding: 12px 30px 12px 16px;
  background-color: ${({ theme }) => theme.secondary1};
  border: solid 2px ${({ theme }) => theme.secondary2};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 32px;
  color: ${({ theme }) => theme.secondary5};
`

export default UploadWhitelist
