import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import abi from '../../../abis/partner-registry-abi.json'

interface UseCreateEventInRegistryProps {
  eventId: number
  startTime: number
  endTime: number
  rewardPerFlexer: string
  tokenRewardPool: string
  isPOAPEnabled: boolean
}

export const useUpdateEventInRegistry = (options?: UseMutationOptions<string, any, UseCreateEventInRegistryProps>) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, UseCreateEventInRegistryProps>(
    async ({ eventId, startTime, endTime, rewardPerFlexer, tokenRewardPool, isPOAPEnabled }) => {
      if (!provider) {
        throw new Error('NOT_CONNECTED_TO_METAMASK')
      }

      const contract = new Contract(process.env.REACT_APP_PARTNER_REGISTRY_ADDRESS as string, abi, provider)

      const tx = await contract.modifyEvent(
        eventId,
        startTime,
        endTime,
        rewardPerFlexer,
        tokenRewardPool,
        isPOAPEnabled
      )

      const receipt = await tx.wait()

      const EVENT_MODIFIED_INDEX = 0
      const EVENT_ID_ARGS_INDEX = 1

      return receipt.events[EVENT_MODIFIED_INDEX].args[EVENT_ID_ARGS_INDEX].toString()
    },
    options
  )
}
