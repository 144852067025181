import { yupResolver } from '@hookform/resolvers/yup'
import { parseUnits } from 'ethers/lib/utils'
import { FormProvider, useForm } from 'react-hook-form'

import { ButtonPrimary } from '../../../../components/Button'
import { FormInput } from '../../../../components/FormInput'
import Loader from '../../../../components/Loader'
import { preventNonNumbersOnKeyPress } from '../../../../utils/preventNonNumbersOnKeyPress'
import { useSendMatic } from '../../hooks/useSendMatic'
import { schema } from './schema'

export interface SendMaticFormValues {
  amount: string
}

interface SendMaticFormProps {
  clerkAddress: string
  onSuccess: () => void
}

export const SendMaticForm = ({ clerkAddress, onSuccess }: SendMaticFormProps) => {
  const formValues = useForm({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formValues

  const { mutateAsync: sendMatic } = useSendMatic()

  const handleDepositTokens = async (values: SendMaticFormValues) => {
    try {
      await sendMatic({
        amount: parseUnits(values.amount).toString(),
        address: clerkAddress,
      })
    } finally {
      onSuccess()
    }
  }

  return (
    <FormProvider {...formValues}>
      <form onSubmit={handleSubmit(handleDepositTokens)}>
        <FormInput name="amount" label="Amount" autoFocus onKeyPress={preventNonNumbersOnKeyPress} />
        <ButtonPrimary type="submit">
          {isSubmitting ? <Loader stroke="#ffffff" size="24px" /> : 'Send Matic'}
        </ButtonPrimary>
      </form>
    </FormProvider>
  )
}
