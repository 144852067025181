import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const gsapAnimations = (gsEle: any, isFadeInOut = false) => {
  const animateFrom = (elem: any, direction: any) => {
    direction = direction || 1
    let x = 0,
      y = direction * (isFadeInOut ? 0 : 100)

    if (elem.classList.contains('gs_reveal_fromLeft')) {
      x = -100
      y = 0
    } else if (elem.classList.contains('gs_reveal_fromRight')) {
      x = 100
      y = 0
    }

    elem.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
    elem.style.opacity = '0'

    gsap.fromTo(
      elem,
      { x, y, autoAlpha: 0 },
      {
        duration: isFadeInOut ? 5 : 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: 'circ.out',
        overwrite: 'auto',
      }
    )
  }

  gsap.utils.toArray(gsEle).forEach(function (elem: any) {
    ScrollTrigger.create({
      trigger: elem,
      onEnter: () => animateFrom(elem, 0),
      onEnterBack: () => animateFrom(elem, 1),
    })
  })
}

export default gsapAnimations
