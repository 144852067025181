import useDocumentTitle from 'hooks/useDocumentTitle'
import React from 'react'
import styled from 'styled-components/macro'

import FormProfile from './FormProfile'

const EditProfile = () => {
  useDocumentTitle(`Profile details | Another-1 Launchpad`)
  return (
    <SectionEdit>
      <DivMain>
        <FormProfile />
      </DivMain>
    </SectionEdit>
  )
}
const SectionEdit = styled.div`
  width: 100%;
`
const DivMain = styled.div`
  width: 100%;
`
export default EditProfile
