import React from 'react'
import { useParams } from 'react-router-dom'

import { routes } from '../../../../constants/routes'
import { useGetProfile } from '../../../../state/profile/hooks'
import { ClerkForm } from '../../../Clerks/components/ClerkForm'
import { CreateEventStepContainer } from '../../components/CreateEventStepContainer'
import { FormHeader } from '../../components/EventForm/styled'

interface AddClerksUrlParams {
  eventId: string
}

export const AddClerks = () => {
  const { eventId } = useParams<AddClerksUrlParams>()
  const { data: profile } = useGetProfile()

  return (
    <CreateEventStepContainer>
      <FormHeader> Add Clerks to your Event </FormHeader>
      <ClerkForm
        eventId={eventId as string}
        continueSkipRoute={routes.partnerPanel.addCollection
          .replace(':eventId', eventId)
          .replace(':partnerAddress', profile.address)}
      />
    </CreateEventStepContainer>
  )
}
