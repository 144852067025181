import 'moment-timezone'

import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { formatUnits } from '@ethersproject/units'
import { CustomLabel, PrimaryCheckbox } from 'components/CheckBox'
import { DatePickerDiv, FormGroup, FormInput, FormLabel, InputContainer, WarningDefineTime } from 'components/Forms'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { OptionType } from 'entities/OptionType'
import { useApi } from 'hooks/useApi'
import useParticle from 'hooks/useParticle'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import Select, { SingleValue } from 'react-select'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

import abi from '../../../../abis/default-an1-sale.json'
import IconInfo from '../../../../assets/icons/icon-info-circle.svg'
import { BtnSubmit } from '../../../../components/Button'
import ColCs from '../../../../components/ColCs'
import IconPortal from '../../../../components/IconPortal'
import RowCs from '../../../../components/RowCs'
import { customStylesSelect } from '../../../../theme'
import { getContractWithoutSigner, setLocalZone, setOtherZone } from '../../../../utils'
import { checkDateGreater } from '../../../../utils/helper'

const sale_is_inactive = 'Private sale is already inactive'

const SaleManagement = (props: any) => {
  const { collectionOptions, selectedCollection, setSelectedCollection, isSelectDisabled } = props
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  const { get, post } = useApi()

  //private sale
  const today = moment.utc().format('YYYY-MM-DD HH:mm:ss')
  const todayUTC = new Date(today)

  const todayUTCHours = todayUTC.getHours()
  const [startDatePrivate, setStartDatePrivate] = useState<Date | null>(null)
  const [endDatePrivate, setEndDatePrivate] = useState<Date | null>(null)
  const [checkEndDate, setCheckEndDate] = useState<boolean>(false)
  const [checkStartDate, setCheckStartDate] = useState<boolean>(false)
  const [isUpdateWhitelist, setIsUpdateWhitelist] = useState<boolean>(false)

  const [startDatePublic, setStartDatePublic] = useState<Date | null>(null)
  const [endDatePublic, setEndDatePublic] = useState<Date | null>(null)
  const [checkEndDatePublic, setCheckEndDatePublic] = useState<boolean>(false)
  const [checkStartDatePublic, setCheckStartDatePublic] = useState<boolean>(false)
  const [salesContract, setSalesContract] = useState<Contract | null>(null)
  const [privateSale, setPrivateSale] = useState<any>(null)
  const [publicSale, setPublicSale] = useState<any>(null)

  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const [updatedPeriods, setUpdatedPeriods] = useState<any>(null)

  const [isPublicSaleActive, setIsPublicSaleActive] = useState<boolean>(true)
  const [isPrivateSaleActive, setIsPrivateSaleActive] = useState<boolean>(true)

  useEffect(() => {
    if (selectedCollection.value && provider) {
      get(
        {
          url: `${process.env.REACT_APP_SERVER_URL}admin-panel/update-sale/${selectedCollection.value}`,
          callback: (result: any) => {
            const contract = getContractWithoutSigner(result.data, abi, provider)
            setSalesContract(contract)
            contract
              .listSalePeriods()
              .then((res: any) => {
                setUpdatedPeriods(null)

                const privateSale = res.find((x: any) => x.saleType == 0)
                const publicSale = res.find((x: any) => x.saleType == 1)

                setPrivateSale(privateSale)
                setPublicSale(publicSale)

                const startDatePrivate = new Date(BigNumber.from(privateSale.timeFrom).toNumber() * 1000)
                const endDatePrivate = new Date(BigNumber.from(privateSale.timeTo).toNumber() * 1000)
                const startDatePublic = new Date(BigNumber.from(publicSale.timeFrom).toNumber() * 1000)
                const endDatePublic = new Date(BigNumber.from(publicSale.timeTo).toNumber() * 1000)

                setStartDatePrivate(startDatePrivate)
                setEndDatePrivate(endDatePrivate)
                setStartDatePublic(startDatePublic)
                setEndDatePublic(endDatePublic)

                setIsPrivateSaleActive(todayUTC.getTime() <= endDatePrivate.getTime())
                setIsPublicSaleActive(todayUTC.getTime() <= endDatePublic.getTime())
              })
              .catch((e: any) => console.log(e))
          },
        },
        true
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection.value, provider])

  const handleStartDateChange = (date: Date | null, saleType: 0 | 1) => {
    const { greaterDay, greaterHour }: any = checkDateGreater(date, todayUTC)
    if (date)
      switch (saleType) {
        case 0:
          setStartDatePrivate(date)
          setUpdatedPeriods({ ...updatedPeriods, 0: true })
          setPrivateSale({ ...privateSale, timeFrom: Math.floor(date.getTime() / 1000) })
          if (greaterDay) {
            setCheckStartDate(true)
          } else if (!greaterDay && greaterHour) {
            setCheckStartDate(false)
          } else {
            setCheckStartDate(false)
            setStartDatePrivate(todayUTC)
            setPrivateSale({ ...privateSale, timeFrom: Math.floor(todayUTC.getTime() / 1000) })
          }
          break

        case 1:
          setStartDatePublic(date)
          setUpdatedPeriods({ ...updatedPeriods, 1: true })
          setPublicSale({ ...publicSale, timeFrom: Math.floor(date.getTime() / 1000) })
          if (greaterDay) {
            setCheckStartDatePublic(true)
          } else if (!greaterDay && greaterHour) {
            setCheckStartDatePublic(false)
          } else {
            setCheckStartDatePublic(false)
            setStartDatePublic(todayUTC)
            console.log(todayUTC, todayUTC.getTime())
            setPublicSale({ ...publicSale, timeFrom: Math.floor(todayUTC.getTime() / 1000) })
          }
          break
      }
  }

  const handleEndDateChange = (date: Date | null, saleType: 0 | 1) => {
    const { greaterDay, greaterHour }: any = checkDateGreater(date, todayUTC)
    if (date)
      switch (saleType) {
        case 0:
          setUpdatedPeriods({ ...updatedPeriods, 0: true })
          setEndDatePrivate(date)
          setPrivateSale({ ...privateSale, timeTo: Math.floor(date.getTime() / 1000) })
          if (greaterDay) {
            setCheckEndDate(true)
          } else if (!greaterDay && greaterHour) {
            setCheckEndDate(false)
          } else {
            setCheckEndDate(false)
            setEndDatePrivate(todayUTC)
            setPrivateSale({ ...privateSale, timeTo: Math.floor(todayUTC.getTime() / 1000) })
          }
          break

        case 1:
          setUpdatedPeriods({ ...updatedPeriods, 1: true })
          setEndDatePublic(date)
          setPublicSale({ ...publicSale, timeTo: Math.floor(date.getTime() / 1000) })
          if (greaterDay) {
            setCheckEndDatePublic(true)
          } else if (!greaterDay && greaterHour) {
            setCheckEndDatePublic(false)
          } else {
            setCheckEndDatePublic(false)
            setEndDatePublic(todayUTC)
            setPublicSale({ ...publicSale, timeTo: Math.floor(todayUTC.getTime() / 1000) })
          }
          break
      }
  }

  const handleLimitPerMintChange = (value: string, saleType: 0 | 1) => {
    switch (saleType) {
      case 0:
        setUpdatedPeriods({ ...updatedPeriods, 0: true })
        setPrivateSale({ ...privateSale, maxPerMint: value })
        break
      case 1:
        setUpdatedPeriods({ ...updatedPeriods, 1: true })
        setPublicSale({ ...publicSale, maxPerMint: value })
        break
    }
  }

  const handleLimitPerPeriodChange = (value: string, saleType: 0 | 1) => {
    switch (saleType) {
      case 0:
        setUpdatedPeriods({ ...updatedPeriods, 0: true })
        setPrivateSale({ ...privateSale, maxPerPeriod: value })
        break
      case 1:
        setUpdatedPeriods({ ...updatedPeriods, 1: true })
        setPublicSale({ ...publicSale, maxPerPeriod: value })
        break
    }
  }

  const handleMaxAllocationChange = (value: string, saleType: 0 | 1) => {
    switch (saleType) {
      case 0:
        setUpdatedPeriods({ ...updatedPeriods, 0: true })
        setPrivateSale({ ...privateSale, maxAllocation: value })
        break
      case 1:
        setUpdatedPeriods({ ...updatedPeriods, 1: true })
        setPublicSale({ ...publicSale, maxAllocation: value })
        break
    }
  }

  const handlePriceChange = (value: string, saleType: 0 | 1) => {
    switch (saleType) {
      case 0:
        setUpdatedPeriods({ ...updatedPeriods, 0: true })
        setPrivateSale({ ...privateSale, price: value })
        break
      case 1:
        setUpdatedPeriods({ ...updatedPeriods, 1: true })
        setPublicSale({ ...publicSale, price: value })
        break
    }
  }

  const handleWhitelistChange = (value: boolean) => {
    setUpdatedPeriods({ ...updatedPeriods, 0: true })
    setIsUpdateWhitelist(value)
  }

  const handleUpdate = () => {
    const _sales: any[] = []

    if (!updatedPeriods) {
      setShowError(true)
      setErrorMessage('You did not make any changes in sales')
      setShowConfirm(false)
      return
    }

    if (updatedPeriods[0])
      _sales.push({
        maxPerMint: privateSale.maxPerMint,
        maxPerPeriod: privateSale.maxPerPeriod,
        merkleRoot: privateSale.merkleRoot,
        price: privateSale.price,
        saleType: privateSale.saleType,
        timeFrom: privateSale.timeFrom,
        timeTo: privateSale.timeTo,
        maxAllocation: privateSale.maxAllocation,
        totalMinted: privateSale.totalMinted,
        isValid: privateSale.isValid,
        updateWhitelist: isUpdateWhitelist,
      })

    if (updatedPeriods[1])
      _sales.push({
        maxPerMint: publicSale.maxPerMint,
        maxPerPeriod: publicSale.maxPerPeriod,
        merkleRoot: publicSale.merkleRoot,
        price: publicSale.price,
        saleType: publicSale.saleType,
        timeFrom: publicSale.timeFrom,
        timeTo: publicSale.timeTo,
        maxAllocation: publicSale.maxAllocation,
        totalMinted: publicSale.totalMinted,
        isValid: publicSale.isValid,
      })

    post(
      {
        url: `${process.env.REACT_APP_SERVER_URL}admin-panel/update-sale/${selectedCollection.value}/${
          salesContract!.address
        }`,
        data: {
          sales: _sales,
        },
        callback: () => {
          setShowSuccess(true)
        },
        callbackFinally: () => {
          setShowConfirm(false)
        },
        errorCallback: (error) => {
          error.response?.data?.includes('Period is already inactive.')
            ? setErrorMessage(sale_is_inactive)
            : setErrorMessage('Something happened')
          setShowError(true)
        },
      },
      true
    )
  }

  const handleChangeSelectedCollection = (collection: SingleValue<OptionType>) => {
    setSelectedCollection(collection as OptionType)
  }

  const handleConfirm = () => {
    setShowConfirm(true)
  }

  return (
    <Wrapper>
      <Title>Sale Management</Title>
      <SectionCollection>
        <FormGroup>
          <FormLabel>Collection</FormLabel>
          <FormSelect>
            {selectedCollection?.value &&
              ((isSelectDisabled && <h3>{selectedCollection.label}</h3>) || (
                <Select
                  styles={customStylesSelect}
                  defaultValue={selectedCollection}
                  options={collectionOptions}
                  classNamePrefix={'select-cs'}
                  id="collectionsOption"
                  name="collectionsOption"
                  onChange={handleChangeSelectedCollection}
                />
              ))}
          </FormSelect>
        </FormGroup>
      </SectionCollection>
      {privateSale && (
        <SectionSale>
          <TitleSale>Private Sale</TitleSale>
          <RowCs space={-16}>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>Mint limit per transaction</FormLabel>
                <FormInput
                  type={'number'}
                  id={'limitTxPr'}
                  name={'limitTxPr'}
                  disabled={!isPrivateSaleActive}
                  value={BigNumber.from(privateSale.maxPerMint || 0).toNumber()}
                  placeholder={'Mint limit per transaction'}
                  onChange={(e) => {
                    handleLimitPerMintChange(e.target.value, 0)
                  }}
                />
              </FormGroup>
            </ColCs>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>Mint limit per wallet</FormLabel>
                <FormInput
                  type={'number'}
                  id={'limitWalletPr'}
                  name={'limitWalletPr'}
                  disabled={!isPrivateSaleActive}
                  value={BigNumber.from(privateSale.maxPerPeriod || 0).toNumber()}
                  placeholder={'Mint limit per wallet'}
                  onChange={(e) => {
                    handleLimitPerPeriodChange(e.target.value, 0)
                  }}
                />
              </FormGroup>
            </ColCs>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>Max limit per stage</FormLabel>
                <FormInput
                  type={'number'}
                  id={'limitAllocPr'}
                  name={'limitAllocPr'}
                  disabled={!isPrivateSaleActive}
                  value={BigNumber.from(privateSale.maxAllocation || 0).toNumber()}
                  placeholder={'Max limit per stage'}
                  onChange={(e) => {
                    handleMaxAllocationChange(e.target.value, 0)
                  }}
                />
              </FormGroup>
            </ColCs>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>NFT price</FormLabel>
                <InputContainer>
                  <FormInput
                    type={'number'}
                    id={'pricePr'}
                    name={'pricePr'}
                    disabled={!isPrivateSaleActive}
                    value={
                      BigNumber.isBigNumber(privateSale?.price)
                        ? formatUnits(privateSale?.price | 0, 6)
                        : privateSale?.price
                    }
                    placeholder={'NFT price'}
                    onChange={(e) => {
                      handlePriceChange(e.target.value, 0)
                    }}
                  />
                  USDT
                </InputContainer>
              </FormGroup>
            </ColCs>
            <ColCs xs={12} sm={4} gutter={16}>
              <FormLabel className={`time-utc ${isPrivateSaleActive ? `` : `disabled`}`}>Start time</FormLabel>
              <DatePickerDiv>
                <LabelBox>
                  <DatePicker
                    selected={setLocalZone(startDatePrivate)}
                    onChange={(e) => {
                      handleStartDateChange(setOtherZone(e), 0)
                    }}
                    showTimeSelect
                    minDate={todayUTC}
                    disabled={!isPrivateSaleActive}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minTime={
                      checkStartDate
                        ? setHours(setMinutes(todayUTC, 0), 0)
                        : setHours(setMinutes(todayUTC, 30), todayUTCHours)
                    }
                    maxTime={setHours(setMinutes(todayUTC, 30), 23)}
                  />
                </LabelBox>
              </DatePickerDiv>
            </ColCs>
            <ColCs xs={12} sm={4} gutter={16}>
              <FormLabel className={`time-utc ${isPrivateSaleActive ? `` : `disabled`}`}>End time</FormLabel>
              <DatePickerDiv>
                <LabelBox>
                  <DatePicker
                    selected={setLocalZone(endDatePrivate)}
                    onChange={(e) => handleEndDateChange(setOtherZone(e), 0)}
                    showTimeSelect
                    minDate={todayUTC}
                    disabled={!isPrivateSaleActive}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minTime={
                      checkEndDate
                        ? setHours(setMinutes(todayUTC, 0), 0)
                        : setHours(setMinutes(todayUTC, 30), todayUTCHours)
                    }
                    maxTime={setHours(setMinutes(todayUTC, 30), 23)}
                  />
                </LabelBox>
              </DatePickerDiv>
            </ColCs>
          </RowCs>
          <WarningDefineTime>
            <IconPortal widthIcon={'20px'} heightIcon={'20px'} SrcImageIcon={IconInfo} />
            <span>The defined dates are set based on UTC timestamp</span>
          </WarningDefineTime>
          <ChooseWhitelist>
            <FormGroup>
              <FormLabel>Update Whitelist?</FormLabel>
              <CustomLabel>
                <PrimaryCheckbox
                  id="terms"
                  name="terms"
                  disabled={!isPrivateSaleActive}
                  onChange={(e) => handleWhitelistChange(e.target.checked)}
                  defaultChecked={isUpdateWhitelist}
                />
                <Text ml="2">Check if you want to update the Whitelist for this Private sale.</Text>
              </CustomLabel>
            </FormGroup>
          </ChooseWhitelist>
        </SectionSale>
      )}
      {publicSale && (
        <SectionSale>
          <TitleSale>Public Sale</TitleSale>
          <RowCs space={-16}>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>Mint limit per transaction</FormLabel>
                <FormInput
                  type={'number'}
                  id={'limitTxPr'}
                  name={'limitTxPr'}
                  disabled={!isPublicSaleActive}
                  value={BigNumber.from(publicSale.maxPerMint || 0).toNumber()}
                  placeholder={'Mint limit per transaction'}
                  onChange={(e) => {
                    handleLimitPerMintChange(e.target.value, 1)
                  }}
                />
              </FormGroup>
            </ColCs>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>Mint limit per wallet</FormLabel>
                <FormInput
                  type={'number'}
                  id={'limitWalletPr'}
                  name={'limitWalletPr'}
                  disabled={!isPublicSaleActive}
                  value={BigNumber.from(publicSale.maxPerPeriod || 0).toNumber()}
                  placeholder={'Mint limit per wallet'}
                  onChange={(e) => {
                    handleLimitPerPeriodChange(e.target.value, 1)
                  }}
                />
              </FormGroup>
            </ColCs>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>Max limit per stage</FormLabel>
                <FormInput
                  type={'number'}
                  id={'limitAllocPub'}
                  name={'limitAllocPub'}
                  disabled={!isPublicSaleActive}
                  value={BigNumber.from(publicSale.maxAllocation || 0).toNumber()}
                  placeholder={'Max limit per stage'}
                  onChange={(e) => {
                    handleMaxAllocationChange(e.target.value, 1)
                  }}
                />
              </FormGroup>
            </ColCs>
            <ColCs gutter={16} xs={12} sm={4}>
              <FormGroup>
                <FormLabel>NFT price</FormLabel>
                <InputContainer>
                  <FormInput
                    type={'number'}
                    id={'pricePb'}
                    name={'pricePb'}
                    disabled={!isPublicSaleActive}
                    value={
                      BigNumber.isBigNumber(publicSale?.price)
                        ? formatUnits(publicSale?.price | 0, 6)
                        : publicSale?.price
                    }
                    placeholder={'NFT price'}
                    onChange={(e) => {
                      handlePriceChange(e.target.value, 1)
                    }}
                  />
                  USDT
                </InputContainer>
              </FormGroup>
            </ColCs>
            <ColCs xs={12} sm={4} gutter={16}>
              <FormLabel className={`time-utc ${isPublicSaleActive ? `` : `disabled`}`}>Start time</FormLabel>
              <DatePickerDiv>
                <LabelBox>
                  <DatePicker
                    selected={setLocalZone(startDatePublic)}
                    onChange={(e) => {
                      handleStartDateChange(setOtherZone(e), 1)
                    }}
                    showTimeSelect
                    minDate={todayUTC}
                    disabled={!isPublicSaleActive}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minTime={
                      checkStartDatePublic
                        ? setHours(setMinutes(todayUTC, 0), 0)
                        : setHours(setMinutes(todayUTC, 30), todayUTCHours)
                    }
                    maxTime={setHours(setMinutes(todayUTC, 30), 23)}
                  />
                </LabelBox>
              </DatePickerDiv>
            </ColCs>
            <ColCs xs={12} sm={4} gutter={16}>
              <FormLabel className={`time-utc ${isPublicSaleActive ? `` : `disabled`}`}>End time</FormLabel>
              <DatePickerDiv>
                <LabelBox>
                  <DatePicker
                    selected={setLocalZone(endDatePublic)}
                    onChange={(e) => handleEndDateChange(setOtherZone(e), 1)}
                    showTimeSelect
                    minDate={todayUTC}
                    disabled={!isPublicSaleActive}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minTime={
                      checkEndDatePublic
                        ? setHours(setMinutes(todayUTC, 0), 0)
                        : setHours(setMinutes(todayUTC, 30), todayUTCHours)
                    }
                    maxTime={setHours(setMinutes(todayUTC, 30), 23)}
                  />
                </LabelBox>
              </DatePickerDiv>
            </ColCs>
          </RowCs>
          <WarningDefineTime>
            <IconPortal widthIcon={'20px'} heightIcon={'20px'} SrcImageIcon={IconInfo} />
            <span>The defined dates are set based on UTC timestamp</span>
          </WarningDefineTime>
        </SectionSale>
      )}
      <BtnSubmit onClick={handleConfirm} type="button" disabled={!salesContract}>
        Update
      </BtnSubmit>
      {showConfirm && (
        <ModalConfirm
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={handleUpdate}
          subText={'Are you sure you want to update the sales?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={errorMessage} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={'The redemption window for this collection has been successfully set.'}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const Title = styled.h3`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`
const FormSelect = styled.div``
const SectionCollection = styled.div`
  width: 100%;
`
const SectionSale = styled.div`
  width: 100%;
  padding-top: 32px;
  border-top: 1px dashed ${({ theme }) => theme.secondary3};
  margin-bottom: 32px;
`
const TitleSale = styled.h6`
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary10};
  margin-top: 0;
  margin-bottom: 20px;
`
const LabelBox = styled.label``
const ChooseWhitelist = styled.div`
  margin-top: 20px;
`
export default SaleManagement
