import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

const _links = [
  { url: '/terms-and-conditions', label: 'Terms & Conditions' },
  { url: '/privacy-policy', label: 'Privacy Policy' },
]

const Links = () => {
  return (
    <Container>
      <span>{new Date().getFullYear()} ANOTHER-1 all rights reserved</span>
      {_links.map((link, index) => {
        return (
          <Link to={link.url} key={index}>
            {link.label}
          </Link>
        )
      })}
    </Container>
  )
}

const Link = styled(LinkRouter)`
  color: ${({ theme }) => theme.secondary5};
  cursor: pointer;
  margin-left: 30px;
  transition: all 0.3s ease-in;
  &:hover {
    color: ${({ theme }) => theme.white};
  }
  text-decoration: none;
  @media (max-width: 640px) {
    &:first-of-type {
      margin-left: 0;
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary5};
  text-decoration: none;
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 640px) {
    flex-wrap: wrap;
    align-items: normal;
    & > span {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`

export default Links
