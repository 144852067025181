import styled from 'styled-components/macro'

export const CancelEventModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 24px;
`

export const CancelEventModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const CancelEventModalHeader = styled.h3`
  margin: 0;
`

export const CancelEventModalHeading = styled.h4`
  margin: 16px 0 8px 0;
  text-align: center;
  color: ${({ theme }) => theme.red5};
  font-size: ${({ theme }) => theme.fontSizeText1};
  line-height: 24px;
  text-transform: uppercase;
  white-space: pre-wrap;
`

export const CancelEventModalEventName = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.red4};
`

export const CancelEventModalParagraph = styled.p`
  text-align: center;
`

export const CancelEventModalButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  margin-top: 24px;
`
