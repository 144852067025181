import { GoogleMap, LoadScript } from '@react-google-maps/api'
import React, { ReactNode, useEffect } from 'react'

const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: '24px',
}

interface GoogleMapsProps {
  children?: ReactNode
  center?: {
    lat: number
    lng: number
  }
  onMapClick?: (e: google.maps.MapMouseEvent) => void
}

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]

const libraries: Libraries = ['places']

const defaultLocation = {
  lat: -3.745,
  lng: -38.523,
}

export const GoogleMaps = ({ children, center, onMapClick }: GoogleMapsProps) => {
  const mapRef = React.useRef<google.maps.Map>()

  const onMapLoad = React.useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map

      if (center) {
        return map.panTo(center)
      }

      const geo = navigator.geolocation
      if (!geo) {
        return map.setCenter(defaultLocation)
      }

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }

          map.panTo(pos)
        },
        () => {
          map.setCenter(defaultLocation)
        }
      )
    },
    [center]
  )

  useEffect(() => {
    if (mapRef?.current && center) {
      mapRef?.current.panTo(center)
    }
  }, [center])

  return (
    <LoadScript
      libraries={libraries}
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
      language="en"
    >
      <GoogleMap onClick={onMapClick} mapContainerStyle={containerStyle} zoom={10} onLoad={onMapLoad}>
        {children}
      </GoogleMap>
    </LoadScript>
  )
}
