import { VideoOrImage } from 'components/Media/VideoOrImage'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import ColCs from '../../../components/ColCs'
import FAQs from '../../../components/FAQs'
import RowCs from '../../../components/RowCs'
import { OptionsAdd } from '../../../types'
import Card from '../Card'

interface SectionsProps {
  sections: any
}
const Sections = ({ sections }: SectionsProps) => {
  const sectionElement = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState<number>(0)

  const handleResize = () => {
    if (!sectionElement || !sectionElement.current) {
      return
    }
    setWidth(sectionElement.current.offsetWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <DescriptionWrapper>
      {sections.map(function (section: any, index: number) {
        const { type } = section
        return (
          <React.Fragment key={index}>
            {type === OptionsAdd.FULL_ROW_IMAGE && (
              <BannerTop>
                <img src={section?.link || ''} alt={'banner_collection'} />
              </BannerTop>
            )}
            {type === OptionsAdd.SUB_TITLE && (
              <DescriptionTop>
                {section.text ? <TextDesc dangerouslySetInnerHTML={{ __html: section.text }} /> : null}
              </DescriptionTop>
            )}
            {(type === OptionsAdd.LEFT_SIDE_IMAGE || type === OptionsAdd.RIGHT_SIDE_IMAGE) && (
              <DescriptionContent>
                <DescriptionContentList>
                  <DescriptionContentItem>
                    <DescItemText style={{ order: type === OptionsAdd.RIGHT_SIDE_IMAGE ? 1 : 2 }}>
                      <DescItemRichText dangerouslySetInnerHTML={{ __html: section.text }}></DescItemRichText>
                    </DescItemText>
                    {section.link && (
                      <DescItemMedia style={{ order: type === OptionsAdd.RIGHT_SIDE_IMAGE ? 2 : 1 }}>
                        <VideoOrImage url={section.link} />
                      </DescItemMedia>
                    )}
                  </DescriptionContentItem>
                </DescriptionContentList>
              </DescriptionContent>
            )}
            {type === OptionsAdd.FULL_ROW_TEXT && (
              <div ref={sectionElement}>
                <SectionAbout height={width * 0.5625} dangerouslySetInnerHTML={{ __html: section.text }} />
              </div>
            )}
            {type === OptionsAdd.FAQ && (
              <FaqBox>
                <TitleFaq>FAQs</TitleFaq>
                <FAQs faq={section.items} />
              </FaqBox>
            )}
            {type === OptionsAdd.MULTIPLES_ITEM && (
              <GroupImages>
                {section.title ? <TittleGroupImages dangerouslySetInnerHTML={{ __html: section.title }} /> : ''}
                <RowCs>
                  {section?.items.map((data: any, index: number, images: any) => {
                    return (
                      <ColCs
                        key={index}
                        xl={images.length > 5 ? 4 : 12 / images.length}
                        xs={12}
                        sm={6}
                        className={'colCs-mb'}
                      >
                        <Card dataHtml={data} />
                      </ColCs>
                    )
                  })}
                </RowCs>
              </GroupImages>
            )}
          </React.Fragment>
        )
      })}
    </DescriptionWrapper>
  )
}
const GroupImages = styled.div`
  width: 100%;
  margin-top: 80px;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 768px) {
    .colCs-mb {
      margin-bottom: 10px;
    }
    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
  @media (max-width: 575px) {
    .colCs-mb {
      margin-bottom: 10px;
    }
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
`
const BannerTop = styled.div`
  margin-top: 50px;
  width: 100%;

  img {
    width: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
  iframe {
    #player {
      width: 100%;
      max-width: 100% !important;
    }
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-left: -16px;
    margin-right: -16px;
  }

  @media (max-width: 568px) {
    margin-top: 25px;
    margin-left: -16px;
    margin-right: -16px;
  }
`
const SectionAbout = styled.div<{ height: number }>`
  margin-top: 80px;
  text-align: center;
  position: relative;

  iframe {
    height: ${({ height }) => height}px;
    width: 100%;
  }
`
const DescriptionWrapper = styled.div`
  padding: 0 60px 52px;
  @media (max-width: 768px) {
    padding: 0 0 30px;
  }
`
const FaqBox = styled.div``
const TextDesc = styled.p`
  font-size: ${({ theme }) => theme.display3};
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary7};
  text-align: center;
  margin: 100px 0 0 0;
`
const DescriptionContent = styled.div`
  width: 100%;
`
const DescriptionContentList = styled.div``
const DescriptionContentItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 90px;
  @media (max-width: 1024px) {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 60px;
  }
`
const DescItemText = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    order: 2 !important;
  }
`
const DescItemMedia = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  min-height: 460px;
  align-items: center;
  img {
    display: block;
    width: 100%;
    min-height: 1px;
    vertical-align: middle;
  }
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    order: 1 !important;
    margin-bottom: 20px;
  }
`
const DescItemTitle = styled.h1`
  line-height: 1.16667;
  color: ${({ theme }) => theme.white};
  font-style: normal;
  margin: 0;
  font-family: 'DIN 1451 Std', sans-serif;
  font-weight: 400;
`
const DescItemRichText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary7};
  margin: 16px 0 0;
`

const TitleFaq = styled.h1`
  line-height: 1.1667;
  color: ${({ theme }) => theme.secondary10};
  margin: 90px 0 40px;
  font-family: 'DIN 1451 Std', sans-serif;
  font-weight: normal;
  @media (max-width: 768px) {
    text-align: center;
    margin: 40px 0;
  }
`
const DescriptionTop = styled.div``
const TittleGroupImages = styled(DescItemTitle)`
  text-align: center;
  margin-bottom: 16px;
`
export default Sections
