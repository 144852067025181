export const getFractalProof = async (signMessage: any) => {
  if (
    !process.env.REACT_APP_FRACTAL_INTEGRATION_NAME ||
    !process.env.REACT_APP_CLIENT_ID ||
    !process.env.REACT_APP_FRACTAL_CREDENTIALS_URL
  )
    throw Error('fractal env variables must be set up')

  const integrationName = process.env.REACT_APP_FRACTAL_INTEGRATION_NAME
  const message = `I authorize ${integrationName} (${process.env.REACT_APP_CLIENT_ID}) to get a proof from Fractal that:
- I passed KYC level basic+liveness+uniq+wallet`

  try {
    const signature = await signMessage(message)
    const encMessage = encodeURIComponent(message)
    const res = await fetch(
      `${process.env.REACT_APP_FRACTAL_CREDENTIALS_URL}?message=${encMessage}&signature=${signature}`
    )
    return await res.json()
  } catch (err) {
    console.log(err)
  }
}
