import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const settingAttributesApi = createApi({
  reducerPath: 'settingAttributes',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ['SETTING_ATTRIBUTE'],
  endpoints: (builder) => ({
    getAttributes: builder.query({
      query: ({ page, limit, keyword }: { page?: number; limit?: number; keyword?: string }) => {
        return {
          url: `search/attributes`,
          params: { keyword: keyword || '', page: page || 1, limit: limit || 8 },
        }
      },
      providesTags: ['SETTING_ATTRIBUTE'],
    }),
    createAttributes: builder.mutation({
      query: ({
        name,
        type,
        information,
        placeholder,
        options,
        post,
        handleSuccess,
        handleError,
        handleFinally,
        refetch,
      }: {
        name: string
        type?: string
        information?: string
        placeholder?: string
        options?: string[]
        post: any
        handleSuccess?: any
        handleError?: any
        handleFinally?: any
        refetch: any
      }) => {
        return post(
          {
            callback: () => {
              handleSuccess()
            },
            callbackFinally: () => {
              handleFinally()
            },
            errorCallback: () => {
              handleError()
            },
            url: `${process.env.REACT_APP_SERVER_URL}admin-panel/attributes`,
            data: { name, type, information, placeholder, options },
          },
          true
        ).then((res: any) => {
          if (res.status === 200) {
            refetch()
          }
        })
      },
      invalidatesTags: ['SETTING_ATTRIBUTE'],
    }),
    updateAttributes: builder.mutation({
      query: ({
        id,
        name,
        type,
        information,
        placeholder,
        options,
        patch,
        handleSuccess,
        handleError,
        handleFinally,
        refetch,
      }: {
        id: string
        name?: string
        type?: string
        information?: string
        placeholder?: string
        options?: string[]
        patch: any
        handleSuccess?: any
        handleError?: any
        handleFinally?: any
        refetch: any
      }) => {
        return patch(
          {
            callback: () => {
              handleSuccess()
            },
            callbackFinally: () => {
              handleFinally()
            },
            errorCallback: () => {
              handleError()
            },
            url: `${process.env.REACT_APP_SERVER_URL}admin-panel/attributes`,
            data: { id, name, type, information, placeholder, options },
          },
          true
        ).then((res: any) => {
          if (res.status === 200) {
            refetch()
          }
        })
      },
      invalidatesTags: ['SETTING_ATTRIBUTE'],
    }),
  }),
})

export const { useGetAttributesQuery, useCreateAttributesMutation, useUpdateAttributesMutation } = settingAttributesApi
