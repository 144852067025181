import { ReactComponent as IconPlus } from 'assets/icons/icon-plus.svg'
import ColCs from 'components/ColCs'
import RowCs from 'components/RowCs'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { ButtonPrimary, ButtonSecondary } from '../../../components/Button'
import ModalConfirm from '../../../components/Modal/ModalConfirm'
import ModalError from '../../../components/Modal/ModalError'
import ModalSuccess from '../../../components/Modal/ModalSuccess'
import { useApi } from '../../../hooks/useApi'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { useUpdateCollectionDescriptionsMutation } from '../../../state/collections/slice'
import { OptionsAdd, SectionEdit } from '../../../types'
import EditBox from '../Components/EditBox'
import Option from '../Components/Option'
const optionsAdd: { name: string; sectionType: number }[] = [
  { name: 'Subtitle', sectionType: OptionsAdd.SUB_TITLE },
  { name: 'Full width image', sectionType: OptionsAdd.FULL_ROW_IMAGE },
  { name: 'Full width text', sectionType: OptionsAdd.FULL_ROW_TEXT },
  { name: 'Left side image & text', sectionType: OptionsAdd.LEFT_SIDE_IMAGE },
  { name: 'Right side image & text', sectionType: OptionsAdd.RIGHT_SIDE_IMAGE },
  { name: 'FAQ', sectionType: OptionsAdd.FAQ },
  { name: 'Multiple item', sectionType: OptionsAdd.MULTIPLES_ITEM },
]
const EditDescription = (props: any) => {
  const { collection, addressCollection, refetch } = props
  const { post } = useApi()
  const [updateCollectionDescriptions] = useUpdateCollectionDescriptionsMutation()
  const [toggleMenu, setToggleMenu] = useState<boolean>(false)
  const [sections, setSections] = useState<SectionEdit[]>([])
  const menuNode = useRef<HTMLDivElement>(null)
  useOnClickOutside(menuNode, () => setToggleMenu(false))
  const [activeSectionIx, setActiveSectionIx] = useState<number>(-1)

  //modal
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const handleSuccess = () => {
    setShowSuccess(true)
    setShowConfirm(false)
  }
  const handleError = () => {
    setShowError(true)
    setShowConfirm(false)
  }
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setToggleMenu(!toggleMenu)
  }
  useEffect(() => {
    if (!collection.collectionDescriptions || !collection?.collectionDescriptions?.sections) {
      return
    }
    setSections([...collection?.collectionDescriptions.sections])
  }, [collection.collectionDescriptions, collection.collectionDescriptions?.sections])
  const setActiveSection = (ix: number) => {
    setActiveSectionIx(ix)
  }
  const handleClickOption = (type: OptionsAdd) => {
    setActiveSectionIx(sections.length)
    const sectionsCv: SectionEdit[] = []
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].create !== 'new') {
        sectionsCv.push(sections[i])
      }
    }
    setSections([...sectionsCv, { type, create: 'new' }])
    setToggleMenu(false)
  }
  const updateSections = (sections: SectionEdit[]) => {
    setSections([...sections])
    setActiveSectionIx(-1)
  }
  const handleSubmitDescriptions = () => {
    updateCollectionDescriptions({
      post,
      collectionAddress: addressCollection,
      sections,
      handleSuccess,
      handleError,
      refetch,
    })
  }
  return (
    <Wrapper>
      <RowCs space={-16}>
        <ColCs gutter={16} sm={6}>
          <Menu ref={menuNode}>
            <CtaAdd onClick={handleClickMenu}>
              <IconPlus /> Add content
            </CtaAdd>
            {toggleMenu && (
              <Options>
                {optionsAdd.map((option, index: number) => {
                  return <Option key={index} option={option} onClick={() => handleClickOption(option.sectionType)} />
                })}
              </Options>
            )}
          </Menu>
        </ColCs>
        <ColCs gutter={16} sm={6}>
          <CtaSave onClick={() => setShowConfirm(true)}>Save changes</CtaSave>
        </ColCs>
      </RowCs>
      <SectionContent>
        {sections.map((section, index) => {
          return (
            <EditBox
              section={section}
              sections={sections}
              key={index}
              order={index}
              updateSections={updateSections}
              active={activeSectionIx === index || section.create === 'new'}
              setActiveSection={setActiveSection}
              setSections={setSections}
            />
          )
        })}
      </SectionContent>
      {showConfirm && (
        <ModalConfirm
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={handleSubmitDescriptions}
          subText={'Are you sure you want to update description?'}
        />
      )}
      <ModalError
        isOpen={showError}
        onDismiss={() => setShowError(false)}
        message={'Update Collection Description Failed'}
      />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={'Update Collection Description Successfully'}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const Menu = styled.div`
  position: relative;
`
const Options = styled.div`
  border-width: 0 2px 2px 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.secondary3};
  background: linear-gradient(180deg, #111111 0%, #1b1b1b 100%);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
`
const CtaAdd = styled(ButtonSecondary)`
  padding: 16px !important;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary7};
  text-transform: none;
  font-weight: 700;
  svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    path {
      fill: ${({ theme }) => theme.secondary7};
    }
  }
`
const CtaSave = styled(ButtonPrimary)`
  padding: 16px !important;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary1};
  text-transform: none;
  font-weight: 700;
`
const SectionContent = styled.div``
export default EditDescription
