import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface Proptype {
  userAddress?: string | null
}

export const becomeCreator = createApi({
  reducerPath: 'becomeCreator',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ['Creator'],
  endpoints: (builder) => ({
    getFormDraft: builder.query({
      query: (userAddress: string | null) => {
        return {
          url: `users/become-creator/${userAddress}`,
        }
      },
    }),
  }),
})

export const { useGetFormDraftQuery } = becomeCreator
