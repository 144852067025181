import { FormikProps, useFormik } from 'formik'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { ButtonGray, ButtonPrimary } from '../../../../components/Button'
import { FormInput, FormTextarea } from '../../../../components/Forms'
import ModalConfirm from '../../../../components/Modal/ModalConfirm'
import ModalError from '../../../../components/Modal/ModalError'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
import { TypeMedia } from '../../../../constants/typeMedia'
import { useApi } from '../../../../hooks/useApi'
import { useFetchMedia } from '../../../../hooks/useFetchMedia'
import { collectionsApi, useUpdateCollectionMutation } from '../../../../state/collections/slice'
import { useAppDispatch } from '../../../../state/hooks'
import EditMedia from '../EditMedia'
interface EditCollectionLdType {
  collection: any
  onClose: () => void
  typeAdd?: boolean
  onCloseAddFeatured: () => void
}
interface TypeInitValue {
  [key: string]: string
}
const EditCollectionLd = (props: EditCollectionLdType) => {
  const { collection, onClose, typeAdd, onCloseAddFeatured } = props
  const [mediaFilesChange, setMediaFilesChange] = useState<any>([])
  const { handleFetchMedia } = useFetchMedia()
  const { patch } = useApi()
  const [updateCollection] = useUpdateCollectionMutation()
  const dispatch = useAppDispatch()
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const featuredFormik: FormikProps<TypeInitValue> = useFormik<TypeInitValue>({
    enableReinitialize: true,
    initialValues: {
      shortBios: !collection?.descriptions?.shortBios ? '' : collection?.descriptions.shortBios,
      nftSampleName: collection?.media?.nftSample?.length > 0 ? collection?.media?.nftSample[0]?.name : '',
    },
    onSubmit: () => {
      setShowConfirm(true)
    },
  })
  const handleRefetch = () => {
    //dispatch(collectionsApi.util.invalidateTags(['Collection']))
    dispatch(collectionsApi.util.resetApiState())
    onClose()
    onCloseAddFeatured()
  }
  const handleSuccess = () => {
    setShowSuccess(true)
    setShowConfirm(false)
    onClose()
    onCloseAddFeatured()
  }
  const handleError = () => {
    setShowError(true)
    setShowConfirm(false)
    onClose()
    onCloseAddFeatured()
  }
  const handleUpdateCollection = async (values: TypeInitValue) => {
    const { shortBios, nftSampleName } = values

    try {
      const urlOriginal = []
      let mediaCollection = collection.media

      if (mediaFilesChange.length > 0) {
        for (let i = 0; i < mediaFilesChange.length; i++) {
          const mediaFile = mediaFilesChange[i]
          const { file, type } = mediaFile
          const srcMedia = await handleFetchMedia(file)
          urlOriginal.push({
            type,
            srcMedia,
          })
        }
      }

      const updateNftSample = (type: string, data: string) => {
        const { nftSample } = mediaCollection
        if (!nftSample) {
          let nftSampleClone = []
          const cloneObj = {
            link: '',
            collection: '',
            name: '',
          }
          nftSampleClone = [
            {
              ...cloneObj,
              [type]: data,
            },
            {
              ...cloneObj,
              [type]: data,
            },
          ]
          mediaCollection = {
            ...mediaCollection,
            nftSample: [...nftSampleClone],
          }
        } else {
          const nftSampleUpdate = nftSample.map((item: any) => {
            return {
              ...item,
              [type]: data,
            }
          })
          mediaCollection = {
            ...mediaCollection,
            nftSample: [...nftSampleUpdate],
          }
        }
      }
      for (let i = 0; i < urlOriginal.length; i++) {
        const { type, srcMedia } = urlOriginal[i]
        if (type === TypeMedia.THUMBNAIL) {
          mediaCollection = {
            ...mediaCollection,
            thumbnail: srcMedia,
          }
        }
        if (type === TypeMedia.PREVIEW) {
          mediaCollection = {
            ...mediaCollection,
            cover: srcMedia,
          }
        }
        if (type === TypeMedia.PROFILE) {
          updateNftSample('link', srcMedia)
        }
      }
      updateNftSample('name', nftSampleName)

      await updateCollection({
        patch,
        collectionAddress: collection.address,
        name: collection.name,
        media: {
          ...collection.media,
          ...mediaCollection,
        },
        descriptions: {
          ...collection.descriptions,
          shortBios,
        },
        isDisplayed: true,
        handleSuccess,
        handleError,
        refetch: handleRefetch,
      })
    } catch (e) {
      console.log('Error submit', e)
    }
    setSubmitting(false)
  }
  const { values, handleChange, setSubmitting, dirty, handleSubmit } = featuredFormik
  return (
    <EditCollectionLdWrapper>
      <form onSubmit={handleSubmit}>
        <EditContent>
          <SectionEdit>
            <EditLeft>
              <Heading5>Thumbnail</Heading5>
              <EditMediaBox>
                <EditMedia
                  data={collection?.media?.thumbnail}
                  type={TypeMedia.THUMBNAIL}
                  accept="image/*"
                  setMediaFilesChange={setMediaFilesChange}
                  mediaFilesChange={mediaFilesChange}
                  handleChange={handleChange}
                  addressCollection={collection.address}
                />
              </EditMediaBox>
            </EditLeft>
            <EditRight>
              <Heading5>Preview image</Heading5>
              <EditMediaBox>
                <EditMedia
                  data={collection?.media?.cover}
                  type={TypeMedia.PREVIEW}
                  accept="image/*"
                  setMediaFilesChange={setMediaFilesChange}
                  mediaFilesChange={mediaFilesChange}
                  handleChange={handleChange}
                  addressCollection={collection.address}
                />
              </EditMediaBox>
            </EditRight>
          </SectionEdit>
          <SectionEdit>
            <EditLeft>
              <Heading5>Profile image</Heading5>
              <EditMediaBox>
                <EditMedia
                  data={collection?.media?.nftSample?.length > 0 && collection?.media?.nftSample[0]?.link}
                  type={TypeMedia.PROFILE}
                  accept="image/*,video/*"
                  setMediaFilesChange={setMediaFilesChange}
                  mediaFilesChange={mediaFilesChange}
                  handleChange={handleChange}
                  addressCollection={collection.address}
                />
              </EditMediaBox>
            </EditLeft>
            <EditRight>
              <ProfileTitle>
                <Heading5>Profile title</Heading5>
                <FormInput
                  type={'text'}
                  name={'nftSampleName'}
                  id={'nftSampleName'}
                  placeholder={'Input profile title'}
                  value={values.nftSampleName ?? ''}
                  onChange={handleChange}
                />
              </ProfileTitle>
              <PreviewDescription>
                <Heading5>Preview Description</Heading5>
                <FormTextarea
                  rows={5}
                  placeholder={'Input preview description...'}
                  name={'shortBios'}
                  id={'shortBios'}
                  value={values.shortBios ?? ''}
                  onChange={handleChange}
                />
              </PreviewDescription>
            </EditRight>
          </SectionEdit>
        </EditContent>
        <CtaGroup>
          <CtaCancel onClick={onClose}>{typeAdd ? 'DISCARD' : 'Cancel'}</CtaCancel>
          <CtaUpdate disabled={typeAdd ? false : !dirty}>{typeAdd ? 'Create' : 'SAVE CHANGES'}</CtaUpdate>
        </CtaGroup>
      </form>

      {showConfirm && (
        <ModalConfirm
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => handleUpdateCollection(values)}
          subText={'Are you sure you want to update collection?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={'Update Collection Failed'} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={'Update Collection Successfully'}
      />
    </EditCollectionLdWrapper>
  )
}
const EditCollectionLdWrapper = styled.div`
  margin-top: 24px;
`
const EditContent = styled.div``
const Heading5 = styled.h5`
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
  margin-bottom: 12px;
  margin-top: 0;
`
const SectionEdit = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 24px;
`
const EditLeft = styled.div`
  flex: 0 0 176px;
  max-width: 176px;
`
const EditMediaBox = styled.div`
  height: 176px;
`
const EditRight = styled.div`
  flex: 1;
`
const ProfileTitle = styled.div``
const PreviewDescription = styled.div`
  margin-top: 20px;
`
const CtaGroup = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 32px;
  & > button {
    flex: 0 0 calc(50% - 32px);
    max-width: calc(50% - 32px);
    font-family: 'Din Pro Bold', sans-serif;
  }
`
const CtaCancel = styled(ButtonGray)`
  color: ${({ theme }) => theme.secondary6};
`
const CtaUpdate = styled(ButtonPrimary)``
export default EditCollectionLd
