import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { ClaimNFTByNFCCodeResponse } from '../models/ClaimNFTByNFCCodeResponse'
import { ErrorStructure } from '../models/RedeemErrors'

interface UseRedeemNFTByNFCCodeProps {
  code: string
}

export const useRedeemNFTByNFCCode = (
  options?: UseMutationOptions<ClaimNFTByNFCCodeResponse, AxiosError<ErrorStructure>, UseRedeemNFTByNFCCodeProps>
) => {
  return useMutation<ClaimNFTByNFCCodeResponse, AxiosError<ErrorStructure>, UseRedeemNFTByNFCCodeProps>(
    async ({ code }) => {
      const result = await axios.post(
        apiEndpoints.redeemNFTByNFCCode,
        {
          code,
        },
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
        }
      )

      return result?.data
    },
    options
  )
}
