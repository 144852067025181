import React from 'react'

import { ModalBase } from '../../../../components/ModalBase/ModalBase'
import { WhitelistUpload } from '../WhitelistUpload'

interface WhitelistModalProps {
  isOpen: boolean
  onClose: () => void
  eventId: string
}

export const WhitelistModal = ({ isOpen, onClose, eventId }: WhitelistModalProps) => {
  return (
    <ModalBase isOpen={isOpen} onDismiss={onClose} header="Upload whitelist" width="900px" maxWidth={900}>
      <WhitelistUpload onSuccess={onClose} eventId={eventId} />
    </ModalBase>
  )
}
