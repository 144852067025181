import * as React from 'react'

export const WalletCoins = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#00D98B"
      fillRule="evenodd"
      d="M3.665 2.164c-1.197 0-2.168.97-2.168 2.168v1a.5.5 0 1 0 1 0v-1c0-.645.523-1.168 1.168-1.168h8.67c.645 0 1.167.523 1.167 1.168v1.5h-1.167a2.167 2.167 0 0 0 0 4.335h1.167v1.501c0 .645-.522 1.168-1.167 1.168H8.667a.5.5 0 1 0 0 1h3.668c1.197 0 2.167-.97 2.167-2.168v-1.613c.395-.187.668-.589.668-1.055V7c0-.466-.273-.868-.668-1.055V4.332c0-1.197-.97-2.168-2.167-2.168h-8.67Zm10.342 7.003A.167.167 0 0 0 14.17 9V7a.167.167 0 0 0-.167-.167h-1.668a1.167 1.167 0 0 0 0 2.334h1.658a.6.6 0 0 1 .01 0h.004ZM.832 8.457A.954.954 0 0 1 .831 8.4c0-.33.169-.592.366-.779a2.31 2.31 0 0 1 .73-.44c.555-.222 1.288-.348 2.072-.348.783 0 1.516.126 2.071.348.276.11.533.255.73.44.197.187.366.45.366.778a.51.51 0 0 1-.001.042v.012a.506.506 0 0 1 .001.047v4.435c0 .33-.169.592-.366.779a2.31 2.31 0 0 1-.73.44c-.555.222-1.287.348-2.071.348-.785 0-1.517-.126-2.072-.348a2.31 2.31 0 0 1-.73-.44c-.197-.187-.366-.45-.366-.778V8.5l.001-.042Zm.999 4.467v-1.077l.095.04c.555.221 1.288.348 2.072.348s1.517-.127 2.072-.349l.096-.04v1.078a.26.26 0 0 1-.053.063c-.074.07-.208.156-.414.239-.41.164-1.011.276-1.7.276-.69 0-1.29-.112-1.7-.276a1.342 1.342 0 0 1-.415-.239.26.26 0 0 1-.053-.063Zm4.335-2.268V9.579a3.354 3.354 0 0 1-.096.04c-.555.222-1.288.348-2.072.348S2.48 9.841 1.926 9.62a3.31 3.31 0 0 1-.095-.04v1.077c.005.01.018.031.053.064.074.07.207.155.413.238.41.164 1.011.277 1.7.277.69 0 1.291-.113 1.701-.277a1.34 1.34 0 0 0 .414-.238.258.258 0 0 0 .054-.064ZM1.828 8.417l.001-.003v.003Zm.056-.07a.328.328 0 0 0-.046.053.328.328 0 0 0 .046.052c.074.07.207.156.413.238.41.165 1.011.277 1.7.277.69 0 1.291-.113 1.701-.277a1.34 1.34 0 0 0 .414-.238.327.327 0 0 0 .047-.052.334.334 0 0 0-.046-.052 1.343 1.343 0 0 0-.414-.238c-.41-.164-1.012-.277-1.7-.277-.69 0-1.29.113-1.701.277a1.34 1.34 0 0 0-.414.238Zm-.055.039v-.003.003Z"
      clipRule="evenodd"
    />
  </svg>
)
