import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const phygitalApi = createApi({
  reducerPath: 'phygital',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ['PHYGITAL'],
  endpoints: (builder) => ({
    getDataTableNFC: builder.query({
      query: ({
        collectionAddress,
        keyword,
        page,
        limit,
        sort,
        status,
        get,
      }: {
        collectionAddress: string
        keyword?: string
        page?: number
        limit?: number
        sort?: string
        status?: string
        get?: any
      }) => {
        return get({
          config: {
            url: `redemption/listRedeemedAssets`,
            headers: {
              authorization: '',
            },
            params: { collectionAddress, keyword, page, limit, sort, status },
          },
        })
      },
    }),
  }),
})

export const { useGetDataTableNFCQuery } = phygitalApi
