import { ButtonTransparent } from 'components/Button'
import { useEventCollections } from 'modules/Collections/hooks/useGetAllowedCollections'
import { useGetProfile } from 'state/profile/hooks'

import IconClose from '../../../../assets/images/svg/icon-close.svg'
import IconPortal from '../../../../components/IconPortal'
import Modal from '../../../../components/Modal'
import { CollectionsForm } from '../CollectionsForm'
import * as Styled from './styled'

interface CollectionsModalProps {
  isOpen: boolean
  onClose: () => void
  eventId: string
}

export const CollectionsModal = ({ isOpen, onClose, eventId }: CollectionsModalProps) => {
  const { data: profile, loading: profileLoading } = useGetProfile()

  const { data: eventCollections, isLoading: collectionsLoading } = useEventCollections(
    {
      partnerAddress: profile.address,
      // @ts-ignore
      eventId,
    },
    {
      enabled: Boolean(!profileLoading && profile && profile.address),
    }
  )

  const isLoading = profileLoading || collectionsLoading

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} maxWidth={750}>
      <Styled.CollectionsModalContainer>
        <Styled.CollectionsModalHeaderContainer>
          <Styled.CollectionsModalHeader>Manage Collections</Styled.CollectionsModalHeader>
          <ButtonTransparent onClick={onClose}>
            <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
          </ButtonTransparent>
        </Styled.CollectionsModalHeaderContainer>

        <CollectionsForm
          {...{ eventId, eventCollections, collectionsLoading: isLoading, partnerAddress: profile.address }}
        />
      </Styled.CollectionsModalContainer>
    </Modal>
  )
}
