import React from 'react'

import IconClose from '../../../../assets/images/svg/icon-close.svg'
import IconPortal from '../../../../components/IconPortal'
import Modal from '../../../../components/Modal'
import { ClaimNFTModalBody } from './ClaimNFTModalBody'
import { Heading, MainHeading, ModalAuthWrapper, ModalHeader, SubHeading } from './styles'

interface ClaimNFTModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: string) => void
  isSuccess: boolean
  successMessage: string
  isError: boolean
  errorMessage: string
  heading: string
  subheading: string
  onRetry: () => void
  onSeeNFT: () => void
  isLoading: boolean
}

export const ClaimNFTModal = ({
  isOpen,
  onClose,
  onSubmit,
  isSuccess,
  successMessage,
  isError,
  errorMessage,
  heading,
  subheading,
  onRetry,
  onSeeNFT,
  isLoading,
}: ClaimNFTModalProps) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onClose} maxWidth={980}>
      <ModalAuthWrapper>
        <ModalHeader>
          <MainHeading>
            <Heading>{heading}</Heading>
            <span onClick={onClose} style={{ cursor: 'pointer' }}>
              <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
            </span>
          </MainHeading>
          <SubHeading>{subheading}</SubHeading>
        </ModalHeader>
        <ClaimNFTModalBody
          onSubmit={onSubmit}
          isSuccess={isSuccess}
          isError={isError}
          errorMessage={errorMessage}
          successMessage={successMessage}
          onRetry={onRetry}
          onSeeNFT={onSeeNFT}
          isLoading={isLoading}
        />
      </ModalAuthWrapper>
    </Modal>
  )
}
