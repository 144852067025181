import Loader from 'components/Loader'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useTimer from 'hooks/useTimer'
import moment from 'moment'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useLocation } from 'react-router-dom'
import { Flex } from 'rebass'
import { useGetVerificationCodeQuery } from 'state/nfts/slice'
import styled from 'styled-components/macro'

import BoxUserCard from '../../components/BoxUserCard/BoxUserCard'
import { InputVerificationCodeAnother } from '../../theme'
import DescriptionNFT from './DescriptionNFT'

const ScannedNFC = () => {
  useDocumentTitle(`Code verification | Another-1 Launchpad`)
  const { search } = useLocation()
  const {
    data: verificationData,
    isLoading,
    error,
  } = useGetVerificationCodeQuery({
    search,
  })

  const { minutes, seconds, timeSecondsProgressbar } = useTimer(
    undefined,
    moment(parseInt(verificationData?.verification.expires)).toDate()
  )

  const hasError = !isLoading && (error || !verificationData?.asset)

  return (
    <ScannedNFCWrapper>
      <ScannedContainer>
        <Flex justifyContent={'center'}>{isLoading && <Loader size={'32px'} />}</Flex>

        {!isLoading &&
          (hasError ? (
            <ScanHeader>
              <Heading>There was an error validating the tag scan</Heading>
              <TimeShow className="error">Please try again </TimeShow>
            </ScanHeader>
          ) : (
            <>
              <ScanHeader>
                <Heading>You have received your NFC verification code</Heading>
                <BoxCode>
                  <Code>
                    <InputVerificationCodeBox>
                      <ReactInputVerificationCode
                        autoFocus={false}
                        value={verificationData?.verification.code.toString()}
                        placeholder={'_'}
                        length={6}
                      />
                    </InputVerificationCodeBox>
                  </Code>
                </BoxCode>

                <BoxProgress>
                  {verificationData?.verification.expires && <ProgressBar seconds={timeSecondsProgressbar} />}
                  <TimeShow>
                    {isNaN(minutes)
                      ? 'This code has expired, please scan the tag again to get a new code'
                      : `Expires in ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}s`}
                  </TimeShow>
                </BoxProgress>
              </ScanHeader>
              <ScanBody>
                <DivInfoImg>
                  <DivSquare>
                    <BoxImg>
                      <img src={verificationData?.asset?.image} alt={'NFT'} />
                    </BoxImg>
                  </DivSquare>
                </DivInfoImg>
                <InfoNFT>
                  <UserCard>
                    <AvatarUser>
                      <img src={verificationData?.asset?.collection?.media?.image} alt={'imgUserCard'} />
                    </AvatarUser>
                    <Username>{verificationData?.asset?.collection?.name}</Username>
                  </UserCard>
                  <HeadingName>{verificationData?.asset?.metadata?.name}</HeadingName>
                  <Flex>
                    <ProfileBox>
                      <BoxUserCard
                        img={verificationData?.asset?.creator?.imageUrl}
                        labelName={'Created by'}
                        name={verificationData?.asset?.creator?.name}
                      />
                    </ProfileBox>
                    <ProfileBox>
                      <BoxUserCard
                        img={verificationData?.asset?.owner?.imageUrl}
                        labelName={'Current owner'}
                        name={verificationData?.asset?.owner?.name}
                      />
                    </ProfileBox>
                  </Flex>
                </InfoNFT>
                <DescriptionNFT asset={verificationData?.asset} />
              </ScanBody>
            </>
          ))}
      </ScannedContainer>
    </ScannedNFCWrapper>
  )
}
const HeadingName = styled.p`
  font-size: 72px;
  line-height: 1.111111;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  margin: 16px 0;
  font-family: 'DIN 1451 Std', sans-serif;
`
const ProfileBox = styled.div`
  margin-right: 20px;
  a {
    text-decoration: none;
  }
`
const ScannedNFCWrapper = styled.div`
  width: 100%;
`
const ScannedContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 64px;
  padding-right: 64px;
  @media (max-width: 768px) {
    padding-left: 46px;
    padding-right: 46px;
  }
`
const ScanHeader = styled.div``

const Heading = styled.h3`
  font-family: 'DIN 1451 Std', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 1.11;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.white};
  margin: 52px 0;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`
const BoxCode = styled.div`
  margin-bottom: 52px;
`
const Code = styled.div``
const BoxProgress = styled.div``
const ProgressBar = styled.div<{ seconds?: number }>`
  max-width: 636px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 4px;
  background: ${({ theme }) => theme.secondary2};
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.primary5};
    animation-name: progressBar;
    animation-duration: ${(props) => props.seconds}s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
`
const TimeShow = styled.p`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText1};
  line-height: 1.5;
  text-align: center;
  color: ${({ theme }) => theme.secondary7};
  margin: 20px 0 0 0;

  & .error {
    color: ${({ theme }) => theme.red5};
  }
`
const ScanBody = styled.div`
  width: 100%;
  margin-top: 52px;
`
const DivInfoImg = styled.div`
  position: relative;
`
const BoxImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.secondary1};
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
const DivSquare = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  cursor: pointer;
  &:hover {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(180deg, rgba(6, 24, 18, 0.9) 12.42%, rgba(6, 24, 18, 0) 100%);
      z-index: 2;
    }
  }
`
const InfoNFT = styled.div`
  margin-top: 52px;
`
const UserCard = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const AvatarUser = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.primary6};
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`
const Username = styled.h4`
  font-family: 'Din Pro Bold', sans-serif;
  margin: 0 0 0 8px;
  line-height: 1.3;
  color: ${({ theme }) => theme.primary6};
  text-transform: capitalize;
  font-weight: 500;
`
const InputVerificationCodeBox = styled(InputVerificationCodeAnother)`
  pointer-events: none;
`
export default ScannedNFC
