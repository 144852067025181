import React from 'react'
import styled from 'styled-components/macro'

import IconEmptySearch from '../../assets/icons/EmptySearch.svg'
const EmptySearchBox = ({ text }: { text?: string }) => {
  return (
    <DivEmpty>
      <img src={IconEmptySearch} alt={'No results match'} />
      <span>{text ? text : 'No results match the keywords'}</span>
    </DivEmpty>
  )
}
const DivEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 25px;
  color: ${({ theme }) => theme.secondary5};
  font-weight: 500;
  gap: 20px;
  cursor: auto;
  :hover {
    background-color: transparent;
  }
  img {
    width: 40px;
    height: 40px;
    display: block;
  }
`
export default EmptySearchBox
