import React from 'react'
import { Flex } from 'rebass'

import Loader from '../../../../components/Loader'
import { routes } from '../../../../constants/routes'
import { EventsTable } from '../../../Events/components/EventsTable'
import { useEvents } from '../../../Events/hooks/useEvents'
import * as Styled from './styled'

export const PartnerEvents = () => {
  const { data: events, isLoading } = useEvents({
    queryParams: {
      cancelledStatus: false,
    },
  })

  return (
    <Styled.Wrapper>
      <h2> Events Management </h2>
      <Styled.PageTitle>Events list</Styled.PageTitle>
      {isLoading ? (
        <Flex p="4" justifyContent="center" alignContent={'center'}>
          <Loader size="48px" />
        </Flex>
      ) : (
        <EventsTable events={events || []} editPath={routes.adminPanelEditEvents} />
      )}
    </Styled.Wrapper>
  )
}
