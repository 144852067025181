import { VideoImageOrFallback } from 'components/Media/VideoImageOrFallback'
import React from 'react'
import styled from 'styled-components/macro'

import ColCs from '../../../../components/ColCs'
import FAQs from '../../../../components/FAQs'
import RowCs from '../../../../components/RowCs'
import { OptionsAdd } from '../../../../types'
import Card from '../../../CollectionDetail/Card'

const SectionPreview = (props: any) => {
  const { section } = props
  const { type, link, text, items } = section
  const getSectionContent = () => {
    switch (type) {
      case OptionsAdd.FULL_ROW_IMAGE:
        return (
          <FullImage>
            <VideoImageOrFallback url={link} />
          </FullImage>
        )
      case OptionsAdd.FULL_ROW_TEXT:
        return <>{text && <FullRowText dangerouslySetInnerHTML={{ __html: text }} />}</>
      case OptionsAdd.SUB_TITLE:
        return <Title dangerouslySetInnerHTML={{ __html: text }} />

      case OptionsAdd.RIGHT_SIDE_IMAGE:
      case OptionsAdd.LEFT_SIDE_IMAGE:
        return (
          <>
            <DivMediaContent>
              <DescItemMedia>
                <VideoImageOrFallback url={link} />
              </DescItemMedia>
              <DescItemText dangerouslySetInnerHTML={{ __html: text }} />
            </DivMediaContent>
          </>
        )
      case OptionsAdd.FAQ:
        return (
          <>
            <FAQs faq={items} />
          </>
        )
      case OptionsAdd.MULTIPLES_ITEM:
        return (
          <>
            <GroupImages>
              {section.title ? <TittleGroupImages dangerouslySetInnerHTML={{ __html: section.title }} /> : ''}
              <RowCs>
                {section?.items.map((data: any, index: number, images: any) => {
                  return (
                    <ColCs
                      key={index}
                      xl={images.length > 5 ? 4 : 12 / images.length}
                      xs={12}
                      sm={6}
                      className={'colCs-mb'}
                    >
                      <Card dataHtml={data} />
                    </ColCs>
                  )
                })}
              </RowCs>
            </GroupImages>
          </>
        )
      default:
        return <></>
    }
  }
  return <SectionContent>{getSectionContent()}</SectionContent>
}
const SectionContent = styled.div`
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.secondary2};
  p {
    font-size: ${({ theme }) => theme.fontSizeText3};
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.25;
    font-family: 'DIN 1451 Std', sans-serif;
  }
`
const FullImage = styled.div`
  height: 337px;
  img {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
  iframe {
    #player {
      width: 100%;
      max-width: 100% !important;
    }
  }
  @media (max-width: 1024px) {
    height: 250px;
  }
  @media (max-width: 768px) {
    height: 200px;
  }

  @media (max-width: 568px) {
    height: 150px;
  }
`
const FullRowText = styled.div``
const Title = styled.div``
const DivMediaContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px -20px;
`
const DescItemText = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const DescItemMedia = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  min-height: 250px;
  max-height: 250px;
  align-items: center;
  img {
    display: block;
    width: 100%;
    min-height: 1px;
    vertical-align: middle;
    height: 100%;
    object-fit: contain;
  }
`
const GroupImages = styled.div`
  width: 100%;
  margin-top: 80px;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 768px) {
    .colCs-mb {
      margin-bottom: 10px;
    }
    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
  @media (max-width: 575px) {
    .colCs-mb {
      margin-bottom: 10px;
    }
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
`

const TittleGroupImages = styled.div`
  line-height: 1.16667;
  color: ${({ theme }) => theme.white};
  font-style: normal;
  margin: 0;
  font-family: 'DIN 1451 Std', sans-serif;
  font-weight: 400;
  text-align: center;
`
export default SectionPreview
