import React, { useState } from 'react'
import styled from 'styled-components/macro'

import FAQItem, { TypeFaq } from './FAQItem'

interface TypeProps {
  faq: TypeFaq[]
  faqTypeOther?: boolean
}
const FAQs = (props: TypeProps) => {
  const { faq, faqTypeOther } = props
  const [active, setActiveFAQ] = useState<any>(null)
  const handleToggleClick = (index: number) => {
    if (active === index) {
      setActiveFAQ(null)
    } else {
      setActiveFAQ(index)
    }
  }

  return (
    <FaqList>
      {faq?.map((faqItem: TypeFaq, index) => {
        return (
          <FAQItem
            key={index}
            content={faqItem}
            isCollapse={index === active}
            handleToggleClick={() => handleToggleClick(index)}
            faqTypeOther={faqTypeOther}
          />
        )
      })}
    </FaqList>
  )
}
const FaqList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export default FAQs
