import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import IconReport from '../../assets/icons/icon_report.svg'
import IconThreeDot from '../../assets/images/three-dot.png'
import { useModalOpen, useModalReport } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import DropdownItem from '../DropdownItem'
import ModalReport from '../Modal/ModalReport'
const menu = [
  {
    icon: IconReport,
    name: 'Report',
  },
]

type Props = {
  userAddress: string
}

const MoreFnc = ({ userAddress }: Props) => {
  const [toggleMore, setToggleMore] = useState<boolean>(false)
  const handleToggleMore = (event: any) => {
    setToggleMore(!toggleMore)
  }
  const toggleModalReport = useModalReport()
  const modalReportsOpen = useModalOpen(ApplicationModal.REPORT_POPUP)
  const handleOpenPopupReport = () => {
    toggleModalReport()
  }
  return (
    <>
      <DivMore onClick={handleToggleMore} openMenu={toggleMore}>
        <img src={IconThreeDot} alt="Icon More" />
        {toggleMore && (
          <Menu>
            {menu.map((menuItem, index: number) => {
              return <DropdownItem key={index} item={menuItem} onClick={handleOpenPopupReport} />
            })}
          </Menu>
        )}
      </DivMore>
      <ModalReport isOpen={modalReportsOpen} onDismiss={toggleModalReport} userAddress={userAddress} />
    </>
  )
}
const DivMore = styled.div<{ openMenu?: boolean }>`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid transparent;
  &:hover {
    border-color: ${({ theme }) => theme.secondary3};
  }
  position: relative;
  ${({ openMenu }) =>
    openMenu &&
    css`
      background-color: ${({ theme }) => theme.primary6};
    `}
`
const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: -2px;
  width: 150px;
  margin-top: 6px;
`
export default MoreFnc
