import { formatUnits } from 'ethers/lib/utils'

import { Event } from '../../models/Event'

const getDateFromTimestamp = (timestamp: number) => {
  const date = new Date(+timestamp * 1000)
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`
}

const getTimeFromTimestamp = (timestamp: number) => {
  const date = new Date(+timestamp * 1000)
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
}

export const parseEventToFormValues = (event: Event, decimals: number) => ({
  name: event.name,
  startDate: {
    date: getDateFromTimestamp(event.startTime),
    time: getTimeFromTimestamp(event.startTime),
  },
  endDate: {
    date: getDateFromTimestamp(event.endTime),
    time: getTimeFromTimestamp(event.endTime),
  },
  location: event.location,
  formattedAddress: event.formattedAddress,
  imageKey: event.imageKey,
  rewardPerFlexer: formatUnits(event.rewardPerFlexer, decimals),
  tokenRewardPool: formatUnits(event.tokenRewardPool, decimals),
  isPOAPEnabled: event.isPOAPEnabled,
})
