import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px;
  gap: 32px;

  width: 400.5px;
  height: 135px;

  background: rgba(130, 71, 229, 0.2);
  border: 1px solid #8247e5;

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`

export const BalanceText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: #7a7a7a;
  margin: 0;
`

export const AmountText = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
`
