import React, { ReactNode } from 'react'

import * as Styled from './styled'

interface FormInputError {
  children: ReactNode
}

export const FormInputError = ({ children }: FormInputError) => {
  return <Styled.FormInputError>{children}</Styled.FormInputError>
}
