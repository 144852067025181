import styled from 'styled-components/macro'

export const Address = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SectionHeader = styled.h4`
  font-weight: 500;
  margin: 16px 0;
`

export const Content = styled.div`
  display: flex;
  gap: 20px;
`

export const ContentItem = styled.div`
  flex: 1 1 50%;
`

export const AddressesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 280px;
  max-height: 280px;
  overflow-y: scroll;
  margin-bottom: 25px;
`

export const ErrorBox = styled.div`
  height: 56px;
`
