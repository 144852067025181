import React from 'react'

import { EditEventForm } from '../../components/EditEventForm'

interface EditProps {
  returnUrl?: string
}

export const Edit = ({ returnUrl }: EditProps) => {
  return <EditEventForm returnUrl={returnUrl} />
}
