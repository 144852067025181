import { formatUnits } from '@ethersproject/units'
import React from 'react'
import styled from 'styled-components/macro'

import IconInfo from '../../../../assets/icons/icon-info-circle.svg'
import { ButtonSecondary } from '../../../../components/Button'
import ColCs from '../../../../components/ColCs'
import IconPortal from '../../../../components/IconPortal'
import PaymentBox from '../../../../components/PaymentBox'
import RowCs from '../../../../components/RowCs'
import { ItemAttribute } from '../../../../entities/OptionType'

export const Confirm = ({
  values,
  back,
  submit,
  optionAttribute,
  nftDetail,
  payWithMatic,
  setPayWithMatic,
  saleInformation,
  updateSaleInformation,
  isBackButtonNotActive,
}: any) => {
  return (
    <ConfirmWrapper>
      <Header>
        <IconPortal SrcImageIcon={IconInfo} widthIcon={'48px'} heightIcon={'48px'} />
        <Heading>Confirmation</Heading>
        <SubTitle>Can you confirm the information below is correct?</SubTitle>
      </Header>
      <ConfirmBody>
        <BodyContent>
          <RowCs className={'cf-row'}>
            <ColCs className={'cf-col'} xs={12}>
              <FieldName>Full name</FieldName>
              <FieldValue>{values.fullName}</FieldValue>
            </ColCs>
            <ColCs className={'cf-col'} xs={12}>
              <FieldName>Address</FieldName>
              <FieldValue>{`${values.address}, ${values.zipCode} ${values.cityState}, ${values.country}`} </FieldValue>
            </ColCs>
            <ColCs className={'cf-col'} xs={12}>
              <FieldName>Phone number</FieldName>
              <FieldValue>{values.phoneNumber}</FieldValue>
            </ColCs>
            <ColCs className={'cf-col'} xs={12}>
              <FieldName>Email</FieldName>
              <FieldValue>{values.email}</FieldValue>
            </ColCs>
            <ColCs className={'cf-col'} xs={12}>
              <FieldName>Passport number</FieldName>
              <FieldValue>{values.passportNumber.toUpperCase()}</FieldValue>
            </ColCs>
            {optionAttribute?.map((option: ItemAttribute) => {
              const { name } = option
              return (
                <ColCs key={`${option.id}`} className={'cf-col'} xs={12}>
                  <FieldName>{name}</FieldName>
                  <FieldValue>{values[name]}</FieldValue>
                </ColCs>
              )
            })}
          </RowCs>
        </BodyContent>
        <PaymentBox
          saleInformation={saleInformation}
          payWithMatic={payWithMatic}
          setPayWithMatic={setPayWithMatic}
          saleAddress={nftDetail?.collection?.sale?.address}
          handleBuying={submit}
          updateSaleInformation={updateSaleInformation}
        >
          {saleInformation && saleInformation.latestPriceInErc20 && (
            <RowCs className={'row-attr'}>
              <ColCs xs={6}>
                <h4>Redemption Price</h4>
              </ColCs>
              <ColCs xs={6}>
                <h4 className={'field-value highlight'}>{formatUnits(saleInformation.latestPriceInErc20, 6)} USDT</h4>
              </ColCs>
            </RowCs>
          )}
        </PaymentBox>
      </ConfirmBody>
      {!isBackButtonNotActive && (
        <Footer>
          <CtaBack onClick={back}>GO BACK</CtaBack>
        </Footer>
      )}
    </ConfirmWrapper>
  )
}
const FieldName = styled.h5`
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary7};
  flex: 0 0 144px;
  max-width: 144px;
  padding-right: 12px;
  font-family: 'Din Pro Regular', sans-serif;
`
const FieldValue = styled.h5`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: ${({ theme }) => theme.primary10};
  flex: 0 0 calc(100% - 144px);
  max-width: calc(100% - 144px);
`
const ConfirmWrapper = styled.div`
  .row-attr {
    margin-bottom: 10px;
    h4 {
      font-family: 'Din Pro Regular', sans-serif;
      font-weight: 500;
      font-size: ${({ theme }) => theme.fontSizeText1};
      line-height: 1.4;
      color: ${({ theme }) => theme.secondary7};
      margin: 0;
      &.field-value {
        color: ${({ theme }) => theme.primary10};
        text-align: right;
      }
      &.highlight {
        color: ${({ theme }) => theme.primary6};
      }
    }
  }
`
const Header = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary3};
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Heading = styled.h3`
  line-height: 1.3333;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary10};
  margin: 24px 0 8px;
`
const SubTitle = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
`
const ConfirmBody = styled.div`
  padding-top: 30px;
`
const BodyContent = styled.div`
  .cf-row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .cf-col {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    h5 {
      margin: 0;
    }
  }
`
const Footer = styled.div``

const CtaBack = styled(ButtonSecondary)`
  margin-top: 10px;
  padding: 10px 24px;
  font-family: 'Din Pro Regular', sans-serif;
  cursor: pointer;
  color: ${({ theme }) => theme.primary10};
`
