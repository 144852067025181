import IconTrash from 'assets/icons/icon-trash.svg'
import IconLeft from 'assets/images/svg/icon-chevronleft.svg'
import IconRight from 'assets/images/svg/icon-chevronright.svg'
import { ButtonSecondary } from 'components/Button'
import Loader from 'components/Loader'
import { PaginationBox } from 'components/Pagination'
import SearchBox from 'components/SearchBox'
import moment from 'moment'
import Pagination from 'rc-pagination'
import Table from 'rc-table'
import React, { useCallback, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Minus } from 'react-feather'
import { Box, Flex } from 'rebass'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'

import EmptySearchBox from '../../../../components/EmptySearchBox'
import ModalConfirmSetRedemption from '../../../../components/Modal/ModalConfirm'
import ModalError from '../../../../components/Modal/ModalError'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
import { useApi } from '../../../../hooks/useApi'
import { useExportCSV } from '../../../../hooks/useExportCSV'
import { collectionsApi } from '../../../../state/collections/slice'
import { useAppDispatch } from '../../../../state/hooks'

interface PropsType {
  whitelist?: any
  setPage?: any
  setSearchTerm?: any
  isLoading: boolean
  refetchData?: any
  collectionAddress?: string
  collectionName?: string
}
const headers = [
  { label: 'User address', key: 'userAddress' },
  { label: 'Email', key: 'email' },
  { label: 'Created at', key: 'createdAt' },
]
const Whitelist = ({
  whitelist,
  setPage,
  setSearchTerm,
  isLoading,
  refetchData,
  collectionAddress,
  collectionName,
}: PropsType) => {
  const handleChangeSearch = (term: string) => {
    setSearchTerm(term)
  }

  const handleChangePage = (current: number) => {
    setPage(current)
  }
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [selectedCell, setSelectedCell] = useState<string>('')
  const { deleteFnc } = useApi()
  const dispatch = useAppDispatch()
  const { totalItems, currentPage, totalPages } = whitelist || { totalItems: 1, currentPage: 1, totalPages: 1 }
  const handleDelete = useCallback(
    (collectionAddress: any, totalItems: any, currentPage: any, totalPages: any) => {
      deleteFnc(
        {
          url: `${process.env.REACT_APP_SERVER_URL}admin-panel/whitelist`,
          data: {
            collectionAddress,
            userAddress: selectedCell,
          },
          callback: () => {
            setShowSuccess(true)
            if (totalItems !== 1 && totalItems % 8 === 1 && currentPage === totalPages) {
              setPage(currentPage - 1)
            }
            dispatch(collectionsApi.util.invalidateTags(['Whitelist']))
          },
          callbackFinally: () => {
            setShowConfirm(false)
          },
          errorCallback: (res) => {
            console.log(res)
            setShowError(true)
          },
        },
        true
      )
    },
    [selectedCell, deleteFnc, dispatch, setPage]
  )

  const columns: any = useMemo(() => {
    const allColumns = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 60,
        align: 'left',
        render(value: any, row: any, index: number) {
          return <span>{index + 1 + (whitelist?.currentPage - 1) * 8}</span>
        },
      },
      {
        title: 'Address',
        dataIndex: 'userAddress',
        key: 'userAddress',
        align: 'left',
        render(value: any) {
          return <div>{shortenAddress(value)}</div>
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 184,
        align: 'left',
        render(value: string) {
          return <div>{value === null ? <Minus /> : value} </div>
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'left',
        render(value: string) {
          return <div> [UTC] {moment.utc(value).format('hh:MM DD/MM/YYYY')}</div>
        },
      },
      {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        width: 50,
        render(value: any, row: any, index: number) {
          return (
            <div
              className={'delete'}
              //onClick={(e: React.MouseEvent<HTMLDivElement>) => handleDelete(e, row.collectionAddress, row.userAddress)}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                setSelectedCell(row.userAddress)
                setShowConfirm(true)
              }}
            >
              <img src={IconTrash} alt={'img_trash'} />
            </div>
          )
        },
      },
    ]
    return allColumns
  }, [whitelist?.currentPage])
  const downloadData = useExportCSV(totalItems, `search/whitelist/${collectionAddress}`)

  return (
    <Wrapper>
      <SectionTitle>Whitelist</SectionTitle>
      <CustomBox mb={4}>
        <Flex flexWrap="wrap" mx={-4} className={'tab-user_flex'}>
          <Box px={4} width={6 / 6} className={'tab-user_search'}>
            <SearchBox placeholder="Search by wallet address or email" onChange={handleChangeSearch} />
          </Box>
        </Flex>
      </CustomBox>
      {isLoading ? (
        <Flex p="4" justifyContent="center" alignContent={'center'}>
          <Loader size="48px" />
        </Flex>
      ) : (
        <Table
          rowKey="userAddress"
          columns={columns}
          data={whitelist?.data}
          className={'rc-table--cs'}
          emptyText={<EmptySearchBox text={'No data'} />}
        />
      )}
      {whitelist?.totalItems > 8 && (
        <PaginationBox>
          <Pagination
            onChange={(current) => {
              handleChangePage(current)
            }}
            current={currentPage}
            pageSize={8}
            total={whitelist.totalItems}
            prevIcon={() => (
              <SpanIcon>
                <img src={IconLeft} alt={'icon_left'} />
              </SpanIcon>
            )}
            nextIcon={() => (
              <SpanIcon>
                <img src={IconRight} alt={'icon_right'} />
              </SpanIcon>
            )}
          />
        </PaginationBox>
      )}
      {whitelist?.totalItems > 0 && (
        <BtnExport className="download">
          <CSVLink headers={headers} filename={`${collectionName} whitelist`} data={downloadData} target="_blank">
            Export to CSV
          </CSVLink>
        </BtnExport>
      )}
      {showConfirm && (
        <ModalConfirmSetRedemption
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => handleDelete(collectionAddress, totalItems, currentPage, totalPages)}
          subText={'Are you sure you want to delete address?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={'Error delete'} />
      <ModalSuccess isOpen={showSuccess} onDismiss={() => setShowSuccess(false)} message={'Delete successfully.'} />
    </Wrapper>
  )
}

export default Whitelist

const Wrapper = styled.div`
  width: 100%;
`
const SectionTitle = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.secondary7};
  line-height: 24px;
  margin-bottom: 24px;
`

const CustomBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  margin: auto;
`

const BtnExport = styled(ButtonSecondary)`
  padding: 0 !important;
  margin-top: 16px;
  a {
    padding: 16px 32px !important;
    width: 100%;
    display: block;
    color: ${({ theme }) => theme.primary10};
    text-decoration: none;
  }
`
const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`
