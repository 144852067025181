import { useCallback } from 'react'

import { useGetImage } from '../state/profile/hooks'
import { sliceUrl } from '../utils'

export const useFetchMedia = () => {
  const getImage = useGetImage()
  const handleFetchMedia = useCallback(
    async (mediaFile: any) => {
      let srcMediaCv: any

      if (mediaFile) {
        const typeMediaCv = mediaFile.type === 'video/quicktime' ? 'video/MOV' : mediaFile.type
        const imageMediaCv = await getImage(mediaFile.name, typeMediaCv)
        const { url, name } = imageMediaCv
        srcMediaCv = sliceUrl(url, name)
        if (srcMediaCv) {
          await fetch(url, {
            headers: {
              'Content-Type': mediaFile.type,
              'Cache-Control': 'public,max-age=31536000,immutable',
              'x-amz-acl': 'public-read',
            },
            body: mediaFile,
            method: 'PUT',
          })
        }
      }
      return srcMediaCv
    },
    [getImage]
  )
  return { handleFetchMedia }
}
