import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import { DefaultAn1Sale } from '../../../abis'
import abi from '../../../abis/default-an1-sale.json'
import { QueryKeys } from '../../../constants/queryKeys'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

interface UseRedemptionInformationProps {
  collectionSaleAddress: string
}

interface RedemptionInformation {
  ethBalance: BigNumber
  erc20Balance: BigNumber
  erc20Allowance: BigNumber
  latestPriceInMatic: BigNumber
  latestPriceInErc20: BigNumber
}

export const useRedemptionInformation = (
  params: UseRedemptionInformationProps,
  options?: UseQueryOptions<UseRedemptionInformationProps, any, RedemptionInformation>
) => {
  // const { library, account } = useActiveWeb3React()
  const { provider, account } = useParticle()

  return useQuery<UseRedemptionInformationProps, any, RedemptionInformation>(
    [QueryKeys.RedemptionInformation, { params, account }],
    // @ts-ignore
    async () => {
      if (!provider) {
        throw new Error('NOT_CONNECTED_TO_METAMASK')
      }

      const contract = new Contract(params.collectionSaleAddress, abi, provider) as DefaultAn1Sale

      const res = await contract.getRedemptionInformation(account ?? AddressZero)

      return {
        ethBalance: BigNumber.from(res[0]._hex),
        erc20Balance: BigNumber.from(res[1]._hex),
        erc20Allowance: BigNumber.from(res[2]._hex),
        latestPriceInMatic: BigNumber.from(res[3]._hex),
        latestPriceInErc20: BigNumber.from(res[4]._hex),
      } as RedemptionInformation
    },
    options
  )
}
