import CollectionItem from 'components/CollectionItem'
import React from 'react'
import { Box } from 'rebass'

const Watchlisted = (props: any) => {
  const { watchlistedItems } = props
  return (
    <Box py={4}>
      {watchlistedItems?.map((collection: any, index: number) => {
        return <CollectionItem key={index} data={collection} />
      })}
    </Box>
  )
}

export default Watchlisted
