import React from 'react'
import styled from 'styled-components/macro'

import TruncateText from '../../../components/TruncateText'
const Intro = () => {
  const textTruncate =
    'Another-1 is the ultimate web3 luxury fashion platform built on Polygon, where users can build, collect, stake, and trade metaverse-ready digital fashion backed by NFC authenticated physical goods. By repurposing fashion products via NFTs for metaverse interoperability; Another-1 serves as a one-stop shop for a new transcending asset class that merges realities to create a dynamic ecosystem of hypebeasts, designers, brands, and resellers secured on-chain.'

  return (
    <IntroWrapper id="about">
      <IntroContent>
        <TextLarge>
          We see our purpose being to merge the physical and digital modern luxury worlds, democratizing the creation
          and trading process of hype goods.
        </TextLarge>
        <Details>
          <SubTitle>About us</SubTitle>
          <TextDescription>
            <TruncateText text={textTruncate} />
          </TextDescription>
        </Details>
      </IntroContent>
    </IntroWrapper>
  )
}
const IntroWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  position: relative;
  @media (max-width: 1024px) {
    margin-top: 60px;
  }
`
const IntroContent = styled.div`
  position: relative;
`
const TextLarge = styled.p`
  font-size: ${({ theme }) => theme.display3};
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary10};
  margin: 0;
  text-transform: uppercase;
  font-family: 'DIN 1451 Std', sans-serif;
`
const Details = styled.div`
  margin-top: 32px;
`
const SubTitle = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary5};
  margin: 32px 0 12px;
`
const TextDescription = styled.div`
  span {
    font-size: ${({ theme }) => theme.fontSizeText1};
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    color: ${({ theme }) => theme.primary10};
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    color: ${({ theme }) => theme.primary10};
  }
`
export default Intro
