import React from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'

import IconChecked from '../../../assets/icons/icon_check.svg'
import IconPortal from '../../IconPortal'
import Modal from '../index'

const ModalWhitelisted = (props: any) => {
  const { isOpen, onDismiss } = props

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={450}>
      <ModalWrapper>
        <BoxReported>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <IconPortal SrcImageIcon={IconChecked} widthIcon={'72px'} heightIcon={'72px'} />
          </Flex>
          <Captions>Congratulations</Captions>
          <P>You have been whitelisted</P>
        </BoxReported>
      </ModalWrapper>
    </Modal>
  )
}
const ModalWrapper = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
`

const BoxSending = styled.div`
  min-height: 216px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const Captions = styled.h3`
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.display3};
  color: ${({ theme }) => theme.primary10};
  line-height: 1.25;
`

const P = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText3};
  color: ${({ theme }) => theme.primary10};
`

const BoxReported = styled(BoxSending)``
export default ModalWhitelisted
