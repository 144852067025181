// eslint-disable-next-line no-restricted-imports
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { useMemo } from 'react'

import { MaticLogoSmall } from '../../../../components/Icons/MaticLogoSmall'
import { TableCellName } from '../../../../components/TableCellName'
import { TableNoMinHeight } from '../../../../components/TableNoMinHeight'
import { shortenAddress } from '../../../../utils'
import { formatBignumberToDecimal } from '../../../../utils/formatBignumberToDecimal'
import { formatDateFromTimestamp } from '../../../../utils/formatDate'
import { ClerkActions } from '../../../PartnerPanel/components/ClerkActions'
import { useClerkListContext } from '../../context/ClerkListContext'
import { Clerk } from '../../models/Clerk'
import * as Styled from './styled'

export const ClerkTable = () => {
  const { clerks } = useClerkListContext()

  const parsedClerks = clerks.filter((clerk) =>
    BigNumber.from(clerk.clerk.maticBalanceInWei || '0').lt(parseUnits('5'))
  )

  const columns: any = useMemo(() => {
    return [
      {
        title: 'NAME',
        dataIndex: 'event.name',
        key: 'name',
        width: 80,
        align: 'left',
        className: 'secondary10',
        render: (value: number, { event }: Clerk) => {
          return <TableCellName>{event.name}</TableCellName>
        },
      },
      {
        title: 'START/END',
        dataIndex: 'event.startTime',
        key: 'startTime',
        align: 'left',
        width: 120,
        className: 'secondary10',
        render: (value: number, { event }: Clerk) => {
          return (
            <div>
              <div>{formatDateFromTimestamp(event.startTime)}</div>
              <div>{formatDateFromTimestamp(event.endTime)}</div>
            </div>
          )
        },
      },
      {
        title: 'ADDRESS',
        dataIndex: 'event.formattedAddress',
        key: 'formattedAddress',
        width: 120,
        align: 'left',
        render: (address: string, { event }: Clerk) => {
          if (event.formattedAddress && event.formattedAddress.length) {
            return event.formattedAddress
          }

          if (!event?.location?.latitude || !event?.location?.longitude) {
            return <>-</>
          }

          return (
            <div>
              {`lat: ${event.location.latitude.toFixed(2)}`}
              <br />
              {`lng: ${event.location.longitude.toFixed(2)}`}
            </div>
          )
        },
      },
      {
        title: 'CLERK',
        dataIndex: 'clerk.clerk.address',
        key: 'clerkAddress',
        width: 120,
        align: 'left',
        className: 'secondary10',
        render: (value: number, { clerk }: Clerk) => {
          return shortenAddress(clerk.address)
        },
      },
      {
        title: 'BALANCE',
        dataIndex: 'event.name',
        key: 'clerkBalance',
        width: 120,
        align: 'left',
        className: 'secondary10',
        render: (value: number, { clerk }: Clerk) => {
          return (
            <Styled.Centered>
              <MaticLogoSmall height={18} width={16} />
              {formatBignumberToDecimal(clerk.maticBalanceInWei || '0')}
            </Styled.Centered>
          )
        },
      },
      {
        title: 'ACTIONS',
        dataIndex: 'address',
        key: 'address',
        render: (value: number, clerk: Clerk) => {
          return (
            <ClerkActions
              eventId={clerk.event.eventId}
              clerkAddress={clerk.clerk.address}
              partnerAddress={clerk.event.userAddress}
            />
          )
        },
      },
    ]
  }, [])

  return (
    <TableNoMinHeight
      rowKey={function (record) {
        return record.clerk.address
      }}
      columns={columns}
      data={parsedClerks}
    />
  )
}
