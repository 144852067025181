import IconLeft from 'assets/images/svg/icon-chevronleft.svg'
import IconRight from 'assets/images/svg/icon-chevronright.svg'
import Loader from 'components/Loader'
import ModalConfirmSetRedemption from 'components/Modal/ModalConfirm'
import ModalSuccess from 'components/Modal/ModalSuccess'
import { PaginationBox } from 'components/Pagination'
import SearchBox from 'components/SearchBox'
import Pagination from 'rc-pagination'
import Table from 'rc-table'
import React, { useCallback, useMemo, useState } from 'react'
import { Minus } from 'react-feather'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'

import { ButtonTransparent } from '../../../../components/Button'
import { Thrash } from '../../../../components/Icons/Thrash'
import ModalError from '../../../../components/Modal/ModalError'
import { Role } from '../../../../constants/roles'
import { useApi } from '../../../../hooks/useApi'
import { useAppDispatch } from '../../../../state/hooks'
import { useGetProfile } from '../../../../state/profile/hooks'
import { usersManagementApi } from '../../../../state/usersManagement/slice'
import { shortenAddress } from '../../../../utils'
import { LockPartnerButton } from '../../../Users/components/LockPartnerButton'
import * as Styled from './styled'

interface TypeProps {
  isLoading: boolean
  dataRole: any
  onSearch: (term: string) => void
  setPage: any
}
export const RoleTable = ({ isLoading, dataRole, onSearch, setPage }: TypeProps) => {
  const dispatch = useAppDispatch()
  const { data: userProfile } = useGetProfile()
  const addressProfile = userProfile.address
  const { deleteFnc } = useApi()

  const [selectedCell, setSelectedCell] = useState<{ userAddress: string; role: string }>({
    userAddress: '',
    role: '',
  })
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const { totalItems, currentPage, totalPages } = dataRole || { totalItems: 1, currentPage: 1, totalPages: 1 }

  const columns: any = useMemo(() => {
    const allColumns = [
      {
        title: (
          <div className={'th_id'}>
            <span>#</span>
          </div>
        ),
        dataIndex: 'index',
        key: 'index',
        width: 60,
        align: 'left',
        className: 'secondary10',
        render(value: any, row: any, index: number) {
          return <span>{index + 1 + (currentPage - 1) * 8}</span>
        },
      },
      {
        title: 'ADDRESS',
        dataIndex: 'userAddress',
        key: 'userAddress',
        align: 'left',
        width: 120,
        className: 'secondary10',
        render(value: any) {
          return (
            <div className="address" style={{ textTransform: 'lowercase' }}>
              {shortenAddress(value)}
            </div>
          )
        },
      },
      {
        title: 'NAME',
        dataIndex: 'userName',
        key: 'userName',
        width: 184,
        align: 'left',
        render(value: string | undefined) {
          return <div>{!value ? <Minus /> : value}</div>
        },
      },
      {
        title: 'ROLE',
        dataIndex: 'role',
        key: 'role',
        align: 'left',
        className: 'secondary10',
        render(value: string) {
          return <span>{value === 'CREATOR' ? 'COLLECTION_OPERATOR' : value}</span>
        },
      },
      {
        title: 'Collection',
        dataIndex: 'collectionAddress',
        key: 'collectionAddress',
        align: 'left',
        className: 'secondary10',
        render(value: string) {
          return (
            <div className="address" style={{ textTransform: 'lowercase' }}>
              {value === '-' ? value : shortenAddress(value)}
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        width: 52,
        render(value: any, row: any) {
          return (
            <Styled.ActionButtonWrapper>
              {row.role === Role.PARTNER && <LockPartnerButton userAddress={row.userAddress} isLocked={row.isLocked} />}
              <ButtonTransparent
                onClick={() => {
                  setSelectedCell({ userAddress: row.userAddress, role: row.role })
                  setShowConfirm(true)
                }}
                disabled={row.userAddress.toLowerCase() === addressProfile.toLowerCase()}
              >
                <Thrash />
              </ButtonTransparent>
            </Styled.ActionButtonWrapper>
          )
        },
      },
    ]
    return allColumns
  }, [currentPage, addressProfile])

  const handleDelete = useCallback(
    (totalItems: any, currentPage: any, totalPages: any) => {
      const { userAddress, role } = selectedCell
      deleteFnc(
        {
          url: `${process.env.REACT_APP_SERVER_URL}admin-panel/role`,
          data: {
            role,
            userAddress,
          },
          callback: () => {
            setShowSuccess(true)
            if (totalItems !== 1 && totalItems % 8 === 1 && currentPage === totalPages) {
              setPage(currentPage - 1)
            }
            dispatch(usersManagementApi.util.invalidateTags(['UsersManagement']))
          },
          callbackFinally: () => {
            setShowConfirm(false)
          },
          errorCallback: (res) => {
            setShowError(true)
          },
        },
        true
      )
    },
    [selectedCell, deleteFnc, dispatch, setPage]
  )

  return (
    <Wrapper>
      <TitleRole>Role list</TitleRole>
      <SearchBoxWrap>
        <SearchBox placeholder={'Search by address or role'} onChange={onSearch} />
      </SearchBoxWrap>
      {isLoading ? (
        <Flex p="4" justifyContent="center" alignContent={'center'}>
          <Loader size="48px" />
        </Flex>
      ) : (
        <Table
          rowKey={function (record, index) {
            return record.userAddress + record.role
          }}
          columns={columns}
          data={dataRole?.data}
          className={'rc-table--cs'}
        />
      )}

      {!isLoading && dataRole?.totalItems > 7 && (
        <PaginationBox>
          <Pagination
            onChange={(current) => {
              setPage(current)
            }}
            pageSize={8}
            current={currentPage}
            total={dataRole.totalItems}
            prevIcon={() => (
              <SpanIcon>
                <img src={IconLeft} alt={'icon_left'} />
              </SpanIcon>
            )}
            nextIcon={() => (
              <SpanIcon>
                <img src={IconRight} alt={'icon_right'} />
              </SpanIcon>
            )}
          />
        </PaginationBox>
      )}
      {showConfirm && (
        <ModalConfirmSetRedemption
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => handleDelete(totalItems, currentPage, totalPages)}
          subText={'Are you sure you want to delete role for address?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={'Error delete'} />
      <ModalSuccess isOpen={showSuccess} onDismiss={() => setShowSuccess(false)} message={'Delete successfully.'} />
    </Wrapper>
  )
}
const Wrapper = styled.div``
const TitleRole = styled.h5`
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary7};
  margin: 0 0 12px 0;
`
const SearchBoxWrap = styled.div`
  margin-bottom: 32px;
`
const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`
