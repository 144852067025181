import logo from 'assets/logo/logo.svg'
import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { Box, Flex, Text } from 'rebass'
import styled from 'styled-components/macro'

import Links from './components/Links'
import Socials from './components/Socials'

const ProfileLinks = [
  { url: '/profile', label: 'profile' },
  // { url: '#', label: 'wallet' },
  { url: '/profile#tab?tab=collections', label: 'my collections' },
  { url: '/profile#tab?tab=likes', label: 'my favourites' },
]

const CompanyLinks = [
  { url: '/#about', label: 'about us' },
  { url: '/#faqs', label: 'faq' },
  // { url: '#', label: 'contact support' },
]

const Footer = () => {
  return (
    <FooterContainer>
      <Main>
        {/* <Row>
          <Newsletter />
        </Row> */}
        <Hr />
        <Flex flexWrap="wrap" mx={-4} className={'flex-md'}>
          <FooterColumn px={4} py={1} width={2 / 5} className={'w-100'}>
            <ImageIcon src={logo} alt="Another-1" />
            <Text pt={4} color="#c6c6c6" width={280}>
              <AboutProject className="primary">Building a permissionless platform</AboutProject>
              <AboutProject>Enhancing the secondary trading of sneakers and fashion collectibles</AboutProject>
              <Contact>
                Contact us at <a href="mailto:info@another-1.io">info@another-1.io</a>{' '}
              </Contact>
            </Text>
          </FooterColumn>
          <FooterColumn px={4} py={1} width={3 / 10}>
            <Text color="#c6c6c6" bg="primary" pb={4}>
              Profile
            </Text>
            {ProfileLinks.map((link, index) => {
              return (
                <SubLink key={index} smooth to={link.url}>
                  {link.label}
                </SubLink>
              )
            })}
          </FooterColumn>
          <FooterColumn px={4} width={3 / 10}>
            <Text color="#c6c6c6" bg="primary" pb={4}>
              Company
            </Text>
            {CompanyLinks.map((link, index) => {
              return (
                <SubLink key={index} smooth to={link.url}>
                  {link.label}
                </SubLink>
              )
            })}
          </FooterColumn>
        </Flex>
        <Flex justifyContent="space-between" mt={4} className={'footer__bottom'}>
          <Socials />
          <Links />
        </Flex>
      </Main>
    </FooterContainer>
  )
}
const FooterContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 65px 160px 45px;
  margin: 0 auto;
  color: ${({ theme }) => theme.white};
  @media (max-width: 1024px) {
    padding: 48px 64px;
    .footer__bottom {
      justify-content: normal;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 768px) {
    padding: 48px 16px;
    .flex-md {
      margin-left: -16px;
      margin-right: -16px;
    }
  }
  @media (max-width: 640px) {
    padding: 24px 20px;
  }
`
const Main = styled.div``

// const Row = styled.div`
//   display: flex;
// `

const Hr = styled.hr`
  border: 1px solid rgba(240, 240, 240, 0.16);
  margin-top: 50px;
  margin-bottom: 50px;
`

const ImageIcon = styled.img`
  display: block;
  width: 180px;
  vertical-align: middle;
  object-fit: contain;
`

const FooterColumn = styled(Box)`
  color: rgba(198, 198, 198, 1);
  @media (max-width: 768px) {
    &.w-100 {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 40px;
    }
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  @media (max-width: 640px) {
    &:not(.w-100) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
`

const SubLink = styled(Link)`
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  padding-bottom: 25px;
  font-weight: 500;
  transition: all 0.3s ease-in;
  color: ${({ theme }) => theme.white};

  &:hover {
    color: ${({ theme }) => theme.primary6};
  }
`
const AboutProject = styled.p`
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 20px;

  &.primary {
    color: ${({ theme }) => theme.primary5};
    text-transform: uppercase;
  }
`

const Contact = styled(AboutProject)`
  margin-block-start: 0.8em;
  color: ${({ theme }) => theme.secondary5};
  font-size: ${({ theme }) => theme.fontSizeText2};
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.secondary5};
    font-weight: bold;
  }
`

export default Footer
