import * as React from 'react'
export const Pin = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={18} fill="none" {...props}>
    <path
      fill="#00FFA3"
      d="M6.5 2.143c1.654 0 4.286 1.2 4.286 4.414 0 1.851-1.475 4.003-4.286 6.274C3.689 10.56 2.214 8.4 2.214 6.557c0-3.214 2.632-4.414 4.286-4.414Zm0-1.715c-2.803 0-6 2.109-6 6.129 0 2.674 1.997 5.494 6 8.443 4.003-2.949 6-5.769 6-8.443 0-4.02-3.197-6.129-6-6.129Z"
    />
    <path
      fill="#00FFA3"
      d="M6.5 4.714a1.72 1.72 0 0 0-1.714 1.714A1.714 1.714 0 1 0 6.5 4.714Zm-6 11.143h12v1.714H.5v-1.714Z"
    />
  </svg>
)
