import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Slider from 'react-slick'
import styled from 'styled-components/macro'

import Avatar from '../../../assets/images/avatar.svg'
import OverlayBottom from '../../../assets/images/img/mask_bottom.png'
import { CollectionType } from '../../../entities/Collection'
import gsapAnimations from '../../../utils/gsapAnimations'
import CardSlide from './CardSlide'

interface HeroProps {
  collections: CollectionType[]
  isLoading: boolean
}

const Hero = ({ collections, isLoading }: HeroProps) => {
  const refSlider = useRef<Slider | null>(null)
  const sliderContainerRef = useRef(null)
  const [sliderCard, setSliderCard] = useState<any>(null)
  const appendDotsFnc = useCallback((dots: any) => <ul>{dots}</ul>, [])

  const collectionsSort = useMemo(() => {
    if (!collections) {
      return []
    }

    return [...collections]
      .filter((item) => item.isDisplayed)
      .sort((collectionPrev: any, collectionNext: any) => {
        const nameA = collectionPrev.name.toUpperCase()
        const nameB = collectionNext.name.toUpperCase()
        if (nameA > nameB) {
          return -1
        }
        if (nameA < nameB) {
          return 1
        }

        return 0
      })
  }, [collections])
  const customPagingFnc = useCallback(
    (i: number) => {
      return (
        <div className="ft-slick__dots--custom">
          <img
            src={
              collectionsSort[i] && collectionsSort[i].media?.thumbnail ? collectionsSort[i].media.thumbnail : Avatar
            }
            alt={'thumb_slide'}
          />
        </div>
      )
    },
    [collectionsSort]
  )
  const settingSlider = {
    dots: true,
    infinite: true,
    fade: true,
    easing: 'linear',
    speed: 500,
    //autoplay: true,
    //autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: appendDotsFnc,
    customPaging: customPagingFnc,
    dotsClass: 'slick-dots slick-thumb',
    arrows: false,
  }
  useEffect(() => {
    setSliderCard(refSlider.current)
  }, [])
  useEffect(() => {
    gsapAnimations(sliderContainerRef.current)
  }, [])
  return (
    <HeroWrap>
      {(!isLoading && (
        <DivSlider ref={sliderContainerRef}>
          <Slider {...settingSlider} ref={refSlider} asNavFor={sliderCard}>
            {collectionsSort?.map((collectionItem: any, index) => {
              return <CardSlide dataSlide={collectionItem} key={index} />
            })}
            {/*<CardSlide dataSlide={collectionsSort[0]} />*/}
            {/*<CardSlide dataSlide={collectionsSort[1] ?? collectionsSort[0]} />*/}
            {/*<CardSlide dataSlide={collectionsSort[2] ?? collectionsSort[0]} />*/}
          </Slider>
          <MaskBottom>
            <img src={OverlayBottom} alt={'overlay'} />
          </MaskBottom>
        </DivSlider>
      )) || (
        <Skeleton
          height={'calc(100vh - 150px)'}
          duration={2}
          borderRadius={0}
          baseColor={'rgba(0, 0, 0, 0.5)'}
          highlightColor={'#323232'}
        />
      )}
    </HeroWrap>
  )
}
const HeroWrap = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
`

const DivSlider = styled.div`
  padding-bottom: 60px;
  position: relative;

  .slick-list {
    width: 100%;
    overflow: hidden;
  }

  .slick-slide {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .slick-thumb {
    bottom: 40px;
    max-width: 1440px;
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    li {
      width: 60px;
      height: 60px;
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      :not(.slick-active) {
        border: 4px solid ${({ theme }) => theme.secondary2};
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:hover {
        border: none;

        &::before {
          content: '';
          background-image: conic-gradient(rgba(0, 255, 163, 0.4) 20deg, transparent 320deg);
          width: 150%;
          height: 150%;
          position: absolute;
          animation: rotateBorder 3s linear infinite;
        }

        &::after {
          content: '';
          width: 50px;
          height: 50px;
          background-color: black;
          position: absolute;
          border-radius: 16px;
        }
      }

      &.slick-active {
        &::before {
          content: '';
          background-image: conic-gradient(#00ffa3 20deg, transparent 320deg);
          width: 150%;
          height: 150%;
          position: absolute;
          animation: rotateBorder 3s linear infinite;
        }

        &::after {
          content: '';
          width: 50px;
          height: 50px;
          background-color: black;
          position: absolute;
          border-radius: 16px;
        }
      }
    }

    .ft-slick__dots--custom {
      width: 48px;
      height: 48px;
      display: block;
      position: relative;
      z-index: 5;
      img {
        width: 100%;
        height: 100%;
        display: block;
        vertical-align: middle;
        border-radius: 16px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding-bottom: 30px;
    .slick-thumb {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @media (max-width: 768px) {
    .slick-slider {
      padding-bottom: 80px;
    }
    .slick-thumb {
      padding-left: 16px;
      padding-right: 16px;
      bottom: 0;
      justify-content: center;
    }
  }
`
const MaskBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 66px;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  @media (max-width: 1024px) {
    height: 36px;
  }
  @media (max-width: 768px) {
    height: 90px;
    z-index: -1;
  }
`
export default React.memo(Hero)
