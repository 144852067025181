import moment from 'moment'
import { Box, Text } from 'rebass/styled-components'
import styled from 'styled-components/macro'

import useTimer from '../../hooks/useTimer'

interface TypeProps {
  exactStart: Date
  status?: string
  exactEnd?: Date
  isUpcoming?: boolean
}
const TimeClock = ({ status, exactStart, exactEnd, isUpcoming = true }: TypeProps) => {
  const { days, hours, minutes, seconds } = useTimer(exactStart, exactEnd)

  return (
    <TimeClockWrapper className={status}>
      {(days >= 1 && (
        <div className="dateDiv">
          <TimeBox>
            <H4 className={status}>{moment(isUpcoming ? exactStart : exactEnd).format('MMM DD, YYYY')}</H4>
          </TimeBox>
          <Label sx={{ textTransform: 'uppercase', textAlign: 'right', fontSize: '12px', fontWeight: 400 }}>
            {moment(isUpcoming ? exactStart : exactEnd).format('HH:mm ZZ')}
          </Label>
        </div>
      )) || (
        <>
          <div className="countDiv">
            <TextTime>{isNaN(hours) ? '00' : hours.toString().padStart(2, '0')}</TextTime>
            <TextUnit>hrs</TextUnit>
          </div>
          <TwoDots>:</TwoDots>
          <div className="countDiv">
            <TextTime>{isNaN(minutes) ? '00' : minutes.toString().padStart(2, '0')}</TextTime>
            <TextUnit>mins</TextUnit>
          </div>
          <TwoDots>:</TwoDots>
          <div className="countDiv">
            <TextTime> {isNaN(seconds) ? '00' : seconds.toString().padStart(2, '0')} </TextTime>
            <TextUnit>secs</TextUnit>
          </div>
        </>
      )}
    </TimeClockWrapper>
  )
}
const TimeClockWrapper = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
  .countDiv {
    flex: 0 0 35px;
    max-width: 35px;
  }

  &.hours {
    justify-content: space-between !important;
  }

  .dateDiv {
    display: flex;
    padding: 5px 0;
    flex-direction: column;
  }

  &.not-whitelisted p,
  &.sale-ended p,
  &.limit-reached p {
    color: ${({ theme }) => theme.orange5};
  }

  &.sold-out p {
    color: ${({ theme }) => theme.purple5};
  }

  &.presale p,
  &.public p {
    color: ${({ theme }) => theme.primary5};
  }
`
const TextTime = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.display3};
  line-height: 1.25;
  color: ${({ theme }) => theme.primary5};
`
const TextUnit = styled.h6`
  margin-top: 8px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.secondary5} !important;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  text-transform: capitalize;
  margin-block-start: 0.1px;
  margin-block-end: 0.1px;
`
const TwoDots = styled.span`
  font-size: ${({ theme }) => theme.display3};
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary5};
`
const TimeBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const H4 = styled.h4`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: 'Din Pro Bold', sans-serif;
  margin-block-start: 0.1px;
  margin-block-end: 0.1px;
  color: ${({ theme }) => theme.primary5};

  &.not-whitelisted,
  &.sale-ended,
  &.limit-reached {
    color: ${({ theme }) => theme.orange5};
  }

  &.sold-out {
    color: ${({ theme }) => theme.purple5};
  }

  &.presale,
  &.publicSale {
    color: ${({ theme }) => theme.primary5};
  }

  &.days {
    color: ${({ theme }) => theme.blue5};
  }
  &.hours {
    color: ${({ theme }) => theme.primary5};
  }
`
const Label = styled(Text)`
  color: ${({ theme }) => theme.secondary5};
`

export default TimeClock
