import JSBI from 'jsbi'
import { useCallback, useEffect, useState } from 'react'

import useIsWindowVisible from './useIsWindowVisible'
import useParticle from './useParticle'

export function useBalance() {
  const { account, provider } = useParticle()
  const windowVisible = useIsWindowVisible()
  const [balance, setBalance] = useState<JSBI>()

  const updateBalance = useCallback(async () => {
    if (account && provider && windowVisible) {
      const signer = provider.getSigner()
      const balance = await signer.getBalance('latest')

      if (balance) {
        setBalance(JSBI.BigInt(balance))
      }
    }
  }, [account, provider])

  useEffect(() => {
    updateBalance()
  }, [updateBalance])

  return { balance, updateBalance }
}
