import styled from 'styled-components/macro'

export const PageWithSidePanelWrapper = styled.div`
  width: 100%;
`

export const PageWithSidePanelContainer = styled.div`
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
`
export const Flex = styled.div`
  display: flex;
`
export const SidebarPageWithSidePanelWrapper = styled.div`
  flex: 0 0 420px;
  max-width: 420px;
`
export const ContentPageWithSidePanelWrapper = styled.div`
  flex: 0 0 calc(100% - 430px);
  max-width: calc(100% - 430px);
  margin-left: 10px;
`
export const ContentPageWithSidePanel = styled.div`
  padding: 32px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(
      59.01% 242.73% at 50% 50%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(122, 123, 123, 0.14) 0.01%,
      rgba(175, 175, 175, 0.022) 100%
    );
  min-height: 430px;

  &.mt-1 {
    margin-top: 24px;
  }
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
