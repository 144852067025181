import { parseUnits } from 'ethers/lib/utils'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../../constants/routes'
import { useAnother1ERC20Decimals } from '../../../../hooks/useAnother1ERC20Decimals'
import { useGetProfile } from '../../../../state/profile/hooks'
import { dateTimeObjectToSecondsTimestamp } from '../../../../utils/dateTimeObjectToSecondsTimestamp'
import { useUploadImage } from '../../../Upload/hooks/useUploadImage'
import { useCreateEventInRegistry } from '../../hooks/useCreateEventInRegistry'
import { useUpdateEvent } from '../../hooks/useUpdateEvent'
import { EventForm, EventFormValues } from '../EventForm'

export const CreateEventForm = () => {
  const history = useHistory()
  const { data: profile } = useGetProfile()
  const { mutateAsync: uploadImage, isLoading: isUploadingImage } = useUploadImage()
  const { mutateAsync: editEvent, isLoading: isEditingEvent } = useUpdateEvent()
  const { mutateAsync: createEventInRegistry, isLoading } = useCreateEventInRegistry()
  const { data: decimals } = useAnother1ERC20Decimals({
    refetchOnWindowFocus: false,
  })

  const handleSubmit = async ({
    name,
    formattedAddress,
    startDate,
    endDate,
    rewardPerFlexer,
    tokenRewardPool,
    files,
    location,
    isPOAPEnabled,
  }: EventFormValues) => {
    const parsedStartDate = dateTimeObjectToSecondsTimestamp(startDate)
    const parsedEndDate = dateTimeObjectToSecondsTimestamp(endDate)

    const parsedRewardPerFlexer = parseUnits(String(rewardPerFlexer), decimals as number).toString()
    const parsedTokenRewardPool = parseUnits(String(tokenRewardPool), decimals as number).toString()

    const eventId = await createEventInRegistry({
      startTime: parsedStartDate,
      endTime: parsedEndDate,
      rewardPerFlexer: parsedRewardPerFlexer,
      tokenRewardPool: parsedTokenRewardPool,
      isPOAPEnabled,
    })

    let imageKey = ''

    if (files && files[0]) {
      imageKey = await uploadImage({
        file: files[0],
      })
    }

    await editEvent({
      data: {
        name,
        formattedAddress,
        imageKey,
        location,
        startTime: parsedStartDate,
        endTime: parsedEndDate,
        rewardPerFlexer: parsedRewardPerFlexer,
        tokenRewardPool: parsedTokenRewardPool,
        isPOAPEnabled,
        isCancelled: false,
      },
      eventId,
      partnerAddress: profile.address,
    })

    history.push(routes.partnerPanel.addClerks.replace(':eventId', eventId).replace(':partnerAddress', profile.address))
  }

  const isSubmitting = isLoading || isEditingEvent || isUploadingImage

  return <EventForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
}
