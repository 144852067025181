import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import IconChevrondown from '../../assets/images/svg/icon-chevrondown.svg'
export interface TypeFaq {
  title: string
  description: string
}
interface TypeProps {
  content: TypeFaq
  handleToggleClick: any
  isCollapse: boolean
  faqTypeOther?: boolean
}
const FAQItem = (props: TypeProps) => {
  const { content, handleToggleClick, isCollapse, faqTypeOther } = props
  const [heightFaq, setHeightFaq] = useState<string>('0px')
  const faqDes = useRef<any>(null)
  const handleClickItem = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleToggleClick()
    setHeightFaq(!isCollapse ? `${faqDes.current.scrollHeight}px` : '0px')
  }
  return (
    <FaqItemWrapper
      onClick={(event: React.MouseEvent<HTMLLIElement>) => handleClickItem(event)}
      faqType={faqTypeOther}
      className={isCollapse ? 'active' : ''}
    >
      <FaqTitle>{content.title}</FaqTitle>
      <IconBox aria-expanded={isCollapse} className={'icon-chevrondown'}>
        <img src={IconChevrondown} alt={'icon_chevrondown'} />
      </IconBox>
      <FaqDesc
        aria-expanded={isCollapse}
        dangerouslySetInnerHTML={{ __html: content.description }}
        ref={faqDes}
        style={{ maxHeight: `${heightFaq}` }}
      ></FaqDesc>
    </FaqItemWrapper>
  )
}
const FaqItemWrapper = styled.li<{ faqType?: boolean }>`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.secondary3};
  padding: 18px 62px 20px 20px;
  position: relative;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.secondary3};
  }
  ${({ faqType }) =>
    faqType &&
    css`
      border-width: 0 0 1px 0;
      border-color: ${({ theme }) => theme.secondary2};
      padding-left: 0;
      padding-right: 32px;
      &.active {
        border-color: ${({ theme }) => theme.primary5};
        & > h4 {
          color: ${({ theme }) => theme.primary5};
        }
      }
      &:hover {
        background-color: transparent;
      }
      & > h4 {
        font-family: 'DIN 1451 Std', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: ${({ theme }) => theme.display3};
        line-height: 1.25;
        &::before {
          display: none;
        }
        padding-left: 0;
      }
      & > .icon-chevrondown {
        right: 0;
      }
    `}
`
const FaqTitle = styled.h4`
  margin: 0;
  line-height: 1.4;
  color: ${({ theme }) => theme.white};
  padding: 0 20px;
  font-weight: 500;
  &::before {
    content: '';
    position: relative;
    width: 4px;
    height: 18px;
    background-color: ${({ theme }) => theme.primary6};
    left: -16px;
    top: 2px;
    display: inline-block;
  }
`
const FaqDesc = styled.div`
  font-size: ${({ theme }) => theme.fontSizeText1};
  line-height: 1.6;
  color: ${({ theme }) => theme.secondary7};
  overflow: hidden;
  max-height: 0;
  transition: all 0.25s ease-in;
  &[aria-expanded='true'] {
    margin-top: 20px;
  }
  &[aria-expanded='false'] {
    max-height: 0 !important;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary6};
  }
`
const IconBox = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 30px;
  transition: all 0.25s ease-in-out;
  &[aria-expanded='true'] {
    transform: rotateZ(-180deg);
  }
`
export default FAQItem
