import * as React from 'react'
export const Phygital = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.375 14a9.625 9.625 0 1 1 19.25 0 9.625 9.625 0 0 1-19.25 0ZM14 2.625C7.718 2.625 2.625 7.718 2.625 14S7.718 25.375 14 25.375 25.375 20.282 25.375 14 20.282 2.625 14 2.625Zm-.854 9.755L14 10.388l.854 1.992c.147.344.422.619.766.766l1.992.854-1.992.854a1.458 1.458 0 0 0-.766.766L14 17.612l-.854-1.992a1.458 1.458 0 0 0-.766-.766L10.388 14l1.992-.854c.344-.147.619-.422.766-.766Zm2.194-3.307c-.505-1.178-2.175-1.178-2.68 0l-1.076 2.51-2.51 1.077c-1.18.505-1.18 2.175 0 2.68l2.51 1.076 1.076 2.511c.505 1.178 2.175 1.178 2.68 0l1.076-2.51 2.511-1.077c1.178-.505 1.178-2.175 0-2.68l-2.51-1.076-1.077-2.51Z"
      clipRule="evenodd"
    />
  </svg>
)
