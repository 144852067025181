import styled from 'styled-components/macro'

export const FormLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary7};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  &.time-utc {
    position: relative;
    &::before {
      content: '[UTC]';
      position: absolute;
      top: 48px;
      left: 12px;
      z-index: 5;
      color: ${({ theme }) => theme.primary10};
    }
  }
  &.disabled {
    &::before {
      color: grey;
    }
  }
`

export const FormInput = styled.input`
  font-family: 'Din Pro Regular', sans-serif;
  padding: 12px 16px;
  border: 1.5px solid ${({ theme }) => theme.secondary2};
  border-radius: 2px;
  outline: none;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary2};
  color: ${({ theme }) => theme.secondary10};
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  &::placeholder {
    color: ${({ theme }) => theme.secondary5};
    font-weight: 600;
  }
  :disabled {
    color: grey;
  }
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.error {
    outline: 2px solid ${({ theme }) => theme.red4};
  }
`

export const FormControl = styled.div`
  &.unit-price {
    position: relative;
    & > input {
      padding-right: 56px;
    }
    &::after {
      content: 'USDT';
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: ${({ theme }) => theme.secondary7};
    }
  }
`
export const DatePickerDiv = styled.div`
  .react-datepicker-popper[data-placement^='bottom'] {
    z-index: 99;
  }
  .react-datepicker__input-container {
    input {
      font-family: 'Din Pro Regular', sans-serif;
      padding: 12px 16px 12px 56px;
      font-size: ${({ theme }) => theme.fontSizeText2};
      line-height: 1.5;
      color: ${({ theme }) => theme.primary10};
      background-color: ${({ theme }) => theme.secondary2};
      border: 1.5px solid ${({ theme }) => theme.secondary2};
      border-radius: 2px;
      width: 100%;
      outline: none;
      position: relative;
      :disabled {
        color: grey;
      }
      &::before {
        content: '[UTC]';
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
      ::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      :-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      ::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-family: 'Din Pro Regular', sans-serif;
  }
  .react-datepicker-time__header {
    font-family: 'Din Pro Regular', sans-serif;
    text-transform: uppercase;
  }
  .react-datepicker__month-container {
    font-family: 'Din Pro Regular', sans-serif;
  }
  .react-datepicker__triangle {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .react-datepicker-popper {
    transform: none !important;
    inset: auto !important;
  }
`

export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 15px;
`

export const ErrorMessage = styled.p`
  margin: 8px 0 0;
  font-family: 'Din Pro Regular', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeText3};
  color: ${({ theme }) => theme.red4};
  white-space: normal;
`

export const WarningDefineTime = styled.div`
  display: flex;
  margin-top: 12px;
  & > span {
    font-size: 14px;
    line-height: 1.43;
    color: ${({ theme }) => theme.secondary6};
    margin-left: 6px;
  }
`
export const FormTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px 16px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.secondary2};
  color: ${({ theme }) => theme.secondary10};
  font-family: 'Din Pro Regular', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  resize: none;
  border-radius: 4px;
  &::placeholder {
    color: ${({ theme }) => theme.secondary5};
    font-weight: 600;
  }
  &:hover {
    outline: 2px solid ${({ theme }) => theme.secondary5};
    background-color: ${({ theme }) => theme.secondary4};
  }
  &:focus {
    outline: 2px solid ${({ theme }) => theme.primary4};
    background-color: ${({ theme }) => theme.secondary1};
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.secondary1};
    width: 10px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.secondary3};
    width: 10px;
    border-radius: 4px;
  }
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary2};
  padding-right: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary7};
`
