import styled from 'styled-components/macro'

export const CollectionsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 24px;
`

export const CollectionsModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const CollectionsModalHeader = styled.h3`
  margin: 0;
`
