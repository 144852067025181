import styled, { css } from 'styled-components/macro'

import { ButtonPrimary } from '../../../../components/Button'

export const Dropdown = styled.div`
  position: relative;
  .select-cs__control--menu-is-open {
    border: 2px solid ${({ theme }) => theme.secondary4} !important;
    border-bottom: none !important;
  }
  .select-cs__menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`
export const FormSelectMulti = styled.div<{ isVisible?: boolean }>`
  position: absolute;
  width: 100%;
  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
  &.form-select-multi--role {
    .select-cs__control--menu-is-open {
      display: none;
    }
  }
`
export const SpanPlaceholder = styled.span`
  color: ${({ theme }) => theme.secondary5};
  font-weight: 600;
`
export const DivSelected = styled.div`
  height: 51px;
  font-family: 'Din Pro Regular', sans-serif;
  padding: 12px 16px;
  border: 1.5px solid ${({ theme }) => theme.secondary2};
  border-radius: 2px;
  outline: none;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary2};
  color: ${({ theme }) => theme.secondary10};
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  display: flex;
`
export const SpanIcon = styled.span<{ isVisible?: boolean }>`
  width: 24px;
  height: 24px;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      svg {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    `}
`
export const FormSelect = styled.div<{ isVisible?: boolean }>`
  .select-cs__menu-portal {
    z-index: 99 !important;
  }
`
export const CtaAddRole = styled(ButtonPrimary)`
  padding: 10px 16px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  height: 51px;
  align-self: flex-end;
`
export const DivFlex = styled.div`
  display: flex;
  margin-bottom: 15px;
  .row-cs {
    flex: 1;
  }
`
export const Error = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText4};
  line-height: 1.33;
  color: ${({ theme }) => theme.secondary7};
  margin: 8px 0 0 0;
`
