import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as IconEdit } from '../../../../assets/icons/icon-edit.svg'
import { ReactComponent as IconTrash } from '../../../../assets/icons/icon-trash.svg'
import Fallback from '../../../../assets/images/fallback.svg'
import { DividerDashed } from '../../../../components/Divider'
import ModalConfirm from '../../../../components/Modal/ModalConfirm'
import ModalError from '../../../../components/Modal/ModalError'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
import { useApi } from '../../../../hooks/useApi'
import { collectionsApi, useUpdateCollectionMutation } from '../../../../state/collections/slice'
import { useAppDispatch } from '../../../../state/hooks'
import EditCollectionLd from '../EditCollectionLd'
interface CollectionItemType {
  collection: any
  index: number
  onCloseAddFeatured: () => void
}
const CollectionItem = (props: CollectionItemType) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { collection, index, onCloseAddFeatured } = props
  const closeEdit = () => {
    setIsEdit(false)
  }
  const openEdit = () => {
    setIsEdit(true)
  }
  const { patch } = useApi()
  const [updateCollection] = useUpdateCollectionMutation()
  const dispatch = useAppDispatch()
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const handleRefetch = () => {
    dispatch(collectionsApi.util.resetApiState())
  }
  const handleSuccess = () => {
    setShowSuccess(true)
    setShowConfirm(false)
    onCloseAddFeatured()
  }
  const handleError = () => {
    setShowError(true)
    setShowConfirm(false)
    onCloseAddFeatured()
  }
  const handleClickHide = async () => {
    try {
      setShowConfirm(true)
      await updateCollection({
        patch,
        collectionAddress: collection.address,
        name: collection.name,
        media: {
          ...collection.media,
        },
        isDisplayed: false,
        handleSuccess,
        handleError,
        refetch: handleRefetch,
      })
    } catch (e) {
      console.log('Error submit', e)
    }
  }
  return (
    <CollectionItemWrapper className={'collection-item'} isEdit={isEdit}>
      <CardCollection>
        <Index>{index + 1}</Index>

        <CardInfo>
          <ImageCollection>
            <img src={collection?.media?.thumbnail || Fallback} alt={'collection'} />
          </ImageCollection>
          <InfoCollection>
            <Heading4>{collection.name}</Heading4>
          </InfoCollection>
        </CardInfo>
        <CardDetail>
          <Cta>
            {!isEdit && <IconEdit onClick={openEdit} />}
            <IconTrash onClick={handleClickHide} />
          </Cta>
        </CardDetail>
      </CardCollection>
      {isEdit && (
        <BoxEdit>
          <DividerDashed />
          <EditCollectionLd collection={collection} onClose={closeEdit} onCloseAddFeatured={onCloseAddFeatured} />
        </BoxEdit>
      )}
      {showConfirm && (
        <ModalConfirm
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => handleClickHide()}
          subText={'Are you sure you want to hide collection?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={'Hide Collection Failed'} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={'Hide Collection Successfully'}
      />
    </CollectionItemWrapper>
  )
}
const CollectionItemWrapper = styled.div<{ isEdit?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.secondary2};
  ${({ isEdit }) =>
    isEdit &&
    css`
      box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary2};
      border-bottom: 0;
    `}
`
const CardCollection = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  padding: 32px 20px;
`
const Index = styled.h4`
  line-height: 1.3;
  color: ${({ theme }) => theme.secondary10};
  margin: 0;
  width: max-content;
  align-self: center;
`
const CardInfo = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
`
const ImageCollection = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 2px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    vertical-align: middle;
    height: 100%;
    object-fit: cover;
  }
`
const InfoCollection = styled.div``
const Heading4 = styled.h4`
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-top: 0;
`
const CardDetail = styled.div`
  display: flex;
  gap: 24px;
  align-self: center;
`

const Cta = styled.div`
  display: flex;
  gap: 8px;
  svg {
    cursor: pointer;
    &:hover {
      path {
        fill: ${({ theme }) => theme.primary10};
      }
      rect {
        fill-opacity: 1;
      }
    }
  }
`
const BoxEdit = styled.div`
  padding: 0 20px 32px;
`
export default CollectionItem
