import * as React from 'react'
export const Lock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={24} height={24} {...props}>
    <title />
    <g data-name={1}>
      <path
        fill="currentColor"
        d="M380 450H143a15 15 0 0 1-15-15V213.66a15 15 0 0 1 15-15h237a15 15 0 0 1 15 15V435a15 15 0 0 1-15 15Zm-222-30h207V228.66H158Z"
      />
      <path
        fill="currentColor"
        d="M351.66 228.66H171.41a15 15 0 0 1-15-15v-60.54a105.13 105.13 0 0 1 210.25 0v60.54a15 15 0 0 1-15 15Zm-165.25-30h150.25v-45.54a75.13 75.13 0 0 0-150.25 0ZM261.54 352.67a46.5 46.5 0 1 1 46.5-46.5 46.55 46.55 0 0 1-46.5 46.5Zm0-63a16.5 16.5 0 1 0 16.5 16.5 16.52 16.52 0 0 0-16.5-16.5Z"
      />
      <path fill="currentColor" d="M261.54 389a15 15 0 0 1-15-15v-36.33a15 15 0 1 1 30 0V374a15 15 0 0 1-15 15Z" />
    </g>
  </svg>
)
