import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { QueryKeys } from '../constants/queryKeys'
import { useAnother1ERC20Contract } from './useAnother1ERC20Contract'

export const useAnother1ERC20Decimals = (options?: UseQueryOptions<any, any, number>) => {
  const contract = useAnother1ERC20Contract()

  return useQuery<any, any, number>(
    [QueryKeys.Erc20Decimals],
    async () => {
      if (!contract) {
        throw new Error('NOT_CONNECTED_TO_METAMASK')
      }

      return await contract.decimals()
    },
    {
      ...options,
      enabled: !!contract,
    }
  )
}
