import { Flex } from 'rebass'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'

import CopyHelper from '../../../components/Copy'
import TruncateText from '../../../components/TruncateText'

const DetailTab = (props: any) => {
  const { nftData } = props

  // const details = useMemo(() => {
  //   return nftData?.metadata?.details
  // }, [nftData])

  return (
    <>
      <>
        <DescriptionContent>
          {nftData?.description && (
            <Description>
              <h5>Description</h5>
              <TruncateText text={nftData?.description} />
            </Description>
          )}
        </DescriptionContent>
        <DivDetailInfo>
          <InfoItem>
            <Flex justifyContent="space-between" alignItems={'center'}>
              <FieldName>Collection</FieldName>
              <Data>{nftData?.collection?.name}</Data>
            </Flex>
          </InfoItem>
          <InfoItem>
            <Flex justifyContent="space-between" alignItems={'center'}>
              <FieldName>Creator</FieldName>
              <Data>{nftData?.creator?.name ?? shortenAddress(nftData?.creator?.address)}</Data>
            </Flex>
          </InfoItem>
          <InfoItem>
            <Flex justifyContent="space-between" alignItems={'center'}>
              <FieldName>Owner</FieldName>
              <Data>{nftData?.owner?.name ?? shortenAddress(nftData?.owner?.address)}</Data>
            </Flex>
          </InfoItem>
        </DivDetailInfo>
        <DivDetailInfo>
          <InfoItem>
            <Flex justifyContent="space-between" alignItems={'center'}>
              <FieldName>Blockchain</FieldName>
              <Data>Polygon</Data>
            </Flex>
          </InfoItem>
          <InfoItem>
            <Flex justifyContent="space-between" alignItems={'center'}>
              <FieldName>Address</FieldName>
              <Data style={{ display: 'flex', alignItems: 'center' }}>
                <CopyHelper toCopy={nftData?.collectionAddress}>
                  <TextAddress>{shortenAddress(nftData?.collectionAddress)}</TextAddress>
                </CopyHelper>
              </Data>
            </Flex>
          </InfoItem>
          <InfoItem>
            <Flex justifyContent="space-between" alignItems={'center'}>
              <FieldName>Token ID</FieldName>
              <Data>{nftData?.tokenId}</Data>
            </Flex>
          </InfoItem>
        </DivDetailInfo>
      </>
    </>
  )
}
const DescriptionContent = styled.div`
  padding-top: 32px;
`
const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  margin-bottom: 16px;
  h5 {
    margin: 0 0 16px 0;
    color: ${({ theme }) => theme.white};
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    margin: 0;
  }
  div {
    span {
      color: ${({ theme }) => theme.secondary6};
    }
  }
`
const DivDetailInfo = styled.div`
  padding: 24px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.secondary2};
  }

  & > div:last-of-type {
    margin-bottom: 0;
  }
`
const InfoItem = styled.div`
  margin-bottom: 8px;
`
const FieldName = styled.h5`
  color: ${({ theme }) => theme.secondary7};
  line-height: 1.5;
  margin: 0;
  text-transform: capitalize;
`
const Data = styled.p`
  color: ${({ theme }) => theme.secondary10};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.25;
`
const TextAddress = styled.span`
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary10};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizeText2};
  + span {
    color: ${({ theme }) => theme.secondary5};
  }
`
export default DetailTab
