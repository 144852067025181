import { ReactNode } from 'react'

import { Role } from '../../constants/roles'
import { UserRole } from '../../entities/Profile'
import NotAuthorized from '../NotAuthorized'

interface RestrictedComponentProps {
  userRoles?: UserRole[]
  permittedRoles?: Role[]
  children: ReactNode
  isPage?: boolean
}

export const RestrictedComponent = ({ userRoles, permittedRoles, children, isPage }: RestrictedComponentProps) => {
  if (!permittedRoles || !permittedRoles.length) {
    return <> {children} </>
  }

  if (!userRoles || !userRoles.length) {
    return null
  }

  const isPermitted = permittedRoles.some((role) => {
    return userRoles.some((userRole) => userRole.role === role && !userRole.isLocked)
  })

  if (!isPermitted && isPage) {
    return <NotAuthorized />
  }

  if (!isPermitted) {
    return null
  }

  return <> {children} </>
}
