import { createSlice } from '@reduxjs/toolkit'

export interface CollectionDetailType {
  pagination: PaginationType
}
interface PaginationType {
  currentPage: number
  limit: number
  data: {
    items: any
    nameItem: string
  }
  totalItems: number | null
  totalPages: number | null
}
const initialState: CollectionDetailType = {
  pagination: {
    currentPage: 1,
    limit: 16,
    data: {
      items: [],
      nameItem: '',
    },
    totalItems: null,
    totalPages: null,
  },
}
const collectionDetailSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    getCollectionItem(state, action) {
      const { currentPage, data, totalItems, totalPages } = action.payload
      state.pagination.data = { ...data }
      state.pagination.currentPage = currentPage
      state.pagination.totalItems = totalItems
      state.pagination.totalPages = totalPages
    },
  },
})
export const { getCollectionItem } = collectionDetailSlice.actions
export default collectionDetailSlice.reducer
