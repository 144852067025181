import { useBalance } from 'hooks/useBalance'
import React from 'react'

import { MaticLogo } from '../../../../components/Icons/MaticLogo'
import Loader from '../../../../components/Loader'
import { formatBignumberToDecimal } from '../../../../utils/formatBignumberToDecimal'
import * as Styled from './styled'

export const MaticBalance = () => {
  const { balance } = useBalance()

  const parsedBalance = formatBignumberToDecimal(String(balance || '0'))

  return (
    <Styled.Container>
      <MaticLogo />
      <Styled.TextContainer>
        <Styled.BalanceText>tokens balance</Styled.BalanceText>
        <Styled.AmountText> {!balance ? <Loader size="16px" /> : parsedBalance} </Styled.AmountText>
      </Styled.TextContainer>
    </Styled.Container>
  )
}
