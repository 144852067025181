import styled from 'styled-components/macro'

export const AddressContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary6};
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const PinContainer = styled.div`
  min-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`
