import React from 'react'
import styled, { css } from 'styled-components/macro'

interface TypeNftSample {
  link: string
  collection: string
  name: string
}
interface PropsType {
  nftSample: TypeNftSample[]
}
const CardNFT = (props: PropsType) => {
  const { nftSample } = props
  return (
    <CardWrapper>
      {nftSample?.map((item: TypeNftSample, index: number) => {
        return (
          <Card key={index} ariaRotate={index === 1} className={index === 0 ? 'above' : ''}>
            <CardBody>
              <DivImage>
                <img src={item.link} alt={'image_nft'} />
              </DivImage>
              <CardContent>
                <Title>{item.name}</Title>
                <Name>{item.collection}</Name>
              </CardContent>
            </CardBody>
          </Card>
        )
      })}
    </CardWrapper>
  )
}
const CardWrapper = styled.div`
  position: relative;
  padding-left: 52px;
  padding-bottom: 42px;
  @media (max-width: 1024px) {
    padding: 0;
  }
`
const Card = styled.div<{ ariaRotate?: boolean }>`
  padding: 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary1};
  border: 2px solid ${({ theme }) => theme.secondary2};
  box-shadow: 0 18px 44px ${({ theme }) => theme.black};
  border-radius: 4px;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &.above {
    &:hover {
      border-color: ${({ theme }) => theme.primary4};
      box-shadow: 0 10px 50px #093a29;
      & + div {
        border-color: ${({ theme }) => theme.primary4};
        box-shadow: 0 10px 50px #093a29;
      }
    }
  }
  ${({ ariaRotate }) =>
    ariaRotate &&
    css`
      transform: rotate(-6.41deg);
      transform-origin: 20% 10%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: calc(100% - 52px);
      &:hover {
        border-color: ${({ theme }) => theme.primary4};
        box-shadow: 0 10px 50px #093a29;
      }
      @media (max-width: 1024px) {
        display: none;
      }
    `}
  @media(max-width: 768px) {
    padding: 16px;
  }
`
const CardBody = styled.div``
const DivImage = styled.div`
  border: 1px solid ${({ theme }) => theme.primary2};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.secondary1};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    max-height: 260px;
  }
`
const CardContent = styled.div`
  margin-top: 16px;
`
const Title = styled.h3`
  font-family: 'Din Pro Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.33334;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};
  margin-bottom: 12px;
  margin-top: 0;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizeText2};
  }
`
const Name = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.primary6};
  margin: 0;
`
export default CardNFT
