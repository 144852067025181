import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseGetAllowedCollections {
  partnerAddress: string
  eventId: string
}

export interface IGetAllowedCollections {
  collectionAddress: string
  eventId: string
  createdAt: Date
  updatedAt: Date
}

export const useEventCollections = (
  params: UseGetAllowedCollections,
  options?: UseQueryOptions<UseGetAllowedCollections, AxiosError<any>, any>
) => {
  const { client } = useApiClientContext()

  return useQuery<UseGetAllowedCollections, AxiosError<any>, IGetAllowedCollections[]>(
    [QueryKeys.GetAllowedCollections, params],
    async () => {
      const result = await client.get(
        apiEndpoints.getAllowedCollections
          .replace('{partnerAddress}', params.partnerAddress)
          .replace('{eventId}', params.eventId),
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
        }
      )

      return result?.data
    },
    options
  )
}
