import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const usersManagementApi = createApi({
  reducerPath: 'usersManagement',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ['UsersManagement', 'Blacklist'],
  endpoints: (builder) => ({
    getRolesUsersManagement: builder.query({
      query: ({ keyword, page, limit }: { keyword?: string; page?: number; limit?: number }) => {
        return {
          url: 'search/roles',
          params: {
            keyword: keyword || '',
            limit: limit || 8,
            page: page || 1,
          },
        }
      },
      providesTags: ['UsersManagement'],
    }),
    addRoleUsersManagement: builder.mutation({
      query: ({ userAddress, role, post }: { userAddress: string; role: string; post: any }) => {
        return post(
          {
            url: `${process.env.REACT_APP_SERVER_URL}admin-panel/role`,
            data: {
              userAddress,
              role,
            },
          },
          true
        )
      },
      invalidatesTags: ['UsersManagement'],
    }),
    deleteRoleUserManagement: builder.mutation({
      query: ({ userAddress, role, deleteFnc }: { userAddress: string; role: string; deleteFnc: any }) => {
        return deleteFnc(
          {
            url: `${process.env.REACT_APP_SERVER_URL}admin-panel/role`,
            data: {
              userAddress,
              role,
            },
          },
          true
        )
      },
      invalidatesTags: ['UsersManagement'],
    }),
    getBlacklistedUsers: builder.query({
      query: ({ keyword, page, limit }: { keyword?: string; page?: number; limit?: number }) => {
        return {
          url: 'search/blacklist',
          params: {
            keyword: keyword || '',
            limit: limit || 8,
            page: page || 1,
          },
        }
      },
      providesTags: ['Blacklist'],
    }),
    blacklistUser: builder.mutation({
      query: ({
        userAddress,
        comment,
        post,
        callback,
        refetchBlacklist,
      }: {
        userAddress: string
        comment: string | null | undefined
        post: any
        callback: any
        refetchBlacklist: any
      }) => {
        return post(
          {
            url: `${process.env.REACT_APP_SERVER_URL}admin-panel/blacklist`,
            data: {
              userAddress,
              comment,
            },
            callback: () => {
              callback('success')
            },
            errorCallback: () => {
              callback('error')
            },
          },
          true
        )
          .then((res: any) => {
            if (res.status === 200) {
              refetchBlacklist()
            }
          })
          .catch((err: any) => {
            console.log(err, 'ERROR ADD BLACKLIST')
          })
      },
      invalidatesTags: ['Blacklist'],
    }),
    removeUserFromBlacklist: builder.mutation({
      query: ({ userAddress }: { userAddress: string }) => {
        return {
          url: `admin-panel/blacklist`,
          method: 'DELETE',
          body: { userAddress },
        }
      },
      invalidatesTags: ['Blacklist'],
    }),
  }),
})

export const {
  useGetRolesUsersManagementQuery,
  useAddRoleUsersManagementMutation,
  useDeleteRoleUserManagementMutation,
  useGetBlacklistedUsersQuery,
  useBlacklistUserMutation,
  useRemoveUserFromBlacklistMutation,
} = usersManagementApi
