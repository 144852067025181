/* eslint-disable @typescript-eslint/no-non-null-assertion */

import styled, { css } from 'styled-components/macro'

import TimeClock from '../../../../components/TimeClock'
import { getActivePeriod, getNextPeriod, SaleType } from '../../../../entities/Sale'

interface CollectionCountdownProps {
  sale?: SaleType
  isUpcoming?: boolean
}

const CollectionCountdown = ({ sale, isUpcoming = false }: CollectionCountdownProps) => {
  const buildDependingOnSaleStatus = () => {
    if (!sale || sale.status !== 1 || !sale.periods || sale.periods.length === 0) {
      return <HighLight className={'coming-soon'}>COMING SOON</HighLight>
    }

    const activePeriod = sale && getActivePeriod(sale)
    const nextPeriod = sale && getNextPeriod(sale)

    const from = new Date()
    const to = new Date()

    if (activePeriod || nextPeriod) {
      const period = activePeriod && !isUpcoming ? activePeriod : nextPeriod
      from.setTime(period!.timeFrom * 1000)
      to.setTime(period!.timeTo * 1000)

      return (
        <>
          <HighLight>
            {period?.saleType == 0 ? 'PRESALE ' : 'PUBLIC SALE '}
            <span>{isUpcoming || (!activePeriod && typeof nextPeriod != undefined) ? 'STARTS' : 'FINISHES'} IN</span>
          </HighLight>
          <TimeClock
            exactStart={from}
            exactEnd={to}
            isUpcoming={isUpcoming || (!activePeriod && typeof nextPeriod != undefined)}
          />
        </>
      )
    } else {
      return <HighLight>SALE STAGES HAVE FINISHED</HighLight>
    }
  }

  // todo add warning
  return <DivClock>{buildDependingOnSaleStatus()}</DivClock>
}
const DivClock = styled.div<{ warning?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: right;
  max-width: 230px;
  width: 100%;

  & > p:first-of-type {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizeText3};
    line-height: 1.3;
    color: ${({ theme }) => theme.secondary7};
    margin-block-start: 0.3px;
    margin-block-end: 0.3px;
  }

  .countDiv {
    & > p:first-child {
      color: ${({ theme }) => theme.primary5};
    }
  }

  ${({ warning }) =>
    warning &&
    css`
      .countDiv {
        & > p:first-child {
          color: ${({ theme }) => theme.primary5};
        }
      }
    `}
`
const HighLight = styled.span`
  color: ${({ theme }) => theme.primary5};
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Din Pro Regular', sans-serif;

  span {
    color: ${({ theme }) => theme.secondary7};
  }
  &.coming-soon {
    font-family: 'Din Pro Regular', sans-serif;
    text-align: center;
  }
`
export default CollectionCountdown
