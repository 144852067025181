import React from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonOutlineNeutral, ButtonPrimary } from '../../../../components/Button'
import * as Styled from './styled'

interface ContinueSkipButtonsProps {
  canContinue: boolean
  route: string
  areButtonsDisabled?: boolean
}

export const ContinueSkipButtons = ({ route, canContinue, areButtonsDisabled }: ContinueSkipButtonsProps) => {
  const history = useHistory()

  const handleContinue = () => {
    history.push(route)
  }

  return (
    <Styled.ButtonContainer>
      <ButtonOutlineNeutral onClick={handleContinue} disabled={areButtonsDisabled || canContinue}>
        Skip
      </ButtonOutlineNeutral>
      <ButtonPrimary onClick={handleContinue} disabled={areButtonsDisabled || !canContinue}>
        Continue
      </ButtonPrimary>
    </Styled.ButtonContainer>
  )
}
