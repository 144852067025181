import React from 'react'
import styled from 'styled-components/macro'

import IconReport from '../../assets/icons/icon_report.svg'
import { DropdownItemType } from '../../types'
import IconPortal from '../IconPortal'
interface PropsType {
  item: DropdownItemType
  onClick?: () => void
}
const DropdownItem = (props: PropsType) => {
  const { icon, name } = props.item
  const { onClick } = props
  return (
    <DropdownContainer onClick={onClick}>
      {icon && <IconPortal SrcImageIcon={IconReport} heightIcon={'24px'} widthIcon={'24px'} />}
      <Name>{name}</Name>
    </DropdownContainer>
  )
}
const DropdownContainer = styled.div`
  width: 100%;
  padding: 14px 20px;
  background-image: linear-gradient(180deg, #111111 0%, #1b1b1b 100%);
  border: 2px solid ${({ theme }) => theme.secondary3};
  display: flex;
`
const Name = styled.h5`
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 0 10px;
`
export default DropdownItem
