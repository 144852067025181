import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import axios from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { sliceUrl } from '../../../utils'

interface UseUploadImageProps {
  file: File
}

const getUploadUrl = async (key: string, type: string, authToken: string) => {
  const result = await axios.get(apiEndpoints.uploadUrl, {
    params: {
      key,
      type,
    },
    headers: {
      authorization: authToken,
    },
  })

  return result.data
}

const uploadImage = async (url: string, type: string, file: File) => {
  return await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'Cache-Control': 'public,max-age=31536000,immutable',
      'x-amz-acl': 'public-read',
    },
  })
}

export const useUploadImage = (options?: UseMutationOptions<any, any, UseUploadImageProps>) => {
  return useMutation<any, any, UseUploadImageProps>(async ({ file }) => {
    const type = !!file.type ? file.type.replace('/', '.') : 'image.jpg'

    const { url, name: returnedName } = await getUploadUrl(file.name, type, localStorage.getItem('authToken') as string)

    const srcImageAvatar = sliceUrl(url, returnedName)

    await uploadImage(url, file.type, file)

    return srcImageAvatar
  }, options)
}
