import { darken } from 'polished'
import { Button as RebassButton, ButtonProps as ButtonPropsOriginal } from 'rebass/styled-components'
import styled from 'styled-components/macro'

type ButtonProps = Omit<ButtonPropsOriginal, 'css'>

export const BaseButton = styled(RebassButton)<
  {
    padding?: string
    width?: string
    $borderRadius?: string
    altDisabledStyle?: boolean
  } & ButtonProps
>`
  padding: ${({ padding }) => padding ?? '16px'};
  width: ${({ width }) => width ?? '100%'};
  font-weight: 500;
  text-align: center;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.secondary1};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border-radius: 0px !important;
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
    pointer-events: all;
  }

  font-family: 'Din Pro Regular', sans-serif;
  text-transform: uppercase;
  font-weight: 500;

  transition: 0.5s !important;

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`

export const ButtonPrimary = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primary6};
  color: ${({ theme }) => theme.secondary1};
  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.primary7};
  }
  // &:active {
  //   background-color: ${({ theme }) => darken(0.1, theme.primary7)};
  // }
  &:disabled {
    background-color: ${({ theme }) => theme.primary2};
    color: ${({ theme }) => theme.secondary1};
    cursor: not-allowed;
    outline: none;
  }
`

export const ConnectButton = styled(ButtonPrimary)`
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 600;
  width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  border: none;
  &:hover {
    border: none;
  }
  a {
    display: block;
    width: 100%;
    text-decoration: none;
    color: ${({ theme }) => theme.secondary1};
  }
`

export const ButtonGray = styled(BaseButton)`
  background-color: ${({ theme }) => theme.secondary1};
  color: ${({ theme }) => theme.secondary10};
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.black)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.secondary1)};
  }
`

export const ButtonSecondary = styled(BaseButton)`
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.secondary3};
  &:focus,
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.secondary4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonOutline = styled(BaseButton)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.secondary4};
  padding: 10px;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.primary6};
  }
`

export const ButtonTransparent = styled(BaseButton)`
  background-color: transparent;
  padding: 6px;
  width: auto;
  color: ${({ theme }) => theme.textFollow};

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.primary6};
    color: #000;
  }
`

export const ButtonPrimaryAnother = styled.button`
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.secondary1};
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  background-color: ${({ theme }) => theme.primary6};
  cursor: pointer;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.primary8};
    border: 2px solid ${({ theme }) => theme.primary6};
  }
  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.primary2};
  }
`
export const ButtonHasIcon = styled.button`
  cursor: pointer;
  font-family: 'Din Pro Regular', sans-serif;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.blue5};
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.white};
  outline: none;
  border: none;
  :hover {
    background-color: ${({ theme }) => theme.blue7};
  }
`

export const BtnSubmit = styled(ButtonPrimary)`
  padding: 16px 32px !important;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 500 !important;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
  &[data-loading='true'] {
    background-color: ${({ theme }) => theme.primary2} !important;
  }
`

export const ButtonOutlineNeutral = styled(BaseButton)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.secondary2};
  color: #ffffff;
  padding: 10px;
  :hover,
  :focus {
    border: 2px solid ${({ theme }) => theme.secondary1};
  }
`
