import useParticle from 'hooks/useParticle'
import Pagination from 'rc-pagination'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import IconLeft from '../../../assets/images/svg/icon-chevronleft.svg'
import IconRight from '../../../assets/images/svg/icon-chevronright.svg'
import Item from '../../../components/Item'
import { PaginationBox } from '../../../components/Pagination'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { useApi } from '../../../hooks/useApi'
import { updateLike } from '../../../services'
import { useFetchCollectionItem, useGetCollectionDetail } from '../../../state/collections/hooks'
import { CollectionParams } from '../index'

const ListItem = () => {
  const { id } = useParams<CollectionParams>()
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const fetchCollectionItem = useFetchCollectionItem()
  const getCollectionDetail = useGetCollectionDetail()
  const { limit, totalItems, currentPage, data } = getCollectionDetail.pagination
  const { items: dataItem, nameItem } = data
  const [dataItemPast, setDataItemPast] = useState<any>()
  const { post } = useApi()

  const handleChangePage = async (currentPage: number, limit: number) => {
    const paramAccount = account ? account : null
    await fetchCollectionItem(id, paramAccount, currentPage, limit)
  }
  const handleClickLike = (id: string, collectionAddress: string) => {
    if (!account) {
      return
    }
    const dataItemSelected = dataItemPast.filter((item: any) => {
      return item.id === id
    })

    ;(async () => {
      const response = await updateLike(id, collectionAddress, post)
      if (response?.status === 200) {
        const { numberLikes, message } = response.data
        const isLiked = message === 'Like success' ? true : false
        dataItemSelected[0] = {
          ...dataItemSelected[0],
          numberLikes,
          isLiked,
        }
        const dataItemPastUpdate = dataItemPast.map((item: any) => {
          if (item.id === dataItemSelected[0].id) {
            item = {
              ...item,
              ...dataItemSelected[0],
            }
          }
          return item
        })
        setDataItemPast(dataItemPastUpdate)
      }
    })()
  }
  useEffect(() => {
    setDataItemPast(dataItem)
  }, [dataItem, account])
  return (
    <ListItemWrapper>
      <Grid>
        {dataItemPast?.map((item: any, key: any) => (
          <ItemBox key={key}>
            <Item dataItem={item} nameItem={nameItem} onClickLike={() => handleClickLike(item.id, item.collection)} />
          </ItemBox>
        ))}
      </Grid>
      <PaginationBox>
        <Pagination
          onChange={(current, pageSize) => {
            handleChangePage(current, pageSize)
          }}
          pageSize={limit}
          current={currentPage}
          total={(totalItems && totalItems) || limit}
          prevIcon={() => (
            <SpanIcon>
              <img src={IconLeft} alt={'icon_left'} />
            </SpanIcon>
          )}
          nextIcon={() => (
            <SpanIcon>
              <img src={IconRight} alt={'icon_right'} />
            </SpanIcon>
          )}
        />
      </PaginationBox>
    </ListItemWrapper>
  )
}
const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`

const ListItemWrapper = styled.div`
  margin-top: 32px;
`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
`
const ItemBox = styled.div`
  padding: 0 16px;
  flex: 0 0 25%;
  max-width: 25%;
`
export default ListItem
