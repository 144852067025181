import styled from 'styled-components/macro'

export const FormInputError = styled.p`
  margin: 8px 0 16px;
  font-family: 'Din Pro Regular', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeText3};
  color: ${({ theme }) => theme.red4};
  white-space: normal;
  min-height: 20px;
`
