import { ButtonTransparent } from 'components/Button'
import { BinIcon } from 'components/Icons/Bin'
import Table from 'rc-table'
import { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components/macro'

import { PenIcon } from '../../../../components/Icons/Pen'
import { routes } from '../../../../constants/routes'
import { useGetProfile } from '../../../../state/profile/hooks'
import { formatDateFromString, formatDateFromTimestamp } from '../../../../utils/formatDate'
import { Event } from '../../models/Event'
import { CancelEventModal } from '../CancelEventModal'
import * as Styled from './styled'

interface EventsTableProps {
  events: Event[]
  editPath?: string
}

export const EventsTable = ({ events, editPath }: EventsTableProps) => {
  const [cancellingEvent, setCancellingEvent] = useState<string | null>(null)
  const { data: profile } = useGetProfile()
  const theme = useContext(ThemeContext)

  const columns: any = useMemo(() => {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        width: 120,
        align: 'left',
        className: 'secondary10',
      },
      {
        title: 'START DATE',
        dataIndex: 'startTime',
        key: 'startTime',
        align: 'left',
        width: 120,
        className: 'secondary10',
        render: formatDateFromTimestamp,
      },
      {
        title: 'END DATE',
        dataIndex: 'endTime',
        key: 'endTime',
        width: 120,
        align: 'left',
        render: formatDateFromTimestamp,
      },
      {
        title: 'ADDRESS',
        dataIndex: 'formattedAddress',
        key: 'formattedAddress',
        width: 120,
        align: 'left',
        render: (address: string, object: Event) => {
          if (address && address.length) {
            return address
          }

          if (!object?.location?.latitude || !object?.location?.longitude) {
            return <>-</>
          }

          return (
            <div>
              {`lat: ${object.location.latitude.toFixed(2)}`}
              <br />
              {`lng: ${object.location.longitude.toFixed(2)}`}
            </div>
          )
        },
      },
      {
        title: 'DATE CREATED',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        align: 'left',
        render: formatDateFromString,
      },
      {
        title: 'ACTIONS',
        dataIndex: 'eventId',
        key: 'eventId',
        width: 120,
        render: (value: string, event: Event) => {
          return (
            <Styled.ActionsContainer>
              <Link
                to={(editPath || routes.editEvents)
                  .replace(':eventId', value)
                  .replace(':partnerAddress', event.userAddress)}
              >
                <PenIcon />
              </Link>
              <ButtonTransparent onClick={() => setCancellingEvent(value)}>
                <BinIcon color={theme.red6} />
              </ButtonTransparent>
            </Styled.ActionsContainer>
          )
        },
      },
    ]
  }, [theme.red6])

  return (
    <>
      {cancellingEvent && (
        <CancelEventModal
          isOpen={!!cancellingEvent}
          onClose={() => setCancellingEvent(null)}
          event={events?.find((ev) => ev.eventId === cancellingEvent)}
          partnerAddress={profile.address}
        />
      )}

      <Table
        rowKey={function (record) {
          return record.eventId
        }}
        columns={columns}
        data={events}
      />
    </>
  )
}
