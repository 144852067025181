import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface Data {
  collectionAddress: string
  tokenId: string
  information: any
}

interface UseFillShippingInformation {
  data: Data
}

export const useFillShippingInformation = (
  options?: UseMutationOptions<any, AxiosError, UseFillShippingInformation>
) => {
  const { client } = useApiClientContext()

  return useMutation<any, AxiosError, UseFillShippingInformation>(async ({ data }) => {
    const result = await client.post(apiEndpoints.fillShippingInformation, data, {
      headers: {
        authorization: localStorage.getItem('authToken') as string,
      },
    })

    return result?.data
  }, options)
}
