import React from 'react'
import styled from 'styled-components/macro'

import { OptionsAdd } from '../../../../types'
interface OptionAddContentType {
  option: {
    name: string
    sectionType: number
  }
  onClick: (type: OptionsAdd) => void
}
const Option = (props: OptionAddContentType) => {
  const { option, onClick } = props
  return (
    <OptionWrapper onClick={() => onClick(option.sectionType)}>
      <LabelText>{option.name}</LabelText>
    </OptionWrapper>
  )
}
const OptionWrapper = styled.div`
  cursor: pointer;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary3};
  &:hover {
    background-color: ${({ theme }) => theme.secondary3};
  }
`
const LabelText = styled.h5`
  margin: 0;
  line-height: 1.5;
  color: ${({ theme }) => theme.primary10};
`
export default Option
