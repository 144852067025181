import * as React from 'react'
export const Star = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14 3 3.708 7.242L26 11.403l-6 5.637L21.416 25 14 21.242 6.584 25 8 17.04l-6-5.637 8.292-1.161L14 3Z"
    />
  </svg>
)
