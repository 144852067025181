import React from 'react'

import { Calendar } from '../Icons/Calendar'
import * as Styled from './styled'

interface DateWithCalendarProps {
  date: string
}

export const DateWithCalendar = ({ date }: DateWithCalendarProps) => {
  return (
    <Styled.DateWithCalendarContainer>
      <Calendar />
      <span>{date}</span>
    </Styled.DateWithCalendarContainer>
  )
}
