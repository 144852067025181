import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { useApiClientContext } from '../../../context/ApiClientContext'
import { Event } from '../models/Event'

interface Data {
  name: string
  formattedAddress: string
  imageKey?: string
  location?: {
    latitude: number
    longitude: number
  }
  startTime: number
  endTime: number
  rewardPerFlexer: string
  tokenRewardPool: string
  isPOAPEnabled: boolean
  isCancelled: boolean
}

interface UseUpdateEventInRegistry {
  data: Data
  eventId: number
  partnerAddress: string
}

export const useUpdateEvent = (options?: UseMutationOptions<Event, AxiosError, UseUpdateEventInRegistry>) => {
  const { client } = useApiClientContext()

  return useMutation<Event, AxiosError, UseUpdateEventInRegistry>(async ({ data, eventId, partnerAddress }) => {
    const result = await client.put(
      apiEndpoints.event.replace('{partnerAddress}', partnerAddress).replace('{eventId}', String(eventId)),
      data,
      {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
      }
    )

    return result?.data
  }, options)
}
