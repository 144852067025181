import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface Asset {
  address: string
  number: number
  addressCollection: string
  timestamp: number
  mintedIds: number[]
  userAddress: string
  passportNumber: string
  itemSize: string
  email: string
  nftId: string
  collection: string
  fullName: string
  phoneNumber: string
  country: string
  cityState: string
  zipCode: string
  post: any
  tokenId: string
}

export const assetsApi = createApi({
  reducerPath: 'assets',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ['NFT', 'POST'],
  endpoints: (builder) => ({
    getNftDetail: builder.query({
      query: ({
        id,
        addressCollection,
        account,
      }: {
        id: string
        addressCollection: string
        account: string | null
      }) => {
        return {
          url: `assets/detail/${addressCollection}/${id}`,
          params: { address: account },
        }
      },
      providesTags: ['NFT'],
    }),
    updateNfts: builder.mutation<Asset, Partial<Asset>>({
      query: ({
        address,
        number,
        addressCollection,
        timestamp,
      }: {
        address: string
        number: number
        addressCollection: string
        timestamp: number
      }) => {
        return {
          url: 'assets/',
          method: 'PATCH',
          body: { address, number, collection: addressCollection, timestamp },
        }
      },
      invalidatesTags: ['NFT'],
    }),
    redeem: builder.mutation({
      query: ({ collectionAddress, tokenId, information, post }) => {
        return post(
          {
            url: `${process.env.REACT_APP_SERVER_URL}redemption/fillShippingInformation`,
            data: {
              collectionAddress,
              tokenId,
              information,
            },
          },
          true
        )
      },
      invalidatesTags: ['NFT'],
    }),
    getVerificationCode: builder.query({
      query: ({ search }: { search: string }) => {
        return {
          url: `redemption/scan${search}`,
        }
      },
    }),
    getLikedNFTs: builder.query({
      query: ({
        address,
        page,
        limit,
        keyword,
        sort,
      }: {
        address: string
        page?: number
        limit?: number
        keyword?: string
        sort?: string
      }) => {
        return {
          url: `search/liked/${address}`,
          params: {
            page: page || 1,
            limit: limit || 8,
            keyword: keyword || '',
            sort: sort || 'ASC',
          },
        }
      },
    }),
    getCollectedNFTs: builder.query({
      query: ({
        address,
        account,
        page,
        limit,
        keyword,
        sort,
      }: {
        address: string
        account?: string | null
        page?: number
        limit?: number
        keyword?: string
        sort?: string
      }) => {
        return {
          url: `search/collected/${address}`,
          params: {
            account: account || null,
            page: page || 1,
            limit: limit || 8,
            keyword: keyword || '',
            sort: sort || 'ASC',
          },
        }
      },
    }),
    getListNFTs: builder.query({
      query: ({
        userAddress,
        collectionAddress,
        account,
        page,
        limit,
      }: {
        userAddress: string | null
        collectionAddress: string | null
        account?: string | null
        page?: number | null
        limit?: number | null
      }) => {
        return {
          url: `assets/${collectionAddress}/${userAddress}`,
          params: {
            account: account || null,
            page,
            limit,
          },
        }
      },
    }),
  }),
})

export const {
  useGetNftDetailQuery,
  useGetLikedNFTsQuery,
  useGetCollectedNFTsQuery,
  useRedeemMutation,
  useGetVerificationCodeQuery,
  useGetListNFTsQuery,
} = assetsApi
