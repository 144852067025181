import '@reach/dialog/styles.css'
import 'polyfills'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-tippy/dist/tippy.css'

import * as Sentry from '@sentry/react'
import { WalletProvider } from 'context/WalletContext'
import { createBrowserHistory } from 'history'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

// import { createWeb3ReactRoot, Web3ReactProvider } from 'web3-react-core'
// import { NetworkContextName } from './constants/misc'
import { ApiClientContextProvider } from './context/ApiClientContext'
import App from './pages/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
// import getLibrary from './utils/getLibrary'

const history = createBrowserHistory({ basename: '/launchpad' })

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', process.env.REACT_APP_SERVER_URL || ''],
    }),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // Capture 100% of the transactions, reduce in production
  tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.1,
  // By default it should be enabled only in production, if you want to test in development change this
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.NODE_ENV ?? 'development',
})

// const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Router history={history}>
        {/* <Web3ReactProvider getLibrary={getLibrary}> */}
        {/* <Web3ProviderNetwork getLibrary={getLibrary}> */}
        <WalletProvider>
          <ApiClientContextProvider>
            <HelmetProvider>
              <ThemeProvider>
                <ThemedGlobalStyle />
                <App />
              </ThemeProvider>
            </HelmetProvider>
          </ApiClientContextProvider>
        </WalletProvider>
        {/* </Web3ProviderNetwork> */}
        {/* </Web3ReactProvider> */}
      </Router>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
