import { UseEventsQueryParams } from 'modules/Events/hooks/useEvents'
import { createContext, ReactNode, useContext } from 'react'
import { Flex } from 'rebass'

import Loader from '../../../components/Loader'
import { useGetProfile } from '../../../state/profile/hooks'
import { useClerks } from '../hooks/useClerks'
import { Clerk } from '../models/Clerk'

export interface ClerkListContextProps {
  clerks: Clerk[]
  refetch: () => void
}

const defaultValues: ClerkListContextProps = {
  clerks: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetch: () => {},
}

const ClerkListContext = createContext<ClerkListContextProps>(defaultValues)

export const useClerkListContext = () => {
  return useContext(ClerkListContext)
}

interface UserAccountContextProviderProps {
  children: ReactNode
  filters?: UseEventsQueryParams
}

export const ClerkListContextProvider = ({ children, filters = {} }: UserAccountContextProviderProps) => {
  const { data: profile, loading } = useGetProfile()

  const {
    data: clerks,
    isFetching,
    isLoading,
    refetch,
  } = useClerks(
    {
      partnerAddress: profile.address,
      queryParams: filters,
    },
    {
      enabled: Boolean(!loading && profile && profile.address),
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading || isFetching) {
    return (
      <Flex p="4" justifyContent="center" alignContent={'center'}>
        <Loader size="48px" />
      </Flex>
    )
  }

  if (!clerks) {
    return null
  }

  return <ClerkListContext.Provider value={{ clerks, refetch }}>{children}</ClerkListContext.Provider>
}
