import { Redirect, Route, Switch } from 'react-router-dom'

import { PageWithSidePanel } from '../../../../components/PageWithSidePanel'
import { RestrictedComponent } from '../../../../components/RestrictedComponent'
import { Role } from '../../../../constants/roles'
import { routes } from '../../../../constants/routes'
import { useGetProfile } from '../../../../state/profile/hooks'
import { Main as EventsScreen } from '../../../Events/screens/Main'
import { PartnerPanelSideMenu } from '../../components/PartnerPanelSidemenu'
import { Dashboard as PartnerDashboard } from '../Dashboard'

export const Main = () => {
  const { data: profile } = useGetProfile()
  return (
    <RestrictedComponent userRoles={profile.roles} permittedRoles={[Role.PARTNER]} isPage>
      <PageWithSidePanel sidePanel={<PartnerPanelSideMenu />}>
        <Switch>
          <Route path={routes.partnerPanel.eventManagement} component={EventsScreen} />
          <Route path={routes.partnerPanel.dashboard} component={PartnerDashboard} />
          <Redirect to={routes.partnerPanel.dashboard} />
        </Switch>
      </PageWithSidePanel>
    </RestrictedComponent>
  )
}
