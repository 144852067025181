import styled from 'styled-components/macro'

export const EventBoxContainer = styled.div`
  padding: 24px;
  width: 100%;
  display: flex;
  gap: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(
      59.01% 242.73% at 50% 50%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(122, 123, 123, 0.14) 0.01%,
      rgba(175, 175, 175, 0.022) 100%
    );
`

export const ImageContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  width: 100%;
  height: 194px;
  object-fit: cover;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

export const EventName = styled.h3`
  font-weight: 400;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 3px;
  margin-top: 0;
`

export const TokenTimeContainer = styled.div`
  width: 100%;
  background: rgba(35, 35, 35, 0.6);
  display: flex;
  padding: 14px;
`

export const TokenInfoContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  align-items: center;
  gap: 18px;
`

export const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TokenInfoHeader = styled.h5`
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #b9b9b9;
  margin-top: 0;
  margin-bottom: 2px;
`

export const TokenInfoData = styled.p`
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #00d98b;
  margin: 0;
`

export const TimeContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TimeHeader = styled.h5`
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #979797;
  margin: 0;
`

export const TimeData = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: #00d98b;
  margin: 0;
`
