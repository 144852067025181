import React from 'react'

import { formatDateFromTimestamp, secondsTimestampToTimestamp } from '../../../../../../utils/formatDate'
import { CountDownClock } from '../CountDownClock'
import * as Styled from './styled'

interface TimeInformationProps {
  startTime: number
  endTime: number
}

export const TimeInformation = ({ startTime, endTime }: TimeInformationProps) => {
  const now = new Date().getTime()
  const parsedEndTime = secondsTimestampToTimestamp(endTime)
  const parsedStartTime = secondsTimestampToTimestamp(startTime)

  if (parsedEndTime < now) {
    return (
      <Styled.TimeContainer>
        <Styled.TimeHeader>Ends At</Styled.TimeHeader>
        <Styled.TimeData ended>ENDED</Styled.TimeData>
      </Styled.TimeContainer>
    )
  }

  if (parsedEndTime > now && parsedStartTime < now) {
    return <CountDownClock fromTime={parsedEndTime} />
  }

  return (
    <Styled.TimeContainer>
      <Styled.TimeHeader>Starts At</Styled.TimeHeader>
      <Styled.TimeData>{formatDateFromTimestamp(startTime)}</Styled.TimeData>
      <Styled.TimeHeader>Ends At</Styled.TimeHeader>
      <Styled.TimeData>{formatDateFromTimestamp(endTime)}</Styled.TimeData>
    </Styled.TimeContainer>
  )
}
