import styled from 'styled-components/macro'

export const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.secondary7};
  margin-bottom: 12px;
  display: block;
  min-height: 20px;
`
