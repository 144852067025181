import { ReactComponent as AdminPanel } from 'assets/icons/admin-panel.svg'
import { ReactComponent as AdminPanelHover } from 'assets/icons/admin-panel_hover.svg'
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg'
import { ReactComponent as LikeIconHover } from 'assets/icons/like_hover.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg'
import { ReactComponent as ProfileIconHover } from 'assets/icons/user_hover.svg'
import { ReactComponent as WatchlistIcon } from 'assets/icons/watchlist.svg'
import { ReactComponent as WatchlistIconHover } from 'assets/icons/watchlist_hover.svg'
import Avatar from 'assets/images/avatar.svg'
import { OptionCardRight } from 'components/CardOption'
import PopupBox from 'components/PopupBox'
import useParticle from 'hooks/useParticle'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from 'rebass/styled-components'
import { useAccountPopup, useAccountPopupToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useGetProfile } from 'state/profile/hooks'
import { shortenAddress } from 'utils'

import { Role } from '../../constants/roles'
import { routes } from '../../constants/routes'
import { RestrictedComponent } from '../RestrictedComponent'
import * as Styled from './styled'

const menuLinks = [
  {
    url: routes.adminPanel.index,
    label: 'Admin Panel',
    icon: <AdminPanel />,
    iconHover: <AdminPanelHover />,
    permittedRoles: [Role.ADMIN],
  },
  {
    url: routes.partnerPanel.index,
    label: 'Partner Panel',
    icon: <AdminPanel />,
    iconHover: <AdminPanelHover />,
    permittedRoles: [Role.PARTNER],
  },
  {
    url: routes.userProfile,
    label: 'profile',
    icon: <ProfileIcon />,
    iconHover: <ProfileIconHover />,
    permittedRoles: [],
  },
  {
    url: routes.userProfileLikes,
    label: 'liked',
    icon: <LikeIcon />,
    iconHover: <LikeIconHover />,
    permittedRoles: [],
  },
  {
    url: routes.userProfileWatchlist,
    label: 'watchlist',
    icon: <WatchlistIcon />,
    iconHover: <WatchlistIconHover />,
    permittedRoles: [],
  },
]

export const AccountPopup = () => {
  const accountPopupOpen = useAccountPopup(ApplicationModal.ACCOUNT_POPUP)
  const toggleAccountPopup = useAccountPopupToggle()
  const { account } = useParticle()
  const { data: profile } = useGetProfile()
  const [isHovered, setIsHovered] = useState<number>()
  const history = useHistory()

  const goto = (link: string) => {
    history.push(link)
    toggleAccountPopup()
  }

  if (!accountPopupOpen) {
    return null
  }

  return (
    <PopupBox isOpen={accountPopupOpen} onDismiss={toggleAccountPopup} className="popup-user">
      <Styled.User>
        <Styled.UserImage>
          <Styled.ImageIcon src={profile?.imageUrl || Avatar} alt="Profile picture" />
        </Styled.UserImage>
        <Styled.DisplayName>{profile?.name || (account && shortenAddress(account))}</Styled.DisplayName>
      </Styled.User>
      <Box mt="4">
        {menuLinks.map((link, index) => {
          return (
            <RestrictedComponent key={link.label} userRoles={profile.roles} permittedRoles={link.permittedRoles}>
              <Styled.Link
                clickable={true}
                onMouseOver={() => setIsHovered(index)}
                onMouseOut={() => setIsHovered(-1)}
                onClick={() => goto(link.url)}
              >
                <Styled.IconWrapper>{isHovered === index ? link.iconHover : link.icon}</Styled.IconWrapper>
                <OptionCardRight>
                  <h5>{link.label}</h5>
                </OptionCardRight>
              </Styled.Link>
            </RestrictedComponent>
          )
        })}
      </Box>
    </PopupBox>
  )
}
