import { ButtonPrimary } from 'components/Button'
import Loader from 'components/Loader'
import ModalCodeAuthentication, { AssetDetails } from 'components/Modal/ModalCodeAuthentication'
import { useApi } from 'hooks/useApi'
import useParticle from 'hooks/useParticle'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Text } from 'rebass'
import styled from 'styled-components/macro'

import AuthenticationRequired from '../../components/AuthenticationRequired'
// import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { shortenAddress } from '../../utils'

const AssignNFC = () => {
  const { get } = useApi()
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [shippingInfoList, setShippingInfoList] = useState<any>([])
  const [selectedAssetDetails, setSelectedAssetDetails] = useState<AssetDetails>({ collectionAddress: '', tokenId: '' })
  const isMounted = useRef<boolean>(false)
  const fetch = async () => {
    try {
      await get(
        {
          url: `${process.env.REACT_APP_SERVER_URL}redemption/listRedeemedAssets`,
          callback: setShippingInfoList,
        },
        true
      )
    } catch (e: any) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    isMounted.current = true
    if (!account || !isMounted.current) {
      return
    }
    setLoading(true)
    fetch().then()
    return () => {
      isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return account ? (
    <div>
      <Header>
        <Title py="2" textAlign={'center'}>
          Assign NFC IDs
        </Title>
        <Subtitle>
          This section allows collection admins to assign NFC ids to assets with shipping information.
        </Subtitle>
      </Header>
      <Box py="4" width={'100%'}>
        {loading ? (
          <Loader size="24px" />
        ) : (
          <Table>
            <thead>
              <tr>
                <th>Collection</th>
                <th>NFTs ID</th>
                <th>NFCs ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {shippingInfoList && shippingInfoList.data ? (
                shippingInfoList.data.map((el: any, ix: React.Key | null | undefined) => {
                  return (
                    <tr key={ix}>
                      <td>{shortenAddress(el.collectionAddress)}</td>
                      <td>{el.tokenId}</td>
                      <td>{el.nfcId}</td>
                      <td>
                        <BtnAssign
                          onClick={() => {
                            setModalOpen(true)
                            setSelectedAssetDetails({
                              collectionAddress: el.collectionAddress,
                              tokenId: el.tokenId,
                            })
                          }}
                        >
                          Assign
                        </BtnAssign>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <p>empty list</p>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Box>
      <ModalCodeAuthentication
        isOpen={modalOpen}
        onDismiss={() => setModalOpen(!modalOpen)}
        name={'modal_assign'}
        reFetch={fetch}
        assetDetails={selectedAssetDetails}
      />
    </div>
  ) : (
    <AuthenticationRequired />
  )
}

export default AssignNFC

const Title = styled(Text)`
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.display3};
  font-weight: 600;
  text-transform: uppercase;
`

const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.secondary6};
  max-width: 400px;
  text-align: center;
`

const Header = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Table = styled.table`
  width: 100%;
  & th {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
  }
  & th,
  td {
    padding: 8px 15px;
  }
`

const BtnAssign = styled(ButtonPrimary)`
  padding: 10px 26px;
`
