import styled from 'styled-components/macro'

export const RewardBoxCointainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 32px;

  width: 259px;

  background: rgba(98, 98, 98, 0.19);
  border: 1px solid ${({ theme }) => theme.secondary3};

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

export const Title = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;

  color: #e2e2e2;
  margin: 0;
`

export const AmountCointainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 11px;

  width: 139px;
  height: 36px;
`

export const Amount = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #ffffff;
  margin: 0;
`
