import ConfirmationIcon from 'assets/icons/confirm.svg'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { ButtonPrimary } from '../../Button'
import Loader from '../../Loader'
import Modal from '../index'
interface ModalContactProps {
  isOpen: boolean
  onDismiss: () => void
  submit: () => void
  name?: string
  subText?: string
}
const Confirmation = (props: ModalContactProps) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const { isOpen, onDismiss, submit, subText } = props
  const handleConfirmation = useCallback(() => {
    setLoading(true)
    submit()
  }, [setLoading, submit])

  const handleClose = () => {
    onDismiss()
    setLoading(false)
  }

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setLoading(false)
  }, [isOpen])
  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} maxWidth={600}>
      <ModalContent>
        <ModalBody>
          <img src={ConfirmationIcon} alt="Icon" />
          <Text>
            <Caps>Confirm</Caps>
            {subText && <SubText>{subText}</SubText>}
          </Text>
          <CtaGroup>
            <CtaGotIt onClick={handleConfirmation} disabled={isLoading}>
              Confirm {isLoading && <Loader size={'24px'} stroke={'#151515'} />}
            </CtaGotIt>
            <CtaGotIt className={'exit'} onClick={handleClose} disabled={isLoading}>
              Back
            </CtaGotIt>
          </CtaGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
const ModalContent = styled.div`
  padding: 48px 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
`
const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .time {
    span {
      font-size: ${({ theme }) => theme.fontSizeText2};
      line-height: 1.5;
      color: ${({ theme }) => theme.secondary5};
      margin-bottom: 4px;
    }
    p {
      margin: 0;
      font-size: ${({ theme }) => theme.fontSizeText2};
      line-height: 1.3;
      color: ${({ theme }) => theme.primary10};
    }
  }
`
const Text = styled.div`
  margin: 32px 0;
`
const Caps = styled.p`
  font-size: ${({ theme }) => theme.display3};
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.orange5};
  margin: 0 0 8px 0;
  font-weight: 600;
`

const SubText = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText2};
  text-align: center;
  color: ${({ theme }) => theme.secondary5};
`
const CtaGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 90%;
`
const CtaGotIt = styled(ButtonPrimary)`
  display: flex;
  gap: 16px;
  font-size: 18px;
  line-height: 1.33;
  font-weight: 500;
  padding: 10px 12px;
  width: 100%;
  color: ${({ theme }) => theme.secondary1};
  text-transform: none;
  background-color: ${({ theme }) => theme.primary6};
  &[disabled] {
    background-color: ${({ theme }) => theme.primary2};
  }
  &.exit {
    background-color: ${({ theme }) => theme.secondary2};
    color: ${({ theme }) => theme.primary10};
  }
`
export default Confirmation
