import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import axios from 'axios'
import { ButtonPrimary } from 'components/Button'
import Loader from 'components/Loader'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import { useWalletPopupToggle } from 'state/application/hooks'
import styled from 'styled-components/macro'

import abi from '../../../abis/default-an1-sale.json'
import NotFoundPage from '../../../components/NotFoundPage'
import { CollectionType } from '../../../entities/Collection'
import { SalePeriodType } from '../../../entities/Sale'
import { MintingSaleInformation } from '../../../entities/SaleInformation'
import { useApi } from '../../../hooks/useApi'
import { getFullMerkle, MerkleWithProof } from '../../../utils/whitelist'
import Mint from './Mint'

interface BoxMintProps {
  collection: CollectionType
  isLoading: boolean
  isError: boolean
  reFetch: () => void
  activePeriod: SalePeriodType
  updateMintingSaleInformation: () => void
  userSaleData: MintingSaleInformation
}

const BoxMint = ({
  collection,
  isLoading,
  isError,
  reFetch,
  activePeriod,
  userSaleData,
  updateMintingSaleInformation,
}: BoxMintProps) => {
  // const { account, library } = useActiveWeb3React()
  const { account, provider } = useParticle()

  const toggleWalletPopup = useWalletPopupToggle()
  const { post } = useApi()
  const [whitelist, setWhitelist] = useState<string[]>([])

  const [payWithMatic, setPayWithMatic] = useState<boolean>(false)
  const [mintStatus, setMintStatus] = useState<string>()
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined)
  const [quantity, setQuantity] = useState<number>(1)

  useDocumentTitle(`${collection?.name ? collection.name + ' - Collection |' : ''}  Another-1 Launchpad`)

  useEffect(() => {
    if (collection && collection.sale)
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}whitelists/${collection.sale.address}.json`)
        .then((res: any) => {
          const _whitelist = res.data
          setWhitelist(_whitelist)
        })
        .catch((e: any) => {
          console.log(e)
        })
  }, [collection])

  const handleMint = async () => {
    if (!account) return
    try {
      setMintStatus('loading')

      if (activePeriod) {
        setLoadingMessage(undefined)

        const contract = new Contract(activePeriod.saleAddress, abi, provider)
        const merkle: MerkleWithProof = (await getFullMerkle(activePeriod.saleAddress, account)) as MerkleWithProof

        let tx
        console.log('proof', merkle.proof)

        if (payWithMatic) {
          tx = await contract.mint(activePeriod.index, quantity, merkle.proof, payWithMatic, {
            value: userSaleData.latestPriceInMatic.mul(quantity).toString(),
          })
        } else {
          tx = await contract.mint(activePeriod.index, quantity, merkle.proof, payWithMatic)
        }

        const receipt = await tx.wait()
        const mintingEvents = receipt.events.filter((el: any) => el.address === collection.address)
        const newIds = []
        for (let i = 0; i < mintingEvents.length; i++) {
          newIds.push(BigNumber.from(mintingEvents[i].topics[3]).toNumber())
        }

        post({
          url: `${process.env.REACT_APP_SERVER_URL}assets/confirm`,
          data: {
            collectionAddress: collection.address,
            tokenIds: newIds,
          },
        })

        setMintStatus('success')
        await updateMintingSaleInformation()
        reFetch()
      }
    } catch (error) {
      if (
        error?.data?.message.includes('DefaultAN1Sale::mint: Value sent is insufficient') ||
        error?.data?.message.includes('DefaultAN1Sale::mint: USDT allowance is insufficient') ||
        error?.data?.message.includes('DefaultAN1Sale::mint: USDT balance is insufficient') ||
        error?.data?.message.includes('insufficient funds for gas') ||
        error?.data?.message.includes('insufficient funds for transfer')
      ) {
        setMintStatus('insufficient-balance')
      } else {
        setMintStatus('error')
        reFetch()
        console.log(error)
      }
    }
  }

  const getState = () => {
    if (collection && activePeriod) {
      if (account && activePeriod.saleType === 0 && !whitelist.includes(account)) {
        return 'not-whitelisted'
      }
      if (userSaleData?.totalMintedInCollection === userSaleData?.maxMintableInCollection) {
        return 'sold-out'
      }
      if (collection?.status === 3 || activePeriod?.maxAllocation === userSaleData?.totalMintedInPeriod) {
        return 'activePeriod-ended'
      }
      if (activePeriod?.maxPerPeriod === userSaleData.userMintedInPeriod) {
        return 'limit-reached'
      }
      if (!activePeriod) {
        return 'sale-ended'
      }
    }

    return ''
  }

  const getBoxContent = () => {
    const state = getState()
    if (state === 'activePeriod-ended') {
      return <H3 className={state}>The sale has ended</H3>
    }
    if (state === 'sold-out') {
      return <H3 className={state}>Sold out!</H3>
    }
    if (state === 'sale-ended') {
      return <H3 className={'sold-out'}>Sale has ended!</H3>
    }
    if (mintStatus === 'loading') {
      return (
        <>
          <H3 className="presale">Minting your NFT</H3>
          <P>{loadingMessage ? loadingMessage : 'Your NFTs are being minted...'}</P>
          <Loader size={'38px'} />
        </>
      )
    }
    if (mintStatus === 'insufficient-balance') {
      return (
        <>
          <H3 className={'error'}>You don&apos;t have enough balance!</H3>
          <Button onClick={() => setMintStatus('')}>Back to Minting</Button>
        </>
      )
    }
    if (mintStatus === 'success') {
      return (
        <>
          <SuccessMark />
          <H3 className="presale">Congratulations!</H3>
          <P>You have minted {quantity} NFTs successfully</P>
          <Button onClick={() => setMintStatus('')}>Back to Minting</Button>
        </>
      )
    }
    if ((collection && isError) || mintStatus === 'error') {
      return (
        <>
          <H3 className={mintStatus}>Something went wrong!</H3>
          <Button onClick={() => setMintStatus('')}>Back to Minting</Button>
        </>
      )
    }
    return (
      <>
        {collection && activePeriod && activePeriod.maxAllocation === userSaleData?.totalMintedInPeriod ? (
          <>
            <H3 className={state}>SOLD OUT!</H3>
            <P>The collection has been sold out</P>
          </>
        ) : collection && activePeriod && activePeriod.maxPerPeriod === userSaleData?.userMintedInPeriod ? (
          <>
            <H3 className={state}>Limit reached!</H3>
            <P>
              You have already reached the maximum amount of <b>{activePeriod.maxPerPeriod} NFTs </b> minted
            </P>
          </>
        ) : state === 'not-whitelisted' ? (
          <>
            <H3 className={state}>You are not whitelisted!</H3>
            <P>Please make sure you are connected with the right wallet account</P>
          </>
        ) : (
          activePeriod && (
            <div>
              {account ? (
                <Mint
                  quantity={quantity}
                  setQuantity={setQuantity}
                  handleMint={handleMint}
                  updateMintingSaleInformation={updateMintingSaleInformation}
                  activePeriod={activePeriod}
                  payWithMatic={payWithMatic}
                  setPayWithMatic={setPayWithMatic}
                  userSaleData={userSaleData}
                />
              ) : (
                <>{!account && <CtaConnect onClick={toggleWalletPopup}>Connect to mint</CtaConnect>}</>
              )}
            </div>
          )
        )}
      </>
    )
  }

  return (
    <BoxMinWrapper className={isLoading ? 'loading' : getState()}>
      {isLoading ? <Loader size={'38px'} /> : collection ? getBoxContent() : <NotFoundPage />}
    </BoxMinWrapper>
  )
}
const BoxMinWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 140px;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin-bottom: 50px;
  border: 1.5px solid ${({ theme }) => theme.primary6};
  span {
    color: ${({ theme }) => theme.primary6};
  }

  &.not-whitelisted,
  &.sale-ended,
  &.limit-reached {
    border: 2px solid ${({ theme }) => theme.orange5} !important;
    span {
      color: ${({ theme }) => theme.orange5} !important;
    }
  }

  &.sold-out {
    border: 2px solid ${({ theme }) => theme.purple5} !important;
    span {
      color: ${({ theme }) => theme.purple5} !important;
    }
  }
`
const CtaConnect = styled(ButtonPrimary)`
  font-family: 'Din Pro Bold', sans-serif;
  padding: 10px 24px;
  max-width: 185px;
  margin: 0 auto;
`

const H3 = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: ${({ theme }) => theme.red1};
  text-align: center;

  &.not-whitelisted,
  &.sale-ended,
  &.limit-reached {
    color: ${({ theme }) => theme.orange5};
  }

  &.sold-out {
    color: ${({ theme }) => theme.purple5};
  }

  &.presale,
  &.public {
    color: ${({ theme }) => theme.primary5};
  }

  &.error {
    color: ${({ theme }) => theme.red5};
  }
`

const P = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textFollow};
  text-align: center;
`

const SuccessMark = styled(SuccessIcon)`
  margin-bottom: 20px;
`

const Button = styled(ButtonPrimary)`
  width: 70%;
  margin-top: 25px;
`

export default BoxMint
