import { useQueryClient } from '@tanstack/react-query'
import { ButtonTransparent } from 'components/Button'
import { useState } from 'react'

import IconClose from '../../../../assets/images/svg/icon-close.svg'
import IconPortal from '../../../../components/IconPortal'
import Loader from '../../../../components/Loader'
import { QueryKeys } from '../../../../constants/queryKeys'
import { useGetProfile } from '../../../../state/profile/hooks'
import { ContinueSkipButtons } from '../../../Events/components/ContinueSkipButtons'
import { useDeleteClerksFromEventInRegistry } from '../../hooks/useDeleteClerksFromEventInRegistry'
import { useDeleteEventClerk } from '../../hooks/useDeleteEventClerk'
import { useEventClerks } from '../../hooks/useEventClerks'
import * as Styled from './styled'

interface ClerksListProps {
  eventId: string | null
  continueSkipRoute?: string
  areButtonsDisabled?: boolean
}

export const ClerksList = ({ eventId, continueSkipRoute, areButtonsDisabled }: ClerksListProps) => {
  const { data: profile, loading } = useGetProfile()

  const { data: clerks, isLoading } = useEventClerks(
    {
      partnerAddress: profile.address,
      // @ts-ignore
      eventId,
    },
    {
      enabled: Boolean(!loading && profile && profile.address && eventId),
    }
  )

  const { mutateAsync: deleteClerk } = useDeleteEventClerk()
  const { mutateAsync: deleteClerkInRegistry } = useDeleteClerksFromEventInRegistry()
  const queryClient = useQueryClient()

  const [removedClerk, setRemovedClerk] = useState<string | null>(null)

  const handleDeleteClerk = async (address: string) => {
    setRemovedClerk(address)

    try {
      await deleteClerkInRegistry({
        clerkAddresses: [address],
      })

      await deleteClerk({
        eventId: Number(eventId),
        partnerAddress: profile.address,
        clerkAddress: address,
      })

      await queryClient.invalidateQueries([QueryKeys.EventClerks])
    } catch (e) {
      console.error(e)
    } finally {
      setRemovedClerk(null)
    }
  }

  if (isLoading) {
    return (
      <Styled.ClerksListContainer>
        <Styled.ClerksListHeader> Clerks List </Styled.ClerksListHeader>
        <Loader size="24px" />
      </Styled.ClerksListContainer>
    )
  }

  if (!clerks || !clerks.length) {
    return (
      <>
        <Styled.ClerksListContainer>
          <Styled.ClerksListHeader> Clerks List </Styled.ClerksListHeader>
          <p> Empty </p>
        </Styled.ClerksListContainer>
        {continueSkipRoute && (
          <ContinueSkipButtons canContinue={false} route={continueSkipRoute} areButtonsDisabled={areButtonsDisabled} />
        )}
      </>
    )
  }

  return (
    <>
      <Styled.ClerksListContainer>
        <Styled.ClerksListHeader> Clerks List </Styled.ClerksListHeader>
        {clerks.map(({ address }, index: number) => (
          <Styled.ClerkListItem key={address}>
            <div>
              {index + 1}. {address}
            </div>
            {removedClerk === address ? (
              <Loader stroke="#ffffff" size="14px" style={{ margin: 6 }} />
            ) : (
              <ButtonTransparent disabled={!!removedClerk} onClick={() => handleDeleteClerk(address)}>
                <IconPortal SrcImageIcon={IconClose} widthIcon={'14px'} heightIcon={'14px'} />
              </ButtonTransparent>
            )}
          </Styled.ClerkListItem>
        ))}
      </Styled.ClerksListContainer>
      {continueSkipRoute && (
        <ContinueSkipButtons
          canContinue={Boolean(clerks.length)}
          route={continueSkipRoute}
          areButtonsDisabled={areButtonsDisabled}
        />
      )}
    </>
  )
}
