import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { useApiClientContext } from '../../../context/ApiClientContext'

interface UseConfirmRedemption {
  collectionAddress: string
  tokenId: string
}

export const useConfirmRedemption = (options?: UseMutationOptions<any, AxiosError, UseConfirmRedemption>) => {
  const { client } = useApiClientContext()

  return useMutation<any, AxiosError, UseConfirmRedemption>(async ({ collectionAddress, tokenId }) => {
    const result = await client.post(
      apiEndpoints.confirmRedemption.replace(':collectionAddress', collectionAddress).replace(':tokenId', tokenId),
      {},
      {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
      }
    )

    return result?.data
  }, options)
}
