import axios from 'axios'

import { useFetchProfile } from '../state/profile/hooks'
import useParticle from './useParticle'

export interface Props {
  url: string
  data?: any
  callback?: (res: any) => void
  callbackFinally?: () => void
  errorCallback?: (error: any) => void
  config?: any
}

export function useApi() {
  // const { account, library } = useWeb3React()
  const { account, personalSign } = useParticle()

  const handleFetchProfile = useFetchProfile()

  const fulfillChallenge = async (apiCall: (props: Props, retry: boolean, token: string) => void, props: Props) => {
    if (!account) return
    const getChallengeURL = process.env.REACT_APP_SERVER_URL + `auth/${account}`
    await axios.get(getChallengeURL, { responseType: 'json' }).then(async (challenge) => {
      // const signer = await library.getSigner()
      const signature = await personalSign?.(`${challenge.data.purpose}${challenge.data.challenge}`)
      if (signature) {
        const resVerificationURL = process.env.REACT_APP_SERVER_URL + `auth/${challenge.data.challenge}/${signature}`
        await axios.get(resVerificationURL, { responseType: 'json' }).then(async (resVerification) => {
          if (resVerification.status === 200) {
            const { token } = resVerification.data
            localStorage.setItem('authToken', token)
            handleFetchProfile(account)
            apiCall(props, false, token)
          } else {
            console.log('Problem authorizing') // todo check what to do if there is an issue authorizing
          }
        })
      }
    })
  }

  const get = <R, T>(props: Props, retry?: boolean, token?: string) => {
    if (!account) return

    const authToken = localStorage.getItem('authToken')

    if (props.config) props.config['headers']['authorization'] = token ? token : authToken
    else props.config = { headers: { authorization: token ? token : authToken } }
    axios
      .get<R, T>(props.url, props.config)
      .then((res: T) => {
        // on success will push the callback function
        if (props.callback) props.callback(res)
      })
      .catch(async (e) => {
        // on error, and if retry is true, will check if authorization failed, and ask for another token
        if (e.response.status === 401) {
          if (!retry) return
          await fulfillChallenge(get, props)
        } else {
          // on other type of error, will execute the error callback
          if (props.errorCallback) props.errorCallback(e)
        }
      })
      .finally(() => {
        // on success will push the callback function
        if (props.callbackFinally) props.callbackFinally()
      })
  }

  const put = (props: Props, retry?: boolean, token?: string) => {
    if (!account) return

    const authToken = localStorage.getItem('authToken')

    if (props.config) props.config['headers']['authorization'] = token ? token : authToken
    else props.config = { headers: { authorization: token ? token : authToken } }
    axios
      .put(props.url, props.data, props.config)
      .then((res) => {
        // on success will push the callback function
        if (props.callback) props.callback(res)
      })
      .catch(async (e) => {
        // on error, and if retry is true, will check if authorization failed, and ask for another token
        if (e.response.status === 401) {
          if (!retry) return
          await fulfillChallenge(put, props)
        } else {
          // on other type of error, will execute the error callback
          if (props.errorCallback) props.errorCallback(e)
        }
      })
      .finally(() => {
        // on success will push the callback function
        if (props.callbackFinally) props.callbackFinally()
      })
  }

  const post = (props: Props, retry?: boolean, token?: string) => {
    if (!account) return

    const authToken = localStorage.getItem('authToken')

    if (props.config) props.config['headers']['authorization'] = token ? token : authToken
    else props.config = { headers: { authorization: token ? token : authToken } }
    return axios
      .post(props.url, props.data, props.config)
      .then((res) => {
        // on success will push the callback function
        if (props.callback) props.callback(res)
        return { status: res.status, data: res.data }
      })
      .catch(async (e) => {
        // on error, and if retry is true, will check if authorization failed, and ask for another token
        if (e.response.status === 401) {
          if (!retry) return
          await fulfillChallenge(post, props)
        } else {
          // on other type of error, will execute the error callback
          if (props.errorCallback) props.errorCallback(e)
        }
      })
      .finally(() => {
        // on success will push the callback function
        if (props.callbackFinally) props.callbackFinally()
      })
  }

  const deleteFnc = (props: Props, retry?: boolean, token?: string) => {
    if (!account) return

    const authToken = localStorage.getItem('authToken')

    if (props.config) props.config['headers']['authorization'] = token ? token : authToken
    else props.config = { headers: { authorization: token ? token : authToken } }
    axios
      .delete(props.url, { ...props.config, data: { ...props.data } })
      .then((res) => {
        // on success will push the callback function
        if (props.callback) props.callback(res)
        return { status: res.status }
      })
      .catch(async (e) => {
        // on error, and if retry is true, will check if authorization failed, and ask for another token
        if (e.response.status === 401) {
          if (!retry) return
          await fulfillChallenge(deleteFnc, props)
        } else {
          // on other type of error, will execute the error callback
          if (props.errorCallback) props.errorCallback(e)
        }
      })
      .finally(() => {
        // on success will push the callback function
        if (props.callbackFinally) props.callbackFinally()
      })
  }

  const patch = (props: Props, retry?: boolean, token?: string) => {
    if (!account) return

    const authToken = localStorage.getItem('authToken')

    if (props.config) props.config['headers']['authorization'] = token ? token : authToken
    else props.config = { headers: { authorization: token ? token : authToken } }
    return axios
      .patch(props.url, props.data, props.config)
      .then((res) => {
        // on success will push the callback function
        if (props.callback) props.callback(res)
        return { status: res.status, data: res.data }
      })
      .catch(async (e) => {
        // on error, and if retry is true, will check if authorization failed, and ask for another token
        if (e.response.status === 401) {
          if (!retry) return
          await fulfillChallenge(patch, props)
        } else {
          // on other type of error, will execute the error callback
          if (props.errorCallback) props.errorCallback(e)
        }
      })
      .finally(() => {
        // on success will push the callback function
        if (props.callbackFinally) props.callbackFinally()
      })
  }

  return { get, put, post, fulfillChallenge, deleteFnc, patch }
}
