import { Input } from '@rebass/forms'
import SearchIcon from 'assets/images/Search-icon.svg'
import IconPortal from 'components/IconPortal'
import { darken } from 'polished'
import React from 'react'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'

const SearchBox = styled(Flex)`
  display: flex;
  flex: 1;
  align-items: center;
  background-color: transparent;
  padding: 5px 15px;
  border: 1px solid ${({ theme }) => theme.secondary5};
  img {
    vertical-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  input {
    font-family: 'Din Pro Regular', sans-serif;
    color: ${({ theme }) => darken(0.53, theme.white)};
    font-weight: 500;
    border: none;
    & :focus-visible {
      outline: none;
    }
  }
`

const SearchBar = () => {
  return (
    <SearchBox as="form" onSubmit={(e: any) => e.preventDefault()}>
      <IconPortal SrcImageIcon={SearchIcon} widthIcon={'24px'} heightIcon={'24px'} />
      <Input id="search" name="search" type="text" placeholder="Search by name or attribute" autoComplete="off" />
    </SearchBox>
  )
}

export default SearchBar
