import React from 'react'
import styled from 'styled-components/macro'

import Footer from '../../Footer'
import Navbar from '../../Header/Navbar'

const LayoutMain = (props: any) => {
  const { children } = props
  return (
    <>
      <HeaderWrapper>
        <Navbar />
      </HeaderWrapper>
      <BodyWrapper>
        {/* <Popups /> */}
        {children}
      </BodyWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </>
  )
}
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  height: 100px;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: top 0.3s;
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 100px 0 0 0;
  align-items: center;
  flex: 1;
  z-index: 1;
  //overflow: hidden;
  @media (max-width: 768px) {
    padding: 100px 16px 16px;
  }
`
const FooterWrapper = styled.div`
  width: 100%;
`
export default LayoutMain
