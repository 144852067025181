import { ReactComponent as Fallback } from 'assets/images/fallback.svg'

import { VideoOrImage } from './VideoOrImage'

export const VideoImageOrFallback = (props: { url?: string }) => {
  if (!props.url) {
    return <Fallback />
  }

  return <VideoOrImage url={props.url} />
}
