//import IconDiscord from 'assets/images/svg/icon-discord.svg'
import IconInstagram from 'assets/images/svg/icon-instagram.svg'
import IconTwitter from 'assets/images/svg/icon-twitter.svg'
import IconWebsite from 'assets/images/svg/icon-website.svg'
import { FormikProps, useFormik } from 'formik'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'
import * as Yup from 'yup'

import Fallback from '../../../assets/images/fallback.svg'
import IconEditImg from '../../../assets/images/svg/icon-edit-green.svg'
import { ButtonPrimary } from '../../../components/Button'
import { FormGroup, FormInput, FormLabel } from '../../../components/Forms'
import IconPortal from '../../../components/IconPortal'
import ModalConfirm from '../../../components/Modal/ModalConfirm'
import ModalError from '../../../components/Modal/ModalError'
import ModalSuccess from '../../../components/Modal/ModalSuccess'
import { useApi } from '../../../hooks/useApi'
import { collectionsApi, useUpdateCollectionMutation } from '../../../state/collections/slice'
import { useAppDispatch } from '../../../state/hooks'
import { useGetImage } from '../../../state/profile/hooks'
import { sliceUrl } from '../../../utils'
import VideoBanner from '../../CollectionDetail/Header/VideoBanner/VideoBanner'

interface TypeSocial {
  [key: string]: string
}
interface TypeInitValue {
  [key: string]: string
}
const socials: TypeSocial[] = [
  {
    name: 'website',
    icon: IconWebsite,
    placeholder: 'Your website',
  },
  {
    name: 'instagram',
    icon: IconInstagram,
    placeholder: 'Insert Instagram link',
  },
  {
    name: 'twitter',
    icon: IconTwitter,
    placeholder: 'Insert Twitter link',
  },
  // {
  //   name: 'discord',
  //   icon: IconDiscord,
  //   placeholder: 'Your Discrod link',
  // },
]
const BasicInfo = (props: any) => {
  const { collection, addressCollection } = props
  const [updateCollection] = useUpdateCollectionMutation()
  const getImage = useGetImage()
  const { patch } = useApi()
  const dispatch = useAppDispatch()
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [file, setFile] = useState<any>({
    name: '',
  })
  const [fileBanner, setFileBanner] = useState<any>({
    name: '',
  })
  const [srcImage, setSrcImage] = useState<string>()

  const [srcImageBackground, setSrcImageBackground] = useState<string>()
  const [isChange, setIsChange] = useState<boolean>(false)
  const [isChangeBanner, setIsChangeBanner] = useState<boolean>(false)
  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', async () => {
      setFile(file)
      setSrcImage(String(reader.result))
      setIsChange(true)
    })
  }
  const handleChangeBanner = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', async () => {
      setFileBanner(file)
      setSrcImageBackground(String(reader.result))
      setIsChangeBanner(true)
    })
  }

  const infoFormik: FormikProps<TypeInitValue> = useFormik<TypeInitValue>({
    enableReinitialize: true,
    initialValues: {
      collectionName: !collection && !collection?.name ? '' : collection.name,
      // description: !collection && !collection?.descriptions.shortBios ? '' : collection?.descriptions.shortBios,
      discord: !collection && !collection?.discord ? '' : collection.discord,
      twitter: !collection && !collection?.twitter ? '' : collection?.twitter,
      instagram: !collection && !collection?.instagram ? '' : collection?.instagram,
      website: !collection && !collection?.websiteUrl ? '' : collection?.websiteUrl,
      avatar: collection && !collection?.media?.image ? '' : collection?.media.image,
      banner: collection && !collection?.media?.video ? '' : collection?.media.video,
    },
    validationSchema: Yup.object().shape({
      collectionName: Yup.string().required('This field cannot be empty'),
      avatar: Yup.string().required('This field cannot be empty'),
      banner: Yup.string().required('This field cannot be empty'),
    }),
    onSubmit: () => {
      setShowConfirm(true)
    },
  })
  const { values, handleSubmit, handleChange, setSubmitting, dirty, isValid } = infoFormik
  const handleSuccess = () => {
    setShowSuccess(true)
    setShowConfirm(false)
  }
  const handleError = () => {
    setShowError(true)
    setShowConfirm(false)
  }
  // const handleFinally = () => {
  //   setSubmitting(false)
  // }
  const handleRefetch = () => {
    dispatch(collectionsApi.util.invalidateTags(['Collection']))
  }
  const handleUpdateInfoCollection = async (values: TypeInitValue) => {
    const { collectionName, description: shortBios, twitter, instagram, website, avatar, banner } = values
    try {
      const typeAvatar = file.type === 'video/quicktime' ? 'video/MOV' : file.type
      const imageAvatar = await getImage(file.name, typeAvatar)
      const { url, name } = imageAvatar
      const srcAvatar = sliceUrl(url, name)

      const typeBanner = fileBanner.type === 'video/quicktime' ? 'video/MOV' : fileBanner.type
      const imageBanner = await getImage(fileBanner.name, typeBanner)
      const { url: urlBanner, name: nameBanner } = imageBanner
      const srcBanner = sliceUrl(urlBanner, nameBanner)
      if (isChange) {
        await fetch(url, {
          headers: {
            'Content-Type': file.type,
            'Cache-Control': 'public,max-age=31536000,immutable',
            'x-amz-acl': 'public-read',
          },
          body: file,
          method: 'PUT',
        })
      }
      if (isChangeBanner) {
        await fetch(urlBanner, {
          headers: {
            'Content-Type': fileBanner.type,
            'Cache-Control': 'public,max-age=31536000,immutable',
            'x-amz-acl': 'public-read',
          },
          body: fileBanner,
          method: 'PUT',
        })
      }
      updateCollection({
        patch,
        collectionAddress: addressCollection,
        name: collectionName,
        media: {
          ...collection.media,
          image: isChange ? srcAvatar : avatar,
          video: isChangeBanner ? srcBanner : banner,
        },
        // descriptions: {
        //   ...collection.descriptions,
        //   shortBios,
        // },
        twitter,
        instagram,
        websiteUrl: website,
        handleSuccess,
        handleError,
        refetch: () => handleRefetch(),
      })
    } catch (e) {
      console.log('Error submit', e)
    }
    setSubmitting(false)
  }
  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Section>
          <Heading>General Info</Heading>
          <FormGroup>
            <FormLabel>
              Collection name <span className={'required'}>*</span>
            </FormLabel>
            <FormInput
              name={'collectionName'}
              id={'collectionName'}
              placeholder={'Collection name'}
              value={values.collectionName ?? ''}
              onChange={handleChange}
            />
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <FormLabel>Short description</FormLabel>*/}
          {/*  <FormTextarea*/}
          {/*    name={'description'}*/}
          {/*    id={'description'}*/}
          {/*    placeholder={'Let’s other users better know you...'}*/}
          {/*    value={values.description ?? ''}*/}
          {/*    onChange={handleChange}*/}
          {/*  />*/}
          {/*</FormGroup>*/}
        </Section>
        <Section>
          <Heading>Image</Heading>
          <DivImage>
            <AvatarBox>
              <FormLabel>
                Collection avatar <span className={'required'}>*</span>
              </FormLabel>
              <AvatarBoxEdit>
                <label htmlFor={'avatar'} className={'label_avatar'}>
                  <IconEdit>
                    <img src={IconEditImg} alt={'Edit_Avatar'} />
                  </IconEdit>
                </label>
                <input
                  type={'file'}
                  accept="image/*,video/*"
                  id={'avatar'}
                  hidden={true}
                  name={'avatar'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event)
                    handleChangeAvatar(event)
                  }}
                />
                <Avatar>
                  {srcImage && (
                    <>
                      {file.type.includes('video') ? (
                        <VideoBanner url={srcImage} />
                      ) : (
                        <img src={srcImage} alt={'Avatar'} onError={(e) => (e.currentTarget.src = Fallback)} />
                      )}
                    </>
                  )}
                  {!srcImage &&
                    ((collection?.media?.image &&
                      (isVideoLink(collection?.media?.image) ? (
                        <VideoBanner url={collection?.media?.image} />
                      ) : (
                        <img
                          src={collection?.media?.image}
                          alt={'Avatar'}
                          onError={(e) => (e.currentTarget.src = Fallback)}
                        />
                      ))) || <></>)}
                </Avatar>
              </AvatarBoxEdit>
            </AvatarBox>
            <BannerBox>
              <FormLabel>
                Banner image <span className={'required'}>*</span>
              </FormLabel>
              <BannerBoxEdit>
                <label htmlFor={'banner'}>
                  <IconEdit>
                    <img src={IconEditImg} alt={'Edit_Banner'} />
                  </IconEdit>
                </label>
                <input
                  type={'file'}
                  accept="image/*,video/*"
                  id={'banner'}
                  hidden={true}
                  name={'banner'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event)
                    handleChangeBanner(event)
                  }}
                />
                <RecommendSize>
                  For the best results, use an image that’s at least 2048 x 1152 pixels and 10MB or less
                </RecommendSize>
                <Banner>
                  {srcImageBackground && (
                    <>
                      {fileBanner.type.includes('video') ? (
                        <VideoBanner url={srcImageBackground} />
                      ) : (
                        <img src={srcImageBackground} alt={'Avatar'} />
                      )}
                    </>
                  )}
                  {!srcImageBackground &&
                    ((collection?.media?.video &&
                      (isVideoLink(collection?.media?.video) ? (
                        <VideoBanner url={collection?.media?.video} />
                      ) : (
                        <img src={collection?.media?.video} alt={'Avatar'} />
                      ))) || <></>)}
                </Banner>
              </BannerBoxEdit>
            </BannerBox>
          </DivImage>
        </Section>
        <Section>
          <Heading>Social</Heading>
          {socials.map((social: TypeSocial) => {
            const { name, icon, placeholder } = social
            return (
              <Social key={name}>
                <SocialIcon>
                  <IconPortal SrcImageIcon={icon} widthIcon={'24px'} heightIcon={'24px'} />
                </SocialIcon>
                <FormInput
                  name={name}
                  id={name}
                  placeholder={placeholder}
                  onChange={handleChange}
                  value={values[`${name}`] ?? ''}
                />
              </Social>
            )
          })}
        </Section>
        <CtaGroup>
          <CtaSubmit type={'submit'} disabled={!isValid || !dirty}>
            Save changes
          </CtaSubmit>
        </CtaGroup>
      </form>
      {showConfirm && (
        <ModalConfirm
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => handleUpdateInfoCollection(values)}
          subText={'Are you sure you want to update info?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={'Update Collection Failed'} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={'Update Collection Successfully'}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  .required {
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
    color: ${({ theme }) => theme.red5};
    margin-left: 6px;
  }
`
const Heading = styled.h2`
  font-family: 'DIN 1451 Std', sans-serif;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.primary10};
`
const Section = styled.section`
  margin-bottom: 80px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 32px;
  }
`
const Social = styled.div`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`
const SocialIcon = styled.div`
  flex: 0 0 40px;
  max-width: 40px;
  padding-right: 16px;
  align-self: center;
`
const DivImage = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const AvatarBox = styled.div`
  flex: 0 0 166px;
  max-width: 166px;
`
const AvatarBoxEdit = styled.div`
  background-color: ${({ theme }) => theme.secondary1};
  position: relative;
  border: 2px solid ${({ theme }) => theme.secondary2};
  border-radius: 4px;
  height: 166px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  label {
    position: relative;
    z-index: 5;
  }
`
const BannerBox = styled.div`
  flex: 1;
  margin-left: 32px;
  @media only screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
`
const BannerBoxEdit = styled(AvatarBoxEdit)``

const IconEdit = styled.span`
  width: 36px;
  height: 36px;
  display: block;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
const Avatar = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 100%;
  position: absolute;
  z-index: 1;
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const Banner = styled(Avatar)``
const RecommendSize = styled.div`
  text-align: center;
  font-family: 'Din Pro Regular', sans-serif;
  color: ${({ theme }) => theme.secondary5};
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 1.43;
  max-width: 300px;
  margin: 15px auto 0;
`
const CtaGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`
const CtaSubmit = styled(ButtonPrimary)`
  padding: 10px 24px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  text-transform: capitalize;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: max-content;
  .submitting {
    display: flex;
    align-items: center;
    & > span:first-of-type {
      margin-right: 6px;
    }
  }
`
// const CtaPreview = styled(ButtonSecondary)`
//   padding: 10px 24px !important;
//   font-size: ${({ theme }) => theme.fontSizeText2};
//   line-height: 1.5;
//   text-transform: capitalize;
//   font-family: 'Din Pro Regular', sans-serif;
//   font-weight: 600 !important;
//   cursor: pointer;
//   width: max-content;
//   margin-right: 12px;
// `

export default BasicInfo
