import React, { useEffect } from 'react'

import * as Styled from './styled'

interface CreateEventStepContainerProps {
  children: React.ReactNode
}

export const CreateEventStepContainer = ({ children }: CreateEventStepContainerProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Styled.Container>
      <Styled.InnerContainer>{children}</Styled.InnerContainer>
    </Styled.Container>
  )
}
