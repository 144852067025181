import 'react-quill/dist/quill.snow.css'

import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import styled from 'styled-components/macro'

interface IEditor {
  toolbarId: string
  value: string
  setValueRichText?: (html: string) => void
  placeholder?: string
  onChangeItem?: (index: number, html: string) => void
  index?: number
}

const Font = Quill.import('formats/font')
Font.whitelist = ['regular', 'bold', '1451-std']
Quill.register(Font, true)
const CustomToolbar = ({ toolbarId }: { toolbarId: string }) => {
  return (
    <ToolbarRichText id={'editor-' + toolbarId}>
      <QLAvailable>
        <span className="ql-formats">
          <select className="ql-font" defaultValue="regular">
            <option value="regular">Din Regular</option>
            <option value="bold">Din Bold</option>
            <option value="1451-std">Din 1451 std</option>
          </select>
          <select className="ql-header" defaultValue={''} onChange={(e: any) => e.persist()}>
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option value="4" />
            <option value="5" />
            <option value="" />
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold" />

          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
          <select className="ql-align" defaultValue={''}>
            <option value="center" />
            <option value="right" />
            <option value="justify" />
            <option value="" />
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          <button className="ql-video" />
        </span>
      </QLAvailable>
    </ToolbarRichText>
  )
}

const Editor = (props: IEditor) => {
  const { toolbarId, value, setValueRichText, placeholder, onChangeItem, index } = props
  const quillModules = React.useMemo(() => {
    return {
      toolbar: {
        container: '#editor-' + toolbarId,
      },
    }
  }, [toolbarId])
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'align',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
  ]

  const handleChange = (html: string) => {
    if (onChangeItem && typeof index === 'number') {
      onChangeItem(index, html)
    } else {
      setValueRichText && setValueRichText(html)
    }
  }
  return (
    <>
      <TextEditor className={'text-editor'}>
        <CustomToolbar toolbarId={toolbarId} />
        <ReactQuill
          defaultValue={value}
          onChange={handleChange}
          placeholder={placeholder}
          formats={formats}
          modules={quillModules}
        />
      </TextEditor>
    </>
  )
}
const ToolbarRichText = styled.div`
  display: flex;
  background-color: transparent;

  & .ql-header:hover {
    background-color: ${({ theme }) => theme.secondary1};
  }
  &.no-focus {
    div:first-of-type {
      .ql-toolbar {
        display: block; // show the first toolbar when no editor is in focus
      }
    }
  }
`
const QLAvailable = styled.div`
  display: flex;
  justify-content: start;
  flex: 1 1 auto;
  margin-right: 5px;
  .ql-formats .ql-stroke {
    stroke: ${({ theme }) => theme.primary10};
  }
  .ql-header.ql-picker {
    color: ${({ theme }) => theme.primary10};
  }
  .ql-video {
    svg {
      .ql-fill {
        fill: ${({ theme }) => theme.primary10};
      }
    }
  }
`
const TextEditor = styled.div<{ imageHasChanged?: boolean }>`
  flex: 1;
  .ql-toolbar.ql-snow {
    font-family: 'Din Pro Regular', sans-serif;
    color: ${({ theme }) => theme.primary10};
    border: 1.5px solid ${({ theme }) => theme.secondary3};
  }
  .ql-container.ql-snow {
    border-width: 0 1.5px 1.5px 1.5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.secondary3};
    background-color: ${({ theme }) => theme.secondary3};
  }
  .ql-snow .ql-picker-options {
    background-color: ${({ theme }) => theme.secondary1} !important;
  }
  &.text-full-editor {
    max-width: 640px;
  }

  &.text-image-editor {
    max-width: 395px;
    height: ${({ imageHasChanged }) => (imageHasChanged ? '370px' : '163px')};
    margin-top: 10px;
  }

  & .ql-editor {
    height: 140px;
    background-color: rgba(0, 20, 45, 0.05);
    font-family: 'Din Pro Regular', sans-serif;

    * {
      color: ${({ theme }) => theme.primary10};
      font-size: ${({ theme }) => theme.fontSizeText2};
    }
    &.ql-blank::before {
      color: ${({ theme }) => theme.secondary6};
      font-style: normal;
    }
  }

  & .ql-container {
    position: relative;
  }
  .ql-tooltip.ql-editing[data-mode='video'] {
    position: static;
    font-family: 'Din Pro Regular', sans-serif;
    input::placeholder {
      font-family: 'Din Pro Regular', sans-serif;
    }
  }
  .ql-snow .ql-picker.ql-font {
    color: ${({ theme }) => theme.primary10};
  }
`
export default Editor
