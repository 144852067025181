import { Pin } from 'components/Icons/Pin'
import React from 'react'

import * as Styled from './styled'

interface AddressProps {
  address: string
}

export const Address = ({ address }: AddressProps) => {
  return (
    <Styled.AddressContainer>
      <Styled.PinContainer>
        <Pin />
      </Styled.PinContainer>
      <span>{address}</span>
    </Styled.AddressContainer>
  )
}
