import { ButtonTransparent } from 'components/Button'
import { ReactNode } from 'react'

import { Close } from '../Icons/Close'
import Modal from '../Modal'
import { ModalProps } from '../Modal'
import * as Styled from './styled'

interface ModalBaseProps extends ModalProps {
  isOpen: boolean
  children: ReactNode
  header?: string
  subHeader?: string
  maxWidth?: number
  width?: string
}

export const ModalBase = ({
  isOpen,
  onDismiss,
  header,
  subHeader,
  children,
  maxWidth = 750,
  width,
}: ModalBaseProps) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={maxWidth} width={width}>
      <Styled.ModalContainer>
        <Styled.ModalHeaderContainer>
          <Styled.HeaderTextContainer>
            <Styled.ModalHeader> {header} </Styled.ModalHeader>
            {subHeader && <Styled.ModalSubHeader> {subHeader} </Styled.ModalSubHeader>}
          </Styled.HeaderTextContainer>

          <ButtonTransparent onClick={onDismiss}>
            <Close />
          </ButtonTransparent>
        </Styled.ModalHeaderContainer>
        {children}
      </Styled.ModalContainer>
    </Modal>
  )
}
