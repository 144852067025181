import { AxiosError } from 'axios'

interface MetamaskError {
  code: string
  message: string
}

// to be extended with other error codes
export const metamaskErrorParser = (error: MetamaskError) => {
  if (error.code === 'ACTION_REJECTED') {
    return 'Transaction rejected.'
  }

  if (error.message.includes('PartnerRegistry::onlyUnlockedEventOwnerOrOperator')) {
    return "Clerk is assigned to another partner's event."
  }

  return 'Unexpected error occurred.'
}

// to be extended with backend error codes
export const backendErrorParser = (error: AxiosError) => {
  if (error?.response?.status && Number(error.response.status) >= 400 && Number(error.response.status) < 500) {
    return error.message
  }
  return 'Unexpected error occurred.'
}
