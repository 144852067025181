import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ButtonOutlineNeutral } from '../../../../components/Button'
import { PenIcon } from '../../../../components/Icons/Pen'
import { routes } from '../../../../constants/routes'
import { ClerksModal } from '../../../Clerks/components/ClerksModal'
import { useEventsListContext } from '../../../Events/context/EventListContext'
import * as Styled from './styled'

interface NoClerksTableActionsProps {
  eventId: string
  partnerAddress: string
}

export const NoClerksTableActions = ({ eventId, partnerAddress }: NoClerksTableActionsProps) => {
  const { refetch } = useEventsListContext()
  const [clerksModalOpen, setClerksModalOpen] = useState<boolean>(false)
  const toggleClerksModal = () => setClerksModalOpen((open) => !open)

  const handleCloseClerkModal = () => {
    toggleClerksModal()
    refetch()
  }

  return (
    <>
      {clerksModalOpen && <ClerksModal isOpen={clerksModalOpen} onClose={handleCloseClerkModal} eventId={eventId} />}
      <Styled.ActionsContainer>
        <ButtonOutlineNeutral type="button" onClick={toggleClerksModal} size="small">
          Add Clerk
        </ButtonOutlineNeutral>
        <Link to={routes.editEvents.replace(':eventId', eventId).replace(':partnerAddress', partnerAddress)}>
          <PenIcon />
        </Link>
      </Styled.ActionsContainer>
    </>
  )
}
