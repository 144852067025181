import React from 'react'
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import IconInstagramImg from '../../../../assets/images/svg/icon-instagram-gray.svg'
import IconTwitterImg from '../../../../assets/images/svg/icon-twitter-gray.svg'
import IconWebsiteImg from '../../../../assets/images/svg/icon-website-gray.svg'
import { CollectionType } from '../../../../entities/Collection'
import { shortenAddress } from '../../../../utils'
import CollectionCountdownHorizontal from '../CollectionCountdownHorizontal'

interface CollectionDropItemProps {
  dataItem: CollectionType
  isUpcoming?: boolean
}

const CollectionDropItem = ({ dataItem, isUpcoming = false }: CollectionDropItemProps) => {
  const { media, descriptions } = dataItem
  const history = useHistory()
  const handleClick = (slug: string) => {
    history.push(`/collection/${slug}`)
  }
  return (
    <BoxItem onClick={() => handleClick(dataItem.slug)}>
      <Item>
        <ItemContent>
          <CollectionImg className={'collection-img'} bgImg={media?.cover} />
          <InformationCard className={'information-card'}>
            <InformationText>
              <Title>
                <LinkRouter to={`/collection/${dataItem.slug}`}>{dataItem.name}</LinkRouter>
              </Title>
              <DivFlex>
                <NameCreator>
                  <LinkRouter to={`/user/${dataItem.creator?.address}`}>
                    {dataItem.creator?.name ?? (dataItem.creator?.address && shortenAddress(dataItem.creator.address))}
                  </LinkRouter>
                </NameCreator>
                <ChannelSocial className={'channel-social'}>
                  <SocialList>
                    <SocialItem href={dataItem?.websiteUrl} target={'_blank'}>
                      <img src={IconWebsiteImg} alt={'website'} />
                    </SocialItem>
                    <SocialItem href={`${dataItem?.instagram}`} target={'_blank'}>
                      <img src={IconInstagramImg} alt={'instagram'} />
                    </SocialItem>
                    <SocialItem href={`${dataItem?.twitter}`} target={'_blank'}>
                      <img src={IconTwitterImg} alt={'twitter'} />
                    </SocialItem>
                  </SocialList>
                </ChannelSocial>
              </DivFlex>

              <DescCollection dangerouslySetInnerHTML={{ __html: descriptions?.shortBios }}></DescCollection>
            </InformationText>
            <Event>
              <CollectionCountdownHorizontal sale={dataItem.sale} slug={dataItem.slug} isUpcoming={isUpcoming} />
            </Event>
          </InformationCard>
        </ItemContent>
      </Item>
    </BoxItem>
  )
}

const BoxItem = styled.div`
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    box-shadow: 0 10px 50px 0 #093a29;
  }
`
const Item = styled.div`
  position: relative;
  padding-bottom: 66.66667%;
  width: 100%;
  height: 0;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.primary3};
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  &:hover {
    border-color: ${({ theme }) => theme.primary5};
  }
`
const ItemContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:hover {
    .information-card {
      background-color: rgba(6, 24, 18, 0.6);
      height: 100%;
    }
  }
`

const Event = styled.div`
  background: rgba(6, 24, 18, 0.6);
  border-radius: 8px;
  @media (max-width: 1366px) {
    padding: 0;
  }
`
const CollectionImg = styled.div<{ bgImg: string }>`
  background-image: url(${(props) => props.bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    display: block;
    vertical-align: middle;
    min-height: 1px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 84.18%);
  }
`
const InformationCard = styled.div`
  padding: 32px;
  position: absolute;
  height: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  transition: height 0.5s ease-in-out;
  @media (max-width: 1366px) {
    padding: 20px;
    height: 100px;
  }
  @media (max-width: 768px) {
    height: 90px;
  }
`
const Title = styled.h3`
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 0;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.white};
  }
  @media (max-width: 1366px) {
    margin-bottom: 12px;
  }
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizeText1};
  }
`
const NameCreator = styled.h4`
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
  margin: 0;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary6};
  }
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizeText2};
  }
`
const DescCollection = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 20px;
  margin: 20px 0 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  cursor: default;
  @media (max-width: 1366px) {
    margin-top: 12px;
  }
`
const ChannelSocial = styled.div``
const SocialList = styled.div`
  display: flex;
  align-items: center;
`
const SocialItem = styled.a`
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
  &:hover img {
    filter: brightness(3.75);
  }
`
const DivFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const InformationText = styled.div``

export default CollectionDropItem
