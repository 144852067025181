// eslint-disable-next-line no-restricted-imports
import { ethers } from 'ethers'
import * as yup from 'yup'

export const schema = yup
  .object({
    address: yup
      .string()
      .required('Required')
      .test('validAddress', 'Invalid address', (value: string) => {
        return ethers.utils.isAddress(value)
      }),
  })
  .required()
