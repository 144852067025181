import React, { useMemo } from 'react'

import Loader from '../../../../components/Loader'
import { useCurrentLocationContext } from '../../../../context/LocationContext'
import { haversineDistance } from '../../../../utils/haversineDistance'
import { useEvents, UseEventsQueryParams } from '../../hooks/useEvents'
import { EventBox } from '../EventBox'
import * as Styled from './styled'
import { Center } from './styled'

interface EventListProps {
  title: string
  filters?: UseEventsQueryParams
}

export const EventsList = ({ title, filters }: EventListProps) => {
  const { data: events, isLoading } = useEvents({ queryParams: filters })
  const { location } = useCurrentLocationContext()

  const sortedEvents = useMemo(() => {
    if (isLoading || !events) {
      return []
    }

    if (!location) {
      return events
    }

    return events.sort((e1, e2) => {
      const d1 = haversineDistance(e1.location, location)
      const d2 = haversineDistance(e2.location, location)

      if (d1 === -1) {
        return 1
      }

      if (d2 === -1) {
        return -1
      }

      const isE1Closer = d1 < d2

      return isE1Closer ? -1 : 1
    })
  }, [isLoading, events, location])

  if (isLoading) {
    return (
      <>
        <Styled.TitleContainer>
          <Styled.Title> {title} </Styled.Title>
        </Styled.TitleContainer>
        <Center>
          <Loader size="24px" />
        </Center>
      </>
    )
  }

  if (!events || !events.length) {
    return <></>
  }

  return (
    <>
      <Styled.TitleContainer>
        <Styled.Title> {title} </Styled.Title>
      </Styled.TitleContainer>
      {sortedEvents.map((event) => (
        <EventBox key={event.eventId} event={event} />
      ))}
    </>
  )
}
