import DefaultAvatar from 'assets/images/default_avatar.png'
import { ButtonSecondary } from 'components/Button'
import Loader from 'components/Loader'
import useParticle from 'hooks/useParticle'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { useState } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { useUpdateWatchlistMutation } from 'state/collections/slice'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'

import ImgCreator from '../../../assets/images/img/imgCreator.png'
import OverlayBottom from '../../../assets/images/img/mask_bottom.png'
import OverlayTop from '../../../assets/images/img/mask_top.png'
import IconInstagramImg from '../../../assets/images/svg/icon-instagram-gray.svg'
import IconTwitterImg from '../../../assets/images/svg/icon-twitter-gray.svg'
import IconWebsiteImg from '../../../assets/images/svg/icon-website-gray.svg'
import CopyHelper from '../../../components/Copy'
import { useApi } from '../../../hooks/useApi'
import { checkUrl, shortenAddress } from '../../../utils'
import VideoBanner from './VideoBanner/VideoBanner'

interface HeaderCollectionDetailProps {
  collection: any
  refetch: () => void
  isLoading: boolean
}

const HeaderCollectionDetail = ({ collection, refetch, isLoading }: HeaderCollectionDetailProps) => {
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const [loading, setLoading] = useState(isLoading)

  const [updateWatchlist, { isLoading: watchlistLoading }] = useUpdateWatchlistMutation()
  const { post } = useApi()

  const handleWatchlist = async () => {
    setLoading(true)
    if (!account) return
    await updateWatchlist({
      userAddress: account,
      collectionAddress: collection.address as string,
      refetch,
      post,
    }).finally(() => {
      setTimeout(() => {
        setLoading(false)
      }, 1500)
    })
  }

  return collection ? (
    <HeaderCollectionWrapper>
      <DivBackgroundCover>
        <MaskTop>
          <img src={OverlayTop} alt={'overlay'} />
        </MaskTop>
        <RatioVideo>
          {collection?.media?.video ? (
            isVideoLink(collection?.media?.video) ? (
              <VideoBanner url={collection?.media?.video ?? ''} />
            ) : (
              collection?.media?.video && (
                <img
                  src={collection.media.video}
                  alt={'image_collection_banner'}
                  onError={(e) => (e.currentTarget.src = DefaultAvatar)}
                />
              )
            )
          ) : (
            <></>
          )}
        </RatioVideo>
      </DivBackgroundCover>
      <DivCollectionInfo>
        <DivCollectionInfoContainer>
          <DivRowInfo>
            <DivLeftInfo>
              <DivImageCollection>
                {collection?.media?.image ? (
                  isVideoLink(collection?.media?.image) ? (
                    <video
                      width={'100%'}
                      height={'100%'}
                      src={collection?.media?.image}
                      controls={false}
                      muted={true}
                      playsInline={true}
                      autoPlay={true}
                      loop={true}
                    ></video>
                  ) : (
                    collection?.media?.image && (
                      <img
                        src={collection.media.image}
                        alt={'image_collection'}
                        onError={(e) => (e.currentTarget.src = DefaultAvatar)}
                      />
                    )
                  )
                ) : (
                  <img src={DefaultAvatar} alt={'image_collection'} />
                )}
                {account && (
                  <EditButton onClick={handleWatchlist}>
                    {watchlistLoading || loading ? (
                      <Loader size={'24px'} stroke={'white'} />
                    ) : collection?.isWatchList ? (
                      'Stop watching'
                    ) : (
                      'Add to watchlist'
                    )}
                  </EditButton>
                )}
              </DivImageCollection>
            </DivLeftInfo>
            <DivMainInfo>
              <DivMainInfoTop>
                <DivAddress>
                  <CopyHelper toCopy={collection?.address}>
                    <TextAddress>{collection?.address && shortenAddress(collection?.address)}</TextAddress>
                  </CopyHelper>
                </DivAddress>
                <NameCollection>
                  <Display1>{collection?.name ?? 'UNNAMED'}</Display1>
                  <Creator>
                    <CreatorText>Created by</CreatorText>
                    <LinkRouter
                      to={collection?.creator.address === account ? '/profile' : `/user/${collection?.creator.address}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <CreatorName>
                        <img src={collection?.creator.avatar || ImgCreator} alt={'img_creator'} />
                        <span>
                          {collection.creator?.name
                            ? collection.creator.name
                            : collection?.creator.address
                            ? shortenAddress(collection?.creator.address)
                            : ''}
                        </span>
                      </CreatorName>
                    </LinkRouter>
                  </Creator>
                </NameCollection>
              </DivMainInfoTop>
              <HeaderAction>
                <SocialList>
                  {collection?.websiteUrl && (
                    <SocialItem href={checkUrl(collection.websiteUrl)} target={'_blank'}>
                      <img src={IconWebsiteImg} alt={'website'} />
                    </SocialItem>
                  )}
                  {collection?.instagram && (
                    <SocialItem href={checkUrl(collection.instagram)} target={'_blank'}>
                      <img src={IconInstagramImg} alt={'instagram'} />
                    </SocialItem>
                  )}
                  {collection?.twitter && (
                    <SocialItem href={checkUrl(collection.twitter)} target={'_blank'}>
                      <img src={IconTwitterImg} alt={'twitter'} />
                    </SocialItem>
                  )}
                </SocialList>
                <OtherLinkBox>{/* <MoreFnc /> */}</OtherLinkBox>
              </HeaderAction>
            </DivMainInfo>
          </DivRowInfo>
        </DivCollectionInfoContainer>
      </DivCollectionInfo>
      <MaskBottom>
        <img src={OverlayBottom} alt={'overlay'} />
      </MaskBottom>
    </HeaderCollectionWrapper>
  ) : (
    <DivError>
      <Display1>COLLECTION NOT FOUND</Display1>
    </DivError>
  )
}

const DivError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const HeaderCollectionWrapper = styled.header`
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  height: calc(100vh - 500px);
  padding-bottom: 60px;
  @media (max-width: 1366px) and (max-height: 768px) {
    margin-bottom: 40px;
    height: calc(100vh - 100px);
  }
  @media (max-width: 768px) {
    height: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`
const DivBackgroundCover = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  @media (max-width: 768px) {
    height: 400px;
    margin-left: -16px;
    margin-right: -16px;
  }
  @media (max-width: 575px) {
    height: 300px;
  }
`
const DivCollectionInfo = styled.div`
  position: absolute;
  z-index: 15;
  max-width: 1440px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  @media (max-width: 768px) {
    transform: none;
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: -100px;
  }
`
const DivCollectionInfoContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 160px;
  @media (max-width: 1024px) {
    padding: 0 60px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
const DivRowInfo = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const DivLeftInfo = styled.div`
  flex: 0 0 156px;
  max-width: 156px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`
const DivMainInfo = styled.div`
  flex: 1;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`
const DivImageCollection = styled.div`
  img {
    display: block;
    width: 100%;
    height: 156px;
    object-fit: cover;
    min-height: 1px;
    vertical-align: middle;
  }
`

const DivAddress = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 12px;
  }
`
const TextAddress = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary6};
  + span {
    color: ${({ theme }) => theme.primary4};
  }
`
const NameCollection = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`
const Display1 = styled.h1`
  margin: 0 0 16px 0;
  line-height: 1.16667;
  vertical-align: top;
  font-family: 'DIN 1451 Std', sans-serif;
  text-transform: uppercase;
`
const DivMainInfoTop = styled.div`
  margin-bottom: 24px;
`
const Creator = styled.div`
  display: flex;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
const CreatorText = styled.span`
  color: ${({ theme }) => theme.secondary5};
  font-size: 14px;
  line-height: 20px;
  margin-right: 6px;
`
const CreatorName = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    display: block;
  }
  span {
    color: ${({ theme }) => theme.primary6};
    font-size: 14px;
    line-height: 1.3;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 6px;
  }
`
const SocialList = styled.div`
  display: flex;
  align-items: center;
`
const SocialItem = styled.a`
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
  &:hover {
    img {
      filter: brightness(3.75);
    }
  }
`

const EditButton = styled(ButtonSecondary)`
  margin-top: 24px;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: ${({ theme }) => theme.primary10};
  padding: 10px 4px;
  gap: 8px;
`
const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
const OtherLinkBox = styled.div`
  display: flex;
`
const RatioVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 100%;
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    width: calc(100% + 32px);
  }
`
const MaskTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  @media (max-width: 768px) {
    width: calc(100% + 32px);
  }
`
const MaskBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 66px;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  @media (max-width: 768px) {
    top: 400px;
    height: 30px;
  }
  @media (max-width: 575px) {
    top: 300px;
  }
`
export default HeaderCollectionDetail
