import { authMap } from 'components/WalletModal/authMap'
import { SUPPORTED_WALLETS } from 'constants/wallet'

const iconMap: Record<string, string> = {
  ...Object.keys(authMap).reduce(
    (accumulator, iconName) => ({
      ...accumulator,
      [`Particle-${iconName}`]: authMap[iconName],
    }),
    {}
  ),
  ...Object.keys(SUPPORTED_WALLETS).reduce(
    (accumulator, walletName) => ({
      ...accumulator,
      [SUPPORTED_WALLETS[walletName].name]: SUPPORTED_WALLETS[walletName].iconURL,
    }),
    {}
  ),
}

export { iconMap }
