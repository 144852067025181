import { Select } from '@rebass/forms'
import React from 'react'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components/macro'

const FilterBox = styled(Select)`
  padding: 12px 10px !important;
  min-width: 380px;
  border: 1px solid ${({ theme }) => theme.secondary5} !important;
  font-family: 'Din Pro Regular', sans-serif;
  & :focus-visible {
    outline: none;
  }
  option {
    color: ${({ theme }) => theme.secondary1};
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
  }
`
interface PropsType {
  onChange?: (orderBy: string) => void
}
const Filter = (props: PropsType) => {
  const { onChange } = props
  const handleChangeOption = (event: any) => {
    if (!onChange) {
      return
    }
    onChange(event.target.value)
  }
  return (
    <Box>
      <FilterBox id="country" name="country" defaultValue="United States" onChange={handleChangeOption}>
        <option value={'ASC'}>Name (A - Z)</option>
        <option value={'DESC'}>Name (Z - A)</option>
      </FilterBox>
    </Box>
  )
}

export default Filter
