import WalletModalOth from 'components/WalletModalOth'
import useParticle from 'hooks/useParticle'
import React, { useRef } from 'react'
import styled from 'styled-components/macro'

// import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { usePopupOpen, useWalletPopupToggle } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'

const NavbarOth = () => {
  const walletNode = useRef<HTMLDivElement>()
  const walletPopupOpen = usePopupOpen(ApplicationModal.WALLET_POPUP)
  const toggleWalletPopup = useWalletPopupToggle()
  useOnClickOutside(walletNode, walletPopupOpen ? toggleWalletPopup : undefined)
  // const [cookies] = useCookies(['isDisconnected'])
  // const { account } = useActiveWeb3React()
  const { account, provider } = useParticle()

  return (
    <>
      {account && !provider ? (
        <>
          <div></div>
        </>
      ) : (
        <AccountElement>
          <WalletModalOth />
        </AccountElement>
      )}
    </>
  )
}
const AccountElement = styled.div`
  width: 100%;
`
export default NavbarOth
