import * as React from 'react'
export const Close = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.923 2.744a.833.833 0 1 0-1.179 1.179L8.821 10l-6.077 6.077a.833.833 0 1 0 1.179 1.179L10 11.178l6.077 6.078a.833.833 0 1 0 1.179-1.179L11.178 10l6.078-6.077a.833.833 0 1 0-1.179-1.179L10 8.821 3.923 2.744Z"
      clipRule="evenodd"
    />
  </svg>
)
