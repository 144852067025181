import axios from 'axios'
import { ButtonPrimary } from 'components/Button'
import { useFormik } from 'formik'
import { useApi } from 'hooks/useApi'
import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import bannerProfileImage from '../../../assets/images/banner.png'
import IconEditImgCover from '../../../assets/images/svg/icon-edit.svg'
import IconEditImg from '../../../assets/images/svg/icon-edit-green.svg'
import IconInstagram from '../../../assets/images/svg/icon-instagram.svg'
import IconWebsite from '../../../assets/images/svg/icon-website.svg'
import AuthenticationRequired from '../../../components/AuthenticationRequired'
import Loader from '../../../components/Loader'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { useFetchProfile, useGetImage, useGetProfile } from '../../../state/profile/hooks'
import { sliceUrl } from '../../../utils'

const FormProfile = () => {
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()
  const handleFetchProfile = useFetchProfile()
  const { data: userProfile } = useGetProfile()
  const getImage = useGetImage()
  const { put, post } = useApi()
  const [file, setFile] = useState<any>({
    name: '',
  })
  const history = useHistory()
  const [srcImage, setSrcImage] = useState<string>()
  const [isChange, setIsChange] = useState<boolean>(false)
  const [fileBackground, setFileBackground] = useState<any>({
    name: '',
  })
  const [srcImageBackground, setSrcImageBackground] = useState<string>()
  const [isChangeBackground, setIsChangeBackground] = useState<boolean>(false)
  const [screenName, setScreenName] = useState<string>('')
  const [twitterLink, setTwitterLink] = useState<string>('')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userProfile.name || '',
      description: userProfile?.description || '',
      email: userProfile.email || '',
      twitter: userProfile?.twitter || '',
      instagram: userProfile?.instagram || '',
      websiteUrl: userProfile?.websiteUrl || '',
      imageUrl: userProfile?.imageUrl || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const imageAvatar = await getImage(file.name, file.type)
        const { url, name } = imageAvatar
        const srcImageAvatar = sliceUrl(url, name)

        const imageBackground = await getImage(fileBackground.name)
        const { url: urlBackground, name: nameBackground } = imageBackground
        const srcImageBackground = sliceUrl(urlBackground, nameBackground)
        if (isChange) {
          await fetch(url, {
            headers: {
              'Content-Type': file.type,
              'Cache-Control': 'public,max-age=31536000,immutable',
              'x-amz-acl': 'public-read', // add this line
            },
            body: file,
            method: 'PUT',
          })
        }
        if (isChangeBackground) {
          await fetch(urlBackground, {
            headers: {
              'Content-Type': fileBackground.type,
              'Cache-Control': 'public,max-age=31536000,immutable',
              'x-amz-acl': 'public-read', // add this line
            },
            body: fileBackground,
            method: 'PUT',
          })
        }
        put(
          {
            url: process.env.REACT_APP_SERVER_URL + `users/${account}`,
            data: {
              name: values.name,
              description: values.description,
              email: values.email,
              twitter: twitterLink || values.twitter,
              instagram: values.instagram || '',
              websiteUrl: values.websiteUrl || '',
              imageUrl: isChange ? srcImageAvatar : userProfile.imageUrl,
              bgImageUrl: isChangeBackground ? srcImageBackground : userProfile.bgImageUrl,
            },
            callback: (res) => {
              if (res.status === 200) {
                if (account) {
                  handleFetchProfile(account)
                }

                setTimeout(() => {
                  history.push({
                    pathname: `/profile`,
                  })
                }, 1500)
              }
            },
            config: {
              headers: {
                authorization: localStorage.getItem('authToken') as string,
              },
            },
          },
          true
        )

        // if (account) {
        //   handleFetchProfile(account)
        // }
      } catch (e) {
        console.log(e)
      }
      setSubmitting(false)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(
          /^[a-zA-Z0-9-_\s]*$/,
          'Invalid username! It can only contain letters, numbers, hyphens (-), and underscores (_).'
        )
        .required(),
      email: Yup.string()
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Invalid email')
        .required(),
      instagram: Yup.string().matches(new RegExp('(https?)?:?(www)?instagram.com/[a-z].{3}'), 'Invalid instagram URL'),
      twitter: Yup.string().matches(
        new RegExp('(https://twitter.com/(?![a-zA-Z0-9_]+/)([a-zA-Z0-9_]+))'),
        'Invalid twitter URL'
      ),
      websiteUrl: Yup.string().matches(
        /^(http:\/\/|https:\/\/)?(www.)?([a-zA-Z0-9]+).[a-zA-Z0-9]*.[‌​a-z]{3}\.([a-z]+)?$/,
        'Invalid website URL'
      ),
    }),
  })
  const handleChangeAvatar = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async (event) => {
      setFile(file)
      setSrcImage(String(reader.result))
      setIsChange(true)
    }
  }
  const handleChangeBackgroundCover = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async (event) => {
      setFileBackground(file)
      setSrcImageBackground(String(reader.result))
      setIsChangeBackground(true)
    }
  }

  const handleConnectTwitter = () => {
    post(
      {
        url: `${process.env.REACT_APP_SERVER_URL}users/twitter/connect`,
        callback: (res) => {
          window.location.href = `${res.data.redirectUrl}`
        },
      },
      true
    )
  }

  const handleDisconnectTwitter = () => {
    sessionStorage.removeItem('screen_name')
    setScreenName('')
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const oauth_token = urlParams.get('oauth_token')
    const oauth_verifier = urlParams.get('oauth_verifier')
    if (!oauth_token || !oauth_verifier) {
      setScreenName(sessionStorage.getItem('screen_name') || '')
      return
    }

    ;(async () => {
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}users/twitter/saveAccessTokens`, {
          params: {
            oauth_token,
            oauth_verifier,
          },
        })
        .then(async (res) => {
          if (!res.data?.screenName) {
            setScreenName(sessionStorage.getItem('screen_name') || '')
            return
          }
          sessionStorage.setItem('screen_name', res.data.screenName)
          setScreenName(res.data.screenName)
        })
        .catch((e) => {
          console.log(e)
        })
    })()
  }, [])

  useEffect(() => {
    if (!screenName) {
      return
    }
    setTwitterLink(`https://twitter.com/${screenName}`)
  }, [screenName])

  return account && localStorage.getItem('authToken') ? (
    <>
      <Form name={'form-edit-profile'} onSubmit={formik.handleSubmit}>
        <DivBoxCoverImage>
          <BannerContainer>
            {/*<CoverImage src={DefaultCoverImage} alt={'Cover_Image'} />*/}
            <CoverImg src={(userProfile && userProfile.bgImageUrl) || bannerProfileImage} alt={'cover_profile'} />
          </BannerContainer>
          <DivBoxEdit>
            <label htmlFor={'imageBgCover'}>
              <IconEdit>
                <img src={IconEditImgCover} alt={'Edit_Cover_Image'} />
              </IconEdit>
            </label>
            <input
              type={'file'}
              accept="image/*"
              id={'imageBgCover'}
              hidden={true}
              name={'imageBgCover'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.handleChange(event)
                handleChangeBackgroundCover(event)
              }}
            />
            <RecommendSize>Perfect ratio for Image or GIF of at least 1920x300 px</RecommendSize>
          </DivBoxEdit>
          {srcImageBackground ? (
            <BgCover src={srcImageBackground} alt={'Background_cover'} />
          ) : (
            userProfile.bgImageUrl && <BgCover src={userProfile.bgImageUrl} alt={'Background_cover'} />
          )}
        </DivBoxCoverImage>
        <FormWrapper>
          <DivMainLeft>
            <TitleSettings>Profile picture</TitleSettings>
            <DivBoxAvatar>
              <DivBoxAvatarEdit>
                <label htmlFor={'imageFile'} className={'label_avatar'}>
                  <IconEdit>
                    <img src={IconEditImg} alt={'Edit_Cover_Image'} />
                  </IconEdit>
                </label>
                <input
                  type={'file'}
                  accept="image/*"
                  id={'imageFile'}
                  hidden={true}
                  name={'imageUrl'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.handleChange(event)
                    handleChangeAvatar(event)
                  }}
                />
              </DivBoxAvatarEdit>
              {srcImage ? (
                <Avatar src={srcImage} alt={'Avatar'} />
              ) : (
                userProfile.imageUrl && <Avatar src={userProfile.imageUrl} alt={'Avatar'} />
              )}
            </DivBoxAvatar>

            <ConnectTwitter
              data-tip=""
              data-for="disconnect"
              onClick={screenName ? handleDisconnectTwitter : handleConnectTwitter}
              data-active={!!screenName}
            >
              {screenName ? screenName : 'Connect Twitter'}
              <FormInput
                type={'hidden'}
                placeholder={'Your Name Twitter'}
                id={'twitter'}
                name={'twitter'}
                value={`https://twitter.com/${screenName}`}
              />
            </ConnectTwitter>
            {screenName && <ReactTooltip id="disconnect">Disconnect</ReactTooltip>}
          </DivMainLeft>
          <DivMainRight>
            <TitleSettings>General Info</TitleSettings>
            <TabContent>
              <FormContent>
                <FormGroup>
                  <FormItem>
                    <Label>Username</Label>
                    <FormInput
                      type={'text'}
                      placeholder={'Enter username'}
                      id={'name'}
                      name={'name'}
                      value={formik.values.name || ''}
                      onChange={formik.handleChange}
                      className={formik.errors?.name && 'error'}
                      autoComplete={'off'}
                    />
                    {formik.errors.name && formik.values.name && <ErrorMessageP> {formik.errors.name} </ErrorMessageP>}
                  </FormItem>
                </FormGroup>
                <FormGroup>
                  <FormItem>
                    <Label>Email</Label>
                    <FormInput
                      type={'text'}
                      placeholder={'Enter email here'}
                      id={'email'}
                      name={'email'}
                      value={formik.values.email || ''}
                      onChange={formik.handleChange}
                      className={formik.errors?.email && 'error'}
                      autoComplete={'off'}
                    />
                    {formik.errors.email && formik.values.email && (
                      <ErrorMessageP> {formik.errors.email} </ErrorMessageP>
                    )}
                  </FormItem>
                </FormGroup>
                <FormGroup>
                  <FormItem>
                    <Label>Bio</Label>
                    <FormTextarea
                      placeholder={'Let’s other users better know you...'}
                      rows={5}
                      value={formik.values.description || ''}
                      id={'description'}
                      name={'description'}
                      onChange={formik.handleChange}
                      autoComplete={'off'}
                    ></FormTextarea>
                  </FormItem>
                </FormGroup>
                <FormGroup>
                  <Label>Links</Label>
                  <DivLink>
                    <IconSocial>
                      <img src={IconInstagram} alt={'instagram'} />
                    </IconSocial>
                    <FormInput
                      type={'text'}
                      placeholder={'Your Instagram URL'}
                      id={'instagram'}
                      name={'instagram'}
                      value={formik.values.instagram || ''}
                      className={formik.errors?.instagram && 'error'}
                      onChange={formik.handleChange}
                      autoComplete={'off'}
                    />
                  </DivLink>
                  {formik.errors.instagram && <ErrorMessageSocial> {formik.errors.instagram} </ErrorMessageSocial>}
                  <DivLink>
                    <IconSocial>
                      <img src={IconWebsite} alt={'website'} />
                    </IconSocial>
                    <FormInput
                      type={'text'}
                      placeholder={'Your site'}
                      id={'websiteUrl'}
                      name={'websiteUrl'}
                      value={formik.values.websiteUrl || ''}
                      className={formik.errors?.websiteUrl && 'error'}
                      onChange={formik.handleChange}
                      autoComplete={'off'}
                    />
                  </DivLink>
                  {formik.errors.websiteUrl && <ErrorMessageSocial> {formik.errors.websiteUrl} </ErrorMessageSocial>}
                </FormGroup>
              </FormContent>

              <DivFormFooter>
                <Ctasubmit type={'submit'} disabled={!formik.dirty || !formik.isValid}>
                  {!formik.isSubmitting ? 'Save changes' : <Loader size={'24px'} stroke={'#151515'} />}
                </Ctasubmit>
              </DivFormFooter>
            </TabContent>
          </DivMainRight>
        </FormWrapper>
      </Form>
    </>
  ) : (
    <AuthenticationRequired />
  )
}
const Form = styled.form`
  width: 100%;
`
const FormContent = styled.div`
  width: 100%;
`
const FormGroup = styled.div`
  margin-bottom: 40px;
`
const FormItem = styled.div``
const Label = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.fontSizeText2};
  font-weight: 600;
  color: ${({ theme }) => theme.secondary8};
  line-height: 1.25;
  margin-bottom: 12px;
`
const FormInput = styled.input`
  display: block;
  width: 100%;
  padding: 12px 16px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.secondary1};
  color: ${({ theme }) => theme.secondary10};
  font-family: 'Din Pro Regular', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  border-radius: 4px;
  &::placeholder {
    color: ${({ theme }) => theme.secondary5};
    font-weight: 600;
  }
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover {
    outline: 2px solid ${({ theme }) => theme.secondary5};
    background-color: ${({ theme }) => theme.secondary4};
  }
  &:focus {
    outline: 2px solid ${({ theme }) => theme.primary4};
    background-color: ${({ theme }) => theme.secondary1};
  }
  &.error {
    outline: 2px solid ${({ theme }) => theme.red1};
  }
`
const ConnectTwitter = styled.div`
  display: flex;
  text-align: center;
  background-color: ${({ theme }) => theme.primary5};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 10px;
  letter-spacing: 0.01em;
  padding: 11px 24px;
  justify-content: center;
  color: ${({ theme }) => theme.secondary2};
  text-transform: uppercase;
  max-width: 200px;
  border-radius: 5px;
  cursor: pointer;

  &[data-active='true'] {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.secondary1};
  }
`
const FormTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px 16px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.secondary1};
  color: ${({ theme }) => theme.secondary10};
  font-family: 'Din Pro Regular', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  resize: none;
  border-radius: 4px;
  &::placeholder {
    color: ${({ theme }) => theme.secondary5};
    font-weight: 600;
  }
  &:hover {
    outline: 2px solid ${({ theme }) => theme.secondary5};
    background-color: ${({ theme }) => theme.secondary4};
  }
  &:focus {
    outline: 2px solid ${({ theme }) => theme.primary4};
    background-color: ${({ theme }) => theme.secondary1};
  }
`
const IconSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-right: 16px;
`
const DivLink = styled.div`
  display: flex;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`
const Ctasubmit = styled(ButtonPrimary)`
  padding: 16px 32px !important;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 212px;
  &[disabled] + .notification-warning {
    display: none;
  }
`
const ErrorMessageP = styled.p`
  margin: 8px 0 0;
  font-family: 'Din Pro Regular', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeText3};
  color: ${({ theme }) => theme.red4};
  white-space: pre;
`

const ErrorMessageSocial = styled(ErrorMessageP)`
  margin-left: 70px;
  margin-bottom: 20px;
`

const DivFormFooter = styled.div`
  display: flex;
  margin-top: 64px;
`
const FormWrapper = styled.div`
  display: flex;
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1024px) {
    max-width: none;
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    flex-wrap: wrap;
  }
`
const DivMainLeft = styled.div`
  flex: 0 0 330px;
  max-width: 330px;
  padding-right: 62px;
  @media (max-width: 1024px) {
    flex: 0 0 233px;
    max-width: 233px;
    padding-right: 25px;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
`
const DivMainRight = styled.div`
  flex: 0 0 calc(100% - 330px);
  max-width: calc(100% - 330px);
  padding-left: 62px;
  @media (max-width: 1024px) {
    flex: 0 0 calc(100% - 233px);
    max-width: calc(100% - 233px);
    padding-left: 25px;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }
`
const TitleSettings = styled.h4`
  font-family: 'Din Pro Regular', sans-serif;
  color: ${({ theme }) => theme.secondary10};
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const TabContent = styled.div``
const DivBoxAvatar = styled.div`
  width: 214px;
  height: 214px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.secondary2};
  background-color: ${({ theme }) => theme.secondary1};
  margin-bottom: 64px;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`
const DivBoxAvatarEdit = styled.div`
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .label_avatar {
    & > span {
      width: 32px;
      height: 32px;
    }
  }
`
const IconEdit = styled.span`
  width: 56px;
  height: 56px;
  display: block;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
const Avatar = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`

const DivBoxCoverImage = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 100px;
`
const DivBoxEdit = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 238px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
`
const RecommendSize = styled.div`
  text-align: center;
  font-family: 'Din Pro Regular', sans-serif;
  color: ${({ theme }) => theme.secondary8};
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 18px;
  margin-top: 15px;
`
const BgCover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`
const BannerContainer = styled.div`
  position: relative;
  height: 300px;
  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
  }
`
const CoverImg = styled.img`
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
`
export default FormProfile
