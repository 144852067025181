import styled from 'styled-components/macro'

import { OptionCardClickable } from '../CardOption'

export const ImageIcon = styled.img`
  display: block;
  width: 56px;
  height: 56px;
  vertical-align: middle;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.secondary3};
`

export const User = styled.div`
  display: flex;
  align-items: center;
`

export const UserImage = styled.div`
  margin-right: 20px;
`

export const DisplayName = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 28px;
  font-weight: 700;
  font-family: 'Din Pro Regular', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 45px;
`

export const IconWrapper = styled.div`
  svg {
    color: red;
  }
`

export const Link = styled(OptionCardClickable)`
  h5 {
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
  }

  &:hover {
    h5 {
      color: ${({ theme }) => theme.primary6} !important;
    }
  }
`
