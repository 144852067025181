import { useEffect, useState } from 'react'

export interface CurrentLocation {
  latitude: number
  longitude: number
}

export const useCurrentLocation = () => {
  const [location, setLocation] = useState<CurrentLocation | null>(null)

  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      setLocation(null)
    } else {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }

          setLocation(pos)
        },
        () => {
          setLocation(null)
        }
      )
    }
  }, [navigator.geolocation])

  return location
}
