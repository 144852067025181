import { LOCATION_FIELD_KEY, USER_INPUT_FIELD_KEY } from 'components/FormInputGoogleMaps'
import * as yup from 'yup'

import { dateTimeObjectToSecondsTimestamp } from '../../../../utils/dateTimeObjectToSecondsTimestamp'

export const schema = yup
  .object({
    name: yup.string().required('Required'),
    startDate: yup.object({
      date: yup.string().required('Required'),
      time: yup.string().required('Required'),
    }),
    endDate: yup
      .object({
        date: yup.string().required('Required'),
        time: yup.string().required('Required'),
      })
      .test('valid', 'End date must be after start date.', (value, context) => {
        const parsedStartDate = dateTimeObjectToSecondsTimestamp(context.parent.startDate)
        const parsedEndDate = dateTimeObjectToSecondsTimestamp(value)

        return parsedEndDate > parsedStartDate
      }),
    rewardPerFlexer: yup.string().required('Required'),
    tokenRewardPool: yup
      .string()
      .required('Required')
      .test('valid', 'Must be bigger or equal to rewards per flexer', (value, context) => {
        return Number(value) >= Number(context.parent.rewardPerFlexer)
      }),
    formattedAddress: yup
      .string()
      .test(
        'address-is-valid',
        'Please use a valid address. Select one of the options or drop a pin.',
        (value, context) => {
          const userInput = context.parent[USER_INPUT_FIELD_KEY]
          const location = context.parent[LOCATION_FIELD_KEY]

          if (location) return true
          else return !!value && !userInput
        }
      ),
  })
  .required()
