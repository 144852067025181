import * as React from 'react'
export const User = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.73 5.315a3.208 3.208 0 1 1 4.538 4.537 3.208 3.208 0 0 1-4.537-4.537Zm-1.237-1.238a4.958 4.958 0 1 1 7.012 7.012 4.958 4.958 0 0 1-7.012-7.012ZM14 15.458c-2.488 0-4.979.602-6.878 1.707-1.89 1.101-3.33 2.797-3.33 4.992v1.167c0 1.127.914 2.042 2.042 2.042h16.333a2.042 2.042 0 0 0 2.042-2.042v-1.167c0-2.195-1.44-3.891-3.33-4.992-1.9-1.105-4.39-1.707-6.879-1.707Zm-8.458 6.7c0-1.336.866-2.552 2.46-3.48 1.588-.924 3.764-1.47 5.998-1.47 2.235 0 4.41.546 5.998 1.47 1.595.928 2.46 2.144 2.46 3.48v1.166c0 .16-.13.292-.291.292H5.833a.292.292 0 0 1-.292-.292v-1.167Z"
      clipRule="evenodd"
    />
  </svg>
)
