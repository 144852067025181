import React from 'react'
import styled from 'styled-components/macro'

const NotAuthorized = () => {
  return (
    <Wrapper>
      <Container>
        <Section>
          <Heading>HEY!</Heading>
          <TextNotification>
            <h1>You are not authorized to view this page!</h1>
            <p>Please connect with an account with the privileges assigned</p>
          </TextNotification>
        </Section>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
const Container = styled.div`
  width: 100%;
`
const Section = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Heading = styled.h3`
  font-family: 'DIN 1451 Std', sans-serif;
  text-transform: uppercase;
  font-size: 160px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.primary6};
  margin-top: 0;
  @media (max-width: 768px) {
    font-size: 130px;
  }
  @media (max-width: 575px) {
    font-size: 100px;
  }
`
const TextNotification = styled.div`
  margin-bottom: 20px;
  h1 {
    color: ${({ theme }) => theme.white};
    font-family: 'DIN 1451 Std', sans-serif;
    line-height: 1.16667;
    margin: 0 0 8px 0;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.6;
    color: ${({ theme }) => theme.secondary6};
    text-align: center;
  }
`

export default NotAuthorized
