import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'
import { QueryKeys } from '../../../constants/queryKeys'
import { useApiClientContext } from '../../../context/ApiClientContext'
import { EventsSummary } from '../models/EventsSummary'
import { UseEventsQueryParams } from './useEvents'

interface UseEventsSummaryProps {
  partnerAddress: string
  queryParams?: UseEventsQueryParams
}

export const useEventsSummary = (
  params: UseEventsSummaryProps,
  options?: UseQueryOptions<UseEventsSummaryProps, AxiosError<any>, EventsSummary>
) => {
  const { client } = useApiClientContext()

  return useQuery<UseEventsSummaryProps, AxiosError<any>, EventsSummary>(
    [QueryKeys.EventsSummary, params],
    async () => {
      const result = await client.get(apiEndpoints.eventsSummary.replace('{partnerAddress}', params.partnerAddress), {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
        params: params.queryParams,
      })

      return result?.data
    },
    options
  )
}
