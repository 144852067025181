import React, { useMemo } from 'react'

import { useAnother1ERC20Decimals } from '../../../../hooks/useAnother1ERC20Decimals'
import { useGetProfile } from '../../../../state/profile/hooks'
import { formatBignumberToDecimal } from '../../../../utils/formatBignumberToDecimal'
import { timestampToSecondsTimestamp } from '../../../../utils/formatDate'
import { useEventsSummary } from '../../../Events/hooks/useEventsSummary'
import { DepositTokensButton } from '../DepositTokensButton'
import { RewardBox } from '../RewardBox'
import { WithdrawTokensButton } from '../WithdrawTokensButton'

export const EventsTokenSummary = () => {
  const { data: profile } = useGetProfile()

  const now = useMemo(() => {
    return timestampToSecondsTimestamp(new Date().getTime())
  }, [])

  const {
    data: summary,
    isLoading: isLoadingSummary,
    refetch,
  } = useEventsSummary(
    {
      partnerAddress: profile.address,
      queryParams: {
        endTimeFrom: now,
        cancelledStatus: false,
      },
    },
    {
      enabled: Boolean(profile && profile.address),
      refetchOnWindowFocus: false,
    }
  )

  const { data: decimals, isLoading: isLoadingDecimals } = useAnother1ERC20Decimals()

  const isLoading = isLoadingDecimals || isLoadingSummary

  const currentDeposit = useMemo(() => {
    if (!decimals || !summary || !summary.currentDeposit) {
      return '0'
    }

    return formatBignumberToDecimal(summary.currentDeposit, decimals)
  }, [decimals, summary])

  const eventsTokenPoolSum = useMemo(() => {
    if (!decimals || !summary || !summary.eventsTokenPoolSum) {
      return '0'
    }

    return formatBignumberToDecimal(summary.eventsTokenPoolSum, decimals)
  }, [decimals, summary])

  const tokensNeeded = useMemo(() => {
    const result = Number(eventsTokenPoolSum) - Number(currentDeposit)

    if (result < 0) {
      return '0'
    }

    return result.toFixed(4)
  }, [eventsTokenPoolSum, currentDeposit])

  return (
    <>
      <RewardBox title="Reward pools  for open events" amount={eventsTokenPoolSum} isLoading={isLoading} />
      <RewardBox title="Total deposit in flex 2 earn contract" amount={currentDeposit} isLoading={isLoading}>
        <div>
          <WithdrawTokensButton onSuccess={refetch} />
        </div>
      </RewardBox>
      <RewardBox title="AN1 Tokens needed" amount={tokensNeeded} isLoading={isLoading}>
        <div>
          <DepositTokensButton onSuccess={refetch} />
        </div>
      </RewardBox>
    </>
  )
}
