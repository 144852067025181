import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonTransparent } from '../../../../components/Button'
import { FormInputError } from '../../../../components/FormInputError'
import { Close } from '../../../../components/Icons/Close'
import Loader from '../../../../components/Loader'
import { shortenAddress } from '../../../../utils'
import { useGenerateEventWhitelist } from '../../hooks/useGenerateMerkleTreeRoot'
import { useSetWhitelistRootInRegistry } from '../../hooks/useSetWhitelistRootInRegistry'
import { ContinueSkipButtons } from '../ContinueSkipButtons'
import { WhitelistForm } from '../WhitelistForm'
import * as Styled from './styled'
import { SectionHeader } from './styled'

interface WhitelistModalProps {
  onSuccess?: () => void
  eventId: string
  continueSkipRoute?: string
}

interface EventParams {
  partnerAddress: string
}

export const WhitelistUpload = ({ onSuccess, eventId, continueSkipRoute }: WhitelistModalProps) => {
  const {
    mutateAsync: generateEventWhitelist,
    isLoading: isGenerating,
    isError: errorGenerating,
  } = useGenerateEventWhitelist()
  const {
    mutateAsync: addMerkleRootToRegistry,
    isLoading: isSettingInRegistry,
    isError: errorSetting,
    isSuccess,
  } = useSetWhitelistRootInRegistry()
  const [addresses, setAddresses] = useState<string[]>([])
  const { partnerAddress } = useParams<EventParams>()

  const addAddress = (newAddresses: string[]) => {
    setAddresses(newAddresses)
  }

  const handleGenerateWhitelist = async () => {
    const merkleRoot = await generateEventWhitelist({
      data: {
        whitelist: addresses,
      },
      eventId,
      partnerAddress,
    })

    await addMerkleRootToRegistry({
      root: merkleRoot.merkleTreeRoot,
      eventId,
    })

    onSuccess && onSuccess()
  }

  const handleRemoveAddress = (address: string) => {
    setAddresses(addresses.filter((existingAddress) => existingAddress !== address))
  }

  const isLoading = isGenerating || isSettingInRegistry
  const isError = errorGenerating || errorSetting

  return (
    <>
      <Styled.Content>
        <Styled.ContentItem>
          <SectionHeader>
            Add addresses <br /> (separated with new line){' '}
          </SectionHeader>
          <WhitelistForm addAddress={addAddress} isError={isError} />
        </Styled.ContentItem>
        <Styled.ContentItem>
          <SectionHeader> Address List </SectionHeader>
          <Styled.AddressesContainer>
            {addresses.map((address, index) => (
              <Styled.Address key={address}>
                <span>
                  {index + 1}. {shortenAddress(address)}
                </span>
                <ButtonTransparent onClick={() => handleRemoveAddress(address)}>
                  <Close />
                </ButtonTransparent>
              </Styled.Address>
            ))}
          </Styled.AddressesContainer>
          {isError && (
            <Styled.ErrorBox>
              {errorGenerating && (
                <FormInputError> Error generating whitelist, please review your address list.</FormInputError>
              )}
              {errorSetting && (
                <FormInputError>
                  Error while setting whitelist in contract, please resend the transaction.
                </FormInputError>
              )}
            </Styled.ErrorBox>
          )}

          {Boolean(addresses && addresses.length) && (
            <ButtonPrimary onClick={handleGenerateWhitelist} disabled={isLoading}>
              {isLoading ? <Loader stroke="#ffffff" size="20.5px" /> : 'Upload Whitelist'}
            </ButtonPrimary>
          )}
        </Styled.ContentItem>
      </Styled.Content>
      {continueSkipRoute && (
        <ContinueSkipButtons
          canContinue={isSuccess}
          route={continueSkipRoute}
          areButtonsDisabled={isGenerating || isSettingInRegistry}
        />
      )}
    </>
  )
}
