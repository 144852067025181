import { useApi } from 'hooks/useApi'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useParticle from 'hooks/useParticle'
import Table from 'rc-table'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { ButtonPrimary } from '../../components/Button'
import CountConvert from '../../components/CountConvert'
import EmptySearchBox from '../../components/EmptySearchBox'
import NavbarOth from '../../components/HeaderOth/NavbarOth'
import ModalConfirmConvert from '../../components/Modal/ModalConfirmConvert'
import ModalError from '../../components/Modal/ModalError'
import ModalSuccess from '../../components/Modal/ModalSuccess'
import SearchBox from '../../components/SearchBox'
import nameToId from '../../constants/ntr1NameToId.json'
// import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { useGetProfile } from '../../state/profile/hooks'
import { getERC1155Contract } from '../../utils'

type TokenData = {
  token_id: string
  name: string
  amount: string
}

type TableTokenData = {
  name: string
  erc: string
  convert: string
  id: string
}

const ConvertType = () => {
  useDocumentTitle(`ANOTHER-1 CONVERT NFT TYPE`)
  // const { account, library } = useActiveWeb3React()
  const { account, provider } = useParticle()

  const { data: userProfile } = useGetProfile()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [chooseNFTConvert, setChooseNFTConvert] = useState<any[]>([])
  const [term, setTerm] = useState<string>('')
  const [subCollections, setSubCollections] = useState<TableTokenData[] | null>(null)
  const [filteredCollections, setFilteredCollections] = useState<TableTokenData[] | null>(null)
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false)

  const onSearch = (term: string) => {
    setTerm(term)
  }

  const { get } = useApi()

  const getMoralisUrl = () => {
    const chain = parseInt(process.env.REACT_APP_CHAIN_ID ?? '') === 80001 ? 'mumbai' : 'polygon'
    const contract = process.env.REACT_APP_NTR1_ERC1155_ADDRESS ?? ''
    return `https://deep-index.moralis.io/api/v2/${account}/nft?chain=${chain}&format=decimal&token_addresses=${contract}&normalizeMetadata=false`
  }

  const updateData = () => {
    get(
      {
        url: getMoralisUrl(),
        config: {
          headers: {
            'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
            accept: 'application/json',
          },
        },
        callback: (res: any) => {
          const subCollections = res.data.result.map((x: TokenData) => {
            return {
              name: nameToId[x.token_id as keyof typeof nameToId],
              erc: parseInt(x.amount),
              convert: 0,
              id: x.token_id,
              image: `https://images-upload.sgp1.digitaloceanspaces.com/collections/thumbnails/0xF20B2647679D32FF36dCD17Fe4FfA5661EF79E7b-${x.token_id}.jpg`,
            }
          })

          setSubCollections(subCollections)
          setFilteredCollections(subCollections)
        },
        callbackFinally: () => {
          // setLoading(false)
        },
      },
      true
    )
  }

  useEffect(() => {
    if (account) updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (subCollections) {
      if (term === '') {
        setFilteredCollections(subCollections)
      } else {
        const filteredCollections = subCollections.filter((x: TableTokenData) =>
          x.name.toLowerCase().startsWith(term.toLowerCase())
        )
        setFilteredCollections(filteredCollections)
        const chooseNFTConvertFiltered = chooseNFTConvert.filter((x) => filteredCollections.some((y) => x.id == y.id))
        setChooseNFTConvert(chooseNFTConvertFiltered)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term])

  const columns: any = useMemo(() => {
    const handleSelected = (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
      const checkedBox = event.target.checked
      if (checkedBox) {
        setChooseNFTConvert((prevState) => {
          return [...prevState, row]
        })
      } else {
        setChooseNFTConvert((prevState) => {
          return prevState.filter((element) => element.id !== row.id)
        })
      }
    }

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      const checkedBox = event.target.checked
      filteredCollections?.forEach((x) => {
        if (checkedBox) {
          setChooseNFTConvert((prevState) => {
            return [...prevState, x]
          })
          setIsSelectedAll(true)
        } else {
          setChooseNFTConvert((prevState) => {
            return prevState.filter((element) => element.id !== x.id)
          })
          setIsSelectedAll(false)
        }
      })
    }

    const allColumns = [
      {
        title: (
          <div className={'th_id'}>
            <Checkbox>
              <input
                type="checkbox"
                id={`enable_1000`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelectAll(event)}
              />
              <label htmlFor={`enable_1000`} className={'enable_1000'}></label>
            </Checkbox>
          </div>
        ),
        dataIndex: 'id',
        key: 'id',
        width: 60,
        align: 'left',
        render(value: any, row: any, index: number) {
          return (
            <Checkbox>
              <input
                type="checkbox"
                id={`enable_${index}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelected(event, row)}
                checked={isSelectedAll || chooseNFTConvert.some((x) => x.id == row.id)}
              />
              <label htmlFor={`enable_${index}`}></label>
            </Checkbox>
          )
        },
      },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        align: 'right',
        render(value: any, row: any, index: number) {
          return <img src={row.image} width="40px" height="30px" />
        },
        width: '50px',
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
      },
      {
        title: 'ERC1155 amt.',
        dataIndex: 'erc',
        key: 'erc',
        align: 'left',
        className: 'min-w150px w150px',
      },
      {
        title: 'CONVERT amt.',
        dataIndex: 'convert',
        key: 'convert',
        align: 'left',
        className: 'min-w180px w180px',
        render(value: any, row: any, index: number) {
          const hasCheck = chooseNFTConvert.findIndex((element) => element.id === row.id)
          return hasCheck !== -1 ? (
            <CountConvert row={row} setChooseNFTConvert={setChooseNFTConvert} indexCheck={hasCheck} />
          ) : (
            ''
          )
        },
      },
    ]
    return allColumns
  }, [filteredCollections, chooseNFTConvert, setChooseNFTConvert])

  const toggleConvert1155 = async () => {
    if (!provider || !account || totalTotransfer === 0) return

    const erc1155 = getERC1155Contract(provider, account)
    const converterAddress = process.env.REACT_APP_CONVERTER_ADDRESS ?? ''

    const idsToTransfer = chooseNFTConvert.map((x) => x.id)
    const amountsToTransfer = chooseNFTConvert.map((x) => parseInt(x.convert))

    const tx = await erc1155
      .safeBatchTransferFrom(account, converterAddress, idsToTransfer, amountsToTransfer, [])
      .catch((err: any) => {
        console.log(err)
        setShowError(true)
        setShowConfirm(false)
      })

    await tx?.wait().then((res: any) => {
      setShowConfirm(false)
      setShowSuccess(true)
      updateData()
    })
  }

  const totalTotransfer =
    chooseNFTConvert.length !== 0
      ? chooseNFTConvert.reduce((total: number, element) => {
          const conv = element.convert === '' ? 0 : parseInt(element.convert)
          return (total += conv)
        }, 0)
      : 0

  return (
    <Wrapper>
      <Header>
        <Title>Convert your NFT type</Title>
        <SubTitle>
          {!account
            ? 'Please connect your wallet on the Polygon network to upgrade your NFTs'
            : 'Converting ERC-1155 NFTs to ERC-721 NFTs'}
        </SubTitle>
      </Header>
      <NavbarOth />
      {account && userProfile.address !== '' && filteredCollections && (
        <>
          <SearchBoxWrap>
            <SearchBox onChange={onSearch} placeholder={'Search by name'} />
          </SearchBoxWrap>
          <TableDetail>
            <Table
              rowKey="id"
              columns={columns}
              data={filteredCollections}
              emptyText={<EmptySearchBox text={'No data'} />}
              //  scroll={{ y: 300 }}
              sticky={true}
              className={'rc-sticky-cs'}
            />
            <DetailFooter>
              <TotalConvert>
                Your Total converting{' '}
                <span>
                  <span>{totalTotransfer}</span> Token
                </span>
              </TotalConvert>
              <CtaConvert disabled={totalTotransfer === 0} onClick={() => setShowConfirm(true)}>
                Convert Now
              </CtaConvert>
            </DetailFooter>
          </TableDetail>
        </>
      )}

      {showConfirm && (
        <ModalConfirmConvert
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => toggleConvert1155()}
          subText={'Are you sure you want to convert these ERC-1155 NFTs to ERC-721 NFTs?'}
          chooseNFTConvert={chooseNFTConvert}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={`Convert Failed`} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={`Your ERC-1155 NFTs has been converted to ERC-721 NFTs`}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
`
const Header = styled.div`
  margin-bottom: 40px;
  @media screen and(max-width: 1024px) {
    margin-bottom: 80px;
  }
  @media screen and(max-width: 768px) {
    margin-bottom: 40px;
  }
`
const Title = styled.div`
  font-family: 'DIN 1451 Std', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 80px;
  color: ${({ theme }) => theme.white};
  text-align: center;
`
const SubTitle = styled.h3`
  margin: 0;
  font-weight: 400;
  font-family: 'Din Pro Regular', sans-serif;
  line-height: 1.333;
  text-align: center;
  color: ${({ theme }) => theme.secondary6};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`
const SearchBoxWrap = styled.div`
  margin-bottom: 32px;
`
const TableDetail = styled.div`
  margin-bottom: 32px;
  position: relative;
`
const Checkbox = styled.div`
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
    color: ${({ theme }) => theme.primary10};
    &::before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border-radius: 4px;
      border: 2px solid ${({ theme }) => theme.secondary3};
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  input:checked {
    & + label::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 6px;
      width: 6px;
      height: 9px;
      border: solid ${({ theme }) => theme.secondary1};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    & + label::before {
      background-color: ${({ theme }) => theme.primary6};
      border-color: ${({ theme }) => theme.primary6};
    }
  }
  label.enable_thead {
    cursor: context-menu;
    &::before {
      cursor: context-menu;
    }
  }
`
const DetailFooter = styled.div`
  margin-top: 32px;
  padding-top: 34px;
  border-top: 1px dashed ${({ theme }) => theme.secondary3};
`
const TotalConvert = styled.p`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  & > span > span {
    color: ${({ theme }) => theme.primary6};
  }
`
const CtaConvert = styled(ButtonPrimary)`
  padding: 10px 16px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 32px;
  text-align: center;
`
export default ConvertType
