import React from 'react'

import { Another1Logo } from '../../../../components/Icons/Another1Logo'
import Loader from '../../../../components/Loader'
import { useAnother1ERC20Balance } from '../../../../hooks/useAnother1ERC20Balance'
import { useFormatErc20Tokens } from '../../../../hooks/useFormatERC20Tokens'
import { useGetProfile } from '../../../../state/profile/hooks'
import * as Styled from './styled'

export const Another1TokenBalance = () => {
  const { data: profile } = useGetProfile()
  const { data: balance, isLoading } = useAnother1ERC20Balance(profile.address, {
    enabled: Boolean(profile && profile.address),
  })

  const parsedBalance = useFormatErc20Tokens(String(balance || '0'))

  return (
    <Styled.Container>
      <Another1Logo />
      <Styled.TextContainer>
        <Styled.BalanceText>tokens balance</Styled.BalanceText>
        <Styled.AmountText> {!parsedBalance || isLoading ? <Loader size="16px" /> : parsedBalance} </Styled.AmountText>
      </Styled.TextContainer>
    </Styled.Container>
  )
}
