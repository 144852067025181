import { BtnSubmit } from 'components/Button'
import ColCs from 'components/ColCs'
import { ErrorMessage, FormGroup, FormInput, FormLabel } from 'components/Forms'
import Confirm from 'components/Modal/ModalConfirm'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import RowCs from 'components/RowCs'
import { useFormik } from 'formik'
import { useApi } from 'hooks/useApi'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
// import DatePicker from 'react-datepicker'
import { useGetWhitelistSettingsQuery, useUpdateWhitelistSettingsMutation } from 'state/collections/slice'
import * as Yup from 'yup'

interface PropsType {
  collectionAddress?: string
}

const SlotManagement = ({ collectionAddress }: PropsType) => {
  const { post } = useApi()
  const today = moment.utc().format('YYYY-MM-DD HH:mm:ss')
  const todayUTC = new Date(today)

  const [startDate, setStartDate] = useState<Date | null>(todayUTC)
  const [endDate, setEndDate] = useState<Date | null>(todayUTC)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const [updateWhitelistSettings] = useUpdateWhitelistSettingsMutation()

  const handleSuccess = () => {
    setShowSuccess(true)
    refetch()
  }

  const handleError = (e: any) => {
    console.log(e)
    setErrorMessage(e?.response?.data)
    setShowSuccess(false)
    setShowError(true)
  }

  const handleSubmit = async (values: any) => {
    await updateWhitelistSettings({
      address: collectionAddress,
      slotLimit: values.slotLimit,
      startTime: moment(startDate).unix(),
      endTime: moment(endDate).unix(),
      post,
      handleSuccess,
      handleError,
    }).finally(() => setShowConfirm(false))
  }

  const {
    data: whitelistSettings,
    isLoading,
    refetch,
  } = useGetWhitelistSettingsQuery(
    {
      address: collectionAddress,
    },
    { skip: !collectionAddress }
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      slotLimit: whitelistSettings?.slotLimit || '',
      startTime: whitelistSettings?.startTime || '',
      endTime: whitelistSettings?.endTime || '',
    },
    validationSchema: Yup.object().shape({
      slotLimit: Yup.number().required('This field is required').positive('This value cannot be less than 0'),
    }),
    onSubmit: (values) => handleSubmit(values),
  })

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date)
  }
  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date)
  }

  useEffect(() => {
    if (!isLoading && collectionAddress && whitelistSettings?.startTime && whitelistSettings?.endTime) {
      setStartDate(moment.utc(whitelistSettings?.startTime, 'X').toDate())
      setEndDate(moment.utc(whitelistSettings?.endTime, 'X').toDate())
    }
  }, [whitelistSettings, isLoading, collectionAddress])
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <RowCs>
          <ColCs md={12} xs={12} className={'form-col'}>
            <FormGroup>
              <FormLabel>Slot limit</FormLabel>
              <FormInput
                type={'number'}
                placeholder={'Enter slot limit'}
                id={'slotLimit'}
                name={'slotLimit'}
                value={formik.values.slotLimit}
                onChange={formik.handleChange}
              />
              {formik.errors.slotLimit && formik.values.slotLimit && (
                <ErrorMessage> {formik.errors.slotLimit.toString()} </ErrorMessage>
              )}
            </FormGroup>
          </ColCs>
          {/* <ColCs md={6} xs={12} className={'form-col'}>
            <FormGroup>
              <FormLabel className={'time-utc'}>Start time</FormLabel>
              <DatePickerDiv>
                <label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    showTimeSelect
                    minDate={todayUTC}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                </label>
              </DatePickerDiv>
            </FormGroup>
          </ColCs>
          <ColCs md={6} xs={12} className={'form-col'}>
            <FormGroup>
              <FormLabel className={'time-utc'}>End time</FormLabel>
              <DatePickerDiv>
                <label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    showTimeSelect
                    minDate={todayUTC}
                    placeholderText="Choose date"
                    timeFormat="HH:mm"
                    timeCaption="time"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                </label>
              </DatePickerDiv>
            </FormGroup>
          </ColCs> */}
        </RowCs>
        <BtnSubmit type="button" disabled={!formik.dirty || !formik.isValid} onClick={() => setShowConfirm(true)}>
          Update
        </BtnSubmit>
      </form>
      <Confirm
        isOpen={showConfirm}
        onDismiss={() => setShowConfirm(false)}
        submit={formik.handleSubmit}
        subText={'Are you sure you want to update these values?'}
      />
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={errorMessage} />
      <ModalSuccess isOpen={showSuccess} onDismiss={() => setShowSuccess(false)} message={'Saved successfully'} />
    </div>
  )
}

export default SlotManagement
