import get from 'lodash.get'
import { InputHTMLAttributes, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { FormInputError } from '../FormInputError'
import { FormInputLabel } from '../FormInputLabel'
import * as Styled from './styled'

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  customError?: string
  autoFocus?: boolean
}

export const FormInput = ({ name, label, customError, autoFocus, ...inputProps }: FormInputProps) => {
  const {
    register,
    formState: { errors },
    setFocus,
  } = useFormContext()

  const errorMessage = useMemo(() => {
    const errorForThisInput = get(errors, name)

    if (!errors || !errorForThisInput || !errorForThisInput.message) {
      return ''
    }

    return errorForThisInput?.message
  }, [errors, name])

  useEffect(() => {
    autoFocus && setFocus(name)
  }, [setFocus, name, autoFocus])

  return (
    <Styled.InputContainer>
      <FormInputLabel htmlFor={name}> {label} </FormInputLabel>
      <Styled.Input id={name} {...inputProps} {...register(name)} />
      <FormInputError>{(errorMessage as string) || customError}</FormInputError>
    </Styled.InputContainer>
  )
}
