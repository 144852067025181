import ImgDefaultItem from 'assets/images/fallback.svg'
import React from 'react'
import { Heart } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { Card, Flex, Image, Text } from 'rebass'
import styled from 'styled-components/macro'

const Item = (props: any) => {
  const history = useHistory()
  const { dataItem, nameItem, onClickLike } = props
  //const [likeChecked, setLikeChecked] = React.useState<boolean>(false)
  const { pathname } = history.location
  const handleLikeOnClick = (e: any) => {
    e.preventDefault()
    if (!onClickLike) {
      return
    }
    onClickLike()
  }

  const handleItemClick = (id: string, collectionAddress: string) => {
    history.push(`/nft/${collectionAddress}/${id}`)
  }

  const handleCollectionNameClick = (collectionAddress: string) => {
    history.push(`/collection/${collectionAddress}`)
  }

  return (
    <Flex className={'item-card'}>
      <CollectionItem>
        <ImageBox className={'image-box'}>
          <OverlayImageBox
            className={'overlay-image-box'}
            onClick={() => handleItemClick(dataItem.tokenId, dataItem.collectionAddress)}
          />
          <CustomImage
            src={
              dataItem?.metadata?.image && dataItem?.isMinted
                ? dataItem?.metadata?.thumbnail
                  ? dataItem?.metadata?.thumbnail
                  : dataItem?.metadata?.image
                : ImgDefaultItem
            }
          />
          {pathname.indexOf('/user/') === 0 || pathname.indexOf('/profile') === 0 ? (
            <></>
          ) : (
            <div className="overlay">
              <Heart
                className={'icon-heart ' + (dataItem?.isLiked ? 'active' : '')}
                onClick={(e) => handleLikeOnClick(e)}
              />
            </div>
          )}
        </ImageBox>
        {dataItem.title && <TitleCard className={'title-card'}>{dataItem.title}</TitleCard>}
        {dataItem.desc && <CollectionInfo fontSize="14px">{dataItem.desc}</CollectionInfo>}
        {dataItem.creator && <TitleCard className={'title-card'}>{dataItem.creator}</TitleCard>}
        {dataItem?.metadata?.name && (
          <TitleCard
            className={'title-card'}
            onClick={() => handleItemClick(dataItem.tokenId, dataItem.collectionAddress)}
          >
            {dataItem?.isMinted ? dataItem.metadata.name : dataItem.tokenId}
          </TitleCard>
        )}
        {nameItem && <CollectionInfo fontSize="14px">{nameItem}</CollectionInfo>}
        {dataItem?.nameCollection && (
          <CollectionInfo fontSize="14px" onClick={() => handleCollectionNameClick(dataItem.collectionAddress)}>
            {dataItem.nameCollection}
          </CollectionInfo>
        )}
      </CollectionItem>
    </Flex>
  )
}

export default Item

const CollectionItem = styled(Card)`
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 42px !important;
`

const CollectionInfo = styled(Text)`
  color: ${({ theme }) => theme.primary6};
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;
`

const CustomImage = styled(Image)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
`

const ImageBox = styled.div`
  position: relative;
  transition: all 0.3s;
  border: 2px solid transparent;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  &:hover {
    border-color: ${({ theme }) => theme.primary6};
    .overlay-image-box {
      opacity: 1;
    }
    .icon-heart {
      opacity: 1;
      visibility: visible;
    }
  }
  .overlay {
    z-index: 15;
  }
  .icon-heart {
    position: absolute;
    z-index: 15;
    top: 20px;
    right: 20px;
    width: 22px;
    height: 22px;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;

    &.active {
      color: ${({ theme }) => theme.primary6};
      fill: ${({ theme }) => theme.primary6};
    }
  }
`
const OverlayImageBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: rgba(4, 33, 23, 0.6);
  z-index: 10;
  opacity: 0;
`
const TitleCard = styled.h5`
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary10};
  margin: 15px 0 12px;
`
