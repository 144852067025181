import { createContext, ReactNode, useContext } from 'react'

import { CurrentLocation, useCurrentLocation } from '../hooks/useCurrentLocation'

export interface CurrentLocationContextProps {
  location: CurrentLocation | null
}

const defaultValues: CurrentLocationContextProps = {
  location: null,
}

const CurrentLocationContext = createContext<CurrentLocationContextProps>(defaultValues)

export const useCurrentLocationContext = () => {
  return useContext(CurrentLocationContext)
}

interface UserAccountContextProviderProps {
  children: ReactNode
}

export const CurrentLocationContextProvider = ({ children }: UserAccountContextProviderProps) => {
  const location = useCurrentLocation()

  return <CurrentLocationContext.Provider value={{ location }}>{children}</CurrentLocationContext.Provider>
}
