import { ReactComponent as IconPlus } from 'assets/icons/icon-plus.svg'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import { useApi } from '../../../hooks/useApi'
import { useCreateAttributesMutation, useUpdateAttributesMutation } from '../../../state/settingAttributes/slice'
import { customStylesSelect } from '../../../theme'
import { ButtonPrimary } from '../../Button'
import ColCs from '../../ColCs'
import { FormGroup, FormInput, FormLabel } from '../../Forms'
import Loader from '../../Loader'
import RowCs from '../../RowCs'
import Modal from '../index'
const optionsType = [
  {
    label: 'INPUT',
    value: 'INPUT',
  },
  {
    label: 'SELECT',
    value: 'SELECT',
  },
]
const ModalSettingAttribute = (props: any) => {
  const { isOpen, onDismiss, dataInit, handleSuccess, handleError, status, refetch } = props

  const [optionsTemporary, setOptionsTemporary] = useState<string[]>([])
  const { post, patch } = useApi()
  const [createAttributes] = useCreateAttributesMutation()
  const [updateAttributes] = useUpdateAttributesMutation()

  const { type, name, information, placeholder, options: optionsInit, id } = dataInit
  const attributeFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedOption: {
        label: type || 'INPUT',
        value: type || 'INPUT',
      },

      attributeName: name || '',
      information: information || '',
      placeholder: placeholder || '',
      options: optionsInit.length > 0 ? [...optionsInit] : [],
      currentOption: '',
    },
    validationSchema: Yup.object().shape({
      attributeName: Yup.string().required('Sorry, this field cannot be empty'),
    }),
    onSubmit: (values) => {
      const { attributeName, information, placeholder, selectedOption } = values
      if (status === 'Create' && !id) {
        createAttributes({
          post,
          name: attributeName,
          information,
          placeholder,
          type: selectedOption.value,
          handleSuccess,
          options: selectedOption.value === 'INPUT' ? [] : optionsTemporary,
          handleError,
          refetch,
          handleFinally: () => {
            onDismiss()
          },
        })
      } else {
        updateAttributes({
          patch,
          id,
          name: attributeName,
          information,
          placeholder,
          type: selectedOption.value,
          handleSuccess,
          options: selectedOption.value === 'INPUT' ? [] : optionsTemporary,
          handleError,
          refetch,
          handleFinally: () => {
            onDismiss()
          },
        })
      }
    },
  })
  const { values, setValues, handleChange, handleSubmit, isSubmitting } = attributeFormik
  const handleClickAdd = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!optionsTemporary.includes(values.currentOption)) {
      setOptionsTemporary([...optionsTemporary, values.currentOption])
      setValues((values) => {
        return {
          ...values,
          currentOption: '',
          options: [...optionsTemporary, values.currentOption],
        }
      })
    }
  }
  const handleDeleteOption = (option: string) => {
    const filterArr = optionsTemporary.filter((item: string) => {
      return item !== option
    })
    setOptionsTemporary(filterArr)
    setValues((values) => {
      return {
        ...values,
        options: [...filterArr],
      }
    })
  }
  useEffect(() => {
    setOptionsTemporary(optionsInit)
  }, [optionsInit])
  return (
    <>
      <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={600}>
        <ModalContent>
          <ModalHeader>
            <h3>Adding new attribute</h3>
            <h6>Creating new attribute and their specific options</h6>
            <span className="icon-close" onClick={onDismiss}>
              <IconClose />
            </span>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <FormLabel>Attribute name</FormLabel>
                <FormInput
                  placeholder={'Please enter the attribute name'}
                  name={'attributeName'}
                  id={'attributeName'}
                  value={values.attributeName ?? ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Information</FormLabel>
                <FormInput
                  placeholder={'Please enter the information'}
                  name={'information'}
                  id={'information'}
                  value={values.information ?? ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Placeholder</FormLabel>
                <FormInput
                  placeholder={'Please enter the placeholder'}
                  name={'placeholder'}
                  id={'placeholder'}
                  value={values.placeholder ?? ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Choose type</FormLabel>
                <FormSelect>
                  <Select
                    styles={customStylesSelect}
                    options={optionsType}
                    placeholder={'Choose type'}
                    classNamePrefix={'select-cs'}
                    defaultValue={optionsType.filter((option) => option.label === values.selectedOption.label)}
                    id="chooseType"
                    name="chooseType"
                    onChange={(value) => {
                      setValues((prevValue: any) => {
                        return {
                          ...prevValue,
                          selectedOption: {
                            label: value?.label,
                            value: value?.value,
                          },
                        }
                      })
                    }}
                  />
                </FormSelect>
              </FormGroup>
              {values.selectedOption.value === 'SELECT' && (
                <>
                  <FormGroup className={'form-group--options'}>
                    <FormLabel>Options Detail</FormLabel>
                    <FormInput
                      placeholder={'Please enter options detail'}
                      name={'currentOption'}
                      id={'currentOption'}
                      onChange={handleChange}
                      value={values.currentOption ?? ''}
                    />
                    <span
                      className="icon-add"
                      onClick={(event: React.MouseEvent<HTMLSpanElement>) => handleClickAdd(event)}
                    >
                      <IconPlus />
                    </span>
                  </FormGroup>
                  {optionsTemporary.length > 0 && (
                    <RowCs space={-10}>
                      {optionsTemporary.map((option: string, index) => (
                        <ColCs xs={4} gutter={10} key={index} className={'col-cs-option'}>
                          <div className="option-item">
                            <p>{option}</p>
                            <span className="icon-delete-option" onClick={() => handleDeleteOption(option)}>
                              <IconClose />
                            </span>
                          </div>
                        </ColCs>
                      ))}
                    </RowCs>
                  )}
                </>
              )}

              <CtaAddAttribute type={'submit'} disabled={!attributeFormik.dirty || !attributeFormik.isValid}>
                {!isSubmitting ? (
                  status === 'Create' ? (
                    'Add attribute'
                  ) : (
                    'Update attribute'
                  )
                ) : (
                  <>
                    <span style={{ marginRight: '6px' }}>
                      {status === 'Create' ? 'Add attribute ' : 'Update attribute '}
                    </span>
                    <Loader size={'24px'} stroke={'#151515'} />
                  </>
                )}
              </CtaAddAttribute>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
const ModalContent = styled.div`
  padding: 48px 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
`
const ModalHeader = styled.div`
  padding-bottom: 20px;
  padding-right: 36px;
  position: relative;
  .icon-close {
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    background-color: ${({ theme }) => theme.secondary3};
  }
  h3 {
    text-transform: uppercase;
    line-height: 1.33;
    font-weight: 700;
    margin: 0 0 6px 0;
  }
  h6 {
    line-height: 1.5;
    color: ${({ theme }) => theme.secondary6};
    margin: 0;
  }
`
const ModalBody = styled.div`
  margin-top: 32px;
  .form-group--options {
    position: relative;
    input {
      padding-right: 40px;
    }
    .icon-add {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 50px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: ${({ theme }) => theme.primary6};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: ${({ theme }) => theme.secondary3};
        }
      }
    }
  }
  .col-cs-option {
    margin-top: 8px;
  }
  .option-item {
    padding: 8px 12px;
    background-color: ${({ theme }) => theme.secondary2};
    border: 1px solid ${({ theme }) => theme.secondary3};
    display: flex;
    p {
      margin: 0 6px 0 0;
      font-size: ${({ theme }) => theme.fontSizeText4};
      line-height: 1.33;
      color: ${({ theme }) => theme.secondary8};
      flex: 1;
    }
    .icon-delete-option {
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3px;
      cursor: pointer;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`
const CtaAddAttribute = styled(ButtonPrimary)`
  margin-top: 32px;

  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  font-weight: 700;
  padding: 10px 12px;
  width: 100%;
  color: ${({ theme }) => theme.secondary1};
  background-color: ${({ theme }) => theme.primary6};
  text-transform: uppercase;
  &[disabled] {
    background-color: ${({ theme }) => theme.primary2};
  }
`
const FormSelect = styled.div``
export default ModalSettingAttribute
