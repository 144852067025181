import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { useOptionAssignAttributeCollection } from '../../../../hooks/useOptionAssignAttributeCollection'
import FormCheckbox from '../Components/FormCheckbox'

interface TypeColumnSelect {
  title: string
  key: string
}
const COLUMN_OPTION_VISIBLE: TypeColumnSelect[] = [
  {
    title: 'Size',
    key: 'itemSize',
  },
]

export const useSelectedColumn = (collectionAddress: string | undefined) => {
  const optionAttribute = useOptionAssignAttributeCollection(collectionAddress)
  const COLUMN_OPTION_AVAILABLE: TypeColumnSelect[] = optionAttribute.map((item: any) => {
    return {
      title: item.name,
      key: item.name,
    }
  })
  const [selectedColumnKeys, setSelectedColumnKeys] = useState<any>({
    image: true,
    tokenId: true,
    orderNo: true,
    color: true,
    name: true,
    itemSize: true,
    nfcId: true,
  })
  const columnMenu = useMemo(() => {
    const toggleSelectColumnKey = (key: string) => {
      setSelectedColumnKeys((prevState: any) => {
        return {
          ...prevState,
          [key]: !selectedColumnKeys[key],
        }
      })
    }
    return (
      <MenuAttributeBox>
        <DivFields>
          <NameFields>Visible fields</NameFields>
          {COLUMN_OPTION_VISIBLE.map((columnOption: TypeColumnSelect) => {
            return (
              <FormCheckbox
                fieldName={columnOption.key}
                onClick={() => toggleSelectColumnKey(columnOption.key)}
                selectedColumnKeys={selectedColumnKeys}
                key={columnOption.key}
              />
            )
          })}
        </DivFields>
        <DivFields>
          <NameFields>Available fields</NameFields>
          {COLUMN_OPTION_AVAILABLE.map((columnOption: TypeColumnSelect) => {
            return (
              <FormCheckbox
                fieldName={columnOption.key}
                onClick={() => toggleSelectColumnKey(columnOption.key)}
                selectedColumnKeys={selectedColumnKeys}
                key={columnOption.key}
              />
            )
          })}
        </DivFields>
      </MenuAttributeBox>
    )
  }, [selectedColumnKeys, COLUMN_OPTION_AVAILABLE])

  return [columnMenu, selectedColumnKeys]
}
const MenuAttributeBox = styled.div`
  width: 100%;
`
const DivFields = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  & > div:not(:last-of-type) {
    margin-bottom: 12px;
  }
`

const NameFields = styled.h5`
  margin: 0 0 8px 0;
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary6};
`
