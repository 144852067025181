import { ModalBase } from '../../../../components/ModalBase/ModalBase'
import { DepositTokenForm } from '../DepositTokensForm'

interface DepositTokensModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

export const DepositTokensModal = ({ isOpen, onClose, onSuccess }: DepositTokensModalProps) => {
  const handleSuccess = () => {
    onSuccess()
    onClose()
  }
  return (
    <ModalBase isOpen={isOpen} onDismiss={onClose} header="Deposit Tokens">
      <DepositTokenForm onSuccess={handleSuccess} />
    </ModalBase>
  )
}
