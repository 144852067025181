import styled from 'styled-components/macro'

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Wrapper = styled.div``
export const PageTitle = styled.h5`
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary7};
  margin: 0 0 12px 0;
`
