import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { load, save } from 'redux-localstorage-simple'

import application from './application/reducer'
import { assignAttributesApi } from './assignAttributes/slice'
import { becomeCreator } from './becomeCreator/slice'
import collectionDetail from './collections/reducer'
import { collectionsApi } from './collections/slice'
import { assetsApi } from './nfts/slice'
import { phygitalApi } from './phygital/slice'
import profile from './profile/reducer'
import { searchApi } from './search/slice'
import { settingAttributesApi } from './settingAttributes/slice'
import user from './user/reducer'
import { usersManagementApi } from './usersManagement/slice'

const PERSISTED_KEYS: string[] = ['user']

const store = configureStore({
  reducer: {
    application,
    user,
    profile,
    [searchApi.reducerPath]: searchApi.reducer,
    [collectionsApi.reducerPath]: collectionsApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [phygitalApi.reducerPath]: phygitalApi.reducer,
    [becomeCreator.reducerPath]: becomeCreator.reducer,
    [usersManagementApi.reducerPath]: usersManagementApi.reducer,
    [settingAttributesApi.reducerPath]: settingAttributesApi.reducer,
    [assignAttributesApi.reducerPath]: assignAttributesApi.reducer,
    collectionDetail,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false })
      .concat(searchApi.middleware)
      .concat(collectionsApi.middleware)
      .concat(assetsApi.middleware)
      .concat(phygitalApi.middleware)
      .concat(becomeCreator.middleware)
      .concat(usersManagementApi.middleware)
      .concat(settingAttributesApi.middleware)
      .concat(assignAttributesApi.middleware)
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: process.env.NODE_ENV === 'test' }),
})

setupListeners(store.dispatch)

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
