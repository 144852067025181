import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { FormInputLabel } from 'components/FormInputLabel'
import Loader from 'components/Loader'
import { QueryKeys } from 'constants/queryKeys'
import { OptionType } from 'entities/OptionType'
import { useInitChooseCollection } from 'hooks/useInitChooseCollection'
import { useAddAllowedCollectionToEvent } from 'modules/Collections/hooks/useAddAllowedCollectionToEvent'
import { useAddAllowedCollectionToEventInRegistry } from 'modules/Collections/hooks/useAddAllowedCollectionToEventInRegistry'
import { IGetAllowedCollections } from 'modules/Collections/hooks/useGetAllowedCollections'
import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import Select from 'react-select'
import { customStylesSelect } from 'theme'

import { useGetProfile } from '../../../../state/profile/hooks'
import { CollectionsList } from '../CollectionsList'
import { schema } from './schema'
import { SubmitButton } from './styled'

interface CollectionsFormProps {
  eventId: string
  eventCollections: IGetAllowedCollections[] | undefined
  collectionsLoading: boolean
  partnerAddress: string
  continueSkipRoute?: string
}

export interface CollectionsFormValues {
  collection: Required<OptionType>
}

export const CollectionsForm = ({
  eventId,
  eventCollections,
  collectionsLoading,
  partnerAddress,
  continueSkipRoute,
}: CollectionsFormProps) => {
  const queryClient = useQueryClient()
  const { data: profile } = useGetProfile()

  const { collectionOptions, isLoading } = useInitChooseCollection()
  const { mutateAsync: addAllowedCollection, isLoading: isAddingAllowedCollections } = useAddAllowedCollectionToEvent()
  const { mutateAsync: addAllowedCollectionInRegistry, isLoading: isAddingAllowedCollectionsToRegistry } =
    useAddAllowedCollectionToEventInRegistry()

  const eventCollectionsAddresses = useMemo(
    () => (eventCollections || []).map(({ collectionAddress }) => collectionAddress),
    [eventCollections]
  )

  const options = useMemo<OptionType[]>(() => {
    if (!collectionOptions) return []

    return collectionOptions.filter(({ value }) => value && !eventCollectionsAddresses?.includes(value))
  }, [collectionOptions, eventCollectionsAddresses])

  const formValues = useForm({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    resetField,
  } = formValues

  const handleAddCollection = async (values: CollectionsFormValues) => {
    try {
      await addAllowedCollectionInRegistry({
        eventId,
        collectionAddresses: [values.collection.value],
      })

      await addAllowedCollection({
        eventId,
        partnerAddress,
        collectionAddress: values.collection.value,
      })

      await queryClient.invalidateQueries([QueryKeys.GetAllowedCollections])
    } catch (e) {
      // TODO: Handle error
      console.error(e)
    } finally {
      resetField('collection')
    }
  }

  const loading = collectionsLoading || isLoading

  return (
    <>
      <FormProvider {...formValues}>
        <form onSubmit={handleSubmit(handleAddCollection)}>
          <Controller
            control={control}
            name="collection"
            render={({ field }) => {
              const { onChange, value, ...rest } = field

              return (
                <>
                  <FormInputLabel htmlFor="collection">Collection</FormInputLabel>

                  <Select
                    styles={customStylesSelect}
                    options={options}
                    classNamePrefix={'select-cs'}
                    menuPortalTarget={document.body}
                    isLoading={loading}
                    noOptionsMessage={() => 'No collections found...'}
                    placeholder="Search for collections..."
                    onChange={(value: OptionType) => {
                      onChange(value)
                    }}
                    value={value || ''}
                    autoFocus
                    {...rest}
                  />

                  <SubmitButton type="submit" disabled={isSubmitting || !value}>
                    {isSubmitting ? <Loader stroke="#ffffff" size="20.5px" /> : 'Add collection'}
                  </SubmitButton>
                </>
              )
            }}
          />
        </form>
      </FormProvider>
      <CollectionsList
        {...{
          eventId,
          eventCollections,
          isLoading,
          areButtonsDisabled: isAddingAllowedCollections || isAddingAllowedCollectionsToRegistry,
          partnerAddress: profile.address,
          continueSkipRoute: continueSkipRoute,
        }}
      />
    </>
  )
}
