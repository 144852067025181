import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ButtonOutlineNeutral } from '../../../../components/Button'
import { PenIcon } from '../../../../components/Icons/Pen'
import { routes } from '../../../../constants/routes'
import { useClerkListContext } from '../../../Clerks/context/ClerkListContext'
import { SendMaticModal } from '../SendMaticModal'
import * as Styled from './styled'

interface ClerksActionsProps {
  clerkAddress: string
  eventId: string
  partnerAddress: string
}

export const ClerkActions = ({ clerkAddress, eventId, partnerAddress }: ClerksActionsProps) => {
  const { refetch } = useClerkListContext()
  const [clerksModalOpen, setClerksModalOpen] = useState<boolean>(false)
  const toggleClerksModal = () => setClerksModalOpen((open) => !open)

  const handleCloseClerkModal = () => {
    toggleClerksModal()
    refetch()
  }

  return (
    <>
      {clerksModalOpen && (
        <SendMaticModal isOpen={clerksModalOpen} onClose={handleCloseClerkModal} clerkAddress={clerkAddress} />
      )}
      <Styled.ActionsContainer>
        <ButtonOutlineNeutral type="button" onClick={toggleClerksModal} size="small">
          Add Tokens
        </ButtonOutlineNeutral>
        <Link to={routes.editEvents.replace(':eventId', eventId).replace(':partnerAddress', partnerAddress)}>
          <PenIcon />
        </Link>
      </Styled.ActionsContainer>
    </>
  )
}
