import * as React from 'react'
export const Thrash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Icons" viewBox="0 0 26 26" {...props} width={22} height={22}>
    <path
      d="M13 0h-2a3 3 0 0 0-3 3v1H2a1 1 0 0 0 0 2h1v14a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4V6h1a1 1 0 0 0 0-2h-6V3a3 3 0 0 0-3-3Zm-3 3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4Zm9 17a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h14Z"
      fill="currentColor"
    />
    <path
      d="M12 9a1 1 0 0 0-1 1v8a1 1 0 0 0 2 0v-8a1 1 0 0 0-1-1ZM15 18a1 1 0 0 0 2 0v-8a1 1 0 0 0-2 0ZM8 9a1 1 0 0 0-1 1v8a1 1 0 0 0 2 0v-8a1 1 0 0 0-1-1Z"
      fill="currentColor"
    />
  </svg>
)
