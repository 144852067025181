import IconEdit from 'assets/icons/icon-edit.svg'
import { ReactComponent as IconPlus } from 'assets/icons/icon-plus.svg'
import IconTrash from 'assets/icons/icon-trash.svg'
import IconLeft from 'assets/images/svg/icon-chevronleft.svg'
import IconRight from 'assets/images/svg/icon-chevronright.svg'
import { ButtonPrimary } from 'components/Button'
import EmptySearchBox from 'components/EmptySearchBox'
import ModalSettingAttribute from 'components/Modal/ModalSettingAttribute'
import { PaginationBox } from 'components/Pagination'
import SearchBox from 'components/SearchBox'
import { useApi } from 'hooks/useApi'
import Pagination from 'rc-pagination'
import Table from 'rc-table'
import React, { useCallback, useMemo, useState } from 'react'
import { Minus } from 'react-feather'
import { useAppDispatch } from 'state/hooks'
import { settingAttributesApi, useGetAttributesQuery } from 'state/settingAttributes/slice'
import styled from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import ModalConfirmSetRedemption from '../../../../components/Modal/ModalConfirm'
import ModalError from '../../../../components/Modal/ModalError'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
const LIMIT = 8
const INIT_ROW = {
  collectionAddress: null,
  information: null,
  name: '',
  options: [],
  type: 'INPUT',
  placeholder: null,
  id: null,
}
const SettingsAttribute = () => {
  const [page, setPage] = useState<number>(1)
  const [term, setTerm] = useState<string>('')
  const [keyword] = useDebounce(term, 500)

  const [showModalAttribute, setShowModalAttribute] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [idSelected, setIdSelected] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const { data: dataSettings, refetch } = useGetAttributesQuery({
    page,
    keyword,
    limit: LIMIT,
  })
  const { totalItems, currentPage, totalPages } = dataSettings || { totalItems: 1, currentPage: 1, totalPages: 1 }
  const dispatch = useAppDispatch()
  const { deleteFnc } = useApi()

  const [selectedRow, setSelectedRow] = useState<any>(INIT_ROW)
  const onSearch = (term: string) => {
    setTerm(term)
  }
  const handleChangePage = (current: number) => {
    setPage(current)
  }

  const columns: any = useMemo(() => {
    const allColumns = [
      {
        title: (
          <div className={'th_id'}>
            <span>#</span>
          </div>
        ),
        dataIndex: 'index',
        key: 'index',
        width: 60,
        align: 'left',
        render(value: any, row: any, index: number) {
          return <span>{index + 1 + (currentPage - 1) * 8}</span>
        },
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: 120,
      },
      {
        title: 'DETAILS',
        dataIndex: 'options',
        key: 'options',
        width: 184,
        align: 'left',
        render(value: any) {
          return !value || value.length === 0 ? (
            <Minus />
          ) : (
            <div>
              {value.reduce((total: string, item: string) => {
                return total + `, ${item}`
              })}
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: 'ud',
        key: 'ud',
        minWidth: '52px',
        width: '52px',
        render(value: any, row: any, index: number) {
          return (
            <div>
              <button
                className={'edit'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  setSelectedRow(row)
                  setStatus('Edit')
                  setShowModalAttribute(true)
                }}
                style={{ marginRight: '6px' }}
              >
                <img src={IconEdit} alt={'img_edit'} />
              </button>

              <button
                className={'delete'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  setIdSelected(row.id)
                  setStatus('Delete')
                  setShowConfirm(true)
                }}
              >
                <img src={IconTrash} alt={'img_trash'} />
              </button>
            </div>
          )
        },
      },
    ]
    return allColumns
  }, [currentPage])
  const handleDeleteAttributes = useCallback(
    (totalItems: any, currentPage: any, totalPages: any, id: any) => {
      deleteFnc(
        {
          url: `${process.env.REACT_APP_SERVER_URL}admin-panel/attributes`,
          data: {
            id,
          },
          callback: () => {
            setShowSuccess(true)
            if (totalItems !== 1 && totalItems % LIMIT === 1 && currentPage === totalPages) {
              setPage(currentPage - 1)
            }
            dispatch(settingAttributesApi.util.invalidateTags(['SETTING_ATTRIBUTE']))
          },
          callbackFinally: () => {
            setShowConfirm(false)
          },
          errorCallback: (res) => {
            console.log(res)
            setShowError(true)
          },
        },
        true
      )
    },
    [deleteFnc, dispatch, setPage]
  )
  return (
    <Wrapper>
      <Title>Setting Attributes</Title>
      <TableSettings>
        <NameTable>Attributes List</NameTable>
        <SearchBoxWrap>
          <SearchBox onChange={onSearch} placeholder={'Search by name'} />
        </SearchBoxWrap>
        <TableDetail>
          <Table
            rowKey="name"
            columns={columns}
            data={dataSettings?.data}
            className={'rc-table--cs'}
            emptyText={<EmptySearchBox text={'No data'} />}
          />
        </TableDetail>
        <CtaAdd
          onClick={() => {
            setSelectedRow(INIT_ROW)
            setStatus('Create')
            setShowModalAttribute(true)
          }}
        >
          <span className={'icon-plus'}>
            <IconPlus />
          </span>
          Add attribute
        </CtaAdd>
        {totalItems > LIMIT && (
          <PaginationBox>
            <Pagination
              onChange={(current) => {
                handleChangePage(current)
              }}
              current={currentPage}
              pageSize={LIMIT}
              total={totalItems}
              prevIcon={() => (
                <SpanIcon>
                  <img src={IconLeft} alt={'icon_left'} />
                </SpanIcon>
              )}
              nextIcon={() => (
                <SpanIcon>
                  <img src={IconRight} alt={'icon_right'} />
                </SpanIcon>
              )}
            />
          </PaginationBox>
        )}
      </TableSettings>
      {showModalAttribute && (
        <ModalSettingAttribute
          isOpen={showModalAttribute}
          onDismiss={() => setShowModalAttribute(false)}
          dataInit={selectedRow}
          handleSuccess={() => {
            setShowSuccess(true)
          }}
          handleError={() => setShowError(true)}
          status={status}
          refetch={refetch}
        />
      )}
      {showConfirm && (
        <ModalConfirmSetRedemption
          isOpen={showConfirm}
          onDismiss={() => setShowConfirm(false)}
          submit={() => handleDeleteAttributes(totalItems, currentPage, totalPages, idSelected)}
          subText={'Are you sure you want to delete attributes?'}
        />
      )}
      <ModalError isOpen={showError} onDismiss={() => setShowError(false)} message={`${status} Attributes Failed`} />
      <ModalSuccess
        isOpen={showSuccess}
        onDismiss={() => setShowSuccess(false)}
        message={`${status} Attributes Successfully.`}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const Title = styled.h3`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`
const TableSettings = styled.div``
const TableDetail = styled.div`
  margin-bottom: 32px;
  position: relative;
  padding-right: 24px;
`
const NameTable = styled.h5`
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary7};
  margin: 0 0 12px 0;
`
const SearchBoxWrap = styled.div`
  margin-bottom: 32px;
`
const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`
const CtaAdd = styled(ButtonPrimary)`
  padding: 10px 16px !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600 !important;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  .icon-plus {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    align-self: flex-start;
    margin-top: 2px;
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: ${({ theme }) => theme.secondary1};
      }
    }
  }
`
export default SettingsAttribute
