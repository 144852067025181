import { Role } from 'constants/roles'
import React, { ReactNode } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { useGetProfile } from '../../state/profile/hooks'
import { RestrictedComponent } from '../RestrictedComponent'
import * as Styled from './styled'

export interface SidebarMenuSubItem {
  label: string
  url: string
}

export interface SideBarMenuItem {
  url: string
  label: string
  icon: ReactNode
  permittedRoles: Role[]
  subItems?: SidebarMenuSubItem[]
}

interface SidebarAdminProps {
  items: SideBarMenuItem[]
  title: string
}

export const SidebarMenu = ({ items, title }: SidebarAdminProps) => {
  const { data: profile } = useGetProfile()
  const location = useLocation()

  return (
    <Styled.SidebarAdminMain>
      <Styled.SidebarHeaderWrapper>
        <Styled.SidebarWelcomeText> Welcome to Another 1 launchpad</Styled.SidebarWelcomeText>
        <Styled.SidebarHeader> {title} </Styled.SidebarHeader>
      </Styled.SidebarHeaderWrapper>
      <Styled.SidebarMenuItemList>
        {items.map((item: SideBarMenuItem) => {
          const isMatch = matchPath(location.pathname, {
            path: item.url,
          })
          return (
            <RestrictedComponent key={item.label} permittedRoles={item.permittedRoles} userRoles={profile.roles}>
              <Styled.SidebarMenuItem to={item.url} activeClassName="selected">
                <Styled.SidebarMenuItemIcon>{item.icon}</Styled.SidebarMenuItemIcon>
                <Styled.ItemPanelName>{item.label}</Styled.ItemPanelName>
              </Styled.SidebarMenuItem>
              {isMatch && item.subItems && (
                <div>
                  {item.subItems.map((subItem) => {
                    return (
                      <Styled.SidebarMenuSubItem to={subItem.url} key={subItem.label} activeClassName="selected" exact>
                        {subItem.label}
                      </Styled.SidebarMenuSubItem>
                    )
                  })}
                </div>
              )}
            </RestrictedComponent>
          )
        })}
      </Styled.SidebarMenuItemList>
    </Styled.SidebarAdminMain>
  )
}
