import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ButtonSecondary } from '../../../../components/Button'
import { FormInput } from '../../../../components/Forms'
import { useGetImage } from '../../../../state/profile/hooks'
import { OptionsAdd, SectionEdit } from '../../../../types'
import { sliceUrl } from '../../../../utils'
import Editor from '../Editor'
import FAQEdit from '../FAQEdit'
import MediaUpload from '../MediaUpload'
import MultipleItem from '../MultipleItem'
import SectionPreview from '../SectionPreview'

const EditBox = (props: any) => {
  const { section, order, updateSections, sections, active, setActiveSection, setSections } = props

  const getImage = useGetImage()
  const { type, link } = section
  const [text, setText] = useState<string>()
  const [mediaFiles, setMediaFile] = useState<any>()
  const [listFaq, setListFaq] = useState<{ [key: string]: string }[]>([
    {
      title: '',
      description: '',
    },
  ])

  const [multipleItem, setMultipleItem] = useState<{ [key: string]: string }[]>([
    {
      link: '',
      description: '',
    },
  ])

  const [titleMultiItem, setTitleMultiItem] = useState<string>('')

  useEffect(() => {
    if (type === OptionsAdd.FAQ) {
      if (!section.items) {
        return
      }
      setListFaq(section.items)
    }

    if (type === OptionsAdd.MULTIPLES_ITEM) {
      if (!section.items) {
        return
      }

      setMultipleItem(section.items)
    }

    if (section.text) {
      setText(section.text)
    }
  }, [section.items, type, section.text])

  const handleRemove = () => {
    sections.splice(order, 1)
    updateSections(sections)
  }

  const handleFetchMedia = async (mediaFiles: any) => {
    let srcMediaCv

    if (mediaFiles) {
      const typeMediaCv = mediaFiles.type === 'video/quicktime' ? 'video/MOV' : mediaFiles.type
      const imageMediaCv = await getImage(mediaFiles.name, typeMediaCv)
      const { url, name } = imageMediaCv
      srcMediaCv = sliceUrl(url, name)
      if (srcMediaCv) {
        await fetch(url, {
          headers: {
            'Content-Type': mediaFiles.type,
            'Cache-Control': 'public,max-age=31536000,immutable',
            'x-amz-acl': 'public-read',
          },
          body: mediaFiles,
          method: 'PUT',
        })
      }
    }
    return srcMediaCv
  }

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    ;(async () => {
      event.preventDefault()

      if (section.create === 'new') {
        delete section.create
      }
      if (type === OptionsAdd.SUB_TITLE || type === OptionsAdd.FULL_ROW_TEXT) {
        sections[order] = {
          ...section,
          text: text ? text : '',
        }
      }
      if (section.type === OptionsAdd.FAQ) {
        sections[order] = {
          ...section,
          items: [...listFaq],
        }
      } else if (
        type === OptionsAdd.LEFT_SIDE_IMAGE ||
        type === OptionsAdd.RIGHT_SIDE_IMAGE ||
        type === OptionsAdd.FULL_ROW_IMAGE
      ) {
        const srcMediaCv = await handleFetchMedia(mediaFiles)

        if (type === OptionsAdd.FULL_ROW_IMAGE) {
          sections[order] = {
            ...section,
            link: srcMediaCv || link || '',
          }
        } else {
          sections[order] = {
            ...section,
            link: srcMediaCv || link || '',
            text: text ? text : '',
          }
        }
      }

      if (type === OptionsAdd.MULTIPLES_ITEM) {
        sections[order] = {
          ...section,
          items: multipleItem,
          title: titleMultiItem,
        }
      }

      updateSections([...sections])
    })()
  }
  const handleCancel = () => {
    setActiveSection(-1)
  }
  const handleOnEdit = () => {
    setActiveSection(order)
    const sectionsCv: SectionEdit[] = []
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].create !== 'new') {
        sectionsCv.push(sections[i])
      }
    }
    setSections([...sectionsCv])
  }
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
  }
  const ContentTypeSection = useCallback(() => {
    switch (type) {
      case OptionsAdd.FULL_ROW_IMAGE:
        return (
          <BoxMedia fullWidth={true}>
            <MediaUpload link={section.link} setMediaFile={setMediaFile} />
          </BoxMedia>
        )
      case OptionsAdd.FULL_ROW_TEXT:
        return (
          <Editor toolbarId={order} placeholder={'Enter content...'} setValueRichText={setText} value={text || ''} />
        )
      case OptionsAdd.RIGHT_SIDE_IMAGE:
        return (
          <>
            <Editor toolbarId={order} placeholder={'Enter content...'} setValueRichText={setText} value={text || ''} />
            <BoxMedia align={'right'}>
              <MediaUpload link={section.link} setMediaFile={setMediaFile} />
            </BoxMedia>
          </>
        )
      case OptionsAdd.LEFT_SIDE_IMAGE:
        return (
          <>
            <BoxMedia>
              <MediaUpload link={section.link} setMediaFile={setMediaFile} />
            </BoxMedia>
            <Editor toolbarId={order} placeholder={'Enter content...'} setValueRichText={setText} value={text || ''} />
          </>
        )
      case OptionsAdd.SUB_TITLE:
        return (
          <FormInput
            name={'subTitle'}
            type={'text'}
            placeholder={'Enter a subtitle'}
            onChange={handleTextChange}
            defaultValue={text}
          />
        )
      case OptionsAdd.FAQ:
        return <FAQEdit order={order} listFaq={[...listFaq]} setListFaq={setListFaq} />
      case OptionsAdd.MULTIPLES_ITEM:
        return (
          <MultipleItem
            order={order}
            multipleItem={multipleItem}
            setMultipleItem={setMultipleItem}
            handleFetchMedia={handleFetchMedia}
            setTitle={setTitleMultiItem}
            title={sections[order].title}
          />
        )
      default:
        return <></>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, order, mediaFiles, text, listFaq, section.link, multipleItem, sections])

  return (
    <>
      {active ? (
        <EditBoxWrapper>
          <EditBoxContent>{ContentTypeSection()}</EditBoxContent>
          <Actions>
            <CtaAction onClick={handleConfirm}>Confirm</CtaAction>
            <CtaAction onClick={handleCancel}>Cancel</CtaAction>
            <CtaAction onClick={handleRemove}>Remove</CtaAction>
          </Actions>
        </EditBoxWrapper>
      ) : (
        <SectionWrapper onClick={handleOnEdit}>
          <SectionPreview section={section} />
        </SectionWrapper>
      )}
    </>
  )
}
const EditBoxWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.secondary4};
  padding: 20px;
  display: flex;
  margin: 16px 0;
  align-items: center;
`
const EditBoxContent = styled.div`
  flex: 0 0 calc(100% - 120px);
  max-width: calc(100% - 120px);
  display: flex;
  align-items: center;
`
const Actions = styled.div`
  padding-left: 20px;
  flex: 0 0 120px;
  max-width: 120px;
`
const CtaAction = styled(ButtonSecondary)`
  padding: 10px !important;
  text-transform: none;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 16px;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`
const BoxMedia = styled.div<{ fullWidth?: boolean; align?: string }>`
  flex: 0 0 200px;
  height: 200px;
  max-width: 200px;
  margin-right: ${({ align }) => (align === 'right' ? 0 : '20px')};
  margin-left: ${({ align }) => (align === 'right' ? '20px' : 0)};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      flex: 0 0 100%;
      max-width: 100%;
      height: 140px;
    `}
`
const SectionWrapper = styled.div``
export default EditBox
