import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const searchApi = createApi({
  reducerPath: 'search',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  endpoints: (builder) => ({
    getSearchResults: builder.query({
      query: ({ searchQuery, account }: { searchQuery: string; account?: string | null }) => {
        const finalAccount = account || null
        return {
          url: `search/${finalAccount}`,
          params: { name: searchQuery },
        }
      },
    }),
  }),
})

export const { useGetSearchResultsQuery } = searchApi
