import { useQueryClient } from '@tanstack/react-query'
import { parseUnits } from 'ethers/lib/utils'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Loader from '../../../../components/Loader'
import { QueryKeys } from '../../../../constants/queryKeys'
import { routes } from '../../../../constants/routes'
import { useAnother1ERC20Decimals } from '../../../../hooks/useAnother1ERC20Decimals'
import { dateTimeObjectToSecondsTimestamp } from '../../../../utils/dateTimeObjectToSecondsTimestamp'
import { useUploadImage } from '../../../Upload/hooks/useUploadImage'
import { useEvent } from '../../hooks/useEvent'
import { useUpdateEvent } from '../../hooks/useUpdateEvent'
import { useUpdateEventInRegistry } from '../../hooks/useUpdateEventInRegistry'
import { EventForm, EventFormValues } from '../EventForm'
import { parseEventToFormValues } from './helpers'

interface EditEventUrlParams {
  eventId: string
  partnerAddress: string
}

interface EditEventFormProps {
  returnUrl?: string
}

export const EditEventForm = ({ returnUrl }: EditEventFormProps) => {
  const history = useHistory()
  const { eventId, partnerAddress } = useParams<EditEventUrlParams>()
  const { data: event, isLoading: isLoadingEvent } = useEvent(
    {
      partnerAddress: partnerAddress,
      eventId,
    },
    {
      enabled: Boolean(partnerAddress),
    }
  )
  const { mutateAsync: uploadImage, isLoading: isUploadingImage } = useUploadImage()
  const { mutateAsync: editEvent, isLoading: isEditingEvent } = useUpdateEvent()
  const { mutateAsync: editEventInRegistry, isLoading } = useUpdateEventInRegistry()
  const { data: decimals, isLoading: isLoadingDecimals } = useAnother1ERC20Decimals({
    refetchOnWindowFocus: false,
  })

  const queryClient = useQueryClient()

  const handleSubmit = async ({
    name,
    formattedAddress,
    startDate,
    endDate,
    rewardPerFlexer,
    tokenRewardPool,
    files,
    location,
    imageKey: defaultImageKey,
    isPOAPEnabled,
  }: EventFormValues) => {
    const parsedStartDate = dateTimeObjectToSecondsTimestamp(startDate)
    const parsedEndDate = dateTimeObjectToSecondsTimestamp(endDate)

    const parsedRewardPerFlexer = parseUnits(String(rewardPerFlexer), decimals as number).toString()
    const parsedTokenRewardPool = parseUnits(String(tokenRewardPool), decimals as number).toString()

    await editEventInRegistry({
      eventId: Number(eventId),
      startTime: parsedStartDate,
      endTime: parsedEndDate,
      rewardPerFlexer: parsedRewardPerFlexer,
      tokenRewardPool: parsedTokenRewardPool,
      isPOAPEnabled,
    })

    let imageKey = defaultImageKey

    if (files && files[0]) {
      imageKey = await uploadImage({
        file: files[0],
      })
    }

    await editEvent({
      data: {
        name,
        formattedAddress,
        imageKey,
        location,
        startTime: parsedStartDate,
        endTime: parsedEndDate,
        rewardPerFlexer: parsedRewardPerFlexer,
        tokenRewardPool: parsedTokenRewardPool,
        isPOAPEnabled,
        isCancelled: false,
      },
      eventId: Number(eventId),
      partnerAddress,
    })

    await queryClient.invalidateQueries([QueryKeys.Event])

    history.push(returnUrl || routes.partnerPanel.eventManagement)
  }

  const isSubmitting = isLoading || isEditingEvent || isUploadingImage

  if (isLoadingEvent || !event || isLoadingDecimals) {
    return <Loader size="48px" />
  }

  return (
    <EventForm
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      defaultValues={parseEventToFormValues(event, decimals as number)}
      submitText="Save changes"
    />
  )
}
