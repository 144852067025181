import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { PageWithSidePanel } from '../../../../components/PageWithSidePanel'
import { RestrictedComponent } from '../../../../components/RestrictedComponent'
import { Role } from '../../../../constants/roles'
import { routes } from '../../../../constants/routes'
import { OptionType } from '../../../../entities/OptionType'
// import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'
import { useInitChooseCollection } from '../../../../hooks/useInitChooseCollection'
import AssignAttributes from '../../../../pages/AdminPanel/CollectionsManagement/AssigningAttributes'
import EditCollection from '../../../../pages/AdminPanel/CollectionsManagement/EditCollection'
import SaleManagement from '../../../../pages/AdminPanel/CollectionsManagement/SaleManagement'
import FeatureCollection from '../../../../pages/AdminPanel/FeatureCollection'
import AssignNFC from '../../../../pages/AdminPanel/Phygital/AssignNFC'
import Redemption from '../../../../pages/AdminPanel/Phygital/Redemption'
import SettingsAttribute from '../../../../pages/AdminPanel/Phygital/SettingsAttribute'
import WhitelistManagement from '../../../../pages/AdminPanel/Phygital/WhitelistManagement'
import BlackList from '../../../../pages/AdminPanel/UsersManagement/BlackList'
import { useFetchProfile, useGetProfile } from '../../../../state/profile/hooks'
import { AdminPanelSideMenu } from '../../components/AdminPanelSideMenu'
import { PartnerEvents } from '../PartnerEvents'
import { RoleManagement } from '../RoleManagement'

export const Main = () => {
  const {
    collectionOptions,
    selectedCollection,
    setSelectedCollection,
    collectionDisplayed,
    isLoading,
    collectionOptionsHide,
    selectedCollectionHide,
    setSelectedCollectionHide,
  } = useInitChooseCollection()
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false)
  useDocumentTitle(`Admin Panel | Another-1 Launchpad`)
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const handleFetchProfile = useFetchProfile()
  const { data: profile } = useGetProfile()

  useEffect(() => {
    if (account) handleFetchProfile(account, null)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (
      collectionOptions?.length > 0 &&
      (profile?.roles[0]?.role == Role.COLLECTION_OPERATOR || profile?.roles[0]?.role == Role.FACTORY_WORKER)
    ) {
      const operableCollection = collectionOptions.find(
        (x: OptionType) => x.value == profile.roles[0].collectionAddress
      )

      if (operableCollection) {
        setSelectedCollection(operableCollection)
        setIsSelectDisabled(true)
      }
    }
  }, [profile, collectionOptions, setSelectedCollection])

  return (
    <PageWithSidePanel sidePanel={<AdminPanelSideMenu />}>
      <Switch>
        <Route path={routes.adminPanel.partnerEvents.index} component={PartnerEvents}>
          <RestrictedComponent userRoles={profile.roles} permittedRoles={[Role.ADMIN]} isPage>
            <PartnerEvents />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.collectionManagement.whitelists}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM, Role.COLLECTION_OPERATOR]}
            isPage
          >
            <WhitelistManagement
              collectionOptions={collectionOptions}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              isSelectDisabled={isSelectDisabled}
              isLoading={isLoading}
            />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.collectionManagement.editCollection}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM, Role.COLLECTION_OPERATOR]}
            isPage
          >
            <EditCollection
              collectionOptions={collectionOptions}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              isSelectDisabled={isSelectDisabled}
            />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.collectionManagement.saleManagement}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM, Role.COLLECTION_OPERATOR]}
            isPage
          >
            <SaleManagement
              collectionOptions={collectionOptions}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              isSelectDisabled={isSelectDisabled}
            />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.collectionManagement.saleManagement}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM, Role.COLLECTION_OPERATOR]}
            isPage
          >
            <SaleManagement
              collectionOptions={collectionOptions}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              isSelectDisabled={isSelectDisabled}
            />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.collectionManagement.index}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM, Role.COLLECTION_OPERATOR]}
            isPage
          >
            <AssignAttributes
              collectionOptions={collectionOptions}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              isSelectDisabled={isSelectDisabled}
            />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.phygital.redemption}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.FACTORY_WORKER, Role.ADMIN, Role.MANAGEMENT_TEAM]}
            isPage
          >
            <Redemption
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              isSelectDisabled={isSelectDisabled}
            />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.phygital.index}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.FACTORY_WORKER, Role.ADMIN, Role.MANAGEMENT_TEAM]}
            isPage
          >
            <AssignNFC
              collectionOptions={collectionOptions}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              isSelectDisabled={isSelectDisabled}
            />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.phygital.settingAttributes}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.FACTORY_WORKER, Role.ADMIN, Role.MANAGEMENT_TEAM]}
            isPage
          >
            <SettingsAttribute />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.userManagement.blacklist}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM]}
            isPage
          >
            <BlackList />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.userManagement.index}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM]}
            isPage
          >
            <RoleManagement />
          </RestrictedComponent>
        </Route>
        <Route path={routes.adminPanel.featuredCollections}>
          <RestrictedComponent
            userRoles={profile.roles}
            permittedRoles={[Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM]}
            isPage
          >
            <FeatureCollection
              collectionDisplayed={collectionDisplayed}
              isLoading={isLoading}
              collectionOptionsHide={collectionOptionsHide}
              selectedCollectionHide={selectedCollectionHide}
              setSelectedCollectionHide={setSelectedCollectionHide}
            />
          </RestrictedComponent>
        </Route>
        <Redirect to={routes.adminPanel.featuredCollections} />
      </Switch>
    </PageWithSidePanel>
  )
}
