import styled from 'styled-components/macro'

export const AlertBox = styled.div`
  text-align: center;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.red4};
  background-color: ${({ theme }) => theme.red5};
  margin-bottom: 24px;
`
