import styled from 'styled-components/macro'

export const RewardBoxButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.primary6};
  background-color: transparent;
  border: none;
`
