import { useQueryClient } from '@tanstack/react-query'
import { ButtonTransparent } from 'components/Button'
import { QueryKeys } from 'constants/queryKeys'
import { IGetAllowedCollections } from 'modules/Collections/hooks/useGetAllowedCollections'
import { useRemoveAllowedCollectionFromEvent } from 'modules/Collections/hooks/useRemoveAllowedCollectionFromEvent'
import { useRemoveAllowedCollectionFromEventInRegistry } from 'modules/Collections/hooks/useRemoveAllowedCollectionFromEventInRegistry'
import { useState } from 'react'

import IconClose from '../../../../assets/images/svg/icon-close.svg'
import IconPortal from '../../../../components/IconPortal'
import Loader from '../../../../components/Loader'
import { ContinueSkipButtons } from '../../../Events/components/ContinueSkipButtons'
import * as Styled from './styled'

interface CollectionsListProps {
  eventId: string
  eventCollections: IGetAllowedCollections[] | undefined
  isLoading: boolean
  partnerAddress: string
  continueSkipRoute?: string
  areButtonsDisabled?: boolean
}

export const CollectionsList = ({
  eventId,
  eventCollections,
  isLoading,
  partnerAddress,
  continueSkipRoute,
  areButtonsDisabled,
}: CollectionsListProps) => {
  const queryClient = useQueryClient()
  const { mutateAsync: removeAllowedCollection } = useRemoveAllowedCollectionFromEvent()
  const { mutateAsync: removeAllowedCollectionInRegistry } = useRemoveAllowedCollectionFromEventInRegistry()

  const [removedCollection, setRemovedCollection] = useState<string | null>(null)

  const handleRemoveAllowedCollection = async (collectionAddress: string) => {
    if (removedCollection) return

    try {
      setRemovedCollection(collectionAddress)

      await removeAllowedCollectionInRegistry({
        eventId,
        collectionAddresses: [collectionAddress],
      })

      await removeAllowedCollection({
        eventId,
        partnerAddress,
        collectionAddress,
      })

      await queryClient.invalidateQueries([QueryKeys.GetAllowedCollections])
    } catch (e) {
      // TODO: Handle error
      console.error(e)
    } finally {
      setRemovedCollection(null)
    }
  }

  if (isLoading) {
    return (
      <Styled.CollectionsListContainer>
        <Styled.CollectionsListHeader> Collections List </Styled.CollectionsListHeader>
        <Loader size="24px" />
      </Styled.CollectionsListContainer>
    )
  }

  if (!eventCollections || !eventCollections.length) {
    return (
      <>
        <Styled.CollectionsListContainer>
          <Styled.CollectionsListHeader> Collections List </Styled.CollectionsListHeader>
          <p> Empty </p>
        </Styled.CollectionsListContainer>
        {continueSkipRoute && (
          <ContinueSkipButtons canContinue={false} areButtonsDisabled={areButtonsDisabled} route={continueSkipRoute} />
        )}
      </>
    )
  }

  return (
    <>
      <Styled.CollectionsListContainer>
        <Styled.CollectionsListHeader> Collections List </Styled.CollectionsListHeader>
        {eventCollections.map(({ collectionAddress }, index) => (
          <Styled.CollectionListItem key={collectionAddress}>
            <div>
              {index + 1}. {collectionAddress}
            </div>
            {removedCollection === collectionAddress ? (
              <Loader stroke="#ffffff" size="14px" style={{ margin: 6 }} />
            ) : (
              <ButtonTransparent
                disabled={!!removedCollection}
                onClick={() => handleRemoveAllowedCollection(collectionAddress)}
              >
                <IconPortal SrcImageIcon={IconClose} widthIcon={'14px'} heightIcon={'14px'} />
              </ButtonTransparent>
            )}
          </Styled.CollectionListItem>
        ))}
      </Styled.CollectionsListContainer>
      {continueSkipRoute && (
        <ContinueSkipButtons
          canContinue={Boolean(eventCollections.length)}
          route={continueSkipRoute}
          areButtonsDisabled={areButtonsDisabled}
        />
      )}
    </>
  )
}
