import axios from 'axios'
import { AutoColumn } from 'components/Column'
import { authMap } from 'components/WalletModal/authMap'
import { ParticleMethods } from 'components/WalletModal/styled'
import { SUPPORTED_WALLETS } from 'constants/wallet'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import useParticle from 'hooks/useParticle'
import usePrevious from 'hooks/usePrevious'
import { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'

import { CHAIN_INFO } from '../../constants/chainInfo'
import { getChainId } from '../../constants/chains'
import { useAppDispatch } from '../../state/hooks'
import { useFetchProfile } from '../../state/profile/hooks'
import { disconnectAccount } from '../../state/profile/reducer'
import AccountDetails from '../AccountDetails'
import Option from './Option'

const WalletModalOth = () => {
  const dispatch = useAppDispatch()
  const { account, provider, chainId, connectWallet } = useParticle()
  const previousAccount = usePrevious(account)
  const defaultChain = getChainId(process.env.REACT_APP_CHAIN_ID)
  const defaultChainInfo = CHAIN_INFO[defaultChain]
  const handleFetchProfile = useFetchProfile()
  const isValidChain = useMemo(() => !Boolean(chainId) || chainId === defaultChain, [chainId])
  const windowVisible = useIsWindowVisible()

  const handleAuthenticate = async () => {
    if (!account) {
      return
    }

    const getChallengeURL = process.env.REACT_APP_SERVER_URL + `auth/${account}`
    await axios
      .get(getChallengeURL, { responseType: 'json' })
      .then(async (challenge) => {
        const signer = provider?.getSigner()
        await signer
          ?.signMessage(`${challenge.data.purpose}${challenge.data.challenge}`)
          .then(async (signature: any) => {
            if (signature) {
              const resVerificationURL =
                process.env.REACT_APP_SERVER_URL + `auth/${challenge.data.challenge}/${signature}`
              await axios.get(resVerificationURL, { responseType: 'json' }).then(async (resVerification) => {
                if (resVerification.status === 200) {
                  const { token } = resVerification.data
                  localStorage.setItem('authToken', token)
                  //isNewUser create cookies
                  await handleFetchProfile(account)
                } else {
                  alert('Signature not verified')
                  dispatch(disconnectAccount())
                }
              })
            }
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              dispatch(disconnectAccount())
            }
            console.log("there's an error signing", e)
          })
      })
      .catch((err) => {
        console.log('auth error', err)
      })
  }

  useEffect(() => {
    if (!account) return
    if (
      isValidChain &&
      provider &&
      !localStorage.getItem('authToken') &&
      previousAccount !== account &&
      windowVisible
    ) {
      handleAuthenticate()
    }
  }, [account])

  const connect = useCallback(
    (walletName: string, id: string, options?: any) => {
      localStorage.setItem('WALLET_NAME', walletName)
      connectWallet?.(id, options)
    },
    [connectWallet]
  )

  const disconnectedContent = useMemo(
    () => (
      <div>
        <ContentWrapper>
          <AutoColumn>
            <OptionGrid>
              {Object.keys(SUPPORTED_WALLETS).map((key) => {
                const option = SUPPORTED_WALLETS[key]
                return (
                  <Option
                    id={`connect-${key}`}
                    onClick={() => connect(option.name, option.connector)}
                    key={key}
                    color={option.color}
                    link={option.href}
                    header={option.name}
                    subheader={null}
                    icon={option.iconURL}
                  />
                  // )
                )
              })}
            </OptionGrid>
          </AutoColumn>
        </ContentWrapper>

        <ParticleMethods>
          {Object.keys(authMap).map((authType) => (
            <img
              key={authType}
              src={authMap[authType]}
              alt=""
              onClick={() =>
                connect(`Particle-${authType}`, 'particle', {
                  preferredAuthType: authType.toLowerCase(),
                })
              }
            />
          ))}
        </ParticleMethods>
        <div>
          <InfoBox style={{ textAlign: 'left' }}>
            By connecting your wallet you agree to our <a href="/">Terms of Service</a> and{' '}
            <a href="/">Privacy Policy</a>
          </InfoBox>
        </div>
      </div>
    ),
    [connect]
  )

  const invalidChain = useMemo(
    () => (
      <div>
        <HeaderRow>Wrong Network</HeaderRow>
        <ContentWrapper>
          <h5>Connect to {defaultChainInfo.label} network by choosing it in the dropdown menu of your wallet.</h5>
        </ContentWrapper>
      </div>
    ),
    []
  )

  const content = useMemo(() => {
    let content = null
    if (provider && !isValidChain) {
      content = invalidChain
    } else if (provider && isValidChain) {
      content = <AccountDetails />
    } else {
      content = disconnectedContent
    }
    return content
  }, [provider, isValidChain, invalidChain, disconnectedContent])

  return (
    <>
      <div>{content}</div>
    </>
  )
}

export default WalletModalOth

const HeaderRow = styled.div`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Din Pro Regular', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 45px;
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  line-height: 1.6;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
  & h5 {
    color: ${({ theme }) => theme.secondary6};
  }
`

const InfoBox = styled.div`
  padding-top: 20px;
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: ${({ theme }) => theme.secondary6};
  a {
    color: ${({ theme }) => theme.primary6};
    cursor: pointer;
  }

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.white};
  }
`

const OptionGrid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 10px;
`
