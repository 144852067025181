import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ButtonOutlineNeutral } from '../../../../components/Button'
import { PenIcon } from '../../../../components/Icons/Pen'
import { routes } from '../../../../constants/routes'
import { CollectionsModal } from '../../../Collections/components/CollectionsModal'
import { useEventsListContext } from '../../../Events/context/EventListContext'
import * as Styled from './styled'

interface NoClerksTableActionsProps {
  eventId: string
  partnerAddress: string
}

export const NoCollectionsTableActions = ({ eventId, partnerAddress }: NoClerksTableActionsProps) => {
  const { refetch } = useEventsListContext()
  const [collectionsModalOpen, setCollectionsModalOpen] = useState<boolean>(false)
  const toggleCollectionsModal = () => setCollectionsModalOpen((open) => !open)

  const handleCloseCollectionModal = () => {
    toggleCollectionsModal()
    refetch()
  }

  return (
    <>
      {collectionsModalOpen && (
        <CollectionsModal isOpen={collectionsModalOpen} onClose={handleCloseCollectionModal} eventId={eventId} />
      )}
      <Styled.ActionsContainer>
        <ButtonOutlineNeutral type="button" onClick={toggleCollectionsModal} size="small">
          Add Collections
        </ButtonOutlineNeutral>
        <Link to={routes.editEvents.replace(':eventId', eventId).replace(':partnerAddress', partnerAddress)}>
          <PenIcon />
        </Link>
      </Styled.ActionsContainer>
    </>
  )
}
