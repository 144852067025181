import DiscordIcon from 'assets/icons/discord.svg'
import InstaIcon from 'assets/icons/insta.svg'
import MediumIcon from 'assets/icons/medium.svg'
import TwitterIcon from 'assets/icons/twitter.svg'
import React from 'react'
import styled from 'styled-components/macro'

const links = [
  { url: 'https://twitter.com/another1_io', icon: TwitterIcon },
  { url: 'https://medium.com/@another1_io', icon: MediumIcon },
  { url: 'https://www.instagram.com/another1_io/', icon: InstaIcon },
  { url: 'https://discord.com/invite/Hr5JrtGw9t', icon: DiscordIcon },
]

const Socials = () => {
  return (
    <Container>
      {links.map((link, index) => {
        return (
          <SocialLink href={link.url} key={index} target={'_blank'}>
            <SocialIcon src={link.icon} alt="Social" />
          </SocialLink>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const SocialLink = styled.a`
  padding: 10px;
  margin-right: 20px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in;
  img {
    vertical-align: middle;
  }
  &:hover {
    border: 2px solid ${({ theme }) => theme.primary6};
  }
`

const SocialIcon = styled.img``

export default Socials
