import axios from 'axios'

export const updateFollow = async (addressParam: string, account: string, post: any) => {
  return await post(
    {
      url: `${process.env.REACT_APP_SERVER_URL}users/follow`,
      data: {
        userAddress: addressParam,
        followerAddress: account,
      },
    },
    true
  )
}

export const updateLike = async (idNFT: string, collectionAddress: string, post: any) => {
  return await post(
    {
      url: `${process.env.REACT_APP_SERVER_URL}assets/like`,
      data: {
        nft: idNFT,
        collection: collectionAddress,
      },
    },
    true
  )
}

export const getFollowers = async (addressUser: string | undefined | null, account: string | undefined | null) => {
  const convertAccount = account || null
  return await axios.get(`${process.env.REACT_APP_SERVER_URL}users/followers/${addressUser}/${convertAccount}`)
}

export const getFollowing = async (addressUser: string | undefined | null, account: string | undefined | null) => {
  const convertAccount = account || null
  return await axios.get(`${process.env.REACT_APP_SERVER_URL}users/following/${addressUser}/${convertAccount}`)
}

export const deleteAddress = async (collectionAddress: string, userAddress: string, deleteFnc: any, refetch: any) => {
  return await deleteFnc(
    {
      callback: () => {
        refetch()
      },
      url: `${process.env.REACT_APP_SERVER_URL}admin-panel/whitelist`,
      data: {
        collectionAddress,
        userAddress,
      },
    },
    true
  )
}
