import React from 'react'

import { Another1TokenBalance } from '../../components/Another1TokenBalance'
import { EventsTableNoClerk } from '../../components/EventsTableNoClerk'
import { EventsTableNoCollection } from '../../components/EventsTableNoCollection'
import { EventsTokenSummary } from '../../components/EventsTokenSummary'
import { LowBalanceClerkTable } from '../../components/LowBalanceClerkTable/LowBalanceClerkTable'
import { MaticBalance } from '../../components/MaticBalance'
import * as Styled from './styled'

export const Dashboard = () => {
  return (
    <Styled.Container>
      <Styled.SpaceBetweenContainer>
        <Another1TokenBalance />
        <MaticBalance />
      </Styled.SpaceBetweenContainer>
      <Styled.SpaceBetweenContainer>
        <EventsTokenSummary />
      </Styled.SpaceBetweenContainer>
      <EventsTableNoClerk />
      <LowBalanceClerkTable />
      <EventsTableNoCollection />
    </Styled.Container>
  )
}
