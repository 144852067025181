import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { useGetCollectionsQuery } from '../../state/collections/slice'
import CollectionDrop from './CollectionDrop'
import Community from './Community'
import FAQsLanding from './FAQsLanding'
import Hero from './Hero'
import Intro from './Intro'
const LandingPage = () => {
  const { data: collections, isLoading } = useGetCollectionsQuery({})
  useDocumentTitle(`ANOTHER-1. IMAGINE. FALL. FOR PHYGITAL CREATIVITY`)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <Wrapper>
      <Hero collections={collections} isLoading={isLoading} />
      <ContainerHero>
        <Intro />
        <SectionWrap>
          <CollectionDrop collections={collections} isLoading={isLoading} />
        </SectionWrap>
        <SectionWrap>
          <Community />
        </SectionWrap>
        <SectionWrap id="faqs">
          <FAQsLanding />
        </SectionWrap>
      </ContainerHero>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const ContainerHero = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-left: 160px;
  padding-right: 160px;
  @media (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`
const SectionWrap = styled.div`
  margin-top: 140px;
  @media (max-width: 1024px) {
    margin-top: 80px;
  }
`
export default LandingPage
