import 'react-tabs/style/react-tabs.css'

import { Tab, TabList } from 'react-tabs'
import styled from 'styled-components/macro'
export const TabListCs = styled(TabList)`
  display: flex;
  padding-left: 0;
  list-style: none;
  padding-bottom: 8px;
  position: relative;
  margin: 0 -22px;
  &::before {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 2px;
    width: calc(100% - 44px);
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.secondary4};
  }
  &[data-show='false'] {
    display: none;
  }
  @media (max-width: 768px) {
    margin: 0 -16px;
    &::before {
      bottom: 0;
      left: 16px;
      transform: none;
      width: calc(100vw - 32px);
    }
  }
  @media (max-width: 390px) {
    &::before {
      width: calc(110vw - 32px);
    }
  }
`

export const TabCs = styled(Tab)`
  cursor: pointer;
  font-size: 24px;
  line-height: 29px;
  font-family: 'Din Pro Bold', sans-serif;
  letter-spacing: -0.85px;
  text-transform: uppercase;
  padding: 0 22px;
  position: relative;
  outline: none;
  span {
    color: ${({ theme }) => theme.secondary8};
    opacity: 0.4;
  }
  &:not(:last-child):after {
    content: '';
    position: absolute;
    z-index: 1;
    right: -4px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.secondary8};
    border-radius: 50%;
    opacity: 0.2;
  }
  &.react-tabs__tab--selected {
    background-color: transparent;
    span {
      color: ${({ theme }) => theme.primary6};
      opacity: 1;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      height: 2px;
      width: calc(100% - 44px);
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.primary6};
    }
  }
  & .count_item {
    opacity: 0;
  }
  @media (max-width: 768px) {
    padding: 0 16px;
    &.react-tabs__tab--selected {
      &::before {
        bottom: -8px;
        width: calc(100% - 32px);
      }
    }
  }
  a {
    text-decoration: none;
  }
`
export const CountItem = styled.span`
  position: relative;
  font-family: 'Din Pro Bold', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: ${({ theme }) => theme.secondary8};
  opacity: 0.4;
  margin-left: 5px;
  top: -8px;
`
