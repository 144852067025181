import axios from 'axios'
import { useMemo } from 'react'

import { useBalance } from './useBalance'

export default function useExchangeRates() {
  const { balance } = useBalance()
  return useMemo(async () => {
    return (await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=ETH,USD,EUR`)).data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance])
}
