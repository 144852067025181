import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const InnerContainer = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
`
