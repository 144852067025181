import styled from 'styled-components/macro'

export const CollectionsListContainer = styled.div`
  margin-top: 40px;
`

export const CollectionsListHeader = styled.h4`
  margin: 0 0 12px 0;
  font-weight: 500;
`

export const CollectionListItem = styled.div`
  display: flex;
  justify-content: space-between;
`
