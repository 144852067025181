import { createSlice } from '@reduxjs/toolkit'

import { ProfileType } from '../../entities/Profile'

interface InitialiseType {
  data: ProfileType
  loading: boolean
  error: string | undefined
  loadingAvatar: boolean
  errorAvatar: string | undefined
}
const initialProfile: ProfileType = {
  address: '',
  name: '',
  description: '',
  email: '',
  twitter: '',
  instagram: '',
  websiteUrl: '',
  bgImageUrl: '',
  imageUrl: null,
  nameImage: '',
  roles: [],
  isCreator: null,
  numberCreator: '0',
  isBecomeCreator: null,
}
const initialState: InitialiseType = {
  data: initialProfile,
  loading: true,
  error: undefined,
  loadingAvatar: false,
  errorAvatar: undefined,
}
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileSuccess(state, action) {
      state.loading = false
      state.data = action.payload
    },
    getProfileError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    getAvatarRequest(state, action) {
      state.loading = action.payload
    },
    getAvatarSuccess(state, action) {
      state.data.imageUrl = action.payload.url
      state.data.nameImage = action.payload.name
    },
    getAvatarError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    disconnectAccount(state) {
      state.data = initialProfile
    },
  },
})

export const { getProfileSuccess, getProfileError, getAvatarSuccess, disconnectAccount } = profileSlice.actions
export default profileSlice.reducer
