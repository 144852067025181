import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import erc155abi from 'abis/erc1155.json'
import moment from 'moment'

import { Erc1155 } from '../abis/Erc1155'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    // throw Error(`Invalid 'address' parameter '${address}'.`)
    return 'Invalid address'
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// account is not optional
function getSigner(library: JsonRpcProvider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(library: JsonRpcProvider, account?: string): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContractWithoutSigner(address: string, ABI: any, library: JsonRpcProvider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, library)
}

// account is optional
export function getContract(address: string, ABI: any, library: JsonRpcProvider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function getERC1155Contract(library: JsonRpcProvider, account?: string): Erc1155 {
  if (library && account) {
    try {
      return getContract(process.env.REACT_APP_NTR1_ERC1155_ADDRESS ?? '', erc155abi, library, account) as Erc1155
    } catch (e) {
      console.log(e)
      throw e
    }
  } else {
    throw new Error('Library or contract are null')
  }
}

//slice url
export function sliceUrl(url: string, name: string) {
  return url.split(name)[0].concat(name)
}
export function countWords(str: string) {
  return str.trim().split(/\s+/).length
}

export function setOtherZone(date: Date | null | undefined) {
  if (!date) return new Date()
  const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
  const otherZone = moment.tz(date, 'UTC').format('Z')
  const dateWithOtherZone = [dateWithoutZone, otherZone].join('')

  return new Date(dateWithOtherZone)
}

export function setLocalZone(date: Date | null | undefined) {
  if (!date) return new Date()
  const dateWithoutZone = moment.tz(date, 'UTC').format('YYYY-MM-DDTHH:mm:ss.SSS')
  const localZone = moment(dateWithoutZone).format('Z')
  const dateWithLocalZone = [dateWithoutZone, localZone].join('')

  return new Date(dateWithLocalZone)
}

export function checkUrl(url: string) {
  return url.includes('//') ? url : '//' + url
}
