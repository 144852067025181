import React from 'react'

import { Collection } from '../../../../components/Icons/Collection'
import { EventIcon } from '../../../../components/Icons/Event'
import { Phygital } from '../../../../components/Icons/Phygital'
import { Star } from '../../../../components/Icons/Star'
import { User } from '../../../../components/Icons/User'
import { SidebarMenu, SideBarMenuItem } from '../../../../components/SidebarMenu'
import { Role } from '../../../../constants/roles'
import { routes } from '../../../../constants/routes'

const adminSidebarMenuItems: SideBarMenuItem[] = [
  {
    label: 'Featured collection',
    url: routes.adminPanel.featuredCollections,
    icon: <Star />,
    permittedRoles: [Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM],
  },
  {
    label: 'Phygital',
    url: routes.adminPanel.phygital.index,
    icon: <Phygital />,
    permittedRoles: [Role.FACTORY_WORKER, Role.ADMIN, Role.MANAGEMENT_TEAM],
    subItems: [
      {
        label: 'Assigning NFC ids',
        url: routes.adminPanel.phygital.index,
      },
      {
        label: 'Set Redemption Window',
        url: routes.adminPanel.phygital.redemption,
      },
      {
        label: 'Settings Attributes',
        url: routes.adminPanel.phygital.settingAttributes,
      },
    ],
  },
  {
    label: 'Partner events',
    url: routes.adminPanel.partnerEvents.index,
    icon: <EventIcon />,
    permittedRoles: [Role.ADMIN],
  },
  {
    label: 'Users management',
    icon: <User />,
    url: routes.adminPanel.userManagement.index,
    permittedRoles: [Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM],
    subItems: [
      {
        label: 'Role and Permission',
        url: routes.adminPanel.userManagement.index,
      },
      {
        label: 'Blacklist',
        url: routes.adminPanel.userManagement.blacklist,
      },
    ],
  },
  {
    label: 'Collections management',
    icon: <Collection />,
    url: routes.adminPanel.collectionManagement.index,
    permittedRoles: [Role.CREATOR, Role.ADMIN, Role.MANAGEMENT_TEAM, Role.COLLECTION_OPERATOR],
    subItems: [
      {
        label: 'Assigning attributes',
        url: routes.adminPanel.collectionManagement.index,
      },
      {
        label: 'Sale Management',
        url: routes.adminPanel.collectionManagement.saleManagement,
      },
      {
        label: 'Edit Collection',
        url: routes.adminPanel.collectionManagement.editCollection,
      },
      {
        label: 'Whitelists',
        url: routes.adminPanel.collectionManagement.whitelists,
      },
    ],
  },
]

export const AdminPanelSideMenu = () => {
  return <SidebarMenu items={adminSidebarMenuItems} title="Admin Panel" />
}
