import AppleIcon from '../../assets/connectors/apple_icon.png'
import DiscordIcon from '../../assets/connectors/discord_icon.png'
import EmailIcon from '../../assets/connectors/email_icon.png'
import FacebookIcon from '../../assets/connectors/facebook_icon.png'
import GithubIcon from '../../assets/connectors/github_icon.png'
import GoogleIcon from '../../assets/connectors/google_icon.png'
import LinkedinIcon from '../../assets/connectors/linkedin_icon.png'
import MicrosoftIcon from '../../assets/connectors/microsoft_icon.png'
import PhoneIcon from '../../assets/connectors/phone_icon.png'
import TwitchIcon from '../../assets/connectors/twitch_icon.png'
import TwitterIcon from '../../assets/connectors/twitter_icon.png'

const authMap: Record<string, string> = {
  Email: EmailIcon,
  Phone: PhoneIcon,
  Twitter: TwitterIcon,
  Google: GoogleIcon,
  Apple: AppleIcon,
  Facebook: FacebookIcon,
  Discord: DiscordIcon,
  Github: GithubIcon,
  Linkedin: LinkedinIcon,
  Microsoft: MicrosoftIcon,
  Twitch: TwitchIcon,
}

export { authMap }
