import React from 'react'
import styled from 'styled-components/macro'

import { UserRoleForm } from '../../components/UserRoleForm'
import { UserRoleTable } from '../../components/UserRoleTable'

export const RoleManagement = () => {
  return (
    <Wrapper>
      <Container>
        <Main>
          <Title>Role & permission management</Title>
          <UserRoleForm />
          <UserRoleTable />
        </Main>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
const Container = styled.div`
  width: 100%;
`
const Main = styled.div`
  padding: 32px 0;
  td.secondary10 {
    color: ${({ theme }) => theme.secondary10};
  }
`
const Title = styled.h3`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`
