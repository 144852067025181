import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect } from 'react'
import { Box, Text } from 'rebass'
import styled from 'styled-components/macro'

const PrivacyPolicy = () => {
  useDocumentTitle(`Privacy Policy | Another-1 Launchpad`)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div>
      <Title py="4" textAlign={'center'}>
        Privacy Policy
      </Title>
      <Content>
        <p>
          This privacy policy (the “Privacy Policy”) describes how your personal information is collected, used, and
          shared when you visit another-1.io (the “Website”) or use any services offered by Another-1 Pte. Ltd.. (“AN1”,
          “Another-1”, “we”, “us” or “our”).
        </p>
        <p>
          By accessing or using the Website, you consent to the information collection and data handling practices
          outlined in this Privacy Policy.
        </p>
        <p>
          Another-1 works with third-party applications that collect data, such as Discord for NFT holder verifications
          or giveaways. We also use necessary analytic tools, among which may include but not limited to Google
          Analytics, to help us understand who our Website visitors are and how they use the Website. You can read more
          about how Google uses your personal information here:{' '}
          <a target={'_blank'} href="https://www.google.com/intl/en/policies/privacy/" rel="noreferrer">
            {' '}
            https://www.google.com/intl/en/policies/privacy/.
          </a>{' '}
          You can also opt-out of Google Analytics here:{' '}
          <a target={'_blank'} href="https://tools.google.com/dlpage/gaoptout" rel="noreferrer">
            https://tools.google.com/dlpage/gaoptout.
          </a>
        </p>
        <p>
          1. The Data We Collect Location data: We collect precise or approximate location data from a user’s device if
          enabled by the user to do so (including GPS and WiFi data).
        </p>
        <p>
          Demographic data: We may collect demographic data about users. We may also receive demographic data about
          users from third parties.
        </p>
        <p>
          Transaction information: We collect transaction information related to the use of the Website, including the
          type of Web3 wallet connected, order details, Ethereum address, and date and time the purchase was made. You
          understand that the Ethereum network is a public blockchain and all of your transaction history initiated
          through the Website will be made public.
        </p>
        <p>
          Usage data: We collect data about how users interact with our Website. This includes data such as access dates
          and times, features or pages viewed, app crashes and other system activity, type of browser and third-party
          sites or services used before interacting with our services. In some cases, we collect this data through
          cookies, pixels, tags, and similar tracking technologies that create and maintain unique identifiers.
        </p>
        <p>
          Device data: We may collect data about the devices used to access our Website, including the hardware models,
          device IP address, operating systems and versions, software, preferred languages, unique device identifiers,
          device motion data, and mobile network data.
        </p>
        <p>2. How We Use the Data Collected To enhance the safety and security of our users and services:</p>
        <p>
          Perform internal operations necessary to provide our services, including to troubleshoot software bugs and
          operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usage and
          activity trends. For research and development:
        </p>
        <p>
          We may use the data we collect for testing, research, analysis, product development and machine learning to
          improve the user experience. This helps us to improve and enhance the safety and security of our services,
          improve our ability to prevent the use of our services for illegal or improper purposes, develop new features
          and products and facilitate insurance and finance solutions in connection with our services.
        </p>
        <p>
          3. Data Retention Another-1 retains transaction and other personal data for our records, unless the user
          requests deletion.
        </p>
        <b>California Privacy Rights</b>
        <p>
          Under the California Consumer Protection Act of 2018 (“CCPA”), a California consumer has the following special
          rights:
        </p>
        <p>
          right to request that the business disclose what personal information it collects, uses, discloses, and sells;
          right to request the deletion of their personal information collected by the business; and the right, at any
          time, to direct a business that sells personal information about the consumer to third parties not to sell the
          consumer’s personal information (“right to opt-out”).
        </p>
        <p>
          California residents may request copies of the data collected about them, that their personal information
          collected byAnother-1 be deleted or to exercise their right of opt out, California residents may make a
          request at contact@another-1.io.
        </p>
        <b>European Privacy Rights</b>
        <p>
          For residents of the European Economic Area (EEA), you have the right to access personal information we hold
          about you and to ask that your personal information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us at contact@another-1.io.
        </p>
        <p>
          Additionally, if you are a EEA resident, we note that we are processing your information in order to fulfill
          contracts we might have with you (for example if you make an order through the Website), or otherwise to
          pursue our legitimate business interests. Please note that your information will be transferred outside of
          Europe, including to the United States.
        </p>
        <p>
          4. Minors Another-1 and our services are not directed toward children, and we don’t knowingly collect personal
          information from children under the age of 13. If we find out that a child under 13 has given us personal
          information, we will take steps to delete that information. If you believe that a child under the age of 13
          has given us personal information, please contact us.
        </p>
        <p>
          5. Changes We may update this privacy policy from time to time in order to reflect, for example, changes to
          our practices or for other operational, legal or regulatory reasons, and will post any changes on the Website
          as soon as they go into effect.
        </p>
        <p>
          6. Definitions “Personal Information” is information that can be used to identify or contact you, such as your
          contact details, including your name, e-mail address and any other information that you provide in the course
          of requesting access and using the Website, including any data about your use of the services, which may be
          collected automatically.
        </p>
        <p>
          “Order Details” is information provided by a user when making or attempting a purchase on the Website, that
          includes: Web3 wallet, Ethereum address, name, shoe size, e-mail address, and mailing address.
        </p>
      </Content>
    </div>
  )
}

export default PrivacyPolicy

const Title = styled(Text)`
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.display2};
  font-weight: 400;
  text-transform: uppercase;
`
const Content = styled(Box)`
  color: ${({ theme }) => theme.secondary7};
  max-width: 770px;
  max-height: 770px;
  line-height: 32px;
  overflow-y: auto;
  padding-right: 15px;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.secondary1};
    width: 12px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.secondary3};
    width: 12px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.secondary4};
  }
  font-size: ${({ theme }) => theme.fontSizeText1};
  a {
    color: ${({ theme }) => theme.secondary5};
    text-decoration: none;
  }
  @media (max-width: 768px) {
    max-width: 600px;
  }
  @media (max-width: 575px) {
    max-width: 280px;
    font-size: 16px;
    line-height: 28px;
  }
`
