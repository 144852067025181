import { useEffect, useState } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components/macro'

import { ButtonPrimary } from '../../../../components/Button'
import { FormGroup, FormLabel } from '../../../../components/Forms'
import { OptionType } from '../../../../entities/OptionType'
import { customStylesSelect } from '../../../../theme'

const EditCollection = (props: any) => {
  const { collectionOptions, selectedCollection, isSelectDisabled } = props
  const [selectedOption, setSelectedOption] = useState<OptionType>({
    value: '',
    label: '',
  })

  useEffect(() => {
    if (Object.keys(selectedCollection).length === 0 && selectedCollection.constructor === Object) {
      return
    }
    setSelectedOption(selectedCollection)
  }, [selectedCollection])

  return (
    <Wrapper>
      <Title>Edit Collection</Title>
      <FormGroup>
        <FormLabel>Collection</FormLabel>
        <FormSelect>
          {' '}
          {selectedCollection?.value &&
            ((isSelectDisabled && <h3>{selectedCollection.label}</h3>) || (
              <Select
                styles={customStylesSelect}
                defaultValue={selectedCollection}
                options={collectionOptions}
                classNamePrefix={'select-cs'}
                id="collectionsOption"
                name="collectionsOption"
                onChange={(value) => {
                  if (!value) {
                    return
                  }
                  setSelectedOption(value)
                }}
              />
            ))}
        </FormSelect>
      </FormGroup>
      {selectedCollection && selectedCollection.value && (
        <DivEdit>
          <LinkRouter to={`/edit-collection/${selectedOption.value}`}>Edit</LinkRouter>
        </DivEdit>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const Title = styled.h3`
  font-family: 'Din Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: ${({ theme }) => theme.primary10};
  margin: 0 0 32px 0;
`
const FormSelect = styled.div``
const DivEdit = styled(ButtonPrimary)`
  padding: 0 !important;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  text-transform: capitalize;
  font-family: 'Din Pro Regular', sans-serif;
  cursor: pointer;
  width: 100%;
  a {
    padding: 10px 12px;
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.secondary1};
    text-transform: uppercase;
    font-weight: 700 !important;
    width: 100%;
  }
`
export default EditCollection
