import EmptySearch from 'assets/icons/EmptySearch.svg'
import Fallback from 'assets/images/fallback.svg'
import Loader from 'components/Loader'
import useParticle from 'hooks/useParticle'
import React, { Fragment } from 'react'
import Highlighter from 'react-highlight-words'
import { useHistory } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import { Image } from 'rebass'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'

import { CollectionType } from '../../entities/Collection'
import { ProfileType } from '../../entities/Profile'
// import useActiveWeb3React from '../../hooks/useActiveWeb3React'

interface SearchSuggestionsProps {
  searchResults: {
    users: ProfileType[]
    collections: CollectionType[]
  }
  isLoading?: boolean
  close?: any
  searchQuery?: string
}

const SearchSuggestions = ({ searchResults, isLoading, close, searchQuery }: SearchSuggestionsProps) => {
  // const { account } = useActiveWeb3React()
  const { account } = useParticle()

  const history = useHistory()

  const styles = useSpring({
    from: { opacity: '0' },
    to: { opacity: '1' },
    config: { duration: 50 },
  })

  const handleClick = (slug: string) => {
    history.push(`/collection/${slug}`)
    close()
  }

  const handleClickUser = (address: string) => {
    if (address === account) {
      history.push(`/profile`)
    } else {
      history.push(`/user/${address}`)
    }
    close()
  }

  return (
    <AnimatedPopup isOpen={true} style={styles}>
      {isLoading ? (
        <ListItem className="loading">
          <Loader size={'24px'} />
        </ListItem>
      ) : (
        <Fragment>
          {searchResults.collections?.length < 1 && searchResults.users.length < 1 && (
            <ListItem className="empty-search">
              <img src={EmptySearch} alt={'No results match'} />
              <span>No results match the keywords</span>
            </ListItem>
          )}
          {searchResults.collections && searchResults.collections.length > 0 && (
            <ListGroup>
              <Header>Collections</Header>
              {searchResults.collections?.map((columnItem: any, itemIndex: number) => {
                return (
                  // <Tooltip
                  //   arrow={true}
                  //   interactive
                  //   arrowSize={'big'}
                  //   key={itemIndex}
                  //   position="left"
                  //   trigger="mouseenter"
                  //   hideDelay={0}
                  //   delay={0}
                  //   theme={'dark'}
                  //   distance={420}
                  //   html={<CollectionTooltip collection={columnItem} />}
                  // >
                  <ListItem key={itemIndex} onClick={() => handleClick(columnItem.slug)}>
                    <div>
                      <Img
                        src={columnItem?.media?.image ? columnItem.media.image : Fallback}
                        onError={(e) => (e.currentTarget.src = Fallback)}
                      />
                      <Text>
                        <Title
                          highlightClassName="highlight"
                          searchWords={[searchQuery]}
                          autoEscape={true}
                          textToHighlight={columnItem.name}
                        />
                        <Sub className="username">{columnItem.creator}</Sub>
                      </Text>
                    </div>
                    <span className="status subtext">{columnItem.status}</span>
                  </ListItem>
                  // </Tooltip>
                )
              })}
            </ListGroup>
          )}
          {searchResults.users && searchResults.users.length > 0 && (
            <ListGroup>
              <Header>users</Header>
              {searchResults.users.map((user: any, itemIndex: number) => {
                return (
                  // <Tooltip
                  //   arrow={true}
                  //   interactive
                  //   arrowSize={'big'}
                  //   key={itemIndex}
                  //   position="left"
                  //   trigger="mouseenter"
                  //   hideDelay={0}
                  //   delay={0}
                  //   theme={'dark'}
                  //   distance={420}
                  //   html={<UserTooltip user={user} account={account} />}
                  // >
                  <ListItem key={itemIndex} onClick={() => handleClickUser(user.address)}>
                    <div>
                      <Img src={user.imageUrl ? user.imageUrl : Fallback} className={'img-user'} />
                      <Text>
                        {/* <Title>{user.name}</Title> */}
                        <Title
                          highlightClassName="highlight"
                          searchWords={[searchQuery]}
                          autoEscape={true}
                          textToHighlight={user.name}
                        />
                        <Sub>{shortenAddress(user.address)}</Sub>
                      </Text>
                    </div>
                    {/*<span className="subtext">{user.numberFollowers} Followers</span>*/}
                  </ListItem>
                  // </Tooltip>
                )
              })}
            </ListGroup>
          )}
          {/* {(searchResults.collections?.length > 0 || searchResults.users.length > 0) && (
            <ButtonBox>
              <Button>All results</Button>
            </ButtonBox>
          )} */}
        </Fragment>
      )}
    </AnimatedPopup>
  )
}

export default SearchSuggestions

const Header = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.secondary7};
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  padding: 15px;
  cursor: default;
`

const ListGroup = styled.div``

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
  cursor: pointer;
  div {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  &:hover {
    background-color: ${({ theme }) => theme.secondary2};
  }

  &.loading {
    justify-content: center;
  }

  &.empty-search {
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 25px;
    color: ${({ theme }) => theme.secondary5};
    font-weight: 500;
    cursor: auto;
    :hover {
      background-color: transparent;
    }
  }

  span.subtext {
    color: ${({ theme }) => theme.secondary5};
  }
  span.status {
    text-transform: uppercase;
  }
  :hover span.subtext {
    color: ${({ theme }) => theme.primary5};
  }
`

const SuggestionsBox = styled.div<{ isOpen?: boolean }>`
  margin-top: 5px;
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.secondary1};
  z-index: 1;
  width: 100%;
  max-height: 800px;
  transition: all 0.3s ease-in;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.secondary3};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.secondary2};
  }
`

const Img = styled(Image)`
  width: 42px;
  &.img-user {
    width: 42px;
    height: 42px;
    border-radius: 100px;
  }
`

const Text = styled.div`
  display: block !important;
`

const Title = styled(Highlighter)`
  font-weight: 700;
  color: ${({ theme }) => theme.secondary5};

  mark {
    font-weight: 500 !important;
    color: ${({ theme }) => theme.white};
    background-color: transparent !important;
  }
`

const Sub = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.secondary5};
  text-transform: capitalize;

  &.username {
    color: ${({ theme }) => theme.primary5};
  }
`

// const ButtonBox = styled.div`
//   padding: 15px;
// `
// const Button = styled(ButtonOutline)`
//   color: ${({ theme }) => theme.white};
//   :hover,
//   :focus {
//     border: 2px solid ${({ theme }) => theme.secondary6};
//     background-color: transparent;
//   }
// `

const AnimatedPopup = animated(SuggestionsBox)
