import { OptionType } from 'entities/OptionType'
import React, { useMemo } from 'react'
import { StylesConfig } from 'react-select'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro'

import { Colors, FontSizes } from './styled'

type TextProps = Omit<TextPropsOriginal, 'css'>

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

function colors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base
    white,
    black,

    //primary colors
    primary1: '#00301F',
    primary2: '#004D31',
    primary3: '#006641',
    primary4: '#009962',
    primary5: '#00D98B',
    primary6: '#00FFA3',
    primary7: '#66FFC8',
    primary8: '#ADFFE1',
    primary9: '#E5FFF6',
    primary10: '#FFFFFF',

    // secondary colors
    secondary1: '#151515',
    secondary2: '#232323',
    secondary3: '#323232',
    secondary4: '#4F4F4F',
    secondary5: '#6D6D6D',
    secondary6: '#8A8A8A',
    secondary7: '#B9B9B9',
    secondary8: '#DCDCDC',
    secondary9: '#EEEEEE',
    secondary10: '#F6F6F6',
    secondary11: '#1B1B1B',

    // red
    red1: '#2a1a1a',
    red2: '#492222',
    red3: '#682A2A',
    red4: '#913434',
    red5: '#E44949',
    red6: '#e86464',

    // orange
    orange1: '#331901',
    orange2: '#663101',
    orange3: '#B25602',
    orange4: '#E56F02',
    orange5: '#FD9637',
    orange6: '#FEB069',

    // purple
    purple1: '#200741',
    purple2: '#360B6C',
    purple3: '#4C1098',
    purple4: '#6114C3',
    purple5: '#8535EA',
    purple6: '#A468EF',

    // blue
    blue1: '#191D47',
    blue2: '#1B2269',
    blue3: '#1D278B',
    blue4: '#212EBD',
    blue5: '#4753D6',
    blue6: '#6C77E4',
    blue7: '#2F3AAA',
    // other
    yellow1: '#F3B71E',
    textFollow: '#979797',
    trafficFollow: '#f6f6f6',
    textTab: '#E2E2E2',
    colorTransparent: 'rgba(255, 255, 255, 0)',
    textStatus: '#6C77E4',

    error: darkMode ? '#FD4040' : '#DF1F38',
    success: darkMode ? '#27AE60' : '#007D35',
    // red2: '#D70000',
    secondaryBlue5: '#6B9DFF',
    secondaryBlue4: '#4482FD',
    textOther: '#DBDFEA',
    textOther1: '#34E4B5',
    textOther2: '#E03A45',
    bgColorOther: '#24263A',
    bgLinearGradient:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(59.01% 242.73% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(122, 123, 123, 0.14) 0.01%, rgba(175, 175, 175, 0.022) 100%)',
  }
}
function fontSizes(): FontSizes {
  return {
    fontSizeText1: '20px',
    fontSizeText2: '16px',
    fontSizeText3: '14px',
    fontSizeText4: '12px',
    capsHeading1: '56px',
    capsHeading2: '36px',
    display1: '64px',
    display2: '48px',
    display3: '32px',
    display4: '80px',
  }
}
function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),
    ...fontSizes(),
    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const themeObject = useMemo(() => theme(true), [])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

/**
 * Preset styles of the Rebass Text component
 */
export const ThemedText = {
  Main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'secondary10'} {...props} />
  },
  Link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  Label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'secondary1'} {...props} />
  },
  Black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'secondary1'} {...props} />
  },
  White(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  Body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'secondary1'} {...props} />
  },
  LargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  MediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  SubHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  Small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  Blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  Yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  DarkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'secondary6'} {...props} />
  },
  Gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  Italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'secondary10'} {...props} />
  },
  Error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'secondary10'} {...props} />
  },
}

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.black} !important;
}

a {
 color: ${({ theme }) => theme.blue1}; 
}
.MailchimpStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tippy-popper[x-placement^=right] [x-arrow].arrow-big {
  border-right: 10px solid ${({ theme }) => theme.secondary1} !important;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -10px
}

.tippy-tooltip {
  background-color: ${({ theme }) => theme.secondary1}; 
  border-radius: 0;
  color: ${({ theme }) => theme.secondary5};
  font-family: 'Din Pro Regular', sans-serif;
  

  .title {
    color: white !important;
    font-size: 20px;
  }

  .timebox {
    color: ${({ theme }) => theme.primary5} !important;
  }
  
  .sub-title{
    color: ${({ theme }) => theme.primary5};
    font-size: 14px;
    text-align: left;
  }
  .address {
    color: ${({ theme }) => theme.secondary6};
    text-align: left;
    font-size: 14px;
  }

  b {
    color: ${({ theme }) => theme.white};
  }

  button {
    background-color: ${({ theme }) => theme.primary6};
    color: ${({ theme }) => theme.secondary1};
    padding: 10px;
  }
}

.tippy-popper[x-placement^=left] [x-arrow] {
  border-top: 7px solid #DBEEF5 !important;
}

.grow {
  transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
}

.shrink {
  transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(0.9);
  }
}
h1{
  font-size: 48px;
}
h2{
  font-size: 36px;
}
h3{
  font-size: 24px;
}
h4{
  font-size: 20px;
}
h5{
  font-size: 16px;
}
h6{
  font-size: 14px;
}
.rc-table{
  min-height: 370px;
  &.rc-sticky-cs{
    .rc-table-body{
      border: none !important;
      max-height: 400px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.secondary1};
        width: 8px;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.secondary3};
        width: 12px;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.secondary4};
      }

    }
  }
  .rc-table-sticky-holder{
    background-color: ${({ theme }) => theme.black};
    border: none !important;
  }
  &-thead{
    tr{
      th{
        border-bottom: 1px solid ${({ theme }) => theme.secondary3};
        border-right: none;
      }
    }
  }
  th,td{
    border: none;
    background-color: transparent !important;
    padding: 6px 12px;
  }
  &-content{
    border: none !important;
    border-radius: 0 !important;
  }
  th{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.primary10}; 
  }
  &-tbody{
    tr{
      td{
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSizeText3};
        line-height: 20px;
        color: ${({ theme }) => theme.secondary10};
        border:none;
      }
    }
  }
  .edit,.delete{
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;

    &[disabled]{
      cursor: not-allowed;
    }
    &:hover {
      &::after {
        display: block;
      }
    }
    &::after {
      display: none;
      content: 'Delete';
      position: absolute;
      top: -24px;
      left: 50%;
      width: max-content;
      min-width: 40px;
      font-family: "Din Pro Regular",sans-serif;
      font-size: ${({ theme }) => theme.fontSizeText3};
      color: ${({ theme }) => theme.secondary7};
      line-height: 1;
      padding: 4px 6px;
      background-color: ${({ theme }) => theme.secondary3};
      border-radius: 4px;
      transform: translateX(-50%);
    }
  }
  .delete {
    &::after {
      content: 'Delete';
    }
  }
  .edit{
    &::after {
      content: 'Edit';
    }
  }
}
.select-cs{
  &__multi-value{
    background-color: transparent !important;
    &__label{
      font-size: ${({ theme }) => theme.fontSizeText3} !important;;
      color: ${({ theme }) => theme.secondary10} !important;;
    }
  }
  &__placeholder{
    color: ${({ theme }) => theme.secondary5} !important;
  }
  &__menu-list--is-multi{
    .select-cs__option--is-selected{
      display: flex;
      &::after{
        content: '';
        display: block;
        position: relative;
        top: 6px;
        left: 6px;
        width: 6px;
        height: 9px;
        border: solid ${({ theme }) => theme.white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        margin-left: auto;
      }
    }
  }
}

.min-w50px{
  min-width: 50px;
}
.min-w80px{
  min-width: 80px;
}
.min-w100px{
  min-width: 100px;
}

.min-w130px{
  min-width: 130px;
}
.min-w150px{
  min-width: 150px;
}
.min-w180px{
  min-width: 180px;
}
.min-w200px{
  min-width: 130px;
}
.w50px{
  width: 50px;
}
.w80px{
  width: 80px;
}
.w100px{
  width: 100px;
}
.w130px{
  width: 130px;
}
.w150px{
  width: 150px;
}
.w180px{
  width: 180px;
}
.w200px{
  width: 200px;
}
.ql-font-1451-std{
  font-family: "DIN 1451 Std",sans-serif;
  line-height: 1;
}
.ql-font-regular{
  font-family: 'Din Pro Regular',sans-serif;
}
.ql-font-bold{
  font-family: 'Din Pro Bold',sans-serif;
}
@keyframes rotateBorder {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
  }
}

@keyframes translateVertical {
  0%{
   transform: translateY(0);
  }
  100%{
    transform: translateY(-10px);
  }
}
@keyframes progressBar {
  0%{
   width: 100%;
  }
  100%{
    width: 0;
  }
}
`
export const SectionContentWrapper = styled.div`
  max-width: 1440px;
  padding-left: 160px;
  padding-right: 160px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (max-width: 1024px) {
    padding: 0 64px;
  }

  @media (max-width: 768px) {
    padding: 0;
    margin-top: 30px !important;
  }
`
export const InputVerificationCodeAnother = styled.div`
  display: flex;
  justify-content: center;

  --ReactInputVerificationCode-itemWidth: 54px;
  --ReactInputVerificationCode-itemHeight: 64px;
  --ReactInputVerificationCode-itemSpacing: 20px;

  .ReactInputVerificationCode__item {
    font-size: ${({ theme }) => theme.display3};
    font-weight: 500;
    line-height: 1.25;
    color: ${({ theme }) => theme.secondary10};
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.secondary2};
    border-radius: 2px;
    box-shadow: none;
    border: 2px solid ${({ theme }) => theme.secondary2};
    :hover {
      border-color: ${({ theme }) => theme.white};
    }
    &.is-active,
    &.is-filled {
      box-shadow: none;
      color: ${({ theme }) => theme.primary5};
      border-color: currentColor;
      :hover {
        border-color: ${({ theme }) => theme.white};
      }
    }
  }
`
export const customStylesSelect: StylesConfig<OptionType | undefined, false> = {
  //select
  control: (base) => ({
    ...base,
    backgroundColor: '#232323',

    border: '2.5px solid #232323',
    borderColor: '#232323',
    borderRadius: '0',
    height: '51px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#4F4F4F',
    },
  }),
  container: (base) => ({
    ...base,
    color: white,
  }),
  input: (base) => ({
    ...base,
    color: '#B9B9B9 !important',
    fontSize: '16px',
    lineHeight: 1.5,
    // '&::placeholder': {
    //   color: 'transparent',
    //   fontWeight: '600',
    // },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  //value selected
  singleValue: (base) => ({
    ...base,
    color: '#ffffff !important',
    fontSize: '16px',
    lineHeight: '1.5',
  }),
  //dropdown menu
  menu: (base: any) => ({
    ...base,
    background: 'linear-gradient(180deg, #111111 0%, #1B1B1B 100%)',
    border: '2px solid #4F4F4F',
    borderRadius: 0,
    zIndex: 100,
  }),
  //option
  option: (base, state) => ({
    ...base,
    padding: '14px 20px',
    borderBottom: '1px solid #323232',
    backgroundColor: state.isFocused ? '#8A8A8A' : state.isSelected ? '#4F4F4F' : 'transparent',
    '&:hover': {
      backgroundColor: '#8A8A8A',
      color: '#F6F6F6',
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: 'white',
    margin: 0,
  }),
  menuList: (base) => ({
    ...base,
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#232323',
    },
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
}
