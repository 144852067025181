import styled from 'styled-components/macro'

export const FormHeader = styled.h3`
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin: 0 0 24px 0;
`

export const FormPageContainer = styled.div`
  width: 100%;
  padding: 30px 240px 60px 60px;
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  gap: 48px;
`

export const FormColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
`

export const FormColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 70%;
`
