import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as IconMinus } from '../../assets/images/svg/icon-minus.svg'
import { ReactComponent as IconPlus } from '../../assets/images/svg/icon-plus.svg'
import { FormInput } from '../Forms'

const TYPE_COUNT = {
  DECREASE: 'DECREASE',
  INCREASE: 'INCREASE',
}
const Index = (props: any) => {
  const { row, setChooseNFTConvert, indexCheck } = props
  const [count, setCount] = useState<string>(row.erc)
  useEffect(() => {
    setChooseNFTConvert((prevState: any[]) => {
      prevState.splice(indexCheck, 1, {
        ...row,
        convert: `${count}`,
      })
      return [...prevState]
    })
  }, [count, row, indexCheck, setChooseNFTConvert])
  const handleClickCount = (type: string) => {
    const countParse = parseInt(count)
    if (type === TYPE_COUNT.INCREASE) {
      if (row.erc > countParse) {
        setCount(`${countParse + 1}`)
      } else {
        setCount(row.erc)
      }
    } else {
      if (countParse > 1) {
        setCount(`${countParse - 1}`)
      } else {
        setCount('1')
      }
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const re = /^[0-9\b]+$/
    if (event.target.value.trim() === '' || re.test(event.target.value)) {
      setCount(event.target.value)
    }
  }
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (count === '') {
      setCount('1')
    }
  }
  return (
    <DivCount>
      <SpanIcon onClick={() => handleClickCount(TYPE_COUNT.DECREASE)} data-limit={count === '1' || count === ''}>
        <IconMinus />
      </SpanIcon>
      <FormNumber
        type={'text'}
        value={count}
        onChange={(event) => handleChange(event)}
        onBlur={(event) => handleBlur(event)}
      />
      <SpanIcon onClick={() => handleClickCount(TYPE_COUNT.INCREASE)} data-limit={count === row.erc || count === ''}>
        <IconPlus />
      </SpanIcon>
      <MaxValue
        onClick={() => {
          setCount(row.erc)
        }}
        data-max={count === row.erc}
      >
        Max
      </MaxValue>
    </DivCount>
  )
}
const DivCount = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.secondary2};
  padding: 2px;
  display: flex;
  height: 28px;
`
const SpanIcon = styled.span`
  background-color: ${({ theme }) => theme.black};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0 0 24px;

  &[data-limit='true'] {
    pointer-events: none;
    svg {
      path {
        fill: ${({ theme }) => theme.secondary5};
      }
    }
  }
`
const FormNumber = styled(FormInput)`
  background-color: ${({ theme }) => theme.black};
  margin-left: 2px;
  margin-right: 2px;
  border: none;
  border-radius: 0;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 20px;
  padding: 2px;
  text-align: center;
`
const MaxValue = styled.span`
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 20px;
  padding: 2px 14px;
  flex: 0 0 58px;
  max-width: 58px;
  color: ${({ theme }) => theme.secondary6};
  text-transform: uppercase;
  cursor: pointer;
  &[data-max='true'] {
    color: ${({ theme }) => theme.primary6};
    pointer-events: none;
  }
`
export default Index
